import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WebSocketState } from './webSocket.interface';



const initialState: WebSocketState = {
  connected: false,
  messages: [],
};

const webSocketSlice = createSlice({
  name: 'webSocket',
  initialState,
  reducers: {
    webSocketConnected(state) {
      state.connected = true;
    },
    webSocketDisconnected(state) {
      state.connected = false;
    },
    messageReceived(state, action: PayloadAction<any>) {
      state.messages.push(action.payload);
    },
    resetMessages(state) {
      state.messages = [];
    }
  },
});

export const { webSocketConnected, webSocketDisconnected, messageReceived, resetMessages } = webSocketSlice.actions;

export default webSocketSlice.reducer;
