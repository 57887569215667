import { fetchWithToken, getHeaders, getParamsToURL } from 'src/utils/utils'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { IFilter } from 'src/redux/Dashboard/KPIForm/KPIForm.interface'

const BASE_URL = process.env.REACT_APP_API || 'NO_URL'

export const getChartKPI = createAsyncThunk(
  'Chart/getChartKPI',
  async (params: IFilter, { rejectWithValue }) => {
    try {
      const paramsString = getParamsToURL(params)
      const url = `${BASE_URL}/kpis/get-graphics?${paramsString}`
      const headers = getHeaders()

      const response = await fetchWithToken(url, {
        method: 'GET',
        headers,
      })

      const data = await response.json()
      if (!response.ok && response) {
        throw new Error(
          data.error.message || '-- A ocurrido un error inesperado --',
        )
      }
      return data.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)
