import React, { useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import AlertDialog from 'src/components/Dialog';
import { useAdminModule } from 'src/hooks/IAM/AdminModule/useAdminModule';
import { usePagination } from 'src/hooks/IAM/Components/Pagination/usePagination';
import { Column } from '../AdminModule.interface';
import useIsMounted from 'src/hooks/useIsMounted';

const TableModules = () => {
  const isMounted = useIsMounted();
  const {
    modulesWithoutFormat,
    modules,
    updatedModule,
    setUpdateModuleAdmin,
    updateModuleAdmin,
    removeModule,
    loadModules,
    loadModulesWithoutFormat,
  } = useAdminModule();

  const {
    currentPage,
    handlePageChange,
    handleRowsPerPageChange,
    rowsPerPage,
    rowsPerPageOptions,
    totalItems,
  } = usePagination();

  const [selectedFolders, setSelectedFolders] = useState<Record<number, any>>({});

  const columns: readonly Column[] = [
    { id: 'keyName', label: 'KeyName', minWidth: 170 },
    { id: 'path', label: 'path', minWidth: 100 },
    {
      id: 'Mover a',
      label: 'Mover a',
      minWidth: 170,
      align: 'left',
    },
    {
      id: 'actions',
      label: 'Acciones',
      minWidth: 170,
      align: 'right',
    },
  ];

  const handleFolderSelect = (rowIndex: number, newValue: any) => {
    setSelectedFolders((prev) => ({
      ...prev,
      [rowIndex]: newValue,
    }));
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align={column.align}
                  sx={{
                    width: column.minWidth,
                  }}
                >
                  <strong>{column.label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {modulesWithoutFormat?.map((row, rowIndex) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={rowIndex}
                  sx={{
                    width: '100%',
                  }}
                >
                  {columns.map((column, columnIndex) => {
                    if (column.id === 'actions') {
                      return (
                        <TableCell key={columnIndex} align={column.align}>
                          <Stack
                            direction="row"
                            gap={2}
                            justifyContent={'flex-end'}
                          >
                            {/* Editar módulo */}
                            <AlertDialog
                              title={`Editar modulo ${row.keyName}`}
                              buttonLabel={'Editar'}
                              disagreeLabel="Cancelar"
                              agreeLabel="Editar"
                              onOpen={() => setUpdateModuleAdmin(row)}
                              onAgree={async () => {
                                updateModuleAdmin(updatedModule);
                              }}
                              onDisagree={() =>
                                console.log('Modulo no editado')
                              }
                              children={
                                <form>
                                  <Box>
                                    <TextField
                                      sx={{ width: '100%', mt: 2 }}
                                      id="outlined-basic"
                                      label="Nombre nuevo modulo"
                                      variant="outlined"
                                      value={updatedModule?.keyName || ''}
                                      onChange={(e) =>
                                        setUpdateModuleAdmin({
                                          ...updatedModule,
                                          keyName: e.target.value,
                                        })
                                      }
                                      required
                                    />
                                  </Box>
                                </form>
                              }
                            />
                            {/* Eliminar módulo */}
                            <AlertDialog
                              buttonLabel={'Eliminar'}
                              color="error"
                              title={`¿Estás seguro de eliminar el modulo "${row.keyName}"?`}
                              disagreeLabel="Cancelar"
                              agreeLabel="Eliminar"
                              onOpen={() => setUpdateModuleAdmin(row)}
                              onAgree={() => {
                                removeModule(row.moduleId ?? 0);
                              }}
                              nameToDelete={row.keyName}
                              withConfirmation
                              onDisagree={() =>
                                console.log('Modulo no eliminado')
                              }
                              children={
                                <div>
                                  <p>
                                    Al eliminar este modulo, se eliminarán todos
                                    los submodulos asociados y sus respectivos
                                    permisos
                                  </p>
                                  <p>
                                    Escriba <strong>{row.keyName}</strong> para
                                    confirmar
                                  </p>
                                </div>
                              }
                            />
                          </Stack>
                        </TableCell>
                      );
                    }

                    if (column.id === 'Mover a') {
                      return (
                        <TableCell key={columnIndex} align={column.align}>
                          <Stack
                            direction={'row'}
                            alignItems={'center'}
                            gap={2}
                          >
                            <Autocomplete
                              fullWidth
                              id="select-module-table"
                              options={[
                                {
                                  keyName: 'Carpeta raiz',
                                  path: '',
                                },
                                ...modules,
                              ]}
                              onChange={(event, newValue) => {
                                handleFolderSelect(rowIndex, newValue);
                              }}
                              getOptionLabel={(option) => option.keyName}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="¿Mover a la carpeta raiz?"
                                  variant="outlined"
                                />
                              )}
                            />
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              disabled={!selectedFolders[rowIndex]}
                              onClick={() => {
                                console.log(
                                  'Mover modulo',
                                  row.keyName,
                                  'a',
                                  selectedFolders[rowIndex]
                                  ,
                                );
                                updateModuleAdmin({
                                  ...row,
                                  parentPath: selectedFolders[rowIndex].path,
                                });
                              }}
                            >
                              Mover
                            </Button>
                          </Stack>
                        </TableCell>
                      );
                    }

                    return (
                      <TableCell key={columnIndex} align={column.align}>
                        {column.id === 'keyName' && <span>{row.keyName}</span>}
                        {column.id === 'path' && <span>{row.path}</span>}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={modulesWithoutFormat.length + 1}
        rowsPerPage={rowsPerPage}
        page={currentPage - 1}
        onPageChange={(event, newPage) => {
          handlePageChange(event, newPage + 1);

          loadModules({
            currentPage: newPage + 1,
            pageSize: rowsPerPage,
          });
          loadModulesWithoutFormat({
            currentPage: newPage + 1,
            pageSize: rowsPerPage,
          });
        }}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Paper>
  );
};

export default TableModules;
