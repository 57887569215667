/**
 * This file contains the definition of the routes with their corresponding components.
 * Each route is represented by an object with a `path` and a `component`.
 * The `path` represents the URL path for the route, and the `component` represents the React component to render for that route.
 *
 * @module App
 * @category App
 * @subcategory Views
 *
 * @example
 * ```ts
 * // Route: /contabilidad
 * // This route displays the main dashboard menu for the accounting module.
 * { path: '/contabilidad', component: <DashboardMenu /> }
 *
 * // Route: /keypago
 * // This route shows the main dashboard menu for the Keypago module.
 * { path: '/keypago', component: <DashboardMenu /> }
 *
 * // Route: /keypago/users
 * // Displays the list of users in the Keypago module.
 * { path: '/keypago/users', component: <UserView /> }
 *
 * // Route: /keypago/users/credit-info-view
 * // This route shows credit information of a specific user.
 * { path: '/keypago/users/credit-info-view', component: <CreditInfoView /> }
 *
 * // Route: /perfil
 * // Opens the user profile view.
 * { path: '/perfil', component: <Profile /> }
 *
 * // Route: /iam
 * // Shows the IAM (Identity and Access Management) dashboard.
 * { path: '/iam', component: <DashboardMenu /> }
 *
 * // Route: /iam/administrar-usuarios
 * // Allows the admin to manage users within the IAM module.
 * { path: '/iam/administrar-usuarios', component: <AdminUser /> }
 *
 * // Route: /iam/administrar-modulos
 * // Admin can manage the modules in the IAM system.
 * { path: '/iam/administrar-modulos', component: <AdminModule /> }
 *
 * // Route: /dashboard/kpi
 * // This route displays KPI (Key Performance Indicators) view.
 * { path: '/dashboard/kpi', component: <KPI /> }
 *
 * // Route: /dashboard/tesoreria
 * // Displays treasury-related data in the dashboard.
 * { path: '/dashboard/tesoreria', component: <Treasury /> }
 * ```
 */
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Login from './views/Login/LoginView'
import RecoveryPassword from './views/RecoveryPassword/RecoveryPasswordView'
import ForgotPassword from './views/ForgotPassword/ForgotPasswordView'
import ChangePassword from './views/ChangePassword/ChangePasswordView'
import DashboardMenu from 'src/components/DashboardMenu/DashboardMenu'
import Layout from './Layouts/Dashboard/Layout'
import PrivateRoute from 'src/components/PrivateRoute/PrivateRoute'
import { AuthProvider } from './contexts/AuthContext'
import { SelectedModulesProvider } from './contexts/IAM/ModuleContext'
import DebugZones from './views/DebugZones/DebugZones'
import Budgets from './views/Budgets/Budgets'
import Treasury from './views/Treasury/TreasuryView'
import KPI from './views/KPI/KPIView'
import FormsKPI from './views/FormsKPI/FormsKPIView'
import Profile from './views/Profile/ProfileView'
import Docs from './views/Docs/DocsView'
import SearchFiles from './views/SearchFilesSales/SearchFilesView'
import SearchFilesCollectionsView from './views/SearchFilesCollections/SearchFilesCollectionsView'
import AdminModule from './views/AdminModule/AdminModule'
import AdminGroups from './views/AdminGroups/AdminGroups'
import AdminPermissions from './views/AdminPermissions/AdminPermissions'
import AdminEndpoints from './views/AdminEndpoints/AdminEndpoints'
import AdminApps from './views/AdminApps/AdminApps'
import AdminUser from './views/AdminUser/AdminUser'
import UserView from './views/Onboarding/Keypago/User/UserView'
import { WebSocketProvider } from './contexts/WebSocketContext'
import React from 'react'
import { AlertProvider } from './contexts/AlertContext'
import Home from './views/Home'
import { ThemeProvider } from '@mui/material/styles'
import theme from './themeMUI'
import { CssBaseline } from '@mui/material'
import GlobalLoading from 'src/components/Loadings/GlobalLoading'
import IndicatorsView from './views/Indicators/IndicatorsView'
import EstrategyView from './views/Strategy/strategyView'
import QuestionView from './views/Questions/questionsView'
import UploadPhotoKeypago from './views/Onboarding/Keypago/UploadPhoto/UploadPhoto'
import AsociateQuestions from './views/AsociateQuestions/AsociateQuestions'
import CreditInfoView from './views/Onboarding/Keypago/User/CreditInfoView'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import ConfigOnboarding from './views/Onboarding/Keypago/ConfigOnboarding/ConfigOnboarding'
import ConfigModelView from './views/Onboarding/Keypago/ConfigModel/ConfigModelView'

/**
 * This file contains the definition of the routes with their corresponding components.
 * Each route is represented by an object with a `path` and a `component`.
 * The `path` represents the URL path for the route, and the `component` represents the React component to render for that route.
 */
const routesWithLayout = [
  { path: '/contabilidad', component: <DashboardMenu /> },
  { path: '/keypago', component: <DashboardMenu /> },
  { path: '/keypago/users', component: <UserView /> },
  { path: '/keypago/users/credit-info-view', component: <CreditInfoView /> },
  { path: '/keypago/config-onboarding', component: <ConfigOnboarding /> },
  { path: '/keypago/config-model', component: <ConfigModelView /> },
  {
    path: '/keypago/proceso-originacion',
    component: <DashboardMenu />,
  },
  {
    path: '/keypago/proceso-originacion/subir-fotos',
    component: <UploadPhotoKeypago />,
  },
  {
    path: '/keypago/proceso-originacion/formulario-usuario',
    component: <UserView />,
  },
  {
    path: '/keypago/proceso-originacion/codigo-otp',
    component: <UserView />,
  },
  { path: '/perfil', component: <Profile /> },
  { path: '/iam', component: <DashboardMenu /> },
  { path: '/iam/administrar-usuarios', component: <AdminUser /> },
  { path: '/iam/administrar-modulos', component: <AdminModule /> },
  { path: '/iam/administrar-aplicaciones', component: <AdminApps /> },
  { path: '/iam/administrar-grupos', component: <AdminGroups /> },
  { path: '/iam/administrar-permisos', component: <AdminPermissions /> },
  { path: '/iam/administrar-endpoints', component: <AdminEndpoints /> },
  { path: '/marketing', component: <DashboardMenu /> },
  { path: '/marketing/presupuestos', component: <Budgets /> },
  { path: '/marketing/depuracion-de-zonas', component: <DebugZones /> },
  { path: '/dashboard', component: <DashboardMenu /> },
  { path: '/dashboard/kpi', component: <KPI /> },
  { path: '/dashboard/formularios-kpi', component: <FormsKPI /> },
  { path: '/dashboard/tesoreria', component: <Treasury /> },
  { path: '/dashboard/indicadores', component: <IndicatorsView /> },
  { path: '/dashboard/estrategias', component: <EstrategyView /> },
  {
    path: '/dashboard/indicadores/:idIndicator',
    component: <AsociateQuestions />,
  },
  { path: '/dashboard/preguntas', component: <QuestionView /> },
  { path: '/contabilidad/subir-archivo', component: <Docs /> },
  { path: '/contabilidad/buscar-archivos-ventas', component: <SearchFiles /> },
  {
    path: '/contabilidad/buscar-archivos-recaudos',
    component: <SearchFilesCollectionsView />,
  },
]
function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalLoading />
        <AlertProvider>
          <SelectedModulesProvider>
            <AuthProvider>
              <WebSocketProvider>
                <Router>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <Layout>
                          <Home />
                        </Layout>
                      }
                    />
                    <Route path="/" element={<PrivateRoute />}>
                      {routesWithLayout.map((route, index) => (
                        // Rutas con Layout
                        <Route
                          key={index}
                          path={route.path}
                          element={<Layout>{route.component}</Layout>}
                        />
                      ))}
                    </Route>
                    {/* Rutas sin Layout */}
                    <Route path="/login" element={<Login />} />
                    <Route
                      path="/olvidaste-tu-contraseña"
                      element={<ForgotPassword />}
                    />
                    <Route
                      path="/recuperar-contraseña"
                      element={<RecoveryPassword />}
                    />
                    <Route
                      path="/cambiar-contraseña"
                      element={<ChangePassword />}
                    />
                    {/* Ruta no encontrada */}
                    <Route path="*" element={<h1>Not Found</h1>} />
                  </Routes>
                </Router>
              </WebSocketProvider>
            </AuthProvider>
          </SelectedModulesProvider>
        </AlertProvider>
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export default App
