export interface Auth {
  userDocId: string
  password: string
}
export interface AuthChangePassword {
  oldPassword?: string
  password: string
  confirmPassword: string
}
export enum StateAuth {
  VALIDATED = 'Validated',
  CHANGED_PASSWORD = 'ChangePassword',
}
export interface AuthResponse {
  success: boolean
  access_token: string | null
  refresh_token: string | null
  session_token: string
  state: StateAuth
}
