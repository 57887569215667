import { CheckBox } from '@mui/icons-material'
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Button,
  Pagination,
  Stack,
} from '@mui/material'
import React, { Fragment } from 'react'
import AlertDialog from 'src/components/Dialog'

const TableIndicator = () => {
  return (
    <div>
      <Fragment>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <CheckBox />
                  </TableCell>
                  <TableCell>Nombres</TableCell>
                  <TableCell>Descripción</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell align="right">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[1, 2, 3] ? (
                  [1, 2, 3, 4, 5, 6].map((user, index) => (
                    <TableRow key={index}>
                      <TableCell width={'100px'}>
                        <CheckBox />
                      </TableCell>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Meta</TableCell>
                      <TableCell>Alcance</TableCell>
                      <TableCell align="right" width={'200px'}>
                        <Stack
                          direction={'row'}
                          gap={1}
                          justifyContent={'flex-end'}
                        >
                          <Button color="secondary" variant="contained">
                            Editar
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      No hay usuarios
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Button
              variant="contained"
              color="error"
              sx={{
                m: 1,
              }}
            >
              eliminar (4)
            </Button>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={3}
              rowsPerPage={5}
              page={0}
              onPageChange={() => {}}
              onRowsPerPageChange={() => {}}
            />
          </Stack>
        </Paper>
      </Fragment>
    </div>
  )
}

export default TableIndicator
