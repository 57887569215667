/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import useIsMounted from 'src/hooks/useIsMounted'
import CustomChart from './components/CustomChart'
import { useChart } from 'src/hooks/Dashboard/Chart/useChart'
import { RootState } from 'src/redux'
import { useSelector } from 'react-redux'
const KPI = () => {
  const isMounted = useIsMounted()
  const { isLoading } = useSelector((state: RootState) => state.loading)
  const { charts, filters, filter, setFilterForm, loadFilters, loadCharts, resetFiltersKPI, resetChartsKPI } =
    useChart()
  useEffect(() => {
    if (!isMounted) return
    resetFiltersKPI()
    loadFilters()
    resetChartsKPI()
  }, [isMounted])
  return (
    <Container>
      {/* genera una iteracion de 5 elementos */}
      <Typography variant="h4" textAlign={'center'}>
        Tableros KPI
      </Typography>
      <Typography variant="h6" textAlign={'center'}>
        {/* current year - next year + quarer */}
        {new Date().getFullYear()} - {new Date().getFullYear() + 1}  - {filters
          ?.find((f) => f?.areaName === filter?.areaName)
          ?.periods?.find(
            (channel) => channel.periodId === filter.periodId,
          )
          ?.quarters?.find((q) => q.quarterId === filter.quarterId)?.quarterName ??
          null}
      </Typography>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          loadCharts()
        }}
      >
        <Stack direction={'row'} marginBlock={4} gap={2}>
          <Autocomplete
            sx={{
              width: '200px',
            }}
            inputValue={filter.areaName}
            clearIcon={null}
            options={filters ?? []}
            onChange={(event, newValue) => {
              setFilterForm({
                ...filter,
                areaName: newValue?.areaName ?? '',
              })
            }}
            getOptionLabel={(option) => option.areaName}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Area"
                placeholder="Selecciona un area"
              />
            )}
          />
          {filters?.find((f) => f.areaName === filter.areaName)?.channels
            ?.length ? (
            <Autocomplete
              sx={{
                width: '200px',
              }}
              clearIcon={null}
              value={
                filters
                  ?.find((f) => f?.areaName === filter?.areaName)
                  ?.channels?.find(
                    (channel) => channel.channelId === filter.channelId,
                  ) ?? null
              }
              options={
                filters?.find((f) => f.areaName === filter.areaName)
                  ?.channels ?? []
              }
              onChange={(event, newValue) => {
                setFilterForm({
                  ...filter,
                  channelId: newValue?.channelId ?? 0,
                })
              }}
              isOptionEqualToValue={(option, value) =>
                option.channelName === value.channelName
              }
              getOptionLabel={(option) => option.channelName}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Canal"
                  placeholder="Selecciona un canal"
                />
              )}
            />
          ) : null}
          <Autocomplete
            sx={{
              width: '200px',
            }}
            clearIcon={null}
            value={
              filters
                ?.find((f) => f?.areaName === filter?.areaName)
                ?.periods?.find(
                  (channel) => channel.periodId === filter.periodId,
                ) ?? null
            }
            options={
              filters?.find((f) => f.areaName === filter.areaName)?.periods ??
              []
            }
            onChange={(event, newValue) => {
              setFilterForm({
                ...filter,
                periodId: newValue?.periodId ?? 0,
              })
            }}
            isOptionEqualToValue={(option, value) =>
              option.periodName === value.periodName
            }
            getOptionLabel={(option) => option.periodName}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Periodo"
                placeholder="Selecciona un periodo"
              />
            )}
          />
          {/* quarters */}
          <Autocomplete
            sx={{
              width: '200px',
            }}
            clearIcon={null}
            value={
              filters
                ?.find((f) => f?.areaName === filter?.areaName)
                ?.periods?.find(
                  (channel) => channel.periodId === filter.periodId,
                )
                ?.quarters?.find((q) => q.quarterId === filter.quarterId) ??
              null
            }
            options={
              filters
                ?.find((f) => f.areaName === filter.areaName)
                ?.periods?.find((p) => p.periodId === filter.periodId)
                ?.quarters ?? []
            }
            onChange={(_, newValue) => {
              setFilterForm({
                ...filter,
                quarterId: newValue?.quarterId ?? 0,
              })
            }}
            isOptionEqualToValue={(option, value) =>
              option.quarterName === value.quarterName
            }
            getOptionLabel={(option) => option.quarterName}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Trimestre"
                placeholder="Selecciona un trimestre"
              />
            )}
          />
          {/* <Autocomplete
            sx={{
              width: '200px',
            }}
            clearIcon={null}
            options={mounths}
            getOptionLabel={(option) => option.monthName}
            value={
              mounths.find((m) => m.monthNumber === filter.monthNumber) ?? null
            }
            isOptionEqualToValue={(option, value) => {
              return option.monthNumber === value.monthNumber
            }}
            onChange={(event, newValue) => {
              setFilterForm({
                ...filter,
                monthNumber: newValue?.monthNumber ?? 0,
              })
            }}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Mes"
                placeholder="Selecciona un mes"
              />
            )}
          /> */}
          <Button variant="contained" color="primary" type="submit">
            <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" />
          </Button>
        </Stack>
      </form>
      <Stack gap={4} direction={'row'} flexWrap={'wrap'} rowGap={4}>
        <CustomChart chartData={charts ?? []} isLoading={isLoading} />
        {(!charts || charts.length <= 0) && !isLoading && (
          <Typography variant="h6" textAlign={'center'} width={'100%'}>
            No hay datos
          </Typography>
        )}
      </Stack>
    </Container>
  )
}

export default KPI
