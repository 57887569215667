import React from 'react'
import TableIndicator from './components/TableQuestions'
import FiltersIndicator from './components/FiltersQuestions'
import { Typography } from '@mui/material'

const EstrategyView = () => {
  return (
    <div>
      <Typography variant="h4" textAlign={'center'} gutterBottom>
        Preguntas
      </Typography>
      <FiltersIndicator />
      <TableIndicator />
    </div>
  )
}

export default EstrategyView
