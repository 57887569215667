/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect, ReactNode } from 'react'
import { routes } from 'src/components/DashboardMenu/Routes'
import {
  ILocalRoutes,
  IPermissions,
} from 'src/components/Sidebar/Sidebar.Interface'
import useIsMounted from 'src/hooks/useIsMounted'
import { getUserPermissions } from 'src/services/IAM/AdminPermissions/AdminPermissions.service'
import { useAlert } from './AlertContext'
import { profile } from 'src/services/User/user.service'
import { User } from 'src/services/User/user.interface'
import useLoading from 'src/hooks/useLoading'

export interface AuthContextType {
  isAuthenticated: boolean
  user: null | User
  userRoutes: ILocalRoutes[]
  setUserRoutes: (routes: ILocalRoutes[]) => void
  setIsAuthenticated: (authenticated: boolean) => void
  setUser: (user: AuthContextType['user']) => void
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [user, setUser] = useState<User | null>(null)
  const [userRoutes, setUserRoutes] = useState<ILocalRoutes[]>([])
  const { showAlert } = useAlert()
  const isMounted = useIsMounted()
  const { startLoading, stopLoading } = useLoading()

  function mergeModules(
    localRoutes: ILocalRoutes[],
    permissions: IPermissions[],
  ): ILocalRoutes[] {
    if (process.env.REACT_APP_ENV === 'dev') {
      return localRoutes
    } else {
      const mergedArray: ILocalRoutes[] = []
      if (permissions.length) {
        permissions?.forEach((mod1) => {
          const mod2 = localRoutes.find((m) => m.keyName === mod1.keyName)

          if (mod2) {
            const newModule: ILocalRoutes = {
              keyName: mod1.keyName,
              moduleId: mod1.moduleId,
              subModules: [],
              icon: mod2.icon,
              title: mod2.title,
              route: mod2.route,
            }

            mod1?.subModules?.forEach((subMod1) => {
              const subMod2 = mod2?.subModules?.find(
                (sm) => sm.keyName === subMod1.keyName,
              )

              if (subMod2) {
                const newSubModule: ILocalRoutes = {
                  keyName: subMod1.keyName,
                  moduleId: subMod1.moduleId,
                  permission: subMod1.permission,
                  title: subMod2.title,
                  icon: subMod2.icon,
                  description: subMod2.description,
                  route: subMod2.route,
                  disabled: subMod2.disabled,
                }

                newModule?.subModules?.push(newSubModule)
              }
            })

            if (newModule) {
              mergedArray.push(newModule)
            }
          }
        })
      }
      return mergedArray

    }
    //THIS CODE ITS OK, DONT TOUCH IT
  }

  useEffect(() => {
    if (!isMounted) return
    if (isAuthenticated) {
      startLoading()
      const fetchPermissions = async () => {
        try {
          const permissions = await getUserPermissions()
          if (permissions) {
            setUserRoutes(mergeModules(routes, permissions))
            showAlert('success', 'Rutas cargadas  correctamente')
          }
          const user = await profile()
          setUser(user)
        } catch (error) {
          showAlert('error', 'Error al obtener los permisos')
        } finally {
          stopLoading()
          if (!isMounted && !isAuthenticated) {
            return
          }
        }
      }

      fetchPermissions()
    }
  }, [isAuthenticated, isMounted])

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken && accessToken !== 'null') {
      setIsAuthenticated(true)
      setUser(JSON.parse(localStorage.getItem('user') || '{}'))
    } else {
      setIsAuthenticated(false)
      setUser(null)
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        userRoutes,
        setUserRoutes,
        setIsAuthenticated,
        setUser,
        // getPermissions,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
