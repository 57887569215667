import './AdminGroup.scss'
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { Button, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import useIsMounted from 'src/hooks/useIsMounted'
import TableGroups from './components/TableGroups'
import { useAdminPermission } from 'src/hooks/IAM/AdminPermission/useAdminPermission'

import { useUserIAM } from 'src/hooks/IAM/AdminUser/useUserIAM'
import { useAdminGroup } from 'src/hooks/IAM/AdminGroups/useAdminGroups'
import CreateGroup from './components/CreateGroup'
import BoxWithShadow from 'src/components/BoxWithShadow'

const AdminGroup = () => {
  const { loadGroups, createGroupAdmin } = useAdminGroup()
  const isMounted = useIsMounted()
  const { group } = useAdminGroup()
  const { loadUsers } = useUserIAM()
  const { loadPermissions, selectedPermissions } = useAdminPermission()

  useEffect(() => {
    if (!isMounted) return
    loadUsers({})
    loadPermissions({
      currentPage: 1,
      pageSize: 100,
    })
    loadGroups({})
  }, [isMounted])

  return (
    <BoxWithShadow sx={{
      padding: 4
    }}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          createGroupAdmin({
            ...group,
            permissions: selectedPermissions,
          })
        }}
      >
        <Typography variant="h4" mb={2}>
          Crear nuevo grupo
        </Typography>
        <CreateGroup />
        <Button
          variant="contained"
          type="submit"
          sx={{
            width: '300px',
          }}
        >
          Crear
        </Button>
      </form>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        sx={{ marginBlock: '20px' }}
      >
        <h2>Todos los grupos</h2>
        <TextField
          id="outlined-basic"
          label="Buscar grupo"
          variant="outlined"
          size="small"
        />
      </Stack>
      {/** START TABLE GROUPS **/}
      <TableGroups />
      {/** END TABLE GROUPS **/}
    </BoxWithShadow>
  )
}

export default AdminGroup
