/* eslint-disable react-hooks/exhaustive-deps */

import styles from './DashboardMenu.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from 'src/contexts/AuthContext'
import { useContext, useEffect, useState } from 'react'
import React from 'react'
import { Stack } from '@mui/material'
import { ILocalRoutes } from '../Sidebar/Sidebar.Interface'
import useIsMounted from 'src/hooks/useIsMounted'

/**
 * Component for rendering the dashboard menu.
 *
 * @remarks
 * The `DashboardMenu` component is used to display a menu of navigable cards. Each card represents a submodule or route.
 * Users can click on a card to navigate to a different route or load submodules within the current route.
 *
 * The component dynamically updates based on the current URL path and user routes.
 *
 * @component
 * @example
 * ```tsx
 * <DashboardMenu />
 * ```
 *
 * @returns {JSX.Element} The rendered dashboard menu component.
 */
const DashboardMenu = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const isMounted = useIsMounted()
  const { userRoutes } = useContext(AuthContext) // Retrieves the available routes from the authentication context
  const path = location.pathname.split('/').filter((item) => item)

  const [currentRoutes, setCurrentRoutes] = useState(() => {
    const initialRoute = userRoutes?.find(
      (route) =>
        route?.title?.toLocaleLowerCase() === path[0]?.toLocaleLowerCase(),
    )
    return initialRoute ? initialRoute.subModules : []
  })

  /**
   * Handles the card click event to navigate or load submodules.
   *
   * @param {ILocalRoutes} route - The route object that the user clicked.
   */
  const handleCardClick = (route: ILocalRoutes) => {
    if (route.subModules && route.subModules.length > 0) {
      setCurrentRoutes(route.subModules)
    } else {
      navigate(route.route)
    }
  }

  useEffect(() => {
    if (!isMounted) return

    const initialRoute = userRoutes?.find(
      (route) =>
        route?.title?.toLocaleLowerCase() === path[0]?.toLocaleLowerCase(),
    )
    setCurrentRoutes(initialRoute ? initialRoute.subModules : [])
  }, [isMounted, location, userRoutes])

  return (
    <>
      <div className={styles.containerCard}>
        {/* Renders the cards for the submodules */}
        {currentRoutes?.map((route) => (
          <div
            id={route.keyName}
            onClick={() => handleCardClick(route)}
            key={route.keyName}
            className={`${styles.card} ${route.disabled ? styles.disabled : ''}`}
          >
            <Stack direction="row" spacing={2} alignItems={'center'}>
              {route.icon && (
                <FontAwesomeIcon icon={route.icon} className={styles.icon} />
              )}
              <h3 className={styles.title}>{route.title}</h3>
            </Stack>
            <p className={styles.description}>{route.description}</p>
          </div>
        ))}
      </div>
    </>
  )
}

export default DashboardMenu
