import React, { useState, useEffect } from 'react'
import ExcelIcon from 'src/assets/images/Layout/sidebar/icons/ExcelIcon.svg'
import styles from './UploadFile.module.scss'

interface UploadFileProps {
  label: string
  onFileSelect: (file: File | null) => void
  selectedFile: File | null
}

/**
 * Componente de carga de archivos.
 * Permite al usuario seleccionar y subir archivos de Excel (.xls, .xlsx) y muestra el nombre del archivo seleccionado.
 *
 * @component
 * @example
 * ```jsx
 * <UploadFile
 *   label="Cargar documento"
 *   onFileSelect={(file) => console.log(file)}
 *   selectedFile={null}
 * />
 * ```
 * 
 * @param {string} props.label - Etiqueta que describe el archivo a cargar.
 * @param {function} props.onFileSelect - Función que se ejecuta cuando se selecciona un archivo. Recibe el archivo seleccionado o `null`.
 * @param {File | null} props.selectedFile - Archivo previamente seleccionado. Si no hay archivo, el valor es `null`.
 * @returns {JSX.Element} Componente de carga de archivos.
 */
const UploadFile: React.FC<UploadFileProps> = ({
  label,
  onFileSelect,
  selectedFile,
}) => {
  const [fileName, setFileName] = useState<string>('')

  /**
   * Efecto que actualiza el nombre del archivo cuando se selecciona uno nuevo.
   * Si no hay archivo seleccionado, el nombre se restablece.
   *
   * @param {File | null} selectedFile - Archivo actualmente seleccionado.
   */
  useEffect(() => {
    if (selectedFile) {
      setFileName(selectedFile.name)
    } else {
      setFileName('')
    }
  }, [selectedFile])

  /**
   * Maneja el evento de cambio en el input de archivo, llamando a la función `onFileSelect`.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - Evento de cambio en el input de archivo.
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0]
    onFileSelect(file)
  }

  return (
    <div className={`${styles.uploadFile}`}>
      <span className={styles.nameDoc}>{label}</span>
      <label
        className={`${styles.uploadButton} ${selectedFile ? styles.uploaded : ''}`}
      >
        <input
          value={''}
          type="file"
          onChange={handleChange}
          accept=".xls,.xlsx"
          style={{ display: 'none' }}
        />
        <span>{selectedFile ? 'Archivo subido' : 'Subir archivo'}</span>
        <img src={ExcelIcon} alt="Excel Icon" />
      </label>
      {fileName && <p className={styles.nameFile}>{fileName}</p>}
    </div>
  )
}

export default UploadFile
