/**
  @description Contiene las funciones para la autenticación de usuarios.
 */

import { getHeaders } from 'src/utils/utils'
import { Auth, AuthChangePassword, AuthResponse } from './auth.interface'
// Importando módulos necesarios.

const REACT_APP_API = process.env.REACT_APP_API || 'NO_URL'

export const login: ({
  userDocId,
  password,
}: Auth) => Promise<AuthResponse> = async ({ userDocId, password }: Auth) => {
  try {
    const formData = new FormData()
    formData.append('userDocId', userDocId)
    formData.append('password', password)
    formData.append('country', 'CO')
    formData.append('userDocType', 'CC')

    const response = await fetch(`${REACT_APP_API}/auth/login`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
      headers: {
        Authorization: '*',
      },
    })
    const data = await response.json()
    if (!response.ok && response) {
      throw new Error(
        data.error.message || '-- A ocurrido un error inesperado --',
      )
    }

    return data
  } catch (error) {
    console.error('Error en la autenticación', error)
    throw error
  }
}

export const changePassword: (
  payload: AuthChangePassword,
  isLogged?: boolean,
) => Promise<AuthResponse> = async (
  payload: AuthChangePassword,
  isLogged?: boolean,
) => {
    try {
      const response = await fetch(`${REACT_APP_API}/auth/change-password`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          ...getHeaders(),
          Authorization: `Bearer ${isLogged ? localStorage.getItem('accessToken') : localStorage.getItem('sessionToken')}`,
        },
      })
      console.log('isLogged', isLogged)

      const data = await response.json()
      if (!response.ok && response) {
        throw new Error(
          data.error.message || '-- A ocurrido un error inesperado --',
        )
      }

      return data
    } catch (error) {
      console.error('Error en la autenticación', error)
      throw error
    }
  }

export const forgotPassword: (
  userDocId: string,
) => Promise<AuthResponse> = async (userDocId: string) => {
  try {
    const response = await fetch(`${REACT_APP_API}/auth/forgot-password`, {
      method: 'POST',
      body: JSON.stringify({ userDocId, userDocType: 'Cc', country: 'Co' }),
      headers: {
        Authorization: '*',
        'Content-Type': 'application/json',
      },
    })
    const data = await response.json()

    if (!response.ok && response) {
      throw new Error(
        data.error.message || '-- A ocurrido un error inesperado --',
      )
    }

    return data
  } catch (error) {
    throw error
  }
}

export async function refreshToken(): Promise<boolean> {
  try {
    const response = await fetch(`${REACT_APP_API}/auth/refresh-token`, {
      method: 'POST',
      credentials: 'include', // para asegurar que las cookies se envíen
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });

    if (!response.ok) {
      throw new Error('Error refrescando el token');
    }

    const data = await response.json();
    localStorage.setItem('accessToken', data.access_token);

    return true;
  } catch (error) {
    return false;
  }
}

// export const logout = async () => {
//   try {
//     const response = await fetch(`${REACT_APP_API}/auth/logout`, {
//       method: 'POST',
//       credentials: 'include',
//       headers: {
//         ...getHeaders(),
//       },
//     })
//     localStorage.clear()
//     if (!response.ok) {
//       throw new Error('Network response was not ok')
//     }
//     return true
//   } catch (error) {
//     console.log('entro aqui')
//     console.error('Error en la autenticación', error)
//     throw error
//   }
// }
