// LoginForm.tsx

// Importaciones necesarias
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import useLoginForm from '../../hooks/useLoginForm'
import logo from '../../assets/images/logo.svg'
import styles from './ForgotPassword.module.scss'
import { forgotPassword } from '../../services/auth/auth.service'
import Loader from 'src/components/Loadings/GlobalLoading'
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import useLoading from 'src/hooks/useLoading'
import { useAlert } from 'src/contexts/AlertContext'

// Componente LoginForm
const Login = () => {
  const { isLoading, startLoading, stopLoading } = useLoading()
  const [open, setOpen] = useState(false)

  const [touched, setTouched] = useState(false) // Estado para rastrear interacciones
  // Obteniendo datos y funciones desde el hook y contexto
  const { formData, setFormData } = useLoginForm()
  const { showAlert } = useAlert()
  const handleRestorePassword = async () => {
    if (formData.cedula.length < 3) {
      setTouched(true)
      showAlert('error', 'Debes ingresar mínimo 5 caracteres')
      return
    }
    setTouched(false)
    try {
      startLoading()
      await forgotPassword(formData.cedula)
      showAlert(
        'success',
        'Se ha enviado un correo con las instrucciones para restablecer la contraseña',
      )
      setOpen(true)
    } catch (error) {
      showAlert('error', String(error))
    } finally {
      stopLoading()
    }
  }

  // Manejador de evento para cambio en campo cédula
  const handleCedulaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //agrega validaciones solo para cedula
    if (e.target.value.length > 10) {
      return
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      cedula: e.target.value,
    }))
  }
  // Renderizado del componente
  return (
    <div className={styles.containerLogin}>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {/* se envio el correo */}
          {'Recuperar contraseña'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Se ha enviado un correo con las instrucciones para restablecer la
            contraseña
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <div className={styles.form}>
        {isLoading && (
          <div className={styles.loadingContainer}>
            <Loader />
          </div>
        )}
        <img src={logo} alt="Logo" className={styles.logo} />
        {/* <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={['Colombia']}
          value={formData.country}
          fullWidth
          onChange={(event, newValue) => {
            setFormData({ ...formData, country: newValue ?? '' })
          }}
          renderInput={(params) => <TextField {...params} label="País" />}
        /> */}
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={['CC', 'CE', 'TI']}
          sx={{ mt: '1rem' }}
          value={formData.userDocType}
          fullWidth
          onChange={(event, newValue) => {
            setFormData({ ...formData, userDocType: newValue ?? '' })
          }}
          renderInput={(params) => (
            <TextField {...params} label="Tipo de documento" />
          )}
        />
        <TextField
          sx={{ mt: '1rem' }}
          id="outlined-basic"
          label="Cédula"
          variant="outlined"
          name="cedula"
          placeholder="Digite su cédula"
          InputLabelProps={{
            shrink: true,
          }}
          value={formData.cedula}
          onChange={handleCedulaChange}
          required
          fullWidth
        />
        {/* Mensaje de error se muestra solo si el formulario fue tocado */}
        <Typography
          variant="body2"
          sx={{ my: 1, color: 'red', textAlign: 'left', width: '100%' }}
        >
          {touched && formData.cedula && 'Debes ingresar mínimo 5 caracteres'}
        </Typography>
        <button
          onClick={handleRestorePassword}
          className={styles.button}
          disabled={isLoading}
        >
          <Typography variant="button">Recuperar contraseña</Typography>
        </button>
        <Link to={'/login'} style={{ marginTop: '16px' }}>
          Volver
        </Link>
      </div>
    </div>
  )
}

// Exportación del componente Login
export default Login
