// src/hooks/useKPIForm.ts
import { useDispatch, useSelector } from 'react-redux'
import { RootState, AppDispatch } from 'src/redux/index'
import {
  setKPIForm,
  setField,
  setFilter,
  resetFilters,
  resetKPIFormFields
} from 'src/redux/Dashboard/KPIForm/KPIFormSlice'
import {
  getFilters,
  updateAnswers,

} from 'src/services/KPI/KPIForm/KPIForm.service'
import { getQuestiosForm } from 'src/services/KPI/Questions/Questions.service'
import {
  IFilter,
  IKPIFormField,
} from 'src/redux/Dashboard/KPIForm/KPIForm.interface'
import { IParamsUpdateAnswers } from 'src/services/KPI/KPIForm/KPIForm.interface'
import { useAlert } from 'src/contexts/AlertContext'
import useLoading from 'src/hooks/useLoading'

export const useKPIForm = () => {
  const { showAlert } = useAlert()
  const { startLoading, stopLoading } = useLoading()
  const dispatch = useDispatch<AppDispatch>()
  const { kpiFormList, filters, filter } = useSelector(
    (state: RootState) => state.kpiForm,
  )

  const loadQuestions = () => {

    try {
      restarKPIFormFields()
      startLoading()
      dispatch(getQuestiosForm(filter))
        .then((result: any) => {
          stopLoading()
          if (getQuestiosForm.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) || 'Error al cargar las preguntas',
            )
          } else if (getQuestiosForm.fulfilled.match(result)) {
            showAlert('success', 'Preguntas cargadas correctamente')
          }
        })
        .finally(() => {
          stopLoading()
        })
      showAlert('success', 'Preguntas cargadas correctamente')
    } catch (error: any) {
      showAlert('error', error.message || 'Ocurrió un error inesperado')
    }
  }
  const loadFilters = (
    { includeGeneral }: { includeGeneral?: boolean } = {
      includeGeneral: false,
    },
  ) => {
    try {
      startLoading()
      dispatch(
        getFilters({
          includeGeneral,
        }),
      )
      showAlert('success', 'Filtros cargados correctamente')
    } catch (error: any) {
      showAlert('error', error.message || 'Ocurrió un error inesperado')
    } finally {
      stopLoading()
    }
  }

  const restarKPIFormFields = () => {
    dispatch(resetKPIFormFields())
  }

  const setFilterForm = (filters: IFilter) => {
    dispatch(setFilter(filters))
  }
  const setAnswers = (answers: IParamsUpdateAnswers[]) => {
    dispatch(updateAnswers(answers))
  }

  const setKPIFormState = (kpiForm: IKPIFormField) => {
    dispatch(setKPIForm(kpiForm))
  }

  const setFieldState = (field: Partial<IKPIFormField>) => {
    dispatch(setField(field))
  }
  const resetFiltersFields = () => {
    dispatch(resetFilters())
  }

  return {
    kpiFormList,
    filters,
    filter,
    setFilterForm,
    setAnswers,
    setKPIFormState,
    setFieldState,
    loadQuestions,
    loadFilters,
    resetFiltersFields,
    restarKPIFormFields
  }
}
