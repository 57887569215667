import { Stack, Typography } from '@mui/material'
import React from 'react'

/**
 * Footer component
 *
 * @remarks
 * This component renders a footer with a copyright notice and some basic information about Keypago.
 * The footer is styled using Material UI's `Stack` and `Typography` components, with a background color from the theme.
 *
 * @component
 * @example
 * ```tsx
 * <Footer />
 * ```
 *
 * @returns {JSX.Element} The rendered footer component.
 */
const Footer = () => {
  return (
    <div>
      <Stack bgcolor={'primary.main'} paddingBlock={2} textAlign={'center'}>
        <Typography variant="body2" color="white">
          Copyright © keypago 2023 | Todos los derechos reservados | Todas las
          imágenes registradas
        </Typography>
      </Stack>
    </div>
  )
}

export default Footer
