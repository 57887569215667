// src/hooks/useChart.ts
import { useDispatch, useSelector } from 'react-redux'
import { RootState, AppDispatch } from 'src/redux/index'
import { setFilter, resetFilters, resetCharts } from 'src/redux/Dashboard/Charts/ChartsSlice'
import { getFilters } from 'src/services/KPI/KPIForm/KPIForm.service'
import { IFilter } from 'src/redux/Dashboard/Charts/Charts.interface'
import { useAlert } from 'src/contexts/AlertContext'
import { getChartKPI } from 'src/services/KPI/Charts/Charts.service'
import useLoading from 'src/hooks/useLoading'

export const useChart = () => {
  const { showAlert } = useAlert()
  const { startLoading, stopLoading } = useLoading()
  const dispatch = useDispatch<AppDispatch>()
  const { filters, filter, charts } = useSelector(
    (state: RootState) => state.kpiChart,
  )
  const loadCharts = () => {
    try {
      startLoading()
      dispatch(getChartKPI(filter))
        .then((result: any) => {
          if (getChartKPI.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) || 'Error al cargar las Gráficas',
            )
          } else if (getChartKPI.fulfilled.match(result)) {
            showAlert('success', 'gráficas cargadas correctamente')
          }
        })
        .finally(() => {
          stopLoading()
        })
      showAlert('success', 'Gráficos cargados correctamente')
    } catch (error: any) {
      showAlert('error', error.message || 'Ocurrió un error inesperado')
    }
  }
  const loadFilters = () => {
    try {
      startLoading()
      dispatch(getFilters({})).finally(() => {
        stopLoading()
      })
      showAlert('success', 'Filtros cargados correctamente')
    } catch (error: any) {
      showAlert('error', error.message || 'Ocurrió un error inesperado')
    }
  }

  const setFilterForm = (filters: IFilter) => {
    dispatch(setFilter(filters))
  }

  const resetFiltersKPI = () => {
    dispatch(resetFilters())
  }

  const resetChartsKPI = () => {
    dispatch(resetCharts())
  }

  return {
    charts,
    filters,
    filter,
    loadCharts,
    setFilterForm,
    loadFilters,
    resetFiltersKPI,
    resetChartsKPI
  }
}
