import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import FlagColombiaIcon from 'src/assets/images/icons/flag-colombia-icon.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClock,
  faDollarSign,
  faDownload,
  faIdCard,
  faKey,
  faMailBulk,
  faUnlock,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import BoxWithShadow from 'src/components/BoxWithShadow'
import useUserkeypago from 'src/hooks/Onboarding/user/useUserkeypago'
import { format } from 'date-fns'
import { formatToCOP } from 'src/utils/utils'
import { Link, useNavigate } from 'react-router-dom'
import useTestModels from 'src/hooks/Onboarding/testModels/useTestModels'
import useIsMounted from 'src/hooks/useIsMounted'

const BoxPicture = ({
  children,
  sx,

  //add events here
}: {
  children: React.ReactNode
  sx?: React.CSSProperties
  //add events here
}) => {
  return (
    <Box
      //add events here

      sx={{
        display: 'flex',
        bgcolor: 'background.paper',
        minWidth: 250,
        borderRadius: 1,
        border: 1,
        borderColor: 'green',
        p: 1,
        alignItems: 'center',
        maxWidth: 250,
        position: 'relative',
        gap: 2,
        cursor: 'pointer',
        transition: 'all 0.3s',
        ':hover': {
          borderColor: 'primary.main',
          backgroundColor: 'rgba(0,0,0,0.1)',
          color: 'primary.main',
        },
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

const ViewUserkp = () => {
  const { getUserInfo, kpUser, downloadDocument } = useUserkeypago()
  const navigate = useNavigate()
  const [filters, setFilters] = useState({
    userDocId: '',
    country: 'CO',
    userDocType: 'CC',
  })

  return (
    <Container>
      <Stack
        direction={'row'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        gap={2}
        my={2}
      >
        <FormControl
          sx={{
            minWidth: 200,
          }}
        >
          <InputLabel id="demo-simple-select-label">
            Tipo de documento
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filters.userDocType}
            label="Tipo de documento"
            sx={{
              backgroundColor: 'white',
            }}
            onChange={(e) => {
              setFilters({
                ...filters,
                userDocType: e.target.value as string,
              })
            }}
          >
            <MenuItem value={'CC'}>Cédula de ciudadania</MenuItem>
            <MenuItem value={'CE'}>Cédula de extranjeria</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Número de documento"
          variant="outlined"
          type="number"
          sx={{
            backgroundColor: 'white',
            maxWidth: 200,
          }}
          value={filters.userDocId}
          onChange={(e) => {
            setFilters({
              ...filters,
              userDocId: e.target.value,
            })
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              getUserInfo(filters)
            }
          }}
        />
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => {
            getUserInfo(filters)
          }}
        >
          Buscar
        </Button>
      </Stack>
      <BoxWithShadow>
        {!kpUser ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 4,
              alignItems: 'center',
            }}
          >
            {/* <img src={DummySelfie} alt="Dummy Selfie" /> */}
            <Typography variant="h6">No se encontraron resultados</Typography>
            <Typography variant="body1">
              Realiza una búsqueda para ver la información de un usuario
            </Typography>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  background:
                    'linear-gradient(0deg, rgba(170,165,255,1) 42%, rgba(116,116,255,1) 67%, rgba(95,72,255,1) 100%)',
                  height: 200,
                  width: '100%',
                  borderRadius: '16px 16px 0 0',
                }}
              ></Box>
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '-70px',
                  left: '50px',
                  overflow: 'hidden',
                  width: '150px',
                  height: '150px',
                }}
              >
                <img
                  src={
                    kpUser?.files.find((item) => item.typeFile === 'selfie')
                      ?.filePath ??
                    'https://www.w3schools.com/howto/img_avatar.png'
                  }
                  alt="Dummy Selfie"
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    border: '5px solid white',
                    objectFit: 'cover',
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4,
                mt: 8,
              }}
            >
              <Stack
                direction="row"
                spacing={1}
                justifyContent={'space-between'}
                alignItems={'flex-start'}
              >
                <Box>
                  <Stack direction="row" spacing={2} alignItems={'center'}>
                    <Typography variant="h6">
                      {kpUser?.clientInfo.firstName}{' '}
                      {kpUser?.clientInfo.lastName}
                    </Typography>
                    <Typography
                      sx={{
                        color: 'green',
                        bgcolor: 'background.paper',
                        borderRadius: 1,
                        border: 1,
                        borderColor: 'green',
                        px: 1,
                        py: 0.5,
                        fontSize: 12,
                        fontWeight: 'bold',
                      }}
                      variant="h6"
                    >
                      Activo
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={1}
                    mt={2}
                    alignItems={'center'}
                  >
                    <img src={FlagColombiaIcon} width={40} />
                    <Typography variant="body1">
                      {kpUser?.clientInfo.country === 'CO' ? 'Colombia' : ''}
                    </Typography>
                  </Stack>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    navigate('/keypago/users/credit-info-view', {
                      state: {
                        country: kpUser?.clientInfo.country,
                        userDocId: kpUser?.clientInfo.clientDocId,
                        userDocType: kpUser?.clientInfo.clientDocType,
                        userType: 'old',
                      },
                    })
                  }}
                >
                  Información crediticia
                </Button>
              </Stack>
              <Divider sx={{ my: 4 }} />
              <Stack direction="row" spacing={1} alignItems={'center'}>
                <Stack flex={1} direction="column" spacing={1}>
                  <Stack direction="row" spacing={1} alignItems={'center'}>
                    <FontAwesomeIcon icon={faKey} />
                    <Typography
                      minWidth={100}
                      fontWeight={'500'}
                      variant="body1"
                    >
                      ID
                    </Typography>
                    <Typography variant="body1">
                      {kpUser?.clientInfo.clientId}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} alignItems={'center'}>
                    <FontAwesomeIcon icon={faIdCard} />
                    <Typography
                      minWidth={100}
                      fontWeight={'500'}
                      variant="body1"
                    >
                      Cédula
                    </Typography>
                    <Typography variant="body1">
                      {kpUser?.clientInfo.clientDocId}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} alignItems={'center'}>
                    <FontAwesomeIcon icon={faMailBulk} />
                    <Typography
                      minWidth={100}
                      fontWeight={'500'}
                      variant="body1"
                    >
                      Correo
                    </Typography>
                    <Typography variant="body1">
                      {kpUser?.clientInfo.email}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack flex={1} direction="column" spacing={1}>
                  <Stack
                    flex={1}
                    direction="row"
                    spacing={1}
                    alignItems={'center'}
                  >
                    <FontAwesomeIcon icon={faClock} />
                    <Typography
                      minWidth={100}
                      fontWeight={'500'}
                      variant="body1"
                    >
                      Fecha de creación
                    </Typography>
                    <Typography variant="body1">
                      {kpUser?.onboardingInfo.creationDate
                        ? format(
                            new Date(kpUser.onboardingInfo.creationDate),
                            'dd-MM-yyyy HH:mm',
                          )
                        : ''}
                    </Typography>
                  </Stack>
                  <Stack
                    flex={1}
                    direction="row"
                    spacing={1}
                    alignItems={'center'}
                  >
                    <FontAwesomeIcon icon={faDollarSign} />
                    <Typography
                      minWidth={100}
                      fontWeight={'500'}
                      variant="body1"
                    >
                      Cupo preaprovado
                    </Typography>
                    <Typography variant="body1">
                      {formatToCOP(Number(kpUser?.resultsModel?.creditLimit))}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Divider sx={{ my: 4 }} />
              <Stack direction="row" spacing={1} alignItems={'center'} mb={2}>
                <FontAwesomeIcon icon={faUser} />
                <Typography variant="h6">Datos del usuario</Typography>
              </Stack>
              {/* Data extracted from the user's photos */}
              <Stack direction="row" spacing={1} alignItems={'center'}>
                <Stack flex={1} direction="row" spacing={1}>
                  <Stack direction="column" spacing={1} flex={1}>
                    <Stack direction="row" spacing={1} alignItems={'center'}>
                      <Typography
                        minWidth={100}
                        fontWeight={'500'}
                        variant="body1"
                      >
                        RH
                      </Typography>
                      <Typography variant="body1">
                        {kpUser?.clientInfo.bloodType}
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems={'center'}>
                      <Typography
                        minWidth={100}
                        fontWeight={'500'}
                        variant="body1"
                      >
                        Género
                      </Typography>
                      <Typography variant="body1">
                        {kpUser?.clientInfo.gender}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="column" spacing={1} flex={1}>
                    <Stack direction="row" spacing={1} alignItems={'center'}>
                      <Typography
                        minWidth={100}
                        fontWeight={'500'}
                        variant="body1"
                      >
                        Estatura
                      </Typography>
                      <Typography variant="body1">
                        {kpUser?.clientInfo.height}
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems={'center'}>
                      <Typography
                        minWidth={100}
                        fontWeight={'500'}
                        variant="body1"
                      >
                        Fecha de nacimiento
                      </Typography>
                      <Typography variant="body1">
                        {format(
                          new Date(kpUser?.clientInfo.issueDate),
                          'dd-MM-yyyy',
                        )}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              {/* Photos success of flow user */}
              <Divider sx={{ my: 4 }} />
              <Typography mb={2} variant="h6">
                Fotos
              </Typography>
              <Stack direction={'row'} flex={1} spacing={2}>
                {kpUser?.files.map((item, index) => (
                  <div
                    onClick={() => downloadDocument(item.fileId)}
                    key={index}
                  >
                    <BoxPicture>
                      <img
                        src={item.filePath}
                        alt="Selfie"
                        width={50}
                        height={50}
                        style={{
                          objectFit: 'cover',
                        }}
                      />
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography variant="body1" fontWeight={'600'}>
                          {item.label}
                        </Typography>
                      </Box>
                      <FontAwesomeIcon
                        icon={faDownload}
                        style={{
                          position: 'absolute',
                          top: '50%',
                          right: '14px',
                          transform: 'translateY(-50%)',
                        }}
                      />
                    </BoxPicture>
                  </div>
                ))}
              </Stack>
              {/* Contracts of user */}
              <Divider sx={{ my: 4 }} />
              <Typography mb={2} variant="h6">
                Documentos
              </Typography>
              <Stack direction={'row'} flex={1} spacing={2}>
                {/* no contract yet */}
                <Typography variant="body1">No hay documentos</Typography>
              </Stack>
              {/* Terms and conditions */}
              <Divider sx={{ my: 4 }} />
              <Typography mb={2} variant="h6">
                Términos y condiciones
              </Typography>
              <Stack direction={'row'} flex={1} spacing={2}>
                {kpUser?.termsAndConditions.map((item, index) => (
                  <Link
                    key={index}
                    to={item.termPath}
                    target="_blank"
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <BoxPicture>
                      <FontAwesomeIcon
                        icon={faUnlock}
                        style={{
                          marginLeft: 10,
                        }}
                      />
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography variant="body1" fontWeight={'600'}>
                          {item.termAndConditionName}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: 'green',
                            fontWeight: 'bold',
                          }}
                        >
                          Aceptados
                        </Typography>
                      </Box>
                    </BoxPicture>
                  </Link>
                ))}
              </Stack>
            </Box>
          </>
        )}
      </BoxWithShadow>
    </Container>
  )
}
export default ViewUserkp
