import React, { useEffect } from 'react'
import { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import useIsMounted from 'src/hooks/useIsMounted'
import { Stack } from '@mui/material'
import BagImage from 'src/assets/images/cards-home/bag.png'
import BagsImage from 'src/assets/images/cards-home/bags.png'
import PercentImage from 'src/assets/images/cards-home/percent.png'
import CheckHandImage from 'src/assets/images/cards-home/check-hand.png'
import DiscountImage from 'src/assets/images/cards-home/20-descuento.png'
import BagCheckImage from 'src/assets/images/cards-home/bag-check.png'
import { Box, Typography, Card, CardContent, CardMedia } from '@mui/material';
import LogoKeypago from 'src/assets/images/logo.svg'

const Home = () => {
  const { isAuthenticated } = useContext(AuthContext)
  const navigate = useNavigate()
  const isMounted = useIsMounted()
  const cards: {
    title: string
    description: string
    image: string
  }[] = [
      {
        title: 'Descuento de bienvenida',
        description: 'para compras en tiendas TOTTO seleccionadas',
        image: DiscountImage,
      },
      {
        title: 'Compra sin estrés',
        description: 'tu cupo sin cuota de manejo ni cargos ocultos',
        image: BagsImage,
      },
      {
        title: 'Tasa de interés preferencial',
        description: 'comprando con keypago',
        image: PercentImage,
      },

      {
        title: 'Te aprobamos de una',
        description: 'súper rápido y solo con tu cédula',
        image: CheckHandImage,
      },

      {
        title: 'Compra ya',
        description: 'y paga el otro mes',
        image: BagCheckImage,
      },
      {
        title: 'Descuentos exclusivos',
        description: 'en productos TOTTO',
        image: BagImage,
      },
    ]
  useEffect(() => {
    if (!isAuthenticated && isMounted) {
      localStorage.clear()
      navigate('/login')
    }
  }, [isAuthenticated, navigate, isMounted])
  return (
    <Stack justifyContent={'center'} direction={'row'} sx={{
      width: '100%',
      minHeight: '88dvh',
      backgroundColor: 'red',
      bgcolor: 'secondary.main', // Asumiendo que tienes esta variable en tu tema
      display: { lg: 'flex', xs: 'none' }, // Hidden on small screens, flex on large screens
      flexDirection: 'column',
      alignItems: 'center',
      gap: 2,
    }}>
      <Box sx={{
        backgroundColor: 'white',
        padding: 2,
        borderRadius: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <img src={LogoKeypago} alt="Bag"
          style={{
            display: 'block',
            margin: '0 auto',
            width: '200px',
            height: 'auto',
          }} />
      </Box>
      <Box
        sx={{
          flexWrap: 'wrap',
          width: '100%',
          maxWidth: '60%',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          contentAlign: 'center',
          alignContent: 'center',
          display: 'flex',
        }}
      >
        {cards.map((card, index) => (
          <Card
            key={index}
            sx={{
              display: 'flex',
              gap: 2,
              width: 400,
              bgcolor: 'white',
              height: 150,
              color: 'black',
              borderRadius: 2,
              alignItems: 'center',
              p: 2,
            }}
          >
            <CardMedia
              component="img"
              loading="lazy"
              src={card.image}
              alt={card.title}
              sx={{ width: 112, height: 112 }}
            />
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: 1
              }}
            >
              <Typography variant="h6">
                <strong>{card.title}</strong>
              </Typography>
              <Typography>{card.description}</Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Stack>
  )
}
export default Home
