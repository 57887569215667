/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { useIndicators } from 'src/hooks/Dashboard/Indicators/useIndicators'
import { useKPIForm } from 'src/hooks/Dashboard/KPIForm/useKPIForm'
import { useQuestions } from 'src/hooks/Dashboard/questions/useQuestions'
import { IResponseGetIndicator } from 'src/services/dashboard/indicator/indicator.interface'

const DialogAsociate = ({
  showModal,
  setShowModal,
  responseLoadIndicator
}: {
  responseLoadIndicator: IResponseGetIndicator | null
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
}) => {
  const { filter, setfilterIndicators } = useIndicators()
  const { filters } = useKPIForm()
  const { joinQuestionToIndicator } = useQuestions()

  return (
    <Dialog open={showModal} onClose={() => setShowModal(false)}>
      <DialogTitle>Asociar preguntas</DialogTitle>
      <DialogContent>
        <Stack spacing={2} py={2}>
          {/* PERIOD FILTER */}
          <Autocomplete
            sx={{
              width: '400px',
            }}
            clearIcon={null}
            value={
              filters
                ?.find((f) => f?.areaName === filter?.areaName)
                ?.periods?.find(
                  (channel) => channel.periodId === filter.periodId,
                ) ?? null
            }
            options={
              filters?.find((f) => f.areaName === filter.areaName)?.periods ??
              []
            }
            onChange={(event, newValue) => {
              setfilterIndicators({
                ...filter,
                periodId: newValue?.periodId ?? 0,
              })
            }}
            isOptionEqualToValue={(option, value) =>
              option.periodName === value.periodName
            }
            getOptionLabel={(option) => option.periodName}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Periodo"
                placeholder="Selecciona un periodo"
              />
            )}
          />
          {/* QUARERS FILTER */}
          <Autocomplete
            sx={{
              width: '400px',
            }}
            clearIcon={null}
            value={
              filters
                ?.find((f) => f?.areaName === filter?.areaName)
                ?.periods?.find(
                  (channel) => channel.periodId === filter.periodId,
                )
                ?.quarters?.find((q) => q.quarterId === filter.quarterId) ??
              null
            }
            options={
              filters
                ?.find((f) => f.areaName === filter.areaName)
                ?.periods?.find((p) => p.periodId === filter.periodId)
                ?.quarters ?? []
            }
            onChange={(_, newValue) => {
              setfilterIndicators({
                ...filter,
                quarterId: newValue?.quarterId ?? 0,
              })
            }}
            isOptionEqualToValue={(option, value) =>
              option.quarterName === value.quarterName
            }
            getOptionLabel={(option) => option.quarterName}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Trimestre"
                placeholder="Selecciona un trimestre"
              />
            )}
          />
          {/* Strategi FILTER */}
          <Autocomplete
            sx={{
              width: '400px',
            }}
            clearIcon={null}

            options={responseLoadIndicator?.infoIndicator.strategies ?? []}

            onChange={(_, newValue) => {
              setfilterIndicators({
                ...filter,
                strategyId: newValue?.strategyId ?? 0,
              })
            }}
            isOptionEqualToValue={(option, value) =>
              option.strategyName === value.strategyName
            }
            getOptionLabel={(option) => option.strategyName}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Estrategia"
                placeholder="Selecciona una estrategia"
              />
            )}
          />
          {/* parameterID */}
          <Autocomplete
            sx={{
              width: '400px',
            }}
            clearIcon={null}
            options={responseLoadIndicator?.infoIndicator.strategies.find(
              (s) => s.strategyId === filter.strategyId,
            )?.parameters ?? []
            }
            onChange={(_, newValue) => {
              setfilterIndicators({
                ...filter,
                parameterId: newValue?.parameterId ?? 0,
              })
            }}
            isOptionEqualToValue={(option, value) =>
              option.parameterName === value.parameterName
            }
            getOptionLabel={(option) => option.parameterName}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Parametro"
                placeholder="Selecciona un parametro"
              />
            )}
          />


          {/* INDICATORS FILTER */}
          <TextField
            sx={{
              width: '400px',
            }}
            label="Pregunta"
            value={filter.question}
            onChange={(e) =>
              setfilterIndicators({
                ...filter,
                question: e.target.value,
              })
            }
          />
          <TextField
            sx={{
              width: '400px',
            }}
            type='number'
            label="Respuesta por defecto"
            value={filter.answerDefault}
            onChange={(e) =>
              setfilterIndicators({
                ...filter,
                answerDefault: e.target.value,
              })
            }
          />
          <Button
            variant="contained"
            onClick={() => {
              setShowModal(false)
              joinQuestionToIndicator({
                answerDefault: Number(filter.answerDefault),
                indicatorId: Number(responseLoadIndicator?.infoIndicator.indicatorId),
                parameterId: Number(filter.parameterId),
                periodId: Number(filter.periodId),
                quarterId: Number(filter.quarterId),
                question: String(filter.question),
              })
            }}
          >
            Asociar
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default DialogAsociate
