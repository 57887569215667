/**
  @description Contiene las funciones para la autenticación de usuarios.
 */

import { getHeaders } from 'src/utils/utils'
import { User } from './user.interface'

// Importando módulos necesarios.

const BASE_URL = process.env.REACT_APP_API || 'NO_URL'

export const profile = async (): Promise<User> => {
  try {
    const response = await fetch(`${BASE_URL}/me/profile`, {
      method: 'GET',
      headers: getHeaders(),
    })
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    const data = await response.json()

    return data.data
  } catch (error) {
    console.error('Error en la autenticación', error)
    throw error
  }
}
