import { fetchWithToken, getHeaders, getParamsToURL } from 'src/utils/utils'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { IStrategy } from './strategies.interface'

const BASE_URL = process.env.REACT_APP_API || 'NO_URL'

export const getStrategies = createAsyncThunk(
  'permission/getStrategies',
  async (payload: any, { rejectWithValue }) => {
    try {
      const paramsString = getParamsToURL(payload)
      const response = await fetchWithToken(
        `${BASE_URL}/kpis/strategy/get-strategies?${paramsString}`,
        {
          method: 'GET',
          headers: getHeaders(),
        },
      )
      const data = await response.json()
      if (!response.ok) {
        throw new Error(data.error.message || 'Ha ocurrido un error inesperado')
      }
      return data.data
    } catch (error: any) {
      return rejectWithValue(new Error(error).message)
    }
  },
)
export const createStrategy = createAsyncThunk(
  'permission/createStrategy',
  async (payload: IStrategy, { rejectWithValue }) => {
    try {
      const response = await fetchWithToken(
        `${BASE_URL}/kpis/strategy/create`,
        {
          method: 'POST',
          headers: getHeaders(),
          body: JSON.stringify(payload),
        },
      )
      const data = await response.json()
      if (!response.ok) {
        throw new Error(data.error.message || 'Ha ocurrido un error inesperado')
      }
      return data
    } catch (error: any) {
      return rejectWithValue(new Error(error).message)
    }
  },
)
