// src/store.ts
import { configureStore } from '@reduxjs/toolkit'
import userReducer from 'src/redux/User/UserSlice'
import userIAMReducer from 'src/redux/IAM/AdminUser/UserIAMSlice'
import permissionsReducer from 'src/redux/IAM/AdminPermission/permissionSlice'
import groupsReducer from 'src/redux/IAM/AdminGroups/adminGroupsSlice'
import endpointsReducer from 'src/redux/IAM/AdminEndpoints/adminEndpointsSlice'
import appsReducer from 'src/redux/IAM/AdminApps/adminAppsSlice'
import kpiFormReducer from 'src/redux/Dashboard/KPIForm/KPIFormSlice'
import kpiChartReducer from 'src/redux/Dashboard/Charts/ChartsSlice'
import LoadingSlice from './Components/Loading/LoadingSlice'
import PaginationSlice from './Components/Pagination/PaginationSlice'
import adminModulesSlice from './IAM/AdminModules/adminModulesSlice'
import strategySlice from './Dashboard/Strategies/StrategiesSlice'
import IndicatorsSlice from './Dashboard/Indicators/IndicatorsSlice'
import QuestionsSlice from './Dashboard/Questions/QuestionsSlice'
import webScoketSlice from './Websocket/webScoketSlice'
import webSocketMiddleware from './Websocket/webScoketMiddleware'
import kpUserSlice from './Onboarding/user/kpUserSlice'
import testModelsSlice from './Onboarding/testModels/testModelsSlice'

const store = configureStore({
  reducer: {
    user: userReducer,
    userIAM: userIAMReducer,
    groups: groupsReducer,
    permissions: permissionsReducer,
    endpoints: endpointsReducer,
    apps: appsReducer,
    kpiForm: kpiFormReducer,
    kpiChart: kpiChartReducer,
    modules: adminModulesSlice,
    loading: LoadingSlice,
    pagination: PaginationSlice,
    strategy: strategySlice,
    indicators: IndicatorsSlice,
    questions: QuestionsSlice,
    webSocket: webScoketSlice,
    kpUser: kpUserSlice,
    testModel: testModelsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(webSocketMiddleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
