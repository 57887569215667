import { getHeaders, getParamsToURL } from 'src/utils/utils'
import { IAdminApp } from './AdminApp.interface'
import { createAsyncThunk } from '@reduxjs/toolkit'

const BASE_URL = process.env.REACT_APP_API || 'NO_URL'

export const createApp = createAsyncThunk(
  'permission/createApp',
  async (payload: IAdminApp, { rejectWithValue }) => {
    try {
      const response = await fetch(`${BASE_URL}/iam/app/create`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
      })
      const data = await response.json()
      if (!response.ok) {
        throw new Error(data.error.message || 'Ha ocurrido un error inesperado')
      }
      return data
    } catch (error: any) {
      return rejectWithValue(new Error(error).message)
    }
  },
)
export const getApps = createAsyncThunk(
  'permission/getApps',
  async (
    pagination: {
      currentPage?: number
      pageSize?: number
    } = {},
    { rejectWithValue },
  ) => {
    try {
      const paramsString = getParamsToURL(pagination)
      const response = await fetch(
        `${BASE_URL}/iam/app/get-apps?${paramsString}`,
        {
          method: 'GET',
          headers: getHeaders(),
        },
      )
      const data = await response.json()
      if (!response.ok && response) {
        throw new Error(
          data.error.message || '-- A ocurrido un error inesperado --',
        )
      }
      return data.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)

export const deleteApp = createAsyncThunk(
  'permission/deleteApp',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await fetch(`${BASE_URL}/iam/app/delete/${id}`, {
        method: 'DELETE',
        headers: getHeaders(),
      })

      if (!response.ok && response) {
        throw new Error('Network response was not ok')
      }

      return await response.json()
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)

export const updateApp = createAsyncThunk(
  'permission/updateApp',
  async (payload: IAdminApp, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${BASE_URL}/iam/app/update/${payload.appId}`,
        {
          method: 'PUT',
          headers: getHeaders(),
          body: JSON.stringify(payload),
        },
      )

      if (!response.ok && response) {
        throw new Error('Network response was not ok')
      }

      return await response.json()
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)
