/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from 'react'
import { INPermission } from 'src/services/IAM/AdminPermissions/AdminPermissions.interface'
import CollapsibleTable from 'src/views/AdminUser/components/CollapsibleTable'

const TablePermissions = ({
  permissions,
  loadPermissions,
  handlerSelectedPermissions,
}: {
  permissions: INPermission[]
  loadPermissions: (params: { currentPage: number; pageSize: number }) => void
  handlerSelectedPermissions: (permissions: INPermission) => void
}) => {
  return (
    <Fragment>
      <CollapsibleTable
        withActions={true}
        loadPermissions={loadPermissions}
        permissionsProp={permissions}
        handlerSelectedPermissions={handlerSelectedPermissions}
      />
    </Fragment>
  )
}
export default TablePermissions
