// src/hooks/useStrategies.ts
import { useDispatch, useSelector } from 'react-redux'
import { RootState, AppDispatch } from 'src/redux/index'
import { useAlert } from 'src/contexts/AlertContext'
import useLoading from 'src/hooks/useLoading'
import {
  associateQuestionsToIndicator,
  getQuestiosForm,
} from 'src/services/KPI/Questions/Questions.service'
import { IFilterIndicators, IPayloadAsociateQuestions } from 'src/services/dashboard/indicator/indicator.interface'
import { setAssociatedQuestion } from 'src/redux/Dashboard/Questions/QuestionsSlice'
import { IQuestionState } from 'src/redux/Dashboard/Questions/Questions.interface'
import { useIndicators } from '../Indicators/useIndicators'
export const useQuestions = () => {
  const { showAlert } = useAlert()
  const { startLoading, stopLoading } = useLoading()
  const dispatch = useDispatch<AppDispatch>()
  const { loadIndicator } = useIndicators()
  const { associatedQuestion } = useSelector(
    (state: RootState) => state.questions,
  )
  const loadQuestions = (pagination: {
    currentPage?: number | null
    pageSize?: number | null
    areaName?: string | null
    indicatorId?: number | null
    periodId?: number | null
    quarterId?: number | null
    channelId?: number | null
  }) => {
    try {
      startLoading()
      dispatch(getQuestiosForm({}))
        .then((result: any) => {
          if (getQuestiosForm.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) || 'Error al cargar las estrategias',
            )
          } else if (getQuestiosForm.fulfilled.match(result)) {
            showAlert('success', 'Estrategias cargadas correctamente')
          }
        })
        .finally(() => {
          stopLoading()
        })
    } catch (error: any) {
      showAlert('error', error.message || 'Ocurrió un error inesperado')
    }
  }

  const joinQuestionToIndicator = (indicator: IPayloadAsociateQuestions) => {
    try {
      startLoading()
      dispatch(associateQuestionsToIndicator(indicator))
        .then((result: any) => {
          if (associateQuestionsToIndicator.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) || 'Error al asociar preguntas',
            )
          } else if (associateQuestionsToIndicator.fulfilled.match(result)) {
            loadIndicator({
              currentPage: 1,
              pageSize: 25,
              indicatorId: String(indicator.indicatorId),
            })
            showAlert('success', 'Preguntas asociadas correctamente')
          }
        })
        .finally(() => {
          stopLoading()
        })
    } catch (error: any) {
      showAlert('error', error.message || 'Ocurrió un error inesperado')
    }
  }
  const updateAssociatedQuestion = (
    question: IQuestionState['associatedQuestion'],
  ) => {
    setAssociatedQuestion(question)
  }

  return {
    associatedQuestion,
    updateAssociatedQuestion,
    loadQuestions,
    joinQuestionToIndicator,
  }
}
