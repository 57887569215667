// paginationSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface PaginationState {
  currentPage: number
  rowsPerPage: number
  rowsPerPageOptions: number[]
  totalItems: number
}

const initialState: PaginationState = {
  currentPage: 1,
  rowsPerPage: 25,
  rowsPerPageOptions: [5, 10, 25, 50, 100],
  totalItems: 0,
}

const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload
    },
    setRowsPerPage(state, action: PayloadAction<number>) {
      state.rowsPerPage = action.payload
    },
    setTotalItems(state, action: PayloadAction<number>) {
      state.totalItems = action.payload
    },
  },
})

export const { setCurrentPage, setRowsPerPage, setTotalItems } =
  paginationSlice.actions
export default paginationSlice.reducer
