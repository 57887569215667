/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  FormGroup,
  Chip,
  OutlinedInput,
  Box,
  SelectChangeEvent,
} from '@mui/material'
import { Field } from 'src/redux/Onboarding/testModels/testModelsSlice.interface'
import useIsMounted from 'src/hooks/useIsMounted'

interface FieldsToShowProps {
  payload: Field
  value: any
  onChange: (key: string, value: any) => void
}

const FieldsToShow: React.FC<FieldsToShowProps> = ({
  payload,
  value,
  onChange,
}) => {
  const isMounted = useIsMounted()

  const handleDeleteChip = (chipToDelete: string) => {
    const newValue = value.filter((chip: string) => chip !== chipToDelete)
    onChange(payload.key, newValue)
  }

  // Función para manejar cambios en inputs genéricos (text, number, etc.)
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onChange(payload.key, event.target.value)
  }

  // Función para manejar cambios en Select
  const handleSelectChange = (event: SelectChangeEvent) => {
    console.log(
      payload.key,
      event.target.value,
      'payload.key, event.target.value',
    )

    onChange(payload.key, event.target.value)
  }

  useEffect(() => {
    // todos los "value" seran = payload.defaultValue
    if (!isMounted) return
    if (payload.defaultValue) {
      if (payload.objType === 'select') {
        const valueFinded = payload.options?.find(
          (option) => option.id === payload.defaultValue,
        )?.value
        onChange(payload.key, valueFinded ?? '')
      } else {
        onChange(payload.key, payload.defaultValue)
      }
    }
  }, [isMounted, payload])

  return (
    <>
      {payload.objType === 'input' ? (
        <FormControl
          required={payload.required}
          sx={{
            display: payload.hidden ? 'none' : 'block',
          }}
        >
          <TextField
            name={payload.key}
            sx={{ width: 200, bgcolor: '#fff' }}
            label={payload.label}
            variant="outlined"
            type={payload.varType === 'int' ? 'number' : 'text'}
            value={value || ''}
            onChange={handleInputChange}
            required={payload.required}
          />
        </FormControl>
      ) : payload.objType === 'select' ? (
        <FormControl
          required={payload.required}
          sx={{
            display: payload.hidden ? 'none' : 'block',
          }}
        >
          <InputLabel>{payload.label} </InputLabel>
          <Select
            name={payload.key}
            sx={{ width: 200, bgcolor: '#fff' }}
            label={payload.label}
            value={
              payload.options?.find((option) => {
                console.log(option.value, value, 'option.value, value')

                return option.value === value
              })?.value ?? ''
            }
            SelectDisplayProps={{ 'aria-label': 'Without label' }}
            onChange={handleSelectChange}
            required={payload.required}
          >
            {payload?.options?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : payload.objType === 'radio' ? (
        <FormControl
          component="fieldset"
          required={payload.required}
          sx={{
            display: payload.hidden ? 'none' : 'block',
          }}
        >
          <RadioGroup
            value={value || ''}
            onChange={handleInputChange}
            sx={{
              display: payload.hidden ? 'none' : 'block',
            }}
          >
            {payload?.options?.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      ) : payload.objType === 'checkbox' ? (
        <FormGroup
          sx={{
            display: payload.hidden ? 'none' : 'block',
          }}
        >
          {payload?.options?.map((option) => (
            <FormControlLabel
              name={payload.key}
              key={option.value}
              control={<Checkbox checked={value?.includes(option.value)} />}
              label={option.label}
              onChange={() => {
                const newValue = value?.includes(option.value)
                  ? value.filter((v: any) => v !== option.value)
                  : [...(value || []), option.value]
                onChange(payload.key, newValue)
              }}
            />
          ))}
        </FormGroup>
      ) : payload.objType === 'date' ? (
        <FormControl
          required={payload.required}
          sx={{
            display: payload.hidden ? 'none' : 'block',
          }}
        >
          <TextField
            name={payload.key}
            sx={{ width: 200, bgcolor: '#fff' }}
            label={payload.label}
            type="date"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={value || ''}
            onChange={handleInputChange}
            required={payload.required}
          />
        </FormControl>
      ) : payload.objType === 'chip' ? (
        <FormControl
          required={payload.required}
          sx={{
            display: payload.hidden ? 'none' : 'block',
          }}
        >
          <InputLabel>{payload.label}</InputLabel>
          <Select
            name={payload.key}
            sx={{ width: 200, bgcolor: '#fff' }}
            label={payload.label}
            multiple
            value={value || []}
            onChange={handleSelectChange}
            input={<OutlinedInput label={payload.label} />}
            renderValue={(selected: any) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((chipValue: string) => (
                  <Chip key={chipValue} label={chipValue} />
                ))}
              </Box>
            )}
          >
            {payload?.options?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null}
    </>
  )
}

export default FieldsToShow
