// src/hooks/useAdminApp.ts
import { useDispatch, useSelector } from 'react-redux'
import { RootState, AppDispatch } from 'src/redux/index'
import {
  createApp,
  deleteApp,
  getApps,
  updateApp,
} from 'src/services/IAM/AdminApps/AdminApp.service'
import { useAlert } from 'src/contexts/AlertContext'
import { IAdminApp } from 'src/services/IAM/AdminApps/AdminApp.interface'
import { setApp, setAppUpdated, resetApps } from 'src/redux/IAM/AdminApps/adminAppsSlice'
import useLoading from 'src/hooks/useLoading'

export const useAdminApp = () => {
  const { showAlert } = useAlert()
  const { startLoading, stopLoading } = useLoading()
  const dispatch = useDispatch<AppDispatch>()
  const { app, apps, loading, error, appUpdated } = useSelector(
    (state: RootState) => state.apps,
  )

  const loadApps = ({
    currentPage = 1,
    pageSize = 25,
  }: {
    currentPage?: number
    pageSize?: number
  }) => {
    try {
      startLoading()
      dispatch(
        getApps({
          currentPage,
          pageSize,
        }),
      )
        .then((result: any) => {
          stopLoading()
          if (getApps.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) || 'Error al cargar las apps',
            )
          } else if (getApps.fulfilled.match(result)) {
            showAlert('success', 'Apps cargados correctamente')
          }
        })
        .finally(() => {
          stopLoading()
        })
      showAlert('success', 'Apps cargados correctamente')
    } catch (error) {
      showAlert('error', 'Error al cargar los apps')
    }
  }

  const setAppAdminUpdated = (app: IAdminApp) => {
    dispatch(setAppUpdated(app))
  }
  const setAppAdmin = (app: IAdminApp) => {
    dispatch(setApp(app))
  }

  const removeApp = (appId: number) => {
    startLoading()
    dispatch(deleteApp(appId))
      .then((result) => {
        stopLoading()
        if (createApp.rejected.match(result)) {
          showAlert(
            'error',
            String(result.payload) || 'Error al eliminar la app',
          )
        } else if (createApp.fulfilled.match(result)) {
          showAlert('success', 'App eliminada correctamente')
          loadApps({
            currentPage: 1,
            pageSize: 25,
          })
        }
      })
      .finally(() => {
        stopLoading()
      })
  }
  const updateAdminApp = (app: IAdminApp) => {
    startLoading()
    dispatch(updateApp(app))
      .then((result) => {
        stopLoading()
        console.log('test')

        if (updateApp.rejected.match(result)) {
          showAlert(
            'error',
            String(result.payload) || 'Error al actualizar la app',
          )
        } else if (updateApp.fulfilled.match(result)) {
          showAlert('success', 'App actualizada correctamente')
          loadApps({
            currentPage: 1,
            pageSize: 25,
          })
        }
      })
      .finally(() => {
        stopLoading()
      })
  }
  const createAdminApp = (app: IAdminApp) => {
    startLoading()

    dispatch(createApp(app))
      .then((result: any) => {
        stopLoading()
        if (createApp.rejected.match(result)) {
          showAlert('error', String(result.payload) || 'Error al crear la app')
        } else if (createApp.fulfilled.match(result)) {
          showAlert('success', 'App creada correctamente')
          loadApps({
            currentPage: 1,
            pageSize: 25,
          })
        }
      })
      .finally(() => {
        stopLoading()
      })
  }

  const clearApps = () => {
    dispatch(resetApps())
  }

  return {
    app,
    apps,
    loading,
    error,
    appUpdated,
    updateAdminApp,
    setAppAdminUpdated,
    createAdminApp,
    setAppAdmin,
    loadApps,
    removeApp,
    clearApps,
  }
}
