/**
 * @file Layout.tsx
 * @description This file is responsible for rendering the layout of the dashboard, including the `Header`, `Sidebar`, and the main content area. The `Sidebar` is used for navigation between the different modules of the dashboard.
 *
 * @module Layout
 */

import React from 'react'
import Header from 'src/components/Header/Header'
import Footer from 'src/components/Footer/Footer'
import TreeSideBar from 'src/components/Sidebar/TreeSideBar'
import { Box, Stack } from '@mui/material'

/**
 * Props for the `Layout` component.
 *
 * @typedef LayoutProps
 * @property {React.ReactNode} children - Child components to be rendered in the main content area of the layout.
 */
interface LayoutProps {
  children: React.ReactNode
}

/**
 * Layout component for rendering the dashboard structure.
 * It contains a `Header` at the top, a `Sidebar` for navigation on the left (or above in mobile view), and the main content area.
 *
 * @component
 * @param {LayoutProps} props - Props passed to the `Layout` component.
 * @param {React.ReactNode} props.children - Child components to be rendered in the main content area.
 *
 * @example
 * ```tsx
 * <Layout>
 *   <DashboardContent />
 * </Layout>
 * ```
 *
 * @returns {JSX.Element} The `Layout` component with a header, sidebar, and main content area.
 */
export default function Layout({ children }: LayoutProps) {
  return (
    <Stack justifyContent={'space-between'} minHeight={'100dvh'}>
      <Header />
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        spacing={0}
        flex={1}
        bgcolor={'#f5f5f5'}
      >
        <TreeSideBar />
        <Box
          sx={{
            flexGrow: 1,
            padding: 2,
            overflow: 'auto',
          }}
        >
          {children}
        </Box>
      </Stack>
      <Footer />
    </Stack>
  )
}
