// LoginForm.tsx

// Importaciones necesarias
import React, { useState, useEffect, useContext } from 'react'
import { AuthContext, AuthContextType } from '../../contexts/AuthContext'
import { Link, useNavigate } from 'react-router-dom'
import useLoginForm from '../../hooks/useLoginForm'
import logo from '../../assets/images/logo.svg'
import styles from './LoginView.module.scss'
import { login } from '../../services/auth/auth.service'
import useIsMounted from 'src/hooks/useIsMounted'
import { StateAuth } from 'src/services/auth/auth.interface'
import {
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { jwtDecode } from 'jwt-decode'
import { useAlert } from 'src/contexts/AlertContext'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/redux'
import useLoading from 'src/hooks/useLoading'
// Define the option type for the user types

// Define the options for the user type select

// Componente LoginForm
const Login = () => {
  const isMounted = useIsMounted()
  const navigate = useNavigate()
  const { startLoading, stopLoading } = useLoading()
  const { showAlert } = useAlert()
  const { setUser, isAuthenticated, setIsAuthenticated } =
    useContext(AuthContext)
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/contabilidad')
    }
    // Encuentra la opción de cliente y la asigna al estado
  }, [isMounted, isAuthenticated, navigate])

  // Estados para la validez de cédula y contraseña
  const [cedulaValid, setCedulaValid] = useState(true) // Inicialmente válidos
  const [passwordValid, setPasswordValid] = useState(true)
  const [touched, setTouched] = useState(false) // Estado para rastrear interacciones
  // Obteniendo datos y funciones desde el hook y contexto
  const { formData, handleChange, setFormData } = useLoginForm()
  const dispatch = useDispatch()
  const { isLoading } = useSelector((state: RootState) => state.loading)
  const handleLogin = async () => {
    setTouched(true) // Marcar formulario como tocado

    // Verificar que ambos campos son válidos antes de proceder
    if (!cedulaValid || !passwordValid) {
      showAlert('error', 'Debes ingresar los datos correctamente')
      return
    }

    if (formData.cedula.length < 3 || formData.password.length < 3) {
      showAlert('error', 'Debes ingresar los datos correctamente')
      return
    }

    // Activa el indicador de carga solo después de pasar las validaciones

    try {
      startLoading()
      const response = await login({
        userDocId: formData.cedula,
        password: formData.password,
      })
      if (response.state === StateAuth.CHANGED_PASSWORD) {
        localStorage.setItem('sessionToken', response.session_token)
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        navigate('/cambiar-contraseña', {
          state: {
            userDocId: formData.cedula,
            password: formData.password,
            country: formData.country,
            userDocType: formData.userDocType,
          },
        })
        return
      }
      if (response.state === StateAuth.VALIDATED) {
        //EL ACCESS TOKEN QUEDA GUARDADO EN EL LOCAL STORAGE
        if (response.access_token) {
          localStorage.setItem('accessToken', response.access_token)
        }

        const userDecode = jwtDecode(
          response.access_token ?? '',
        ) as AuthContextType['user']
        setIsAuthenticated(true)
        setUser(userDecode)
        navigate('/contabilidad')
      }
    } catch (error) {
      showAlert('error', String(error))
    } finally {
      stopLoading()
    }
  }

  // Manejador de evento para cambio en campo cédula
  const handleCedulaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      cedula: e.target.value,
    }))
  }

  // Manejador de evento para cambio en campo contraseña
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isValid = e.target.value.length >= 5
    setPasswordValid(isValid)
    setTouched(true) // Asume que el campo ha sido tocado cuando cambia
    handleChange(e)
  }

  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
  }

  // Renderizado del componente
  return (
    <div className={styles.containerLogin}>
      <div className={styles.form}>
        <img src={logo} alt="Logo" className={styles.logo} />
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={['CC', 'CE', 'TI']}
          sx={{ mt: '1rem' }}
          value={formData.userDocType}
          fullWidth
          onChange={(event, newValue) => {
            setFormData({ ...formData, userDocType: newValue ?? '' })
          }}
          renderInput={(params) => (
            <TextField {...params} label="Tipo de documento" />
          )}
        />
        <TextField
          sx={{ mt: '1rem' }}
          id="outlined-basic"
          label="Cédula"
          variant="outlined"
          name="cedula"
          value={formData.cedula}
          onChange={handleCedulaChange}
          required
          fullWidth
          placeholder="Digite su cédula"
          InputLabelProps={{
            shrink: true,
          }}
        />
        {/* Mensaje de error se muestra solo si el formulario fue tocado */}
        <Typography
          variant="body2"
          sx={{ my: 1, color: 'red', textAlign: 'left', width: '100%' }}
        >
          {touched &&
            !cedulaValid &&
            formData.cedula &&
            'Debes ingresar mínimo 5 caracteres'}
        </Typography>
        <FormControl fullWidth variant="outlined">
          <InputLabel shrink htmlFor="outlined-adornment-password">
            Contraseña
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            name="password"
            value={formData.password}
            onChange={handlePasswordChange}
            required
            placeholder="Digite su contraseña"
            notched
            inputProps={{
              minLength: 5,
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>

        {/* Mensaje de error para contraseña */}
        <Typography
          variant="body2"
          sx={{ my: 1, color: 'red', textAlign: 'left', width: '100%' }}
        >
          {touched &&
            !passwordValid &&
            formData.password &&
            'Debes ingresar mínimo 5 caracteres'}
        </Typography>
        <Button
          onClick={handleLogin}
          variant="contained"
          color="primary"
          fullWidth
          //LOADING

          disabled={isLoading}
        >
          <Typography variant="button">Iniciar sesión</Typography>
        </Button>
        <Link
          to={'/olvidaste-tu-contraseña'}
          style={{ marginTop: '16px', textDecoration: 'none' }}
        >
          ¿Olvidaste tu contraseña?
        </Link>
      </div>
    </div>
  )
}

// Exportación del componente Login
export default Login
