/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import BoxWithShadow from 'src/components/BoxWithShadow'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import JsonView from '@uiw/react-json-view'
import useIsMounted from 'src/hooks/useIsMounted'
import { Link } from 'react-router-dom'
import {
  Button,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import useTestModels from 'src/hooks/Onboarding/testModels/useTestModels'
import {
  IFilterSettings,
  ResponseGetModelsCreditLimit,
} from 'src/redux/Onboarding/testModels/testModelsSlice.interface'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const ConfigModelView = () => {
  const [externalTabValue, setExternalTabValue] = useState(0)
  const isMounted = useIsMounted()
  const {
    testModelCreditLimit,
    testConfigModelCreditLimit,
    rsponseGetConfigModelCreditLimit,
    responseGetModelsCreditLimit,
  } = useTestModels()
  const [filtersSelected, setFiltersSelected] = useState({
    modelId: '',
    modelVersionId: '',
  })
  const handleExternalTabChange = (
    event: React.SyntheticEvent,
    newValue: number,
  ) => {
    setExternalTabValue(newValue)
  }

  useEffect(() => {
    if (!isMounted) return
    testModelCreditLimit()
  }, [isMounted])

  return (
    <Container>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          testConfigModelCreditLimit({
            modelVersionId: Number(filtersSelected.modelVersionId),
          })
        }}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          my={2}
        >
          {/* {JSON.stringify(formValues, null)} */}
          <Link to="/keypago/users" style={{ textDecoration: 'none' }}>
            <IconButton aria-label="arrow back" color="primary">
              <FontAwesomeIcon icon={faArrowLeft} size="1x" />
            </IconButton>
          </Link>
          <Stack
            direction={'row'}
            justifyContent={'flex-end'}
            alignItems={'center'}
            gap={2}
            flexWrap={'wrap'}
          >
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="demo-simple-select-label">Modelos</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Modelos"
                value={filtersSelected.modelId}
                sx={{ backgroundColor: 'white' }}
                onChange={(e) => {
                  setFiltersSelected({
                    ...filtersSelected,
                    modelId: e.target.value,
                  })
                }}
              >
                {responseGetModelsCreditLimit?.map(
                  (item: ResponseGetModelsCreditLimit, index: number) => (
                    <MenuItem value={item.modelId} key={index}>
                      {item.modelName}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="demo-simple-select-label">Versiones</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Versiones"
                value={filtersSelected.modelVersionId}
                sx={{ backgroundColor: 'white' }}
                onChange={(e) => {
                  setFiltersSelected({
                    ...filtersSelected,
                    modelVersionId: e.target.value,
                  })
                }}
              >
                {responseGetModelsCreditLimit
                  ?.find(
                    (item) => item.modelId === Number(filtersSelected.modelId),
                  )
                  ?.versions.map((item, index) => (
                    <MenuItem value={item.modelVersionId} key={index}>
                      {item.modelVersion}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
            >
              Buscar
            </Button>
          </Stack>
        </Stack>
      </form>
      <BoxWithShadow>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={externalTabValue}
              onChange={handleExternalTabChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Configuración de modelo"
                {...a11yProps(0)}
                sx={{
                  alignItems: 'flex-end',
                }}
              />
            </Tabs>
          </Box>
          {/* Panels */}
          <CustomTabPanel value={externalTabValue} index={0}>
            <JsonView value={rsponseGetConfigModelCreditLimit ?? {}} />
          </CustomTabPanel>
        </Box>
      </BoxWithShadow>
    </Container>
  )
}

export default ConfigModelView
