/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { AuthContext } from '../../contexts/AuthContext'
import useIsMounted from 'src/hooks/useIsMounted'
import GlobalLoading from '../Loadings/GlobalLoading'
import { useDispatch } from 'react-redux'
import useWebsocket from 'src/hooks/useWebSocket'

/**
 * PrivateRoute component that protects private routes by checking if the user is authenticated.
 *
 * If the user is not authenticated, they are redirected to the login page.
 * If the WebSocket connection is not established, a loading indicator is shown.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 * @example
 * <PrivateRoute>
 *   <ProtectedComponent />
 * </PrivateRoute>
 */
const PrivateRoute = () => {
  // Use AuthContext to check authentication status
  const { isAuthenticated } = useContext(AuthContext)

  // Custom hook to check if the component is mounted
  const isMounted = useIsMounted()

  // Redux dispatch function
  const dispatch = useDispatch()

  // Custom hook to manage WebSocket connection
  const { connected } = useWebsocket()

  // Establish WebSocket connection
  useEffect(() => {
    // Dispatch the action to connect the WebSocket
    if (!isMounted) return
    dispatch({ type: 'webSocket/connect' })
  }, [dispatch, isMounted])

  // Log WebSocket connection status
  useEffect(() => {
    if (!isMounted) return
    console.log("WebSocket connected:", connected)
  }, [connected, isMounted])

  // Show loading state while mounting
  if (!isMounted) {
    return (
      <div>
        <GlobalLoading />
        <h1>Cargando...</h1>
      </div>
    )
  }

  // Redirect to login if not authenticated
  if (isMounted && !isAuthenticated) {
    return <Navigate to="/login" replace />
  }

  // Render the protected route if authenticated
  if (isAuthenticated) {
    return <Outlet />
  }

  // Default redirect to home
  return <Navigate to="/" />
}

export default PrivateRoute
