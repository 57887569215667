import React, { useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import CollapsibleTable from './CollapsibleTable'
import { useAdminPermission } from 'src/hooks/IAM/AdminPermission/useAdminPermission'
import { INPermission } from 'src/services/IAM/AdminPermissions/AdminPermissions.interface'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, minWidth: '780px' }}>{children}</Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function StepperModal({
  permissionsProp,
  loadPermissions,
  selectedPermissions,
  handlerSelectedPermissions,
}: {
  permissionsProp: INPermission[]
  selectedPermissions: INPermission[]
  loadPermissions: (params: { currentPage: number; pageSize: number }) => void
  handlerSelectedPermissions: (permissions: INPermission) => void
}) {
  const [value, setValue] = React.useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Todos los permisos" {...a11yProps(0)} />
          <Tab label="Seleccionados" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <CollapsibleTable
          permissionsProp={permissionsProp}
          loadPermissions={loadPermissions}
          handlerSelectedPermissions={handlerSelectedPermissions}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <CollapsibleTable
          selectedPermissions={selectedPermissions}
          loadPermissions={loadPermissions}
          handlerSelectedPermissions={handlerSelectedPermissions}
        />
      </CustomTabPanel>
    </Box>
  )
}
