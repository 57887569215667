import { fetchWithToken, getHeaders } from 'src/utils/utils'
import { IUser } from './AdminUser.interface'
import { createAsyncThunk } from '@reduxjs/toolkit'

const BASE_URL = process.env.REACT_APP_API || 'NO_URL'

export const createUser = async (user: IUser) => {
  try {
    const url = `${BASE_URL}/iam/user/create`
    const headers = getHeaders()

    const response = await fetchWithToken(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(user),
    })

    const data = await response.json()
    if (!response.ok && response) {
      throw new Error(
        data.error.message || '-- A ocurrido un error inesperado --',
      )
    }
    return data
  } catch (error) {
    console.error('Error en la creación de módulo', error)
    throw error
  }
}

export const getUsers = createAsyncThunk(
  'users/getUsers',
  async (_, { rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/iam/user/get-users`
      const headers = getHeaders()

      const response = await fetchWithToken(url, {
        method: 'GET',
        headers,
      })

      const data = await response.json()
      if (!response.ok && response) {
        throw new Error(
          data.error.message || '-- A ocurrido un error inesperado --',
        )
      }
      return {
        data: data.data,
        details: data.detail.totalItems,
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)

export const disableUserOrEnable = async ({
  id,
  active,
}: {
  id: number
  active: boolean
}) => {
  try {
    const url = `${BASE_URL}/iam/user/active/${id}`
    const headers = getHeaders()

    const response = await fetchWithToken(url, {
      method: 'DELETE',
      headers,
      body: JSON.stringify({ active }),
    })

    const data = await response.json()
    if (!response.ok && response) {
      throw new Error(
        data.error.message || '-- A ocurrido un error inesperado --',
      )
    }
    return data
  } catch (error) {
    throw error
  }
}

export const updateUser = async (user: IUser) => {
  try {
    const url = `${BASE_URL}/iam/user/update/${user.userId}`
    const headers = getHeaders()

    const response = await fetchWithToken(url, {
      method: 'PUT',
      headers,
      body: JSON.stringify(user),
    })

    const data = await response.json()
    if (!response.ok && response) {
      throw new Error(
        data.error.message || '-- A ocurrido un error inesperado --',
      )
    }
    return data
  } catch (error) {
    throw error
  }
}
