/* eslint-disable react-hooks/exhaustive-deps */
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { Fragment, useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import BoxWithShadow from 'src/components/BoxWithShadow'
import { useKPIForm } from 'src/hooks/Dashboard/KPIForm/useKPIForm'
import useIsMounted from 'src/hooks/useIsMounted'
import { IKPIFormField } from 'src/redux/Dashboard/KPIForm/KPIForm.interface'
import { IParamsUpdateAnswers } from 'src/services/KPI/KPIForm/KPIForm.interface'

const KPI = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IKPIFormField>()
  const isMounted = useIsMounted()
  const {
    kpiFormList,
    filters,
    filter,
    setFilterForm,
    loadQuestions,
    loadFilters,
    setAnswers,
    resetFiltersFields, restarKPIFormFields
  } = useKPIForm()

  const onSubmit: SubmitHandler<IKPIFormField> = (data) => {

    const answers: IParamsUpdateAnswers[] = []
    kpiFormList?.forEach((question) => {
      if (!question.indicatorQuestions) return
      question.indicatorQuestions.forEach((q) => {
        if (!data.indicatorQuestions) return
        if (
          data.indicatorQuestions[q.questionByMonthId]?.answer !== undefined
        ) {
          answers.push({
            questionByMonthId: q.questionByMonthId,
            answer: data.indicatorQuestions[q.questionByMonthId]?.answer,
          })
        }
      })
    })

    const result = answers
    setAnswers(result)
    loadQuestions()
  }
  //EFECTS

  useEffect(() => {
    if (!isMounted) return
    restarKPIFormFields()
    loadFilters()
    resetFiltersFields()
  }, [isMounted])

  return (
    <BoxWithShadow sx={{
      padding: 2
    }}>
      <Container>
        <Typography variant="h4" textAlign={'center'}>
          KPIs estratégicos del área {filter.areaName}
        </Typography>
        <Typography
          variant="body2"
          textAlign={'center'}
          m={'auto'}
          marginBlock={2}
          maxWidth={550}
        >
          ¡Hola! Por favor diligencia la información solicitada para el cálculo
          de los KPIs de tu área para abril de 2024. La fecha máxima de envío de
          la información es el 10 de mayo de 2024.
        </Typography>
        {/* <pre>{JSON.stringify(filters, null, 2)}</pre> */}
        <form
          onSubmit={(e) => {
            e.preventDefault()
            loadQuestions()
          }}
        >
          <Stack direction={'row'} marginBlock={4} gap={2}>
            <Autocomplete
              sx={{
                width: '200px',
              }}
              inputValue={filter.areaName ?? ''}
              clearIcon={null}
              options={filters ?? []}
              onChange={(event, newValue) => {
                setFilterForm({
                  ...filter,
                  areaName: newValue?.areaName ?? '',
                })
              }}
              getOptionLabel={(option) => option.areaName}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Area"
                  placeholder="Selecciona un area"
                />
              )}
            />
            {filters?.find((f) => f.areaName === filter.areaName)?.channels
              ?.length ? (
              <Autocomplete
                sx={{
                  width: '200px',
                }}
                clearIcon={null}
                value={
                  filters
                    ?.find((f) => f?.areaName === filter?.areaName)
                    ?.channels?.find(
                      (channel) => channel.channelId === filter.channelId,
                    ) ?? null
                }
                options={
                  filters?.find((f) => f.areaName === filter.areaName)
                    ?.channels ?? []
                }
                onChange={(event, newValue) => {
                  setFilterForm({
                    ...filter,
                    channelId: newValue?.channelId ?? 0,
                  })
                }}
                isOptionEqualToValue={(option, value) =>
                  option.channelName === value.channelName
                }
                getOptionLabel={(option) => option.channelName}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Canal"
                    placeholder="Selecciona un canal"
                  />
                )}
              />
            ) : null}
            {/* periods */}
            <Autocomplete
              sx={{
                width: '200px',
              }}
              clearIcon={null}
              value={
                filters
                  ?.find((f) => f?.areaName === filter?.areaName)
                  ?.periods?.find(
                    (channel) => channel.periodId === filter.periodId,
                  ) ?? null
              }
              options={
                filters?.find((f) => f.areaName === filter.areaName)?.periods ??
                []
              }
              onChange={(event, newValue) => {
                setFilterForm({
                  ...filter,
                  periodId: newValue?.periodId ?? 0,
                })
              }}
              isOptionEqualToValue={(option, value) =>
                option.periodName === value.periodName
              }
              getOptionLabel={(option) => option.periodName}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Periodo"
                  placeholder="Selecciona un periodo"
                />
              )}
            />
            {/* quarters */}
            <Autocomplete
              sx={{
                width: '200px',
              }}
              clearIcon={null}
              value={
                filters
                  ?.find((f) => f?.areaName === filter?.areaName)
                  ?.periods?.find(
                    (channel) => channel.periodId === filter.periodId,
                  )
                  ?.quarters?.find((q) => q.quarterId === filter.quarterId) ??
                null
              }
              options={
                filters
                  ?.find((f) => f.areaName === filter.areaName)
                  ?.periods?.find((p) => p.periodId === filter.periodId)
                  ?.quarters ?? []
              }
              onChange={(_, newValue) => {
                setFilterForm({
                  ...filter,
                  quarterId: newValue?.quarterId ?? 0,
                })
              }}
              isOptionEqualToValue={(option, value) =>
                option.quarterName === value.quarterName
              }
              getOptionLabel={(option) => option.quarterName}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Trimestre"
                  placeholder="Selecciona un trimestre"
                />
              )}
            />
            <Autocomplete
              sx={{
                width: '200px',
              }}
              clearIcon={null}
              options={
                filters
                  ?.find((f) => f.areaName === filter.areaName)
                  ?.periods?.find((p) => p.periodId === filter.periodId)
                  ?.quarters?.find((q) => q.quarterId === filter.quarterId)
                  ?.months ?? []
              }
              getOptionLabel={(option) => option.monthName}
              value={
                filters
                  ?.find((f) => f?.areaName === filter?.areaName)
                  ?.periods?.find(
                    (channel) => channel.periodId === filter.periodId,
                  )
                  ?.quarters?.find((q) => q.quarterId === filter.quarterId)
                  ?.months?.find((m) => m.monthNumber === filter.monthNumber) ??
                null
              }
              isOptionEqualToValue={(option, value) => {
                return option.monthNumber === value.monthNumber
              }}
              onChange={(event, newValue) => {
                setFilterForm({
                  ...filter,
                  monthNumber: newValue?.monthNumber ?? 0,
                })
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Mes"
                  placeholder="Selecciona un mes"
                />
              )}
            />
            <Button variant="contained" color="primary" type="submit">
              <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" />
            </Button>
          </Stack>
        </form>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            {kpiFormList?.map((question, index) => (
              <Fragment key={question.indicatorId}>
                <Typography variant="h6">
                  Indicador "{question.indicatorName}"
                </Typography>
                {question?.indicatorQuestions?.map((q, i) => (
                  <Controller
                    key={i}
                    name={`indicatorQuestions.${q.questionByMonthId}.answer`}
                    control={control}
                    defaultValue={Number(q?.answer ?? 0)} // El valor por defecto inicial para react-hook-form
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={q.question}
                        variant="outlined"
                        value={field.value} // Usar solo 'value' ya que es un campo controlado
                        onChange={(e) => field.onChange(e.target.value)} // Manejar cambios
                        error={
                          !!errors.indicatorQuestions?.[q.questionByMonthId]?.answer
                        }
                        helperText={
                          errors.indicatorQuestions?.[q.questionByMonthId]?.answer &&
                          'Este campo es requerido'
                        }
                      />
                    )}
                  />
                ))}
              </Fragment>

            ))}
            {kpiFormList?.length ? (
              <Button type="submit" variant="contained" color="primary">
                Enviar
              </Button>
            ) : (
              <Typography variant="h6" textAlign={'center'}>
                No hay preguntas para este indicador
              </Typography>
            )}
          </Box>
        </Box>
      </Container>
    </BoxWithShadow>
  )
}

export default KPI
