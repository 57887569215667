/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import {
  Autocomplete,
  Button,
  Chip,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { useAdminPermission } from 'src/hooks/IAM/AdminPermission/useAdminPermission'
import { useAdminEndpoint } from 'src/hooks/IAM/AdminEndpoint/useAdminEndpoint'
import { IModule } from 'src/components/Sidebar/Sidebar.Interface'
import { IEndpointGet } from 'src/services/IAM/AdminEndpoints/AdminEndpoints.interface'
import useIsMounted from 'src/hooks/useIsMounted'
import { useAdminModule } from 'src/hooks/IAM/AdminModule/useAdminModule'

const CreatePermissions = () => {
  const { loadEndpoints, endpoints } = useAdminEndpoint()
  const {
    permissions,
    newPermission,
    setNewPermissionAdmin,
    createPermissionAdmin,
  } = useAdminPermission()
  const { modulesWithoutFormat } = useAdminModule()
  const isMounted = useIsMounted()
  useEffect(() => {
    if (!isMounted) return
    loadEndpoints({
      currentPage: 1,
      pageSize: 100,
    })
  }, [isMounted])

  return (
    <div>
      <Typography variant="h3" textAlign={'center'} marginBottom={4}>
        Crear Permiso
      </Typography>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          createPermissionAdmin(newPermission)
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Autocomplete
              sx={{
                flex: 1,
              }}
              disablePortal
              id="combo-box-demo"
              options={modulesWithoutFormat ?? []}
              multiple
              value={newPermission.modules}
              getOptionLabel={(option: IModule) => `${option.keyName}`}
              onChange={(e, value) => {
                setNewPermissionAdmin({
                  ...newPermission,
                  modules: value,
                })
              }}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option: IModule, index) => (
                  <Chip
                    label={`${option.keyName}`}
                    {...getTagProps({ index })}
                    key={index}
                  />
                ))
              }
              isOptionEqualToValue={(option, value) => {
                return option.moduleId === value.moduleId
              }}
              renderInput={(params) => (
                <TextField {...params} label="Modulos" />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="firstName"
              label="Condición"
              type="text"
              fullWidth
              value={newPermission.condition}
              helperText="Debe ser un JSON"
              onChange={(e) =>
                setNewPermissionAdmin({
                  ...newPermission,
                  condition: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="firstName"
              label="Descripción"
              type="text"
              fullWidth
              value={newPermission.description}
              onChange={(e) =>
                setNewPermissionAdmin({
                  ...newPermission,
                  description: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              sx={{
                flex: 1,
              }}
              disablePortal
              id="combo-box-demo"
              options={endpoints}
              value={
                permissions?.find(
                  (permission) =>
                    permission.permissionId === newPermission.endpointId,
                ) as IEndpointGet | null | undefined
              }
              onChange={(e, value) => {
                setNewPermissionAdmin({
                  ...newPermission,
                  endpointId: value?.endpointId ?? 0,
                })
              }}
              getOptionLabel={(option) => `${option.appName}:${option.path}`}
              isOptionEqualToValue={(option, value) => {
                return option?.endpointId === value?.endpointId
              }}
              renderInput={(params) => (
                <TextField {...params} label="Endpoint" />
              )}
            />
          </Grid>
        </Grid>
        <Button
          sx={{ width: '250px', mt: 2, height: '50px' }}
          type="submit"
          variant="contained"
        >
          Crear
        </Button>
      </form>
    </div>
  )
}

export default CreatePermissions
