import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material'
import React, { useState } from 'react'
import { useStrategies } from 'src/hooks/Dashboard/Strategies/useStrategies'

const FiltersIndicator = () => {
  const [open, setOpen] = useState(false)
  const {
    createNewStrategy,
    updateStrategy,
    strategy,
  } = useStrategies()
  return (
    <div>
      <Stack direction={'row'} justifyContent={'flex-end'} gap={2} my={2}>
        <Button onClick={() => setOpen(true)} variant="contained">
          Crear estrategia
        </Button>
      </Stack>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle id="alert-dialog-title">Crear estrategia</DialogTitle>
        <DialogContent
          sx={{
            minWidth: 500,
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault()
              createNewStrategy(strategy)
            }}
          >
            <Stack
              gap={2}
              flexWrap={'wrap'}
              sx={{
                my: 2,
              }}
            >
              <TextField
                sx={{
                  flex: 1,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={strategy.strategyName}
                onChange={(e) =>
                  updateStrategy({
                    ...strategy,
                    strategyName: e.target.value,
                  })
                }
                required
                label="Nombre"
                placeholder="Nombre de la estrategia"
              />
              <TextField
                sx={{
                  flex: 1,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Ecuación"
                value={strategy.detailLogical}
                onChange={(e) =>
                  updateStrategy({
                    ...strategy,
                    detailLogical: e.target.value,
                  })
                }
                placeholder="Detalle de la ecuación"
                required
              />
              <Autocomplete
                options={['goal', 'compliance', 'executed']}
                sx={{
                  flex: 1,
                }}
                getOptionLabel={(option) => option}
                isOptionEqualToValue={(option, value) => option === value}
                value={strategy.type}
                onChange={(_, value) =>
                  updateStrategy({
                    ...strategy,
                    type: value ?? '',
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    label="Tipo"
                    placeholder="Tipo de estrategia"
                  />
                )}
              />
              <TextField
                sx={{
                  flex: 1,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Descripción"
                value={strategy.description}
                onChange={(e) =>
                  updateStrategy({
                    ...strategy,
                    description: e.target.value,
                  })
                }
                required
                placeholder="Descripción de la estrategia"
              />
              <Autocomplete
                multiple
                options={
                  strategy?.parameters?.map((option) => option.parameterName) ??
                  []
                }
                value={strategy?.parameters?.map(
                  (option) => option.parameterName,
                )}
                onChange={(_, value) => {
                  updateStrategy({
                    ...strategy,
                    parameters: value.map((parameterName) => ({
                      parameterName,
                    })),
                  })
                }}
                freeSolo
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => {
                    const { key, ...tagProps } = getTagProps({ index })
                    return (
                      <Chip
                        variant="outlined"
                        label={option}
                        key={key}
                        {...tagProps}
                      />
                    )
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Parámetros"
                    placeholder="Parámetros"
                  />
                )}
              />
              <Button fullWidth type="submit" variant="contained">
                Crear
              </Button>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default FiltersIndicator
