import React, { useState } from 'react'
import { Select, MenuItem, Stack, SelectChangeEvent } from '@mui/material'
import { IModule } from '../Sidebar/Sidebar.Interface'
import { useAdminModule } from 'src/hooks/IAM/AdminModule/useAdminModule'

interface Props {
  /**
   * Data that includes the modules and submodules to be displayed in the autocomplete.
   * Can be `null` if no data is available.
   */
  data?: IModule | null
}

/**
 * `InfiniteAutocomplete` is a recursive component that renders a select dropdown of submodules
 * within modules. It allows infinite nested selection, where each selected submodule displays
 * another select with its own submodules.
 *
 * @component
 * @example
 * ```tsx
 * <InfiniteAutocomplete data={moduleData} />
 * ```
 * @param {Props} props - The props object containing the module data.
 * @returns {JSX.Element} The rendered component.
 *
 * @description
 * This component is useful for handling nested structures where each selected submodule can
 * lead to more submodules, forming an infinite hierarchy of selections. It uses `useState` to
 * manage the selected submodule and renders nested `InfiniteAutocomplete` components recursively.
 *
 * @see [Select](https://mui.com/material-ui/react-select/) - Material UI Select component.
 * @see [useAdminModule](#) - Custom hook to handle admin module actions, such as selecting modules.
 */
const InfiniteAutocomplete = ({ data }: Props) => {
  const { addModuleSelect } = useAdminModule()
  const [selectedSubModule, setSelectedSubModule] = useState<string | null>(
    null,
  )

  /**
   * Handles the change event when a submodule is selected. Updates the selected submodule state
   * and adds the selected submodule using `addModuleSelect`.
   * @param {SelectChangeEvent} event - The event triggered by changing the selected submodule.
   */
  const handleSelectChange = (event: SelectChangeEvent) => {
    console.log('event.target.value', event.target.value)

    setSelectedSubModule(event.target.value as string)
    const selectedModule = data?.subModules?.find(
      (subModule) => subModule.keyName === event.target.value,
    )
    if (selectedModule) {
      addModuleSelect(selectedModule)
    }
  }

  /**
   * Retrieves the selected submodule data based on the current selection.
   */
  const selectedSubModuleData = data?.subModules?.find(
    (subModule) => subModule.keyName === selectedSubModule,
  )

  return (
    <Stack direction={'row'}>
      {data?.subModules && data.subModules.length ? (
        <Select
          value={selectedSubModule || ''}
          onChange={(e) => handleSelectChange(e)}
          displayEmpty
          sx={{
            width: 200,
            minWidth: 120,
            mr: 2,
          }}
        >
          <MenuItem value="">
            <em>PRINCIPAL</em>
          </MenuItem>
          {data?.subModules?.map((subModule, index) => (
            <MenuItem key={index} value={subModule.keyName}>
              {subModule.keyName}
            </MenuItem>
          ))}
        </Select>
      ) : null}

      {selectedSubModuleData && (
        <Stack direction={'row'} spacing={2}>
          <InfiniteAutocomplete data={selectedSubModuleData} />
        </Stack>
      )}
    </Stack>
  )
}

export default InfiniteAutocomplete
