import { faCss3Alt, faHashnode } from '@fortawesome/free-brands-svg-icons'
import { ILocalRoutes } from '../Sidebar/Sidebar.Interface'
import {
  faUpload,
  faSearch,
  faFileInvoiceDollar,
  faChartSimple,
  faChartPie,
  faCogs,
  faChartLine,
  faUsers,
  faCube,
  faShapes,
  faCodeMerge,
  faPersonChalkboard,
  faLayerGroup,
  faLocationDot,
  IconDefinition,
  faCalculator,
  faMobileScreen,
  faRectangleList,
  faPercent,
  faGears,
  faSliders,
} from '@fortawesome/free-solid-svg-icons'
import keyPagoLogo from 'src/assets/images/keypago-short-logo.svg'
/**
 * Represents the routes configuration for the DashboardMenu component.
 */
export const routes: ILocalRoutes[] = [
  {
    keyName: 'iam',
    icon: faCogs,
    disabled: false,
    title: 'IAM',
    route: '/iam',
    color: '#201646',
    subModules: [
      {
        keyName: 'users',
        title: 'Usuarios',
        icon: faUsers,
        description: 'Crea, modifica o elimina usuarios existentes',
        route: '/iam/administrar-usuarios',
        color: '#201646',
        disabled: false,
      },
      {
        keyName: 'modules',
        title: 'Módulos',
        icon: faCodeMerge,
        description: 'Crea, modifica o elimina módulos existentes',
        route: '/iam/administrar-modulos',
        color: '#201646',
        disabled: false,
      },
      {
        keyName: 'apps',
        title: 'Aplicaciones',
        icon: faShapes,
        description: 'Crea, modifica o elimina aplicaciones existentes',
        route: '/iam/administrar-aplicaciones',
        color: '#201646',
        disabled: false,
      },
      {
        keyName: 'endpoints',
        title: 'Endpoints',
        icon: faCube,
        description: 'Crea, modifica o elimina endpoints existentes',
        route: '/iam/administrar-endpoints',
        color: '#201646',
        disabled: false,
      },
      // {
      //   keyName: 'resources',
      //   title: 'Recursos',
      //   icon: faCube,
      //   description: 'Crea, modifica o elimina recursos existentes',
      //   route: '/iam/administrar-recursos',
      //   color: '#201646',
      //   disabled: false,
      // },
      {
        keyName: 'permissions',
        title: 'Permisos',
        icon: faPersonChalkboard,
        description: 'Crea, modifica o elimina permisos existentes',
        route: '/iam/administrar-permisos',
        color: '#201646',
        disabled: false,
      },
      {
        keyName: 'groups',
        title: 'Grupos',
        icon: faLayerGroup,
        description: 'Crea, modifica o elimina grupos existentes',
        route: '/iam/administrar-grupos',
        color: '#201646',
        disabled: false,
      },
    ],
  },
  {
    keyName: 'keypago',
    title: 'keypago',
    description: 'keypago app',
    icon: keyPagoLogo as unknown as IconDefinition,
    disabled: false,
    route: '/keypago',
    color: '#201646',
    subModules: [
      {
        keyName: 'keypagoUsers',
        title: 'Usuarios',
        description: 'Administra los usuarios de keypago',
        icon: faUsers,
        route: '/keypago/users',
        color: '#201646',
      },
      {
        keyName: 'keypagoUsersHistory',
        title: 'Historial',
        description: 'Administra los usuarios de keypago',
        icon: faPercent,
        route: '/keypago/users/credit-info-view',
        color: '#201646',
        paramsRoute: {
          userType: 'new',
        },
      },
      {
        keyName: 'keypagoConfigModel',
        title: 'Configuracion de Modelos',
        description: 'Configura los modelos de configuracion global',
        icon: faGears,
        route: '/keypago/config-model',
        color: '#201646',
        paramsRoute: {
          userType: 'new',
        },
      },
      {
        keyName: 'keypagoConfigOnboarding',
        title: 'Configuracion Onboarding',
        description: 'Configura el proceso de onboarding',
        icon: faSliders,
        route: '/keypago/config-onboarding',
        color: '#201646',
        paramsRoute: {
          userType: 'new',
        },
      },
    ],
  },
  {
    keyName: 'marketing',
    title: 'Marketing',
    description: 'KPIs de tráfico, leads, conversiones, campañas etc.',
    icon: faChartSimple,
    disabled: false,
    route: '/marketing',
    color: '#201646',
    subModules: [
      {
        keyName: 'budgets',
        title: 'Presupuestos',
        description: 'Controla tus presupuestos de marketing',
        icon: faChartSimple,
        route: '/marketing/presupuestos',
        color: '#201646',
      },
      {
        keyName: 'debugZones',
        title: 'Depuración de Zonas',
        description: 'Depura las zonas de tu aplicación',
        icon: faLocationDot,
        route: '/marketing/depuracion-de-zonas',
        color: '#201646',
      },
    ],
  },
  {
    keyName: 'accounting',
    icon: faCalculator,
    title: 'Contabilidad',
    route: '/contabilidad',
    color: '#201646',
    subModules: [
      {
        keyName: 'upload-file',
        title: 'Subir archivo',
        description: 'Sube tus archivos contables aquí',
        icon: faUpload,
        disabled: false,
        route: '/contabilidad/subir-archivo',
        color: '#201646',
      },
      {
        keyName: 'sales',
        title: 'Ventas',
        icon: faSearch,
        disabled: false,
        description: 'Encuentra tus franquicias, pagos, ventas y mas.',
        route: '/contabilidad/buscar-archivos-ventas',
        color: '#201646',
      },
      {
        keyName: 'treasury',
        title: 'Tesoreria',
        icon: faFileInvoiceDollar,
        disabled: false,
        description: 'Informes de ventas, pagos, anulaciones y recaudos.',
        route: '/dashboard/tesoreria',
        color: '#201646',
      },
    ],
  },
  {
    keyName: 'dashboard',
    icon: faChartLine,
    title: 'Dashboard',
    route: '/dashboard',
    color: '#201646',
    subModules: [
      {
        keyName: 'kpi',
        title: 'KPIs Generales',
        description: 'KPIs de tráfico, leads, conversiones, campañas etc',
        icon: faChartPie,
        route: '/dashboard/kpi',
        color: '#201646',
      },
      {
        keyName: 'formsKPI',
        title: 'Formularios KPI',
        description:
          'Formularios de KPIs de tráfico, leads, conversiones, campañas etc',
        icon: faChartPie,
        route: '/dashboard/formularios-kpi',
        color: '#201646',
      },
      {
        keyName: 'indicators',
        title: 'Indicadores',
        icon: faChartLine,
        disabled: false,
        description: 'Indicadores de ventas, pagos, anulaciones y recaudos.',
        route: '/dashboard/indicadores',
        color: '#201646',
      },
      {
        keyName: 'strategy',
        title: 'Estrategias',
        icon: faChartLine,
        disabled: false,
        description: 'Estrategias de ventas, pagos, anulaciones y recaudos.',
        route: '/dashboard/estrategias',
        color: '#201646',
      },
    ],
  },
]
