import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Button,
} from '@mui/material'
import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIndicators } from 'src/hooks/Dashboard/Indicators/useIndicators'

const TableIndicator = () => {
  const { indicators } = useIndicators()
  const navigate = useNavigate()
  return (
    <div>
      <Fragment>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Descripción</TableCell>
                  <TableCell>Canal</TableCell>
                  <TableCell>Nombre area</TableCell>
                  <TableCell>Etiqueta 1</TableCell>
                  <TableCell>Etiqueta 2</TableCell>
                  <TableCell>Estrategias</TableCell>
                  <TableCell align="right">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {indicators.length > 0 ? (
                  indicators?.map((indicator, index) => (
                    <TableRow key={index}>
                      <TableCell>{indicator.indicatorName}</TableCell>
                      <TableCell>{indicator.indicatorDescription}</TableCell>
                      <TableCell>{indicator.channelName}</TableCell>
                      <TableCell>{indicator.areaName}</TableCell>
                      <TableCell>{indicator.indicatorLabelOne}</TableCell>
                      <TableCell>{indicator.indicatorLabelTwo}</TableCell>
                      <TableCell>
                        {indicator.strategies.map((strategy, i) => (
                          <span key={i}>{strategy.strategyName} </span>
                        ))}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          variant="contained"
                          onClick={() => navigate(`/dashboard/indicadores/${indicator.indicatorId}`)}
                        >
                          Ver más
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      No hay indicadores
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={indicators.length}
            rowsPerPage={25}
            page={0}
            onPageChange={() => { }}
            onRowsPerPageChange={() => { }}
          />
        </Paper>
      </Fragment>
    </div>
  )
}

export default TableIndicator
