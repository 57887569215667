import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux'
import {
  hideLoading,
  showLoading,
} from 'src/redux/Components/Loading/LoadingSlice'

const useLoading = () => {
  const dispatch = useDispatch()
  const { isLoading } = useSelector((state: RootState) => state.loading)
  const startLoading = () => dispatch(showLoading())
  const stopLoading = () => dispatch(hideLoading())
  return {
    isLoading,
    startLoading,
    stopLoading,
  }
}

export default useLoading
