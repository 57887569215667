import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material'
import React from 'react'
import { useIndicators } from 'src/hooks/Dashboard/Indicators/useIndicators'
import { useKPIForm } from 'src/hooks/Dashboard/KPIForm/useKPIForm'
import { useStrategies } from 'src/hooks/Dashboard/Strategies/useStrategies'
const CreateIndicator = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: (open: boolean) => void
}) => {
  const { createNewIndicator, indicatorCreate, updateIndicatorCreate } = useIndicators()
  const { filters } = useKPIForm()
  const { strategies } = useStrategies()
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Crear Indicador</DialogTitle>
      <DialogContent>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            createNewIndicator(indicatorCreate)
            setOpen(false)
          }}
        >
          <Stack
            gap={2}
            flexWrap={'wrap'}
            direction={'row'}
            sx={{
              my: 2,
            }}
          >
            <TextField
              sx={{
                flex: '1 1 100%',
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={indicatorCreate.indicatorName}
              onChange={(event) => {
                updateIndicatorCreate({
                  ...indicatorCreate,
                  indicatorName: event.target.value,
                })
              }}
              label="Nombre del indicador"
              placeholder="Nombre del indicador"
              type="text"
              required
            />
            <Autocomplete
              options={filters ?? []}
              sx={{
                flex: 1,
              }}
              value={
                filters?.find((filter) => filter.areaId === indicatorCreate.areaId) ||
                null
              }
              onChange={(event, newValue) => {
                updateIndicatorCreate({
                  ...indicatorCreate,
                  areaId: newValue?.areaId ?? null,
                })
              }}
              getOptionLabel={(option) => option.areaName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Area"
                  placeholder="Nombre de área"
                  required
                />
              )}
            />
            <Autocomplete
              options={filters?.map((filter) => filter.channels).flat() ?? []}
              sx={{
                flex: '1 1 100%',
              }}
              getOptionLabel={(option) => option?.channelName ?? ''}
              value={
                filters
                  ?.map((filter) => filter.channels)
                  .flat()
                  .find(
                    (channel) => channel?.channelId === indicatorCreate?.channelId,
                  ) || null
              }
              onChange={(event, newValue) => {
                updateIndicatorCreate({
                  ...indicatorCreate,
                  channelId: newValue?.channelId ?? null,
                })
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Canal"
                  placeholder="Canal"
                  required
                />
              )}
            />
            <TextField
              sx={{
                flex: '1 1 100%',
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={indicatorCreate.indicatorDescription}
              onChange={(event) => {
                updateIndicatorCreate({
                  ...indicatorCreate,
                  indicatorDescription: event.target.value,
                })
              }}
              label="Descripción"
              placeholder="Descripción"
              type="text"
              required
            />
            <TextField
              sx={{
                flex: '1 1 48%',
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Etiqueta 1"
              placeholder="Etiqueta 1"
              type="text"
              value={indicatorCreate.indicatorLabelOne}
              required
              onChange={(event) => {
                updateIndicatorCreate({
                  ...indicatorCreate,
                  indicatorLabelOne: event.target.value,
                })
              }}
            />
            <TextField
              sx={{
                flex: '1 1 48%',
              }}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Etiqueta 2"
              label="Etiqueta 2"
              type="text"
              required
              value={indicatorCreate.indicatorLabelTwo}
              onChange={(event) => {
                updateIndicatorCreate({
                  ...indicatorCreate,
                  indicatorLabelTwo: event.target.value,
                })
              }}
            />
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={strategies.flatMap((strategy) => {
                return strategy.strategies.map((strategyInter) => {
                  return {
                    ...strategyInter,
                    strategyType: strategy.strategyType,
                  }
                })
              })}
              className="form-group-input"
              fullWidth
              multiple
              groupBy={(option) => option.strategyType || ''}
              getOptionLabel={(option) =>
                `${option.strategyId} - ${option.strategyName}`
              }
              onChange={(event, newValue) => {
                updateIndicatorCreate({
                  ...indicatorCreate,
                  strategies: newValue.map((strategy) => {
                    return {
                      strategyId: strategy.strategyId,
                    }
                  }),
                })
              }}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={`${option.strategyId} - ${option.strategyName}`}
                    {...getTagProps({ index })}
                    key={index}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Estrategias"
                  placeholder="Escribe una o más estrategias"
                />
              )}
            />

            <Button type="submit" fullWidth variant="contained">
              Crear
            </Button>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default CreateIndicator
