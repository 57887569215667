import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';

interface AlertDialogProps {
  /**
   * The title of the dialog.
   * @since 1.0.0
   */
  title: string;

  /**
   * The label for the button that opens the dialog.
   * @since 1.0.0
   */
  buttonLabel: string;

  /**
   * The label for the "agree" button. Optional.
   * @default "Agree"
   * @since 1.0.0
   */
  agreeLabel?: string;

  /**
   * The label for the "disagree" button. Optional.
   * @default "Disagree"
   * @since 1.0.0
   */
  disagreeLabel?: string;

  /**
   * Child components or elements to display inside the dialog.
   * @since 1.0.0
   */
  children?: React.ReactNode;

  /**
   * Whether the dialog requires confirmation input (e.g., deleting a specific name).
   * @default false
   * @since 1.0.0
   */
  withConfirmation?: boolean;

  /**
   * The name that needs to be typed for confirmation (e.g., for deletion). Used with `withConfirmation`.
   * @since 1.0.0
   */
  nameToDelete?: string;

  /**
   * The maximum width of the dialog. Possible values are 'xs', 'sm', 'md', 'lg', 'xl', or `false`.
   * @default "md"
   * @since 1.0.0
   */
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;

  /**
   * The color of the buttons. One of the MUI color options.
   * @default "primary"
   * @since 1.0.0
   */
  color?: 'inherit' | 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning';

  /**
   * Callback function when the user clicks "agree".
   * @since 1.0.0
   */
  onAgree?: () => void;

  /**
   * Callback function when the dialog is closed.
   * @since 1.0.0
   */
  onClose?: () => void;

  /**
   * Callback function when the user clicks "disagree".
   * @since 1.0.0
   */
  onDisagree?: () => void;

  /**
   * Callback function when the dialog is opened.
   * @since 1.0.0
   */
  onOpen?: () => void;
}

/**
 * A reusable alert dialog component that provides the ability to present users with choices,
 * optionally requiring confirmation input for actions like deletions.
 * 
 * The dialog includes options for custom button labels, titles, and even the requirement of a confirmation name input.
 * 
 * @component
 * @category User Interface Components
 * @since 1.0.0
 * 
 * @param {AlertDialogProps} props - The props for configuring the alert dialog.
 * 
 * @returns {JSX.Element} A dialog component for handling user actions with optional confirmation.
 * 
 * @example
 * ```tsx
 * <AlertDialog 
 *   title="Confirm Deletion"
 *   buttonLabel="Delete Item"
 *   agreeLabel="Yes, Delete"
 *   disagreeLabel="Cancel"
 *   withConfirmation={true}
 *   nameToDelete="ItemName"
 *   onAgree={() => console.log('Item Deleted')}
 * />
 * ```
 */
export default function AlertDialog(props: AlertDialogProps) {
  const [open, setOpen] = React.useState(false);
  const [nombre, setNombre] = React.useState(''); // Estado para el valor del nombre

  /**
   * Opens the dialog and triggers the onOpen callback if provided.
   * 
   * @since 1.0.0
   */
  const handleClickOpen = () => {
    setOpen(true);
    props.onOpen?.();
  };

  /**
   * Closes the dialog and triggers the onClose callback if provided.
   * 
   * @since 1.0.0
   */
  const handleClose = () => {
    setOpen(false);
    props.onClose?.();
  };

  /**
   * Triggers the onAgree callback and closes the dialog.
   * 
   * @since 1.0.0
   */
  const handleAgree = () => {
    props.onAgree?.();
    handleClose();
  };

  /**
   * Triggers the onDisagree callback and closes the dialog.
   * 
   * @since 1.0.0
   */
  const handleDisagree = () => {
    props.onDisagree?.();
    handleClose();
  };

  /**
   * Handles the input change in the confirmation text field (if required).
   * 
   * @param {React.ChangeEvent<HTMLInputElement>} event - The input change event for the name field.
   * @since 1.0.0
   */
  const handleNombreChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNombre(event.target.value);
  };

  return (
    <React.Fragment>
      <Button
        color={props.color || 'primary'}
        variant="contained"
        onClick={handleClickOpen}
        sx={{ height: 'fit-content' }}
      >
        {props.buttonLabel || 'Open alert dialog'}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-paper': {
            width: 'fit-content',
            maxWidth: props.maxWidth || 'md',
            minWidth: '350px',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {props.title || 'Default Title'}
        </DialogTitle>
        <DialogContent sx={{ pt: '10px !important' }}>
          {props.children}
          {props.withConfirmation && (
            <TextField
              sx={{ width: '100%', mt: 2 }}
              id="outlined-basic"
              label="Nombre a eliminar"
              variant="outlined"
              size="small"
              value={nombre}
              onChange={handleNombreChange}
              required
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisagree} color={props.color || 'primary'}>
            {props.disagreeLabel || 'Disagree'}
          </Button>
          {props.withConfirmation && (
            <Button
              onClick={handleAgree}
              autoFocus
              disabled={!nombre || nombre !== props.nameToDelete}
              variant="contained"
              color={props.color || 'primary'}
            >
              {props.agreeLabel || 'Agree'}
            </Button>
          )}
          {!props.withConfirmation && (
            <Button onClick={handleAgree} autoFocus variant="contained">
              {props.agreeLabel || 'Agree'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
