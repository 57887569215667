// usePagination.ts
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/redux/index'
import {
  setCurrentPage,
  setRowsPerPage,
  setTotalItems,
} from 'src/redux/Components/Pagination/PaginationSlice'

export const usePagination = () => {
  const dispatch = useDispatch()
  const { currentPage, rowsPerPage, rowsPerPageOptions, totalItems } =
    useSelector((state: RootState) => state.pagination)

  const handlePageChange = (event: unknown, newPage: number) => {
    if (newPage <= 0) {
      dispatch(setCurrentPage(0))
      return
    }
    dispatch(setCurrentPage(newPage))
  }

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)))
  }

  const handleTotalItems = (total: number) => {
    dispatch(setTotalItems(total ?? 0))
  }

  return {
    currentPage,
    rowsPerPage,
    rowsPerPageOptions,
    totalItems,
    handleTotalItems,
    handlePageChange,
    handleRowsPerPageChange,
  }
}
