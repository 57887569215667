import { Fragment } from 'react'
import {
  Autocomplete,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { ChangeEvent, useState } from 'react'
import { MagicKindOfStore } from '../SearchFilesCollections/SearchFilesCollections.interface'
import AlertDialog from 'src/components/Dialog'
import { Box, Paper } from '@mui/material'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import GlobalLoading from 'src/components/Loadings/GlobalLoading'
import useLoading from 'src/hooks/useLoading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
const Budgets = () => {
  const { isLoading, startLoading, stopLoading } = useLoading()
  const [filters, setFilters] = useState({
    type: '',
    responsible: '',
    costCenter: '',
    regionalTrade: '',
    channel: '',
    companyName: '',
    pk: '',
  })
  const handleOnChange = ({
    value,
    propierty,
  }: {
    value: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string
    propierty:
      | 'type'
      | 'costCenter'
      | 'responsible'
      | 'regionalTrade'
      | 'channel'
      | 'companyName'
      | 'pk'
  }) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [propierty]: value,
    }))
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    console.log('filters', filters)
  }
  const users = [
    {
      userDocId: '123456',
      firstName: 'Juan',
      lastName: 'Perez',
      email: '',
      active: true,
      phone: '123456789',
      type: 'admin',
    },
    {
      userDocId: '123456',
      firstName: 'Juan',
      lastName: 'Perez',
      email: '',
      active: true,
      phone: '123456789',
      type: 'admin',
    },
    {
      userDocId: '123456',
      firstName: 'Juan',
      lastName: 'Perez',
      email: '',
      active: true,
      phone: '123456789',
      type: 'admin',
    },
    {
      userDocId: '123456',
      firstName: 'Juan',
      lastName: 'Perez',
      email: '',
      active: true,
      phone: '123456789',
      type: 'admin',
    },
    {
      userDocId: '123456',
      firstName: 'Juan',
      lastName: 'Perez',
      email: '',
      active: true,
      phone: '123456789',
      type: 'admin',
    },
  ]

  return (
    <div>
      <Typography mb={4} variant="h4">
        Depuración de zonas
      </Typography>
      <Stack direction={'row-reverse'} gap={2} mb={2} flexWrap={'wrap'}>
        <AlertDialog
          title="Filtros"
          buttonLabel="Crear Zona"
          children={
            <form onSubmit={handleSubmit}>
              <Stack direction={'row'} gap={2} mt={2} flexWrap={'wrap'}>
                <Autocomplete
                  sx={{
                    width: '30%',
                    maxWidth: '380px',
                  }}
                  clearIcon={null}
                  options={[
                    MagicKindOfStore.Nalsani,
                    MagicKindOfStore.Franquicias,
                    MagicKindOfStore.Distribuidores,
                  ]}
                  value={filters.type}
                  onChange={(e, value) => {
                    handleOnChange({ value: value ?? '', propierty: 'channel' })
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label="Tipo de tienda"
                      placeholder="Tipo de tienda"
                    />
                  )}
                />
                <TextField
                  required
                  sx={{
                    width: '30%',
                    maxWidth: '380px',
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Centro de costos"
                  label="Centro de costos"
                  onChange={(e) =>
                    handleOnChange({
                      value: e.target.value,
                      propierty: 'costCenter',
                    })
                  }
                  value={filters.costCenter}
                />
                <TextField
                  required
                  sx={{
                    width: '30%',
                    maxWidth: '380px',
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Responsable"
                  label="Responsable"
                  onChange={(e) =>
                    handleOnChange({
                      value: e.target.value,
                      propierty: 'responsible',
                    })
                  }
                  value={filters.responsible}
                />
                <TextField
                  required
                  sx={{
                    width: '30%',
                    maxWidth: '380px',
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Regional"
                  label="Regional"
                  onChange={(e) =>
                    handleOnChange({
                      value: e.target.value,
                      propierty: 'regionalTrade',
                    })
                  }
                  value={filters.regionalTrade}
                />
                <Autocomplete
                  sx={{
                    width: '30%',
                    maxWidth: '380px',
                  }}
                  clearIcon={null}
                  options={[
                    'PREMIUM',
                    'MOVILES',
                    'OUTLET',
                    'ESTANDART',
                    'TODOS',
                  ]}
                  value={filters.channel}
                  onChange={(e, value) => {
                    handleOnChange({ value: value ?? '', propierty: 'channel' })
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label="Canal"
                      placeholder="Busca tu canal"
                    />
                  )}
                />
                <TextField
                  required
                  sx={{
                    width: '30%',
                    maxWidth: '380px',
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Nombre de la empresa"
                  label="Nombre de la empresa"
                  onChange={(e) =>
                    handleOnChange({
                      value: e.target.value,
                      propierty: 'companyName',
                    })
                  }
                  value={filters.companyName}
                />
                <TextField
                  required
                  sx={{
                    width: '30%',
                    maxWidth: '380px',
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="PK"
                  label="PK"
                  onChange={(e) =>
                    handleOnChange({ value: e.target.value, propierty: 'pk' })
                  }
                  value={filters.pk}
                />
              </Stack>
            </form>
          }
        />
        {/*Descargar*/}
        <Button
          variant="contained"
          color="primary"
          onClick={() => console.log('Descargar')}
        >
          <FontAwesomeIcon icon={faFileExcel} />
        </Button>
      </Stack>
      {isLoading && <GlobalLoading />}
      <Paper>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Tipo de tienda</TableCell>
                <TableCell>Centro de costos</TableCell>
                <TableCell>Responsable</TableCell>
                <TableCell>Regional</TableCell>
                <TableCell>Canal</TableCell>
                <TableCell>Nombre de la empresa</TableCell>
                <TableCell>PK</TableCell>

                <TableCell align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users && users.length ? (
                users.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell>Nalsani</TableCell>
                    <TableCell>123456</TableCell>
                    <TableCell>Juan Perez</TableCell>
                    <TableCell>Regional</TableCell>
                    <TableCell>Moviles</TableCell>
                    <TableCell>Nombre de la empresa</TableCell>
                    <TableCell>PK</TableCell>
                    <TableCell align="right">
                      {user.active ? (
                        <Stack
                          gap={2}
                          direction="row"
                          justifyContent={'flex-end'}
                        >
                          <AlertDialog
                            title={`Editar user ${user.firstName} ${user.lastName}`}
                            buttonLabel={'Editar'}
                            disagreeLabel="Cancelar"
                            agreeLabel="Editar"
                            onDisagree={() => console.log('user no editado')}
                            children={<></>}
                          />
                          <AlertDialog
                            buttonLabel={'desactivar'}
                            title={`¿Estás seguro de activar el usuario "${user.firstName} ${user.lastName}"?`}
                            disagreeLabel="Cancelar"
                            agreeLabel="desactivar"
                            color="error"
                            nameToDelete={user.userDocId}
                            withConfirmation
                            onDisagree={() => console.log('user no eliminado')}
                            children={
                              <div>
                                <p>
                                  Al activar el usuario
                                  <strong> {user.userDocId}</strong> podrá
                                  acceder de nuevo al sistema
                                </p>
                                <p>
                                  Escriba <strong>{user.userDocId}</strong> para
                                  confirmar
                                </p>
                              </div>
                            }
                          />
                        </Stack>
                      ) : (
                        <AlertDialog
                          buttonLabel={'activar'}
                          title={`¿Estás seguro de activar el user "${user.firstName} ${user.lastName}"?`}
                          disagreeLabel="Cancelar"
                          agreeLabel="activar"
                          color="success"
                          nameToDelete={user.userDocId}
                          withConfirmation
                          onDisagree={() => console.log('user no eliminado')}
                          children={
                            <div>
                              <p>
                                Al activar el user{' '}
                                <strong>{user.userDocId}</strong> no podrá
                                acceder al sistema
                              </p>
                              <p>
                                Escriba <strong>{user.userDocId}</strong> para
                                confirmar
                              </p>
                            </div>
                          }
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No hay usuarios
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}

export default Budgets
