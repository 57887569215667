/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react'
import {
  Autocomplete,
  Box,
  Chip,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material'
import AlertDialog from 'src/components/Dialog'
import { IUser } from 'src/services/IAM/AdminUser/AdminUser.interface'
import { useAdminGroup } from 'src/hooks/IAM/AdminGroups/useAdminGroups'
import { useAdminPermission } from 'src/hooks/IAM/AdminPermission/useAdminPermission'
import { useUserIAM } from 'src/hooks/IAM/AdminUser/useUserIAM'
import StepperModal from 'src/views/AdminUser/components/StepperModal'
import { INPermission } from 'src/services/IAM/AdminPermissions/AdminPermissions.interface'
import { usePagination } from 'src/hooks/IAM/Components/Pagination/usePagination'
const columns = [
  { label: 'ID', minWidth: 170 },
  { label: 'Nombre', minWidth: 170 },
  { label: 'Descripcion', minWidth: 100 },
  { label: 'Acciones', minWidth: 100 },
]

const TableGroups = () => {
  const {
    currentPage,
    handlePageChange,
    handleRowsPerPageChange,
    rowsPerPage,
    rowsPerPageOptions,
    totalItems,
  } = usePagination()
  const {
    groups,
    updatedGroup,
    setGroupAdmin,
    removeGroup,
    setUpdateGroup,
    updatedGroupAdmin,
  } = useAdminGroup()
  const { loadPermissions, permissions } = useAdminPermission()
  const { usersIAM } = useUserIAM()
  const [selectedPermissions, setSelectedPermissions] = useState<
    INPermission[]
  >([])
  const handlerSelectedPermissions = (permission: INPermission) => {
    console.log(permission, 'permission')

    const updatedPermission = {
      ...permission,
      active: !permission.active,
    }

    const newSelectedPermissions = selectedPermissions.filter(
      (p) => p.permissionId !== permission.permissionId,
    )

    if (updatedPermission.active) {
      setSelectedPermissions([...newSelectedPermissions, updatedPermission])
    } else {
      setSelectedPermissions(newSelectedPermissions)
    }
  }

  const joinPermissions = (): INPermission[] => {
    const permissionsJoined = permissions.map((p) => ({
      ...p,
      active: updatedGroup.permissions.some(
        (up) => up.permissionId === p.permissionId,
      )
        ? true
        : p.active,
    }))

    selectedPermissions.forEach((selected) => {
      const index = permissionsJoined.findIndex(
        (item) => item.permissionId === selected.permissionId,
      )
      if (index !== -1) {
        permissionsJoined[index].active = selected.active
      } else {
        permissionsJoined.push({ ...selected, active: false })
      }
    })

    return permissionsJoined
  }

  const jointSelectedPermissions = (): INPermission[] => {
    const joinedPermissions = joinPermissions().filter((e) => e.active)
    const combinedPermissions = [
      ...updatedGroup.permissions,
      ...joinedPermissions,
    ]

    const uniquePermissions = combinedPermissions.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.permissionId === item.permissionId),
    )

    return uniquePermissions.map((item) => ({
      ...item,
      active: true,
    }))
  }

  return (
    <Fragment>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    align={'center'}
                    style={{ minWidth: column.minWidth }}
                  >
                    <strong>{column.label}</strong>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {groups.map((group, e) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={e}>
                    <TableCell align="center">{group.groupId}</TableCell>
                    <TableCell align="center">{group.groupName}</TableCell>
                    <TableCell align="center">{group.description}</TableCell>
                    <TableCell align="center">
                      <Stack
                        gap={2}
                        direction="row"
                        justifyContent={'flex-end'}
                      >
                        <AlertDialog
                          title={`Editar grupo "${updatedGroup.groupName}"`}
                          buttonLabel={'Editar'}
                          disagreeLabel="Cancelar"
                          agreeLabel="Editar"
                          onOpen={() => {
                            setUpdateGroup(group)
                          }}
                          onAgree={async () => {
                            updatedGroupAdmin(updatedGroup)
                          }}
                          children={
                            <form>
                              <Box>
                                <TextField
                                  sx={{ mt: 2 }}
                                  fullWidth
                                  id="outlined-basic"
                                  label="Nombre grupo"
                                  variant="outlined"
                                  size="small"
                                  value={updatedGroup?.groupName || ''}
                                  required
                                  onChange={(e) => {
                                    setUpdateGroup({
                                      ...updatedGroup,
                                      groupName: e.target.value,
                                    })
                                  }}
                                />
                                <Autocomplete
                                  disablePortal
                                  id="combo-box-demo"
                                  options={usersIAM ?? null}
                                  sx={{ mt: 2 }}
                                  className="form-group-input"
                                  size="small"
                                  fullWidth
                                  multiple
                                  value={updatedGroup?.users ?? []}
                                  onChange={(event, newValue: IUser[]) => {
                                    setUpdateGroup({
                                      ...updatedGroup,
                                      users: newValue,
                                    })
                                  }}
                                  isOptionEqualToValue={(option, value) => {
                                    return option.email === value.email
                                  }}
                                  getOptionLabel={(option) => `${option.email}`}
                                  renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option: IUser, index) => (
                                      <Chip
                                        label={`${option.email}`}
                                        {...getTagProps({ index })}
                                        key={index}
                                      />
                                    ))
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} label="Usuario" />
                                  )}
                                />
                                <Stack
                                  sx={{
                                    mt: 2,
                                  }}
                                >
                                  <AlertDialog
                                    title="Permisos"
                                    buttonLabel={`Editar permisos ${updatedGroup.permissions.length}`}
                                    maxWidth="lg"
                                    agreeLabel="Confirmar"
                                    disagreeLabel="Cancelar"
                                    onAgree={() => {
                                      updatedGroupAdmin({
                                        ...updatedGroup,
                                        permissions: jointSelectedPermissions(),
                                      })
                                    }}
                                    children={
                                      <Stack direction={'row'}>
                                        <StepperModal
                                          loadPermissions={loadPermissions}
                                          permissionsProp={joinPermissions()}
                                          //DONT REPEAT
                                          selectedPermissions={jointSelectedPermissions()}
                                          handlerSelectedPermissions={
                                            handlerSelectedPermissions
                                          }
                                        />
                                      </Stack>
                                    }
                                  />
                                </Stack>
                              </Box>
                            </form>
                          }
                        />
                        <AlertDialog
                          buttonLabel={'eliminar'}
                          title={`¿Estás seguro de eliminar el grupo "${updatedGroup.groupName}"?`}
                          disagreeLabel="Cancelar"
                          agreeLabel="eliminar"
                          color="error"
                          onOpen={() => {
                            setUpdateGroup(group)
                          }}
                          onAgree={() => {
                            removeGroup(Number(group.groupId))
                          }}
                          nameToDelete={updatedGroup.groupName}
                          withConfirmation
                          onDisagree={() => {}}
                          children={
                            <div>
                              <p>
                                Al activar el usuario
                                <strong> {updatedGroup.groupName}</strong> podrá
                                acceder de nuevo al sistema
                              </p>
                              <p>
                                Escriba{' '}
                                <strong>{updatedGroup.groupName}</strong> para
                                confirmar
                              </p>
                            </div>
                          }
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={groups.length + 1}
          rowsPerPage={rowsPerPage}
          page={currentPage - 1}
          onPageChange={(event, newPage) => {
            handlePageChange(event, newPage + 1)
            loadPermissions({
              currentPage: newPage + 1,
              pageSize: rowsPerPage,
            })
          }}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>
    </Fragment>
  )
}

export default TableGroups
