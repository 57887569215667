// src/features/user/groupSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { IGroup } from 'src/services/IAM/AdminGroup/AdminGroup.interface'
import { IGroupState } from './adminGroups.interface'
import {
  getGroups,
  creategroup,
  deleteGroup,
} from 'src/services/IAM/AdminGroup/AdminGroup.service'

const initialState: IGroupState = {
  group: {
    groupId: 0,
    groupName: '',
    permissions: [],
    users: [],
    description: '',
  },
  groups: [],
  updatedGroup: {
    groupId: 0,
    groupName: '',
    permissions: [],
    users: [],
    description: '',
  },
  selectedGroups: [],
  loading: false,
  error: null,
}

const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    setGroup: (state, action: PayloadAction<IGroup>) => {
      state.group = action.payload
    },
    resetGroup: () => initialState,

    updateGroup: (state, action: PayloadAction<IGroup>) => {
      state.updatedGroup = action.payload
    },
    // deleteGroup: (state, action: PayloadAction<number>) => {
    //   state.groups = state.groups.filter((p) => p.groupId !== action.payload)
    // },
    resetGroups: (state) => {
      state.groups = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getGroups.fulfilled,
        (state, action: PayloadAction<IGroup[]>) => {
          state.loading = false
          state.groups = action.payload
        },
      )
      .addCase(getGroups.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(
        creategroup.fulfilled,
        (state, action: PayloadAction<IGroup>) => {
          state.loading = false
          state.groups.push(action.payload)
        },
      )
      .addCase(creategroup.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(
        deleteGroup.fulfilled,
        (state, action: PayloadAction<IGroup>) => {
          state.loading = false
        },
      )
      .addCase(deleteGroup.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  },
})

export const { resetGroup, setGroup, updateGroup } = groupSlice.actions
export default groupSlice.reducer
