/* eslint-disable react-hooks/exhaustive-deps */
// Importaciones necesarias
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import logo from '../../assets/images/logo.svg'
import styles from './RecoveryPassword.module.scss'
import Loader from 'src/components/Loadings/GlobalLoading'
import useIsMounted from 'src/hooks/useIsMounted'
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material'
import useLoading from 'src/hooks/useLoading'
import { changePassword } from 'src/services/auth/auth.service'
import { useAlert } from 'src/contexts/AlertContext'

// Componente para cambio de contraseña
const ChangePasswordForm = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { showAlert } = useAlert()
  const { isLoading, startLoading, stopLoading } = useLoading()
  const isMounted = useIsMounted()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  })
  const password = watch('password')
  useEffect(() => {
    if (!isMounted) return
    const search = location.search
    const token = new URLSearchParams(search).get('token')
    console.log(token, 'token')

    if (token) {
      localStorage.setItem('sessionToken', String(token))
    }
  }, [isMounted])

  const onSubmit = async () => {
    const data = {
      password: password,
      confirmPassword: watch('confirmPassword'),
    }

    try {
      startLoading()
      await changePassword(data)
      stopLoading()
      showAlert('success', 'Contraseña cambiada correctamente')
      navigate('/login')
    } catch (error) {
      stopLoading()
      showAlert('error', 'Error al cambiar la contraseña')
    }
  }

  return (
    <div className={styles.containerLogin}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        {isLoading && <Loader />}
        <img src={logo} alt="Logo" className={styles.logo} />
        <FormControl fullWidth sx={{ mt: '1rem' }}>
          <InputLabel htmlFor="password">Nueva contraseña</InputLabel>
          <OutlinedInput
            id="password"
            type="password"
            {...register('password', {
              required: 'Este campo es requerido',
              minLength: { value: 5, message: 'Mínimo 5 caracteres' },
            })}
            error={!!errors.password}
            label="Nueva contraseña"
          />
          <Typography color="error">{errors.password?.message}</Typography>
        </FormControl>
        <FormControl fullWidth sx={{ mt: '1rem' }}>
          <InputLabel htmlFor="confirmPassword">
            Confirmar nueva contraseña
          </InputLabel>
          <OutlinedInput
            id="confirmPassword"
            type="password"
            {...register('confirmPassword', {
              required: 'Este campo es requerido',
              validate: (value) =>
                value === password || 'Las contraseñas no coinciden',
            })}
            error={!!errors.confirmPassword}
            label="Confirmar nueva contraseña"
          />
          <Typography color="error">
            {errors.confirmPassword?.message}
          </Typography>
        </FormControl>
        <button type="submit" className={styles.button} disabled={isLoading}>
          Cambiar contraseña
        </button>
      </form>
    </div>
  )
}

// Exportación del componente ChangePasswordForm
export default ChangePasswordForm
