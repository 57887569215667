// src/hooks/useAdminEndpoint.ts
import { useDispatch, useSelector } from 'react-redux'
import { RootState, AppDispatch } from 'src/redux/index'
import { resetEndpoint } from 'src/redux/IAM/AdminEndpoints/adminEndpointsSlice'
import {
  deleteEndpoint,
  getEndpoints,
  createEndpoint,
  updateEndpoint,
} from 'src/services/IAM/AdminEndpoints/AdminEndpoints.service'
import { useAlert } from 'src/contexts/AlertContext'
import { usePagination } from '../Components/Pagination/usePagination'
import {
  IBodyEndpoint,
  IEndpoint,
} from 'src/services/IAM/AdminEndpoints/AdminEndpoints.interface'
import useLoading from 'src/hooks/useLoading'

export const useAdminEndpoint = () => {
  const { handleTotalItems } = usePagination()
  const { showAlert } = useAlert()
  const dispatch = useDispatch<AppDispatch>()
  const { startLoading, stopLoading } = useLoading()
  const { endpoints, endpoint, loading, error } = useSelector(
    (state: RootState) => state.endpoints,
  )

  const loadEndpoints = ({
    currentPage = 1,
    pageSize = 25,
  }: {
    currentPage?: number
    pageSize?: number
  } = {}) => {
    try {
      startLoading()
      dispatch(
        getEndpoints({
          currentPage,
          pageSize,
        }),
      )
        .then((result: any) => {
          if (getEndpoints.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) || 'Error al crear la app',
            )
          } else if (getEndpoints.fulfilled.match(result)) {
            showAlert('success', 'Endpoints obtenidos correctamente')
            if (
              {
                currentPage,
                pageSize,
              }
            ) {
              handleTotalItems(result.payload.details)
            }
          }
        })
        .finally(() => {
          stopLoading()
        })
    } catch (error) {
      showAlert('error', 'Error al obtener los endpoints')
    }
  }

  const removeEndpoint = (endpointId: number) => {
    startLoading()
    dispatch(deleteEndpoint(endpointId))
      .then((result: any) => {
        if (deleteEndpoint.rejected.match(result)) {
          showAlert('error', String(result.payload) || 'Error al crear la app')
        } else if (deleteEndpoint.fulfilled.match(result)) {
          showAlert('success', 'Endpoint eliminado correctamente')
          loadEndpoints()
        }
      })
      .finally(() => {
        stopLoading()
      })
  }

  const updateEndpointAdmin = (payload: IBodyEndpoint) => {
    startLoading()
    dispatch(updateEndpoint(payload))
      .then((result: any) => {
        if (updateEndpoint.rejected.match(result)) {
          showAlert('error', String(result.payload) || 'Error al crear la app')
        } else if (updateEndpoint.fulfilled.match(result)) {
          showAlert('success', 'Endpoint actualizado correctamente')
          loadEndpoints()
        }
      })
      .finally(() => {
        stopLoading()
      })
  }

  const createEndpointAdmin = (payload: IEndpoint) => {
    startLoading()
    dispatch(createEndpoint(payload))
      .then((result: any) => {
        if (createEndpoint.rejected.match(result)) {
          showAlert('error', String(result.payload) || 'Error al crear la app')
        } else if (createEndpoint.fulfilled.match(result)) {
          showAlert('success', 'Endpoint creado correctamente')
          loadEndpoints()
        }
      })
      .finally(() => {
        stopLoading()
      })
  }

  const clearEndpoints = () => {
    dispatch(resetEndpoint())
  }

  return {
    endpoints,
    endpoint,
    loading,
    error,
    updateEndpointAdmin,
    createEndpointAdmin,
    loadEndpoints,
    removeEndpoint,
    clearEndpoints,
  }
}
