import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux'
import {
    messageReceived, webSocketConnected, webSocketDisconnected, resetMessages
} from 'src/redux/Websocket/webScoketSlice'

const useWebsocket = () => {
    const dispatch = useDispatch()
    const { connected, messages } = useSelector((state: RootState) => state.webSocket)

    // use websocket connected
    const handleConnected = () => {
        dispatch(webSocketConnected())
    }

    // use websocket disconnected
    const handleDisconnected = () => {
        dispatch(webSocketDisconnected())
    }

    // use message received
    const handleMessageReceived = (data: any) => {
        dispatch(messageReceived(data))
    }

    //reset messages
    const resetMessagesWS = () => {
        dispatch(resetMessages())
    }
    function waitForConnection(): Promise<void> {
        dispatch({ type: 'webSocket/connect' });
        handleDisconnected()
        return new Promise((resolve, reject) => {
            const timeout = 10000; // 10 segundos
            const start = Date.now();

            const interval = setInterval(() => {

                if (connected) {
                    console.log(connected, 'websocket: ' + connected + localStorage.getItem('connectionId'));
                    clearInterval(interval);
                    resolve(); // Resolución exitosa
                } else if (Date.now() - start > timeout) {
                    console.log('Tiempo de espera agotado. No se pudo establecer conexión.');
                    clearInterval(interval);
                    reject(new Error('Tiempo de espera agotado. No se pudo establecer conexión.'));
                }
            }, 1000); // Verifica cada 100ms
        });
    }

    return {
        connected,
        messages,
        handleConnected,
        handleDisconnected,
        handleMessageReceived,
        resetMessagesWS,
        waitForConnection
    }
}

export default useWebsocket
