/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import TableStrategy from './components/TableStrategy'
import FiltersStrategy from './components/FiltersStrategy'
import { Typography } from '@mui/material'
import { useStrategies } from 'src/hooks/Dashboard/Strategies/useStrategies'
import useIsMounted from 'src/hooks/useIsMounted'
import { useKPIForm } from 'src/hooks/Dashboard/KPIForm/useKPIForm'

const EstrategyView = () => {
  const { loadStrategies } = useStrategies()
  const { loadFilters } = useKPIForm()
  const isMounted = useIsMounted()
  useEffect(() => {
    if (!isMounted) return
    loadFilters()
    loadStrategies({})
  }, [isMounted])
  return (
    <div>
      <Typography variant="h4" textAlign={'center'} gutterBottom>
        Estrategias
      </Typography>
      <FiltersStrategy />
      <TableStrategy />
    </div>
  )
}

export default EstrategyView
