import React from 'react'
import Paper from '@mui/material/Paper'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TablePagination from '@mui/material/TablePagination'
import { IRowsPSE } from '../SearchFilesCollections.interface'
import { formatToCOP } from 'src/utils/utils'

interface Header {
  label: string
  columns: number
}

interface TablePSEProps {
  headersPSE: Header[]
  rows: IRowsPSE[] | null
  rowsPerPage: number
  page: number
  totalRows: number
  totalItems: number
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
}

/**
 * TablePSE component displays a table with PSE data.
 *
 * @component
 * @example
 * ```tsx
 * <TablePSE
 *   headersPSE={headersPSE}
 *   rows={rows}
 *   rowsPerPage={rowsPerPage}
 *   page={page}
 *   handleChangePage={handleChangePage}
 *   handleChangeRowsPerPage={handleChangeRowsPerPage}
 * />
 * ```
 *
 * @param {Object[]} headersPSE - An array of objects representing the headers of the table.
 * @param {string} headersPSE[].label - The label of the header.
 * @param {number} headersPSE[].columns - The number of columns the header spans.
 * @param {Object[]} rows - An array of objects representing the rows of the table.
 * @param {string} rows[].date - The date of the row.
 * @param {number} rows[].achCount - The count of ACH for the row.
 * @param {number} rows[].achAmount - The amount of ACH for the row.
 * @param {number} rows[].suiteCount - The count of Suite for the row.
 * @param {number} rows[].suiteAmount - The amount of Suite for the row.
 * @param {number} rows[].coreCount - The count of Core for the row.
 * @param {number} rows[].coreAmount - The amount of Core for the row.
 * @param {number} rows[].samePeriodCount - The count of same period for the row.
 * @param {number} rows[].samePeriodAmount - The amount of same period for the row.
 * @param {number} rows[].previousPeriodCount - The count of previous period for the row.
 * @param {number} rows[].previousPeriodAmount - The amount of previous period for the row.
 * @param {number} rowsPerPage - The number of rows per page.
 * @param {number} page - The current page number.
 * @param {Function} handleChangePage - The function to handle page change.
 * @param {Function} handleChangeRowsPerPage - The function to handle rows per page change.
 *
 * @returns {JSX.Element} The rendered TablePSE component.
 */
const TablePSE: React.FC<TablePSEProps> = ({
  headersPSE,
  rows,
  rowsPerPage,
  page,
  totalRows,
  totalItems,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  return (
    <>
      {/* TABLE START */}
      <div id="table-pse">
        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow style={{ zIndex: 0, position: 'relative' }}>
                  {headersPSE.map((item, index) => (
                    <TableCell
                      align="center"
                      key={index}
                      colSpan={item.columns}
                    >
                      {item.label}
                    </TableCell>
                  ))}
                </TableRow>
                {/* HEADERS PSE */}
                <TableRow style={{ zIndex: 0, position: 'relative' }}>
                  <TableCell
                    align={'center'}
                    style={{ top: 57, minWidth: 170, verticalAlign: 'middle' }}
                  >
                    Fecha
                  </TableCell>
                  {[0, 1, 2, 3, 4].map((item) => (
                    <React.Fragment key={item}>
                      <TableCell
                        align={'center'}
                        style={{
                          top: 57,
                          minWidth: 170,
                          verticalAlign: 'middle',
                        }}
                      >
                        {item === 3 ? 'Mismo periodo' : ''}
                        {item === 4 ? 'Periodos anteriores' : ''}
                        <div>Número</div>
                      </TableCell>
                      <TableCell
                        align={'center'}
                        style={{
                          top: 57,
                          minWidth: 170,
                          verticalAlign: 'middle',
                        }}
                      >
                        {item === 3 ? 'Mismo Periodo' : ''}
                        {item === 4 ? 'Periodos anteriores' : ''}
                        <div>Monto</div>
                      </TableCell>
                    </React.Fragment>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows && rows.length ? (
                  rows.map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell align="center">
                          <strong>{row.date}</strong>
                        </TableCell>
                        <TableCell align="center">{row.achCount}</TableCell>
                        <TableCell align="center">
                          {formatToCOP(Number(row.achAmount))}
                        </TableCell>
                        <TableCell align="center">{row.suiteCount}</TableCell>
                        <TableCell align="center">
                          {formatToCOP(Number(row.suiteAmount))}
                        </TableCell>
                        <TableCell align="center">{row.coreCount}</TableCell>
                        <TableCell align="center">
                          {formatToCOP(Number(row.coreAmount))}
                        </TableCell>
                        <TableCell align="center">
                          {row.samePeriodCount}
                        </TableCell>
                        <TableCell align="center">
                          {formatToCOP(Number(row.samePeriodAmount))}
                        </TableCell>
                        <TableCell align="center">
                          {row.previousPeriodCount}
                        </TableCell>
                        <TableCell align="center">
                          {formatToCOP(Number(row.previousPeriodAmount))}
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={11} align="center">
                      No hay registros
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalItems || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      {/* TABLE END */}
    </>
  )
}

export default TablePSE
