import React, { Key } from 'react'
import { Stack, Typography, TypographyProps } from '@mui/material'
import {
  BarChart,
  axisClasses,
  useXScale,
  useYScale,
  useDrawingArea,
} from '@mui/x-charts'
import styled from 'styled-components'
import { IChartKPI } from 'src/services/KPI/Charts/Charts.interface'

const OptionWithBorder = styled(Typography)<TypographyProps>(({ theme }) => ({
  padding: '10px',
  textAlign: 'center',
  borderRadius: '5px',
  border: '1px solid #201646',
  flex: 1,
  width: '100%',
}))

const LoadingRect = styled('rect')({
  opacity: 0.2,
  fill: 'lightgray',
})

const LoadingText = styled('text')(({ theme }) => ({
  stroke: 'none',
  fill: 'grey',
  shapeRendering: 'crispEdges',
  textAnchor: 'middle',
  dominantBaseline: 'middle',
}))

const ratios = [0.2, 0.8, 0.6, 0.5]

function LoadingOverlay() {
  const xScale = useXScale<'band'>()
  const yScale = useYScale()
  const { left, width, height } = useDrawingArea()

  const bandWidth = xScale.bandwidth()
  const [bottom, top] = yScale.range()

  return (
    <g>
      {xScale.domain().map((item: string | number | Date, index: number) => {
        const ratio = ratios[index % ratios.length]
        const barHeight = ratio * (bottom - top)

        return (
          <LoadingRect
            key={String(item)}
            x={xScale(item)}
            width={bandWidth}
            y={bottom - barHeight}
            height={barHeight}
          />
        )
      })}
      <LoadingText x={left + width / 2} y={top + height / 2}>
        Cargando informacion
      </LoadingText>
    </g>
  )
}

const CustomChart = ({
  chartData,
  isLoading,
}: {
  chartData: IChartKPI[]
  isLoading: boolean
}) => {
  const loadingArray = [...Array(6)].map((_, index) => ({
    nameIndicator: 'Cargando...',
    percentageMonths: ['1', 'b', 'c'],
    percentageExecuted: 0,
    goal: 0.0,
    percentageCompliance: 0,
    description: 'Cargando...',
    months: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
    series: [],
  }))
  const findMaxValue = (item: any): number => {
    return item?.series.reduce((maxValue: number, serie: any) => {
      const serieMax = Math.max(...serie.data)
      return serieMax > maxValue ? serieMax : maxValue
    }, -Infinity)
  }
  return (
    <>
      {(isLoading ? loadingArray : chartData)?.map(
        (item: any, index: number) => {
          return (
            <Stack key={index} alignItems={'center'} flexBasis={500}>
              <OptionWithBorder
                variant="body1"
                color={'white'}
                bgcolor={'primary.main'}
              >
                {item.nameIndicator}
              </OptionWithBorder>
              <Stack
                spacing={2}
                mt={2}
                direction={'row'}
                justifyContent={'space-between'}
                width={'100%'}
              >
                <OptionWithBorder variant="body2">
                  Ejecutado <br />
                  <strong>{item.percentageExecuted}</strong>
                </OptionWithBorder>
                <OptionWithBorder variant="body2">
                  Meta <br /> <strong>{item.goal}</strong>
                </OptionWithBorder>
                <OptionWithBorder variant="body2">
                  Cumplimiento <br />
                  <strong
                    style={{
                      color: 'green',
                    }}
                  >
                    {item.percentageCompliance}
                  </strong>
                </OptionWithBorder>
              </Stack>
              <Typography variant="body2" mt={2} textAlign={'center'}>
                {item.description}
              </Typography>
              <BarChart
                loading={isLoading}
                xAxis={[
                  {
                    scaleType: 'band',
                    data: item.months,
                  },
                  {
                    scaleType: 'band',
                    data: item.percentageMonths.map((month: number, index: Key) => ({
                      value: month,
                      id: `${month}-${index}`,
                    })).map((month: any) => month.value),
                    position: 'top',
                    id: 'top-axis',
                  },
                ]}
                series={item.series}
                yAxis={[
                  {
                    id: 'left-axis',
                    position: 'left',
                    max: findMaxValue(item) + findMaxValue(item) * 0.1,
                  },
                  {
                    id: 'right-axis',
                    position: 'right',
                  },
                ]}
                slotProps={{
                  legend: {
                    direction: 'row',
                    position: { vertical: 'bottom', horizontal: 'middle' },
                    padding: 0,
                  },
                  noDataOverlay: {
                    message: 'No hay datos',
                  },
                }}
                slots={{ loadingOverlay: LoadingOverlay }}
                margin={{
                  left: 60,
                  right: 60,
                  top: 50,
                  bottom: 60,
                }}
                colors={['#8F7AB6', '#231D44', '#201646']}
                barLabel="value"
                height={350}
                width={470}
                topAxis={{
                  label: '',
                  axisId: 'top-axis',
                }}
                sx={(theme) => ({
                  [`.MuiBarElement-series-l_id`]: {
                    stroke: '#edff60',
                  },
                  [`.MuiBarElement-series-r_id`]: {
                    stroke: '#edff60',
                  },
                  [`.MuiBarLabel-root`]: {
                    fontWeight: '600',
                    fill: '#fff',
                  },
                  [`.${axisClasses.root}`]: {
                    [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                      stroke: '#201646',
                      strokeWidth: 1,
                    },
                    [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                      stroke: '#201646',
                      strokeWidth: 1,
                    },
                    [`.${axisClasses.tickLabel}`]: {
                      fill: '#201646',
                    },
                  },
                })}
              />
            </Stack>
          )
        },
      )}
    </>
  )
}

export default CustomChart
