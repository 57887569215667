//index.tsx

// Importaciones necesarias para renderizar y ejecutar la aplicación.
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import {
  faB,
  faCheckSquare,
  faCoffee,
  faDatabase,
  faHouseLaptop,
  faS,
  faWindowMaximize,
  faMoneyBill,
  faTachometerAlt,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { Provider } from 'react-redux'
import store from 'src/redux/index'

library.add(
  faB,
  faS,
  faHouseLaptop,
  faCheckSquare,
  faCoffee,
  faDatabase,
  faWindowMaximize,
  faMoneyBill,
  faTachometerAlt,
  faUserTie,
  faInstagram as IconDefinition,
)

// Creación de una raíz para el renderizado concurrente.
/**
 * Renders the root component of the application.
 *
 * @param {HTMLElement} rootElement - The root element to render the application into.
 * @returns {void}
 */
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

// Renderizado de la aplicación en el elemento con id 'root'.
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
)
