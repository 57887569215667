import {
  Box,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import AlertDialog from 'src/components/Dialog'
import { useIndicators } from 'src/hooks/Dashboard/Indicators/useIndicators'
import {
  IGETIndicatorsResponse,
  IPayloadUpdateQuestion,
  IResponseGetIndicator,
} from 'src/services/dashboard/indicator/indicator.interface'
import { useParams } from 'react-router-dom'

const TableQuestionsIndicator = ({
  questionsSelected,
  rowsPerPage,
  page,
  responseLoadIndicator,
  selectedQuestions,
  setQuestionsSelected,
  handleChangePage,
  handleChangeRowsPerPage,
}: {
  questionsSelected: IResponseGetIndicator['rows']
  rowsPerPage: number
  page: number
  responseLoadIndicator: IResponseGetIndicator | null
  selectedQuestions: IResponseGetIndicator['rows']
  setQuestionsSelected: Dispatch<SetStateAction<IResponseGetIndicator['rows']>>
  handleChangePage: (event: unknown, newPage: number) => void
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  const [questionToEdit, setQuestionToEdit] = useState<IPayloadUpdateQuestion>({
    indicatorId: null,
    periodId: null,
    question: null,
    answerDefault: null,
    parameterId: null,
    quarterId: null,
    parameterByStrategyId: null,
  })
  const { updateQuestionIndicator, removeQuestionsIndicator } = useIndicators()
  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      {/* Aquí va el componente EnhancedTable */}
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          {/* Aquí van los componentes TableHead y TableBody */}
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={
                    questionsSelected.length ===
                    responseLoadIndicator?.rows?.length
                  }
                  onChange={() => {
                    if (
                      questionsSelected.length ===
                      responseLoadIndicator?.rows?.length
                    ) {
                      setQuestionsSelected([])
                    } else {
                      setQuestionsSelected(responseLoadIndicator?.rows ?? [])
                    }
                  }}
                />
              </TableCell>
              <TableCell>Periodo</TableCell>
              <TableCell>Trimestre</TableCell>
              <TableCell>Parámetro</TableCell>
              <TableCell>Pregunta</TableCell>
              <TableCell>Respuesta por defecto</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* header table */}

            {responseLoadIndicator?.rows?.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={questionsSelected.some(
                        (question) =>
                          question.questionByMonthId === row.questionByMonthId,
                      )}
                      onChange={() => {
                        if (
                          questionsSelected.some(
                            (question) =>
                              question.questionByMonthId ===
                              row.questionByMonthId,
                          )
                        ) {
                          setQuestionsSelected(
                            questionsSelected.filter(
                              (question) =>
                                question.questionByMonthId !==
                                row.questionByMonthId,
                            ),
                          )
                        } else {
                          setQuestionsSelected([...questionsSelected, row])
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">{row.commercialPeriodName}</TableCell>
                  <TableCell align="left">{row.quarterName}</TableCell>
                  <TableCell align="left">{row.parameterName}</TableCell>
                  <TableCell align="left">{row.questionName}</TableCell>
                  <TableCell align="left">{row.answerDefault}</TableCell>
                  <TableCell align="right">
                    <AlertDialog
                      title={`Editar pregunta ${row.questionName}`}
                      buttonLabel={'Editar'}
                      disagreeLabel="Cancelar"
                      agreeLabel="Editar"
                      onOpen={() => {
                        setQuestionToEdit({
                          indicatorId: row.indicatorId,
                          periodId: row.periodId,
                          question: row.questionName,
                          answerDefault: row.answerDefault,
                          parameterId: row.parameterId,
                          quarterId: row.quarterId,
                          parameterByStrategyId: row.parameterByStrategyId,
                        })
                      }}
                      onAgree={() =>
                        updateQuestionIndicator({
                          indicatorId: questionToEdit.indicatorId,
                          question: questionToEdit.question,
                          answerDefault: questionToEdit.answerDefault,
                          parameterId: questionToEdit.parameterId,
                          periodId: questionToEdit.periodId,
                          quarterId: questionToEdit.quarterId,
                          parameterByStrategyId:
                            questionToEdit.parameterByStrategyId,
                        })
                      }
                      onDisagree={() => console.log('endpoint no editado')}
                      children={
                        <form
                          onSubmit={() => {
                            updateQuestionIndicator({
                              indicatorId: row.indicatorId,
                              question: row.questionName,
                              answerDefault: row?.answerDefault,
                              parameterId: row.parameterId,
                              periodId: row.periodId,
                              quarterId: row.quarterId,
                              parameterByStrategyId: row.parameterByStrategyId,
                            })
                          }}
                        >
                          {/* DESCRIPTION */}
                          <TextField
                            id="outlined-basic"
                            label="Descripción"
                            variant="outlined"
                            sx={{
                              mb: 2,
                            }}
                            fullWidth
                            value={questionToEdit.question}
                            onChange={(e) => {
                              setQuestionToEdit({
                                ...questionToEdit,
                                question: e.target.value,
                              })
                            }}
                          />
                          {/* ANSWER DEFAULT */}
                          <TextField
                            id="outlined-basic"
                            label="Respuesta por defecto"
                            variant="outlined"
                            sx={{
                              mb: 2,
                            }}
                            fullWidth
                            value={questionToEdit.answerDefault}
                            onChange={(e) => {
                              setQuestionToEdit({
                                ...questionToEdit,
                                answerDefault: Number(e.target.value),
                              })
                            }}
                          />
                        </form>
                      }
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
          <caption>
            {/* Aquí van los componentes de filtros */}
            <AlertDialog
              buttonLabel={`Eliminar ${questionsSelected.length} pregunta(s)`}
              title={`¿Estás seguro de eliminar estas preguntas?`}
              disagreeLabel="Cancelar"
              agreeLabel="Eliminar"
              color="error"
              onAgree={() => {
                removeQuestionsIndicator(
                  questionsSelected.map((question) => ({
                    parameterId: question.parameterId,
                    indicatorId: question.indicatorId,
                    periodId: question.periodId,
                    quarterId: question.quarterId,
                    parameterByStrategyId: question.parameterByStrategyId,
                  })),
                )
              }}
              nameToDelete={'Confirmar'}
              withConfirmation
              onDisagree={() => console.log('endpoint no eliminado')}
              children={
                <Box>
                  <Typography mb={2}>
                    Escriba <strong>Confirmar</strong> para confirmar
                  </Typography>
                  {/* List of questions */}
                  <List dense>
                    {questionsSelected.map((question) => (
                      <ListItem key={question.questionId}>
                        - <ListItemText primary={question.questionName} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              }
            />
          </caption>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={responseLoadIndicator?.rows?.length ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default TableQuestionsIndicator
