// src/features/user/KPIFormSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IIndicatorState } from './Indicators.interface'
import {
  IGETIndicatorsResponse,
  IIndicatorCreate,
} from 'src/services/dashboard/indicator/indicator.interface'
import {
  createIndicator,
  getIndicator,
  getIndicators,
} from 'src/services/dashboard/indicator/indicator.service'

const initialState: IIndicatorState = {
  loading: false,
  indicators: [],
  indicator: {
    indicatorId: 0,
    areaId: 0,
    channelId: 0,
    indicatorName: '',
    indicatorLabelOne: '',
    indicatorLabelTwo: '',
    indicatorDescription: '',
    areaName: '',
    channelName: '',
    indicatorActive: false,
    strategies: [],
  },
  indicatorCreate: {
    areaId: null,
    channelId: null,
    indicatorName: '',
    indicatorLabelOne: '',
    indicatorLabelTwo: '',
    indicatorDescription: '',
    strategies: [],
  },
  filter: {
    areaName: null,
    channelId: null,
    indicatorName: null,
    periodId: null,
    quarterId: null,
  },
  responseLoadIndicator: null,
}

const StrategySlice = createSlice({
  name: 'KPIForm',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<IIndicatorState['filter']>) => {
      state.filter = action.payload
    },
    setIndicator: (
      state,
      action: PayloadAction<IIndicatorState['indicator']>,
    ) => {
      state.indicator = action.payload
    },
    setIndicatorCreate: (
      state,
      action: PayloadAction<IIndicatorState['indicatorCreate']>,
    ) => {
      state.indicatorCreate = action.payload
    },
    resetFilters: (state) => {
      state.filter = initialState.filter
    },
    setResponseLoadIndicator: (
      state,
      action: PayloadAction<IIndicatorState['responseLoadIndicator']>,
    ) => {
      state.responseLoadIndicator = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        createIndicator.fulfilled,
        (state, action: PayloadAction<IIndicatorCreate[]>) => {
          state.loading = false
          state.indicatorCreate = {
            areaId: 0,
            channelId: 0,
            indicatorName: '',
            indicatorLabelOne: '',
            indicatorLabelTwo: '',
            indicatorDescription: '',
            strategies: [],
          }
        },
      )
      .addCase(
        getIndicators.fulfilled,
        (state, action: PayloadAction<IGETIndicatorsResponse[]>) => {
          state.loading = false
          state.indicators = action.payload
        },
      )
      .addCase(getIndicator.pending, (state) => {
        state.loading = true
      })
      .addCase(
        getIndicator.fulfilled,
        (
          state,
          action: PayloadAction<IIndicatorState['responseLoadIndicator']>,
        ) => {
          state.loading = false
          state.responseLoadIndicator = action.payload
          console.log(state.responseLoadIndicator, 'state.indicator')
        },
      )
  },
})

export const {
  setFilter,
  setIndicatorCreate,
  resetFilters,
  setIndicator,
  setResponseLoadIndicator,
} = StrategySlice.actions
export default StrategySlice.reducer
