import React, { useContext } from 'react'
import clsx from 'clsx'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import {
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2Root,
  TreeItem2GroupTransition,
} from '@mui/x-tree-view/TreeItem2'
import {
  unstable_useTreeItem2 as useTreeItem,
  UseTreeItem2Parameters,
} from '@mui/x-tree-view/useTreeItem2'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon'
import { AuthContext } from 'src/contexts/AuthContext'
import { ILocalRoutes } from './Sidebar.Interface'
import { IconDefinition } from '@fortawesome/free-brands-svg-icons'
import { useNavigate } from 'react-router-dom'
import useIsMounted from 'src/hooks/useIsMounted'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

declare module 'react' {
  interface CSSProperties {
    '--tree-view-color'?: string
    '--tree-view-bg-color'?: string
  }
}

interface StyledTreeItemProps
  extends Omit<UseTreeItem2Parameters, 'rootRef'>,
  React.HTMLAttributes<HTMLLIElement> {
  bgColor?: string
  bgColorForDarkMode?: string
  color?: string
  colorForDarkMode?: string
  labelIcon: IconDefinition | IconProp
  labelInfo?: string
}

/**
 * Custom styled root component for TreeItem2.
 */
const CustomTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))

/**
 * Custom styled content component for TreeItem2.
 */
const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  marginBottom: theme.spacing(0.3),
  color: theme.palette.text.secondary,
  borderRadius: theme.spacing(2),
  paddingRight: theme.spacing(1),
  fontWeight: theme.typography.fontWeightMedium,
  '&.expanded': {
    fontWeight: theme.typography.fontWeightRegular,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&.focused, &.selected, &.selected.focused': {
    backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
    color: 'var(--tree-view-color)',
  },
}))

/**
 * Custom styled icon container for TreeItem2.
 */
const CustomTreeItemIconContainer = styled(TreeItem2IconContainer)(
  ({ theme }) => ({
    marginRight: theme.spacing(1),
  }),
)

/**
 * Custom styled group transition component for TreeItem2.
 */
const CustomTreeItemGroupTransition = styled(TreeItem2GroupTransition)(
  ({ theme }) => ({
    marginLeft: 0,
    [`& .content`]: {
      paddingLeft: theme.spacing(2),
    },
  }),
)

/**
 * Custom Tree Item component that integrates with the TreeItem2 API.
 *
 * @component
 * @param {StyledTreeItemProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 * @example
 * <CustomTreeItem
 *   itemId="1"
 *   label="Item Label"
 *   labelIcon={someIcon}
 *   onClick={() => console.log('Item clicked')}
 * />
 */
const CustomTreeItem = React.forwardRef(function CustomTreeItem(
  props: StyledTreeItemProps,
  ref: React.Ref<HTMLLIElement>,
) {
  const theme = useTheme()
  const {
    id,
    itemId,
    label,
    disabled,
    children,
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    colorForDarkMode,
    bgColorForDarkMode,
    ...other
  } = props

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
  } = useTreeItem({ id, itemId, children, label, disabled, rootRef: ref })

  const style = {
    '--tree-view-color':
      theme.palette.mode !== 'dark' ? color : colorForDarkMode,
    '--tree-view-bg-color':
      theme.palette.mode !== 'dark' ? bgColor : bgColorForDarkMode,
  }

  return (
    <div>
      <CustomTreeItemRoot {...getRootProps({ ...other, style })}>
        <CustomTreeItemContent
          {...getContentProps({
            className: clsx('content', {
              expanded: status.expanded,
              selected: status.selected,
              focused: status.focused,
            }),
          })}
        >
          <CustomTreeItemIconContainer {...getIconContainerProps()}>
            <TreeItem2Icon status={status} />
          </CustomTreeItemIconContainer>
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              alignItems: 'center',
              p: 0.5,
              pr: 0,
            }}
          >
            <Box color="inherit" sx={{ mr: 1 }} />
            {LabelIcon && typeof LabelIcon !== 'string' ? (
              <FontAwesomeIcon
                icon={LabelIcon as IconProp}
                style={{
                  marginLeft: '-10px',
                }}
              />
            ) : (
              <img
                src={LabelIcon}
                alt={LabelIcon}
                width={'25px'}
                style={{
                  marginLeft: '-10px',
                }}
              />
            )}
            <Typography
              {...getLabelProps({
                variant: 'body2',
                sx: {
                  display: 'flex',
                  fontWeight: 'inherit',
                  flexGrow: 1,
                  ml: 2,
                },
              })}
              variant="body2"
            />
            <Typography variant="body1" color="inherit">
              {labelInfo}
            </Typography>
          </Box>
        </CustomTreeItemContent>
        {children && (
          <CustomTreeItemGroupTransition {...getGroupTransitionProps()} />
        )}
      </CustomTreeItemRoot>
    </div>
  )
})

/**
 * End icon component for the tree view.
 *
 * @returns {JSX.Element} The rendered component.
 */
const EndIcon = () => {
  return <div style={{ width: 24 }} />
}

/**
 * GmailTreeView component renders a tree view of user routes.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 * @example
 * <GmailTreeView />
 */
export default function GmailTreeView() {
  const { userRoutes } = useContext(AuthContext)
  const isMounted = useIsMounted()
  const navigate = useNavigate()

  /**
   * Renders tree items from the provided routes.
   *
   * @param {ILocalRoutes[]} routes - The routes to render.
   * @param {string} [parentIndex=''] - The index of the parent item.
   * @returns {JSX.Element[]} The rendered tree items.
   */
  const renderTreeItems = (
    routes: ILocalRoutes[],
    parentIndex: string = '',
  ) => {
    return routes.map((route, index) => {
      const itemId = parentIndex ? `${parentIndex}-${index}` : String(index)
      return (
        <CustomTreeItem
          key={itemId}
          itemId={itemId}
          label={route.title}
          labelIcon={route.icon as IconProp} // Default icon if none is provided
          disabled={route.disabled}
          color={route.color}
          onClick={(event) => {
            if (!isMounted) return
            event.preventDefault()
            event.stopPropagation() // Evita la propagación del evento
            navigate(route.route, {
              replace: true,
              state: { ...route.paramsRoute }
            })
          }}
        >
          {route.subModules && renderTreeItems(route.subModules, itemId)}
        </CustomTreeItem>
      )
    })
  }

  return (
    <SimpleTreeView
      aria-label="gmail"
      defaultExpandedItems={['3']}
      defaultSelectedItems="5"
      slots={{
        expandIcon: ArrowRightIcon,
        collapseIcon: ArrowDropDownIcon,
        endIcon: EndIcon,
      }}
      sx={{
        flexGrow: 1,
        maxWidth: 400,
        pt: 2,
        pr: 2,
        minWidth: 250,
        bgcolor: 'background.paper',
        boxShadow: 1,
      }}
    >
      {renderTreeItems(userRoutes)}
    </SimpleTreeView>
  )
}
