import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { ReactComponent as CalendarIcon } from '../../assets/images/Dashboard/General/icons/calendar.svg'
import styles from './DatepickerComponent.module.scss'
import { Input, Stack } from '@mui/material'

/**
 * Props for the DatepickerComponent.
 *
 * @interface DatepickerComponentProps
 * @property {Date | null} startDate - The selected start date.
 * @property {Date | null} endDate - The selected end date.
 * @property {(startDate: Date | null, endDate: Date | null) => void} onDateChange - Callback function invoked when the start or end date changes.
 */
interface DatepickerComponentProps {
  startDate: Date | null
  endDate: Date | null
  onDateChange: (startDate: Date | null, endDate: Date | null) => void
}

/**
 * DatepickerComponent
 *
 * @remarks
 * This component provides a user interface for selecting a date range using two date pickers, one for the start date and one for the end date.
 * It supports customization through Material UI's `Input` component and provides a styled wrapper.
 *
 * The component tracks the selected dates and triggers the `onDateChange` callback when either date is changed.
 *
 * @component
 * @example
 * ```tsx
 * <DatepickerComponent
 *    startDate={new Date()}
 *    endDate={new Date()}
 *    onDateChange={(start, end) => console.log(start, end)}
 * />
 * ```
 *
 * @param {DatepickerComponentProps} props - The component props.
 * @returns {JSX.Element} The rendered DatepickerComponent.
 */
const DatepickerComponent: React.FC<DatepickerComponentProps> = ({
  startDate,
  endDate,
  onDateChange,
}) => {
  const [internalStartDate, setInternalStartDate] = useState<Date | null>(
    startDate,
  )
  const [internalEndDate, setInternalEndDate] = useState<Date | null>(endDate)

  /**
   * Synchronizes internal state with external startDate and endDate props.
   */
  useEffect(() => {
    setInternalStartDate(startDate)
    setInternalEndDate(endDate)
  }, [startDate, endDate])

  /**
   * Handles changes to the start date.
   *
   * @param {Date | null} date - The new start date.
   */
  const handleStartDateChange = (date: Date | null) => {
    setInternalStartDate(date)
    onDateChange(date, internalEndDate)
  }

  /**
   * Handles changes to the end date.
   *
   * @param {Date | null} date - The new end date.
   */
  const handleEndDateChange = (date: Date | null) => {
    setInternalEndDate(date)
    onDateChange(internalStartDate, date)
  }

  return (
    <Stack
      sx={{
        width: '100%',
        maxWidth: '270px',
        border: '1px solid #0004',
        padding: '10px 0.8rem',
        borderRadius: '5px',
      }}
      direction="row"
      spacing={2}
      alignItems={'center'}
    >
      <CalendarIcon className={styles.calendarIcon} />
      <DatePicker
        selected={internalStartDate}
        onChange={handleStartDateChange}
        dateFormat="dd/MM/yyyy"
        wrapperClassName={styles.datePickerWrapper}
        customInput={
          <Input
            placeholder="Fecha de inicio"
            size="small"
            sx={{
              width: '100%',
              maxWidth: '100px',
              ':before': {
                borderBottom: 'none',
              },
              input: {
                p: '0',
              },
            }}
            value={
              internalStartDate
                ? internalStartDate.toLocaleDateString('es-ES')
                : ''
            }
          />
        }
      />
      <span> - </span>
      <DatePicker
        selected={internalEndDate}
        onChange={handleEndDateChange}
        dateFormat="dd/MM/yyyy"
        wrapperClassName={styles.datePickerWrapper}
        customInput={
          <Input
            placeholder="Fecha de fin"
            size="small"
            value={
              internalEndDate ? internalEndDate.toLocaleDateString('es-ES') : ''
            }
            sx={{
              width: '100%',
              maxWidth: '100px',
              ':before': {
                borderBottom: 'none',
              },
              input: {
                p: '0',
              },
            }}
          />
        }
      />
    </Stack>
  )
}

export default DatepickerComponent
