import { Button, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { useAdminApp } from 'src/hooks/IAM/AdminApp/useAdminApp'
/**
 * Renders a form for creating a new admin application.
 *
 * @remarks
 * The `FormAdminApp` component is used to collect necessary information
 * for creating a new admin application. It captures the application's ID,
 * name, type, and description, and calls a function to create the application
 * upon submission.
 *
 * @component
 * @example
 * ```tsx
 * import FormAdminApp from 'path/to/FormAdminApp';
 *
 * const App = () => {
 *   return (
 *     <div>
 *       <h1>Create New Application</h1>
 *       <FormAdminApp />
 *     </div>
 *   );
 * };
 * ```
 *
 * @returns {JSX.Element} The rendered `FormAdminApp` component.
 */
const FormAdminApp = (): JSX.Element => {
  const { app, setAppAdmin, createAdminApp } = useAdminApp()

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        createAdminApp(app)
      }}
    >
      <Typography variant="body1" className="form-title" mb={4}>
        New Application
      </Typography>
      <Stack
        direction={'row'}
        spacing={2}
        justifyContent={'space-between'}
        mt={2}
        mb={2}
      >
        <TextField
          id="outlined-basic"
          sx={{ flex: 1 }}
          label="API Gateway ID"
          variant="outlined"
          size="small"
          onChange={(e) =>
            setAppAdmin({ ...app, apiGatewayId: e.target.value })
          }
        />
        <TextField
          id="outlined-basic"
          sx={{ flex: 1 }}
          label="Application Name"
          variant="outlined"
          size="small"
          defaultValue={app.name}
          onChange={(e) => setAppAdmin({ ...app, name: e.target.value })}
          required
        />
        <TextField
          id="outlined-basic"
          sx={{ flex: 1 }}
          label="Application Type"
          variant="outlined"
          size="small"
          defaultValue={app.type}
          onChange={(e) => setAppAdmin({ ...app, type: e.target.value })}
          required
        />
        <TextField
          id="outlined-basic"
          sx={{ flex: 1 }}
          label="Application Description"
          variant="outlined"
          size="small"
          multiline
          defaultValue={app.description}
          onChange={(e) => setAppAdmin({ ...app, description: e.target.value })}
          required
        />
      </Stack>
      <Button
        variant="contained"
        type="submit"
        color="primary"
        sx={{ width: '200px' }}
      >
        Create
      </Button>
    </form>
  )
}

export default FormAdminApp
