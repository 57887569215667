import Logo from 'src/assets/images/logo2.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOut, faUser } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from 'react-router-dom'
import {
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { useContext } from 'react'
import { AuthContext } from 'src/contexts/AuthContext'
import React from 'react'
import { useAlert } from 'src/contexts/AlertContext'
import GlobalLoading from '../Loadings/GlobalLoading'
import useLoading from 'src/hooks/useLoading'
import { useDispatch } from 'react-redux'
import { logout } from 'src/redux/User/UserSlice'

/**
 * Component that renders the header of the application, including the logo, user information, and navigation options.
 *
 * @component
 * @returns {JSX.Element} The rendered Header component.
 *
 * @description
 * This component displays the application's logo, the authenticated user's information, and provides navigation options such as "Profile" and "Logout". The component uses React context for authentication and alert handling.
 *
 * The header contains a responsive layout with a `Menu` for user actions and an avatar with account settings options.
 * It also manages the loading state when logging out and displays a global loading indicator if necessary.
 *
 * @example
 * ```tsx
 * <Header />
 * ```
 *
 * @hook
 * - `useContext(AuthContext)` - To access the authentication state and methods such as `setIsAuthenticated`.
 * - `useAlert()` - To handle alerts, showing messages when the user logs out or if an error occurs.
 * - `useLoading()` - Custom hook to handle loading states during the logout process.
 *
 * @see [useContext](https://reactjs.org/docs/hooks-reference.html#usecontext)
 * @see [useAlert](#) - A custom hook defined in the `AlertContext`.
 * @see [useLoading](#) - A custom hook to control loading states.
 */
const Header = () => {
  const { user, setIsAuthenticated } = useContext(AuthContext)
  const { showAlert } = useAlert()
  const { startLoading, isLoading, stopLoading } = useLoading()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  /**
   * Handles user logout by dispatching the logout action, navigating to the login page, and showing an alert.
   * If an error occurs during logout, an error alert is displayed.
   */
  const handleLogout = async () => {
    try {
      startLoading()
      dispatch(logout())
      navigate('/login')
      setIsAuthenticated(false)
      showAlert('success', 'Sesión cerrada correctamente')
    } catch (error) {
      showAlert('error', 'Error al cerrar sesión')
    } finally {
      stopLoading()
    }
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  /**
   * Opens the user account menu.
   * @param {React.MouseEvent<HTMLElement>} event - The click event that triggers the menu.
   */
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  /**
   * Closes the user account menu.
   */
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Stack
      direction={'row'}
      justifyContent={{
        xs: 'center',
        md: 'space-between',
      }}
      p={2}
      sx={{
        bgcolor: 'primary.main',
      }}
    >
      {isLoading && <GlobalLoading />}
      <Box justifyContent={'center'}>
        <Link to="/">
          <img src={Logo} alt="Logo keypago" />
        </Link>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        sx={{
          display: {
            xs: 'none',
            md: 'block',
          },
        }}
      >
        <MenuItem
          onClick={() => {
            navigate('/perfil')
            handleClose()
          }}
        >
          <FontAwesomeIcon color="#000" icon={faUser} />
          <Typography ml={3}>Perfil</Typography>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faSignOut} color="#000" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <Stack
        sx={{
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
        direction={'row'}
        alignItems={'center'}
      >
        <Typography color={'#fff'}>
          Bienvenido,{' '}
          {user ? `${user?.firstName} ${user?.lastName}` : 'Usuario'}
        </Typography>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar
              alt="User icon"
              src="https://www.w3schools.com/howto/img_avatar.png"
            />
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  )
}

export default Header
