import { Box } from '@mui/material'
import React from 'react'

/**
 * A box component with shadow and customizable styles.
 *
 * This component utilizes Material-UI's Box to create a box with a shadow effect, rounded corners,
 * and customizable styles.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The content to be rendered inside the box.
 * @param {React.CSSProperties} [props.sx] - Optional custom styles to apply to the box.
 *
 * @example
 * ```tsx
 * <BoxWithShadow sx={{ padding: 2 }}>
 *   <h1>Hello World</h1>
 * </BoxWithShadow>
 * ```
 *
 * @returns {JSX.Element} The rendered box component.
 */
const BoxWithShadow = ({
  children,
  sx,
}: {
  children: React.ReactNode
  sx?: React.CSSProperties
}) => {
  return (
    <Box
      sx={{
        boxShadow: 1,
        borderRadius: 4,
        bgcolor: 'background.paper',
        m: 1,
        width: '100%',
        height: '100%',
        border: '1px solid #E0E0E0',
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

export default BoxWithShadow
