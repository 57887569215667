import React, { useContext, useState } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { useNavigate, useLocation, Link, Form } from 'react-router-dom'
import styles from './ChangePasswordView.module.scss'
import logo from '../../assets/images/logo.svg'
import { changePassword } from '../../services/auth/auth.service'
import useLoading from 'src/hooks/useLoading'
import GlobalLoading from 'src/components/Loadings/GlobalLoading'
import { AuthContext } from 'src/contexts/AuthContext'
import jwtDecode from 'jwt-decode'
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography,
  Autocomplete,
  TextField,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useAlert } from 'src/contexts/AlertContext'

interface FormData {
  userDocId: string
  oldPassword: string
  confirmPassword: string
  country: string
  userDocType: string
}

const ChangePasswordForm = () => {
  const { showAlert } = useAlert()
  const { setUser } = useContext(AuthContext)
  const navigate = useNavigate()
  const location = useLocation()
  const { isLoading, startLoading, stopLoading } = useLoading()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    control,
  } = useForm<FormData>({
    defaultValues: {
      country: location.state?.country || '',
      userDocType: location.state?.userDocType || '',
      userDocId: location.state?.userDocId || '',
      oldPassword: location.state?.oldPassword || '',
      confirmPassword: '',
    },
  })

  const [showPassword, setShowPassword] = useState(false)

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    if (data.confirmPassword !== data.confirmPassword) {
      setError('confirmPassword', {
        type: 'manual',
        message: 'Las contraseñas no coinciden',
      })
      return
    }
    try {
      startLoading()
      const response = await changePassword({
        oldPassword: data.oldPassword,
        confirmPassword: data.confirmPassword,
        password: data.confirmPassword,
      })

      if (response.success) {
        // Asumiendo que la API retorna un objeto con una propiedad 'success'
        showAlert('success', 'Contraseña cambiada exitosamente')
        // setUser(jwtDecode(response.accessToken)) // Asegúrate de que la respuesta incluya un 'accessToken'
        navigate('/')
      }
    } catch (error) {
      showAlert('error', 'Ha ocurrido un error al cambiar la contraseña')
      navigate('/login')
    } finally {
      stopLoading()
    }
  }

  return (
    <div className={styles.container}>
      {isLoading && <GlobalLoading />}
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Link to="/login">
          <img src={logo} alt="Logo" className={styles.logo} />
        </Link>
        <FormControl fullWidth margin="normal">
          <Controller
            name="userDocType"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={['CC', 'CE', 'TI']}
                sx={{ mt: '1rem' }}
                value={value}
                onChange={(_, newValue) => onChange(newValue ?? '')}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tipo de documento"
                    variant="outlined"
                  />
                )}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel htmlFor="userDocId">Identificación</InputLabel>
          <OutlinedInput
            id="userDocId"
            type="text"
            {...register('userDocId', {
              required: 'El Identificación es obligatorio',
            })}
            label="Identificación"
            error={Boolean(errors.userDocId)}
          />
          <Typography
            variant="body2"
            sx={{ my: 1, color: 'red', textAlign: 'left', width: '100%' }}
          >
            {errors.userDocId?.message}
          </Typography>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel htmlFor="oldPassword">Contraseña Asignada</InputLabel>
          <OutlinedInput
            id="oldPassword"
            type="password"
            {...register('oldPassword', {
              required: 'La contraseña asignada es obligatoria',
            })}
            label="Contraseña Asignada"
            error={Boolean(errors.oldPassword)}
          />
          <Typography
            variant="body2"
            sx={{ my: 1, color: 'red', textAlign: 'left', width: '100%' }}
          >
            {errors.oldPassword?.message}
          </Typography>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel htmlFor="confirmPassword">Nueva Contraseña</InputLabel>
          <OutlinedInput
            id="confirmPassword"
            type={showPassword ? 'text' : 'password'}
            {...register('confirmPassword', {
              required: 'La contraseña es obligatoria',
              minLength: {
                value: 8,
                message: 'La contraseña debe tener al menos 8 caracteres',
              },
              pattern: {
                value:
                /^(?=.*[a-zñáéíóú])(?=.*[A-ZÑÁÉÍÓÚ])(?=.*\d)(?=.*[\W_])[A-Za-zñáéíóúÑÁÉÍÓÚ\d\W_]{8,}$/,
                message:
                  'La contraseña debe tener al menos una minúscula, una mayúscula, un número y un caracter especial',
              },
            })}
            label="Nueva Contraseña"
            error={Boolean(errors.confirmPassword)}
          />
          <Typography
            variant="body2"
            sx={{ my: 1, color: 'red', textAlign: 'left', width: '100%' }}
          >
            {errors.confirmPassword?.message}
          </Typography>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel htmlFor="confirmPassword">
            Confirmar Nueva Contraseña
          </InputLabel>
          <OutlinedInput
            id="confirmPassword"
            type={showPassword ? 'text' : 'password'}
            {...register('confirmPassword', {
              required: 'Por favor, repita su contraseña',
              validate: (value) =>
                value === watch('confirmPassword') ||
                'Las contraseñas no coinciden',
            })}
            label="Confirmar Nueva Contraseña"
            error={Boolean(errors.confirmPassword)}
          />
        </FormControl>
        <Typography
          variant="body2"
          sx={{ my: 1, color: 'red', textAlign: 'left', width: '100%' }}
        >
          {errors.confirmPassword?.message}
        </Typography>
        <button type="submit" className={styles.button} disabled={isLoading}>
          Cambiar contraseña
        </button>
      </form>
    </div>
  )
}

export default ChangePasswordForm
