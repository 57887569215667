import { getHeaders, getParamsToURL } from 'src/utils/utils'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  IIndicator,
  IIndicatorCreate,
  IPayloadCopyQuestion,
  IPayloadRemoveQuestion,
  IPayloadUpdateQuestion,
} from './indicator.interface'

const BASE_URL = process.env.REACT_APP_API || 'NO_URL'

export const createIndicator = createAsyncThunk(
  'permission/createIndicator',
  async (payload: IIndicatorCreate, { rejectWithValue }) => {
    try {
      const response = await fetch(`${BASE_URL}/kpis/indicator/create`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
      })
      const data = await response.json()
      if (!response.ok) {
        throw new Error(data.error.message || 'Ha ocurrido un error inesperado')
      }
      return data
    } catch (error: any) {
      return rejectWithValue(new Error(error).message)
    }
  },
)

export const updateQuestion = createAsyncThunk(
  'permission/updateQuestion',
  async (payload: IPayloadUpdateQuestion, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${BASE_URL}/kpis/question/update-question`,
        {
          method: 'PUT',
          headers: getHeaders(),
          body: JSON.stringify(payload),
        },
      )
      const data = await response.json()
      if (!response.ok) {
        throw new Error(data.error.message || 'Ha ocurrido un error inesperado')
      }
      return data
    } catch (error: any) {
      return rejectWithValue(new Error(error).message)
    }
  },
)

export const removeQuestion = createAsyncThunk(
  'permission/updateQuestion',
  async (payload: IPayloadRemoveQuestion[], { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${BASE_URL}/kpis/question/disassociate-questions`,
        {
          method: 'POST',
          headers: getHeaders(),
          body: JSON.stringify({ questions: payload }),
        },
      )
      const data = await response.json()
      if (!response.ok) {
        throw new Error(data.error.message || 'Ha ocurrido un error inesperado')
      }
      return data
    } catch (error: any) {
      return rejectWithValue(new Error(error).message)
    }
  },
)

export const copyQuestions = createAsyncThunk(
  'permission/updateQuestion',
  async (payload: IPayloadCopyQuestion, { rejectWithValue }) => {
    try {
      const response = await fetch(`${BASE_URL}/kpis/question/copy`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
      })
      const data = await response.json()
      if (!response.ok) {
        throw new Error(data.error.message || 'Ha ocurrido un error inesperado')
      }
      return data
    } catch (error: any) {
      return rejectWithValue(new Error(error).message)
    }
  },
)

export const getIndicators = createAsyncThunk(
  'permission/getIndicators',
  async (
    pagination: {
      currentPage?: number | null
      pageSize?: number | null
    },
    { rejectWithValue },
  ) => {
    try {
      const paramsString = getParamsToURL(pagination)
      const response = await fetch(
        `${BASE_URL}/kpis/indicator/get-indicators?${paramsString}`,
        {
          method: 'GET',
          headers: getHeaders(),
        },
      )
      const data = await response.json()
      if (!response.ok) {
        throw new Error(data.error.message || 'Ha ocurrido un error inesperado')
      }
      return data.data
    } catch (error: any) {
      return rejectWithValue(new Error(error).message)
    }
  },
)

export const getIndicator = createAsyncThunk(
  'permission/getIndicator',
  async (
    payload: {
      currentPage?: number | null
      pageSize?: number | null
      indicatorId: string
    },
    { rejectWithValue },
  ) => {
    try {
      const paramsString = getParamsToURL(payload)
      const response = await fetch(
        `${BASE_URL}/kpis/indicator/get-data-indicator?${paramsString}`,
        {
          method: 'GET',
          headers: getHeaders(),
        },
      )
      const data = await response.json()
      if (!response.ok) {
        throw new Error(data.error.message || 'Ha ocurrido un error inesperado')
      }
      return data.data
    } catch (error: any) {
      return rejectWithValue(new Error(error).message)
    }
  },
)
