import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Column, Data, DummyData, DataItem } from './TableMui.interface';
import { ChangeEvent, useEffect, useState } from 'react';
import { formatToCOP } from 'src/utils/utils';
import React from 'react';

/**
 * Defines the empty columns structure used for the table header when no data is available.
 */
const columnsEmpty = [
  { id: 'date', label: '' },
  { id: 'currentSale', label: 'Ventas' },
  {
    id: 'samePeriodSale',
    label: 'Anulaciones Mismo Periodo',
  },
  {
    id: 'previousPeriodSale',
    label: 'Anulaciones Periodos Anteriores',
  },
];

/**
 * Complete column structure for the table, defining the layout for each table header.
 */
const columns: readonly Column[] = [
  { id: 'date', label: 'Fecha', minWidth: 70 },
  { id: 'currentSale', label: '# Ventas', minWidth: 70 },
  { id: 'currentValue', label: 'Monto ', minWidth: 70 },
  {
    id: 'samePeriodSale',
    label: '# Anulaciones',
    minWidth: 70,
    align: 'left',
  },
  {
    id: 'samePeriodValue',
    label: 'Monto ',
    minWidth: 70,
    align: 'left',
  },
  {
    id: 'previousPeriodSale',
    label: '# Anulaciones',
    minWidth: 70,
    align: 'left',
  },
  {
    id: 'previousPeriodValue',
    label: 'Monto ',
    minWidth: 70,
    align: 'left',
  },
];

/**
 * Helper function to create a data object that will be displayed in the table.
 *
 * @param {string} date - The date of the data entry.
 * @param {number} currentSale - The number of current sales.
 * @param {string} currentValue - The total value of current sales formatted as currency.
 * @param {number} samePeriodSale - The number of cancellations for the same period.
 * @param {string} samePeriodValue - The total value of same period cancellations formatted as currency.
 * @param {number} previousPeriodSale - The number of cancellations from previous periods.
 * @param {string} previousPeriodValue - The total value of previous period cancellations formatted as currency.
 * @returns {Data} The constructed data object.
 */
function createData(
  date: string,
  currentSale: number,
  currentValue: string,
  samePeriodSale: number,
  samePeriodValue: string,
  previousPeriodSale: number,
  previousPeriodValue: string,
): Data {
  return {
    date,
    currentSale,
    currentValue,
    samePeriodSale,
    samePeriodValue,
    previousPeriodSale,
    previousPeriodValue,
  };
}

/**
 * Renders a sticky header table with pagination. The table displays sales data including
 * current sales, cancellations in the same period, and cancellations from previous periods.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {DummyData | null} props.dataTable - Data to be displayed in the table.
 * @param {number} props.page - Current page number for pagination.
 * @param {number} props.rowsPerPage - Number of rows displayed per page.
 * @param {number} props.totalItems - Total number of items in the dataset.
 * @param {function} props.onGetData - Function to retrieve new data when page or rows per page change.
 * @returns {JSX.Element} The rendered sticky header table component.
 * 
 * @example
 * <StickyHeadTable
 *   dataTable={dataTable}
 *   page={1}
 *   rowsPerPage={10}
 *   totalItems={100}
 *   onGetData={({ page, pageSize }) => fetchData(page, pageSize)}
 * />
 */
export default function StickyHeadTable({
  dataTable,
  page,
  rowsPerPage,
  totalItems,
  onGetData,
}: {
  dataTable: DummyData | null,
  page: number,
  rowsPerPage: number,
  totalItems: number,
  onGetData: ({ page, pageSize }: { page: number, pageSize: number }) => void,
}) {
  const [rows, setRows] = useState<Data[] | null>(null);

  /**
   * Builds the data for the table from the provided `dataTable`.
   *
   * @param {DataItem[] | null} data - The array of data items to build rows from.
   * @returns {Data[]} The array of rows built from the data.
   */
  const buildData = (data: DataItem[] | null): Data[] => {
    if (!data) return [];
    const arrayBuild = data.map((item) => {
      return createData(
        item.date,
        Number(item.currentSale),
        formatToCOP(Number(item.currentValue)),
        Number(item.samePeriodSale),
        formatToCOP(Number(item.samePeriodValue)),
        Number(item.previousPeriodsSale),
        formatToCOP(Number(item.previousPeriodsValue)),
      );
    });
    setRows(arrayBuild);
    return arrayBuild;
  };

  // Updates the rows state whenever `dataTable` changes
  useEffect(() => {
    if (dataTable) {
      buildData(dataTable.rows);
    } else {
      setRows(null);
    }
  }, [dataTable]);

  return (
    <div id="table-sales">
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {rows
                  ? columnsEmpty.map((column, index) => (
                    <TableCell
                      key={index}
                      align={'center'}
                      colSpan={index !== 0 ? 2 : 1}
                      style={{ minWidth: 70, fontSize: '12px', zIndex: 0 }}
                    >
                      <strong>{column.label}</strong>
                    </TableCell>
                  ))
                  : null}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {rows ? (
                  columns.map((column, index) => (
                    <TableCell
                      key={index}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontSize: '12px',
                      }}
                    >
                      <strong>{column.label}</strong>
                    </TableCell>
                  ))
                ) : (
                  <TableCell align={'center'}>
                    <strong>No hay información disponible</strong>
                  </TableCell>
                )}
              </TableRow>
              {rows
                ? rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                      >
                        {columns.map((column, e) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={e} align={column.align}>
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          showLastButton
          showFirstButton
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalItems ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(
            event: React.MouseEvent<HTMLButtonElement> | null,
            newPage: number,
          ) => {
            onGetData({ page: newPage, pageSize: rowsPerPage });
          }}
          onRowsPerPageChange={(event: ChangeEvent<HTMLInputElement>) => {
            onGetData({ page: 0, pageSize: parseInt(event.target.value, 10) });
          }}
        />
      </Paper>
    </div>
  );
}
