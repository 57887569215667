import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import JsonView from '@uiw/react-json-view'
import {
  Autocomplete,
  Checkbox,
  Chip,
  Stack,
  TablePagination,
  TextField,
} from '@mui/material'
import {
  INPermission,
  IPermissionCreate,
} from 'src/services/IAM/AdminPermissions/AdminPermissions.interface'
import { useAdminPermission } from 'src/hooks/IAM/AdminPermission/useAdminPermission'
import AlertDialog from 'src/components/Dialog'
import { usePagination } from 'src/hooks/IAM/Components/Pagination/usePagination'
import { IModule } from 'src/components/Sidebar/Sidebar.Interface'
import { useAdminModule } from 'src/hooks/IAM/AdminModule/useAdminModule'

function Row({
  row,
  withActions,
  handlerSelectedPermissions,
}: {
  row: INPermission
  withActions?: boolean
  handlerSelectedPermissions: (permissions: INPermission) => void
}) {
  const { active, completeEndpoint, condition, methods, permissionId } = row
  const [open, setOpen] = useState(false)
  const {
    updatedPermission,
    setUpdatePermissionAdmin,
    removePermission,
    updatePermissionAdmin,
  } = useAdminPermission()
  const { modulesWithoutFormat } = useAdminModule()
  return (
    <React.Fragment>
      <TableRow
        onClick={() => setOpen(!open)}
        sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }}
      >
        {!withActions ? (
          <TableCell>
            <Checkbox
              color="primary"
              inputProps={{
                'aria-label': 'select all desserts',
              }}
              checked={active}
              onChange={() => {
                handlerSelectedPermissions(row)
                setOpen(false)
              }}
            />
          </TableCell>
        ) : null}
        <TableCell align="right">{permissionId}</TableCell>
        <TableCell sx={{
          color: row.public ? 'red' : 'green',
        }} align="right">{row.public ? 'Si' : 'No'}</TableCell>
        <TableCell align="right">
          {methods?.map((m) => m?.method).join(', ')}
        </TableCell>
        <TableCell align="right">{completeEndpoint}</TableCell>
        <TableCell align="right">
          {JSON.stringify(condition)?.slice(0, 20)}
        </TableCell>
        <TableCell align="right">
          <Stack direction="row" justifyContent={'flex-end'} spacing={2}>
            {withActions && (
              <Stack
                direction="row"
                spacing={2}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                <AlertDialog
                  title={`Editar permiso "${updatedPermission?.completeEndpoint}"`}
                  buttonLabel={'Editar'}
                  disagreeLabel="Cancelar"
                  agreeLabel="Editar"
                  onOpen={() => {
                    setUpdatePermissionAdmin({
                      ...updatedPermission,
                      completeEndpoint: row.completeEndpoint,
                      condition: JSON.stringify(row.condition),
                      endpointId: row?.endpointId ?? 0,
                      permissionId: row.permissionId,
                      modules: (row?.modules as unknown as IModule[]) ?? [],
                    })
                    console.log(typeof JSON.stringify(row.condition))
                  }}
                  onAgree={async () => {
                    updatePermissionAdmin({
                      ...updatedPermission,
                      condition: updatedPermission?.condition,
                    })
                  }}
                  children={
                    <form
                      onSubmit={(e) => {
                        e.preventDefault()
                        setUpdatePermissionAdmin(updatedPermission)
                      }}
                    >
                      <Autocomplete
                        sx={{
                          mb: 2,
                        }}
                        disablePortal
                        id="combo-box-demo"
                        options={modulesWithoutFormat ?? []}
                        multiple
                        value={updatedPermission?.modules ?? []}
                        getOptionLabel={(option: IModule) =>
                          `${option.keyName}`
                        }
                        onChange={(e, value) => {
                          setUpdatePermissionAdmin({
                            ...updatedPermission,
                            modules: value,
                          } as IPermissionCreate)
                        }}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option: IModule, index) => (
                            <Chip
                              label={`${option.keyName}`}
                              {...getTagProps({ index })}
                              key={index}
                            />
                          ))
                        }
                        isOptionEqualToValue={(option, value) => {
                          return option.moduleId === value.moduleId
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Modulos" />
                        )}
                      />

                      <TextField
                        id="condition"
                        sx={{
                          mb: 2,
                        }}
                        label="Condición"
                        type="text"
                        fullWidth
                        value={updatedPermission.condition}
                        helperText="Debe ser un JSON"
                        onChange={(e) => {
                          setUpdatePermissionAdmin({
                            ...updatedPermission,
                            condition: e.target.value,
                          })
                        }}
                      />

                      <TextField
                        id="firstName"
                        label="Descripción"
                        type="text"
                        fullWidth
                        sx={{
                          mb: 2,
                        }}
                        value={updatedPermission.description}
                        onChange={(e) =>
                          setUpdatePermissionAdmin({
                            ...updatedPermission,
                            description: e.target.value,
                          })
                        }
                      />

                      {/* <Autocomplete
                        sx={{
                          mb: 2,
                        }}
                        disablePortal
                        id="combo-box-demo"
                        options={endpoints}
                        value={
                          endpoints.find(
                            (e) =>
                              e?.endpointId === updatedPermission?.endpointId,
                          ) || null
                        }
                        onChange={(e, value) => {
                          setUpdatePermissionAdmin({
                            ...updatedPermission,
                            endpointId: value?.appId ?? 0,
                          })
                        }}
                        getOptionLabel={(option) => `${option.path}`}
                        isOptionEqualToValue={(option, value) => {
                          return option?.appId === value?.appId
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Endpoint" />
                        )}
                      /> */}
                    </form>
                  }
                />
                <AlertDialog
                  buttonLabel={'eliminar'}
                  title={`¿Estás seguro de eliminar el permiso "${updatedPermission?.completeEndpoint}"?`}
                  disagreeLabel="Cancelar"
                  agreeLabel="eliminar"
                  color="error"
                  onOpen={() => {
                    setUpdatePermissionAdmin({
                      condition: row.condition,
                      description: row.completeEndpoint,
                      endpointId: row.permissionId,
                      completeEndpoint: row.completeEndpoint,
                    })
                  }}
                  onAgree={() => {
                    console.log(updatedPermission)

                    removePermission(row.permissionId || 0)
                  }}
                  nameToDelete={updatedPermission?.completeEndpoint}
                  withConfirmation
                  onDisagree={() =>
                    console.log('updatedPermission no eliminado')
                  }
                  children={
                    <div>
                      <Typography
                        sx={{
                          mb: 2,
                        }}
                      >
                        Al eliminar el permiso
                        <strong>
                          {' '}
                          {updatedPermission?.completeEndpoint}
                        </strong>{' '}
                        todos los usuarios que lo tengan asignado no podrán
                        acceder al endpoint.
                      </Typography>
                      <p>
                        Escriba{' '}
                        <strong>{updatedPermission?.completeEndpoint}</strong>{' '}
                        para confirmar
                      </p>
                    </div>
                  }
                />
              </Stack>
            )}
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Condición
              </Typography>
              <JsonView value={condition} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default function CollapsibleTable({
  permissionsProp,
  selectedPermissions,
  withActions,
  loadPermissions,
  handlerSelectedPermissions,
}: {
  permissionsProp?: INPermission[]
  selectedPermissions?: INPermission[]
  withActions?: boolean
  loadPermissions: (params: { currentPage: number; pageSize: number }) => void
  handlerSelectedPermissions: (permissions: INPermission) => void
}) {
  const {
    currentPage,
    rowsPerPage,
    rowsPerPageOptions,
    totalItems,
    handlePageChange,
    handleRowsPerPageChange,
  } = usePagination()

  return (
    <Paper>
      <TableContainer
        sx={{ maxHeight: 440, overflow: 'auto' }}
        className="testCharlie"
      >
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead>
            <TableRow>
              {!withActions && <TableCell />}
              <TableCell align="right">ID</TableCell>
              <TableCell align="right">Publico</TableCell>
              <TableCell align="right">Metodos</TableCell>
              <TableCell align="right">Endpoint</TableCell>
              <TableCell align="right">Condition</TableCell>
              {!withActions && <TableCell />}
              {withActions && <TableCell align="right"> Acciones </TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {selectedPermissions?.map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  withActions={withActions}
                  handlerSelectedPermissions={handlerSelectedPermissions}
                />
              ))}
              {!selectedPermissions &&
                permissionsProp?.map((row, index) => (
                  <Row
                    key={index}
                    row={row}
                    withActions={withActions}
                    handlerSelectedPermissions={handlerSelectedPermissions}
                  />
                ))}
            </>
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={permissionsProp ? permissionsProp?.length + 1 : 1}
        rowsPerPage={rowsPerPage}
        page={currentPage - 1}
        onPageChange={(event, newPage) => {
          handlePageChange(event, newPage + 1)

          loadPermissions({
            currentPage: newPage + 1,
            pageSize: rowsPerPage,
          })
        }}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Paper>
  )
}
