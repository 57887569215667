import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material'
import React, { useState } from 'react'

const FiltersIndicator = () => {
  const [open, setOpen] = useState(false)
  return (
    <div>
      <Stack direction={'row'} justifyContent={'flex-end'} gap={2}>
        <Autocomplete
          options={[]}
          sx={{ width: 300 }}
          getOptionLabel={(option) => 'label'}
          renderInput={(params) => (
            <TextField {...params} label="Tipos" placeholder="Todos" />
          )}
        />
        <Button variant="contained">
          <FontAwesomeIcon icon={faSearch} size="xl" />
        </Button>
      </Stack>
      <Stack direction={'row'} justifyContent={'flex-end'} gap={2} my={2}>
        <Button onClick={() => setOpen(true)} variant="contained">
          Crear pregunta
        </Button>
      </Stack>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Crear pregunta</DialogTitle>
        <DialogContent
          sx={{
            minWidth: 500,
          }}
        >
          <Stack
            gap={2}
            flexWrap={'wrap'}
            sx={{
              my: 2,
            }}
          >
            <TextField
              sx={{
                flex: 1,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Nombre"
              placeholder="Nombre de la estrategia"
            />
            <TextField
              sx={{
                flex: 1,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Descripción"
              placeholder="Descripción de la estrategia"
            />
            <Autocomplete
              options={[]}
              sx={{
                flex: 1,
              }}
              getOptionLabel={(option) => 'label'}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Tipo"
                  placeholder="Todos"
                />
              )}
            />
            <Button fullWidth variant="contained">
              Crear
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default FiltersIndicator
