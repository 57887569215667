/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import TableIndicator from './components/TableIndicator'
import FiltersIndicator from './components/FiltersIndicator'
import { Typography } from '@mui/material'
import { useStrategies } from 'src/hooks/Dashboard/Strategies/useStrategies'
import useIsMounted from 'src/hooks/useIsMounted'
import BoxWithShadow from 'src/components/BoxWithShadow'

const IndicatorsView = () => {
  const { loadStrategies } = useStrategies()
  const isMounted = useIsMounted()
  useEffect(() => {
    if (!isMounted) return
    loadStrategies({})
  }, [isMounted])
  return (
    <div>
      <BoxWithShadow sx={{ padding: 4 }}>
        <Typography variant="h4" textAlign={'center'} gutterBottom>
          Indicadores
        </Typography>
        <FiltersIndicator />
        <TableIndicator />
      </BoxWithShadow>
    </div>
  )
}

export default IndicatorsView
