import React from 'react'
import {
  Autocomplete,
  Button,
  Chip,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { createUser } from 'src/services/IAM/AdminUser/AdminUser.service'
import GlobalLoading from 'src/components/Loadings/GlobalLoading'
import useLoading from 'src/hooks/useLoading'
import { useAlert } from 'src/contexts/AlertContext'
import { IGroup } from 'src/services/IAM/AdminGroup/AdminGroup.interface'
import AlertDialog from 'src/components/Dialog'
import StepperModal from './StepperModal'
import { useUserIAM } from 'src/hooks/IAM/AdminUser/useUserIAM'
import { useAdminPermission } from 'src/hooks/IAM/AdminPermission/useAdminPermission'
import { useAdminGroup } from 'src/hooks/IAM/AdminGroups/useAdminGroups'

const CreateUser = () => {
  const { groups } = useAdminGroup()
  const { userIAM, setUserIAMAdmin, clearUserIAM, loadUsers } = useUserIAM()
  const {
    permissions,
    loadPermissions,
    selectedPermissions,
    setSelectPermission,
  } = useAdminPermission()
  const { showAlert } = useAlert()
  const { isLoading, startLoading, stopLoading } = useLoading()
  const kindOfDocuments = ['', 'CC', 'CE', 'NIT']
  const kindOfUser = ['', 'backoffice', 'client']
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      startLoading()
      await createUser({
        ...userIAM,
        permissions: selectedPermissions,
      })
      showAlert('success', 'Usuario creado correctamente')
      loadUsers({})
      clearUserIAM()
    } catch (error) {
      showAlert('error', String(error))
    } finally {
      stopLoading()
    }
  }

  return (
    <div>
      {isLoading && <GlobalLoading />}
      <Typography variant="h3" textAlign={'center'} marginBottom={4}>
        Crear usuario
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Autocomplete
              id="combo-box-demo"
              defaultValue={''}
              options={kindOfDocuments}
              getOptionLabel={(option) => option}
              value={userIAM.userDocType ?? ''}
              onChange={(event, newValue: string | null) =>
                setUserIAMAdmin({ ...userIAM, userDocType: newValue ?? '' })
              }
              renderInput={(params) => (
                <TextField {...params} label="Tipo de documento" />
              )}
              renderOption={(props, option) =>
                option === '' ? null : (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="userDocId"
              label="Documento ID"
              type="text"
              fullWidth
              value={userIAM.userDocId}
              onChange={(e) =>
                setUserIAMAdmin({ ...userIAM, userDocId: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="firstName"
              label="Nombre"
              type="text"
              fullWidth
              value={userIAM.firstName}
              onChange={(e) =>
                setUserIAMAdmin({ ...userIAM, firstName: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="lastName"
              label="Apellido"
              type="text"
              fullWidth
              value={userIAM.lastName}
              onChange={(e) =>
                setUserIAMAdmin({ ...userIAM, lastName: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="email"
              label="Correo"
              type="email"
              fullWidth
              value={userIAM.email}
              onChange={(e) =>
                setUserIAMAdmin({ ...userIAM, email: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="phone"
              label="Celular"
              type="tel"
              fullWidth
              value={userIAM.phone}
              onChange={(e) =>
                setUserIAMAdmin({ ...userIAM, phone: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              id="combo-box-demo"
              defaultValue={''}
              options={kindOfUser}
              getOptionLabel={(option) => option}
              value={userIAM.type ?? ''}
              renderInput={(params) => (
                <TextField {...params} label="Tipo de usuario" />
              )}
              onChange={(event, newValue: string | null) => {
                setUserIAMAdmin({ ...userIAM, type: newValue ?? '' })
              }}
              renderOption={(props, option) =>
                option === '' ? null : (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              sx={{
                flex: 1,
              }}
              disablePortal
              id="combo-box-demo"
              options={groups ?? []}
              multiple
              value={userIAM.groups ?? []}
              onChange={(event, newValue: IGroup[]) =>
                setUserIAMAdmin({ ...userIAM, groups: newValue })
              }
              getOptionLabel={(option) => `${option.groupName}`}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option: IGroup, index) => (
                  <Chip
                    label={`${option.groupName}`}
                    {...getTagProps({ index })}
                    key={index}
                  />
                ))
              }
              isOptionEqualToValue={(option, value) => {
                return option.groupName === value.groupName
              }}
              renderInput={(params) => <TextField {...params} label="Grupos" />}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AlertDialog
              title="Permisos"
              buttonLabel={`Agregar permisos: ${selectedPermissions.length}`}
              maxWidth="lg"
              children={
                <Stack direction={'row'}>
                  <StepperModal
                    handlerSelectedPermissions={setSelectPermission}
                    selectedPermissions={selectedPermissions}
                    loadPermissions={loadPermissions}
                    permissionsProp={permissions}
                  />
                </Stack>
              }
            />
          </Grid>
        </Grid>
        <Button
          sx={{
            width: {
              xs: '100%',
              md: '250px',
            },
            mt: 2,
            height: '50px',
          }}
          type="submit"
          variant="contained"
        >
          Crear
        </Button>
      </form>
    </div>
  )
}

export default CreateUser
