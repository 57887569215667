export enum IWsStatusCode {
  OK = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

export enum IWsState {
  FINISHED = 'Finished',
  ERROR = 'Error',
  PROCESSING = 'Processing',
}

export interface IWsMessage {
  statusCode: number
  path: string
  apiGatewayId: string
  state: IWsState
  advertisingMessage: string
  message: string
  body?: {
    success?: true | false
    data?: {
      finish?: true | false
      flow: { continueFlow: true | false; screenKey: string }
      metadata: any
    }
  }
}

export enum IWSScreenKey {
  FRONTAL_IDENTIFICATION = 'frontal-identification',
  BACK_IDENTIFICATION = 'back-identification',
  SELFIE = 'selfie',
  FORM_USER = 'form-user',
  CODE_VERIFICATION = 'code-verification',
  REFERENCES = 'references',
  SECURITY_QUESTIONS = 'security-questions',
  CONFIRMATION = 'confirmation',
  ATTEMPTS_EXHAUSTED = 'attempts-exhausted',
  HOME = 'home',
  SERVER_ERROR = '500',
  NOT_FOUND = '404',
}

export enum IWSPathsHttpTreasury {
  DOWNLOAD_DATA_TREASURY = '/treasury/information/download',
}
export enum IWSPathsHttpAccounting {
  DOWNLOAD_REPORT_SALES = '/accounting/report-credit-cancellation/download',
  DOWNLOAD_REPORT_COLLECTIONS = '/accounting/payments-stores/download',
  DOWNLOAD_REPORT_COLLECTIONS_PSE = '/accounting/payments-pse/download',
  GET_STORES_PSE = '/accounting/payments-pse',
  GET_STORES = '/accounting/payments-stores',
  GET_SALES = '/accounting/sales',
}

export enum EHTTPMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export interface WebSocketState {
  connected: boolean;
  messages: any[];
}