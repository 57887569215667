import React, { useEffect } from 'react'
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material'
import { useAdminApp } from 'src/hooks/IAM/AdminApp/useAdminApp'
import AlertDialog from 'src/components/Dialog'
import useIsMounted from 'src/hooks/useIsMounted'
import { usePagination } from 'src/hooks/IAM/Components/Pagination/usePagination'
/**
 * Renders a table displaying admin applications.
 *
 * @remarks
 * The `TableAdminApps` component is used to display a list of admin applications in a tabular format.
 * It allows users to edit or delete applications. The table includes columns for the API Gateway ID,
 * application name, type, description, and actions. Pagination is also provided to navigate through the list of applications.
 *
 * @component
 * @example
 * ```tsx
 * import TableAdminApps from 'path/to/TableAdminApps';
 *
 * const AdminAppManagement = () => {
 *   return (
 *     <div>
 *       <h1>Admin Applications</h1>
 *       <TableAdminApps />
 *     </div>
 *   );
 * };
 * ```
 *
 * @returns {JSX.Element} The rendered `TableAdminApps` component.
 */
const TableAdminApps = () => {
  const {
    apps,
    appUpdated,
    loadApps,
    removeApp,
    updateAdminApp,
    setAppAdminUpdated,
  } = useAdminApp()
  const isMounted = useIsMounted()
  const {
    currentPage,
    handlePageChange,
    handleRowsPerPageChange,
    rowsPerPage,
    rowsPerPageOptions,
    totalItems,
  } = usePagination()
  useEffect(() => {
    if (!isMounted) return
    handlePageChange(null, 1)
  }, [isMounted])
  return (
    <Paper>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">ID Apigateway</TableCell>
              <TableCell align="left">Nombre</TableCell>
              <TableCell align="left">Tipo</TableCell>
              <TableCell align="left">Descripción</TableCell>
              <TableCell align="right">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {apps ? (
              apps.map((app, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {app?.apiGatewayId}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {app.name}
                    </TableCell>
                    <TableCell align="left">{app.type}</TableCell>
                    <TableCell align="left">{app.description}</TableCell>
                    <TableCell align="left">
                      <Stack
                        justifyContent={'flex-end'}
                        direction={'row'}
                        spacing={2}
                      >
                        <AlertDialog
                          title={`Editar app ${app.name}`}
                          buttonLabel={'Editar'}
                          disagreeLabel="Cancelar"
                          agreeLabel="Editar"
                          onOpen={() => setAppAdminUpdated(app)}
                          onAgree={async () => {
                            console.log(appUpdated, 'appUpdated')

                            updateAdminApp(appUpdated)
                          }}
                          onDisagree={() => console.log('app no editado')}
                          children={
                            <form>
                              <Box>
                                <TextField
                                  sx={{ width: '100%', mt: 2 }}
                                  id="outlined-basic"
                                  label="ID Apigateway"
                                  variant="outlined"
                                  value={appUpdated?.apiGatewayId || ''}
                                  onChange={(e) =>
                                    setAppAdminUpdated({
                                      ...appUpdated,
                                      apiGatewayId: e.target.value,
                                    })
                                  }
                                />
                                <TextField
                                  sx={{ width: '100%', mt: 2 }}
                                  id="outlined-basic"
                                  label="Nombre app"
                                  variant="outlined"
                                  value={appUpdated?.name || ''}
                                  onChange={(e) =>
                                    setAppAdminUpdated({
                                      ...appUpdated,
                                      name: e.target.value,
                                    })
                                  }
                                />
                                <TextField
                                  sx={{ width: '100%', mt: 2 }}
                                  id="outlined-basic"
                                  label="Tipo de app"
                                  variant="outlined"
                                  value={appUpdated?.type || ''}
                                  onChange={(e) =>
                                    setAppAdminUpdated({
                                      ...appUpdated,
                                      type: e.target.value,
                                    })
                                  }
                                  required
                                />
                                <TextField
                                  sx={{ width: '100%', mt: 2 }}
                                  id="outlined-basic"
                                  label="Descripción app"
                                  variant="outlined"
                                  value={appUpdated?.description || ''}
                                  onChange={(e) =>
                                    setAppAdminUpdated({
                                      ...appUpdated,
                                      description: e.target.value,
                                    })
                                  }
                                  required
                                />
                              </Box>
                            </form>
                          }
                        />
                        <AlertDialog
                          buttonLabel={'Eliminar'}
                          title={`¿Estás seguro de eliminar el app "${app.name}"?`}
                          disagreeLabel="Cancelar"
                          agreeLabel="Eliminar"
                          color="error"
                          onAgree={() => {
                            removeApp(Number(app.appId))
                          }}
                          nameToDelete={app.name}
                          withConfirmation
                          onDisagree={() => console.log('app no eliminado')}
                          children={
                            <div>
                              <p>
                                Al eliminar este app, se eliminarán todos los
                                subapps asociados y sus respectivos permisos
                              </p>
                              <p>
                                Escriba <strong>{app.name}</strong> para
                                confirmar
                              </p>
                            </div>
                          }
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No hay aplicaciones
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={apps.length + 1}
        rowsPerPage={rowsPerPage}
        page={currentPage - 1}
        onPageChange={(event, newPage) => {
          handlePageChange(event, newPage + 1)

          loadApps({
            currentPage: newPage + 1,
            pageSize: rowsPerPage,
          })
        }}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Paper>
  )
}

export default TableAdminApps
