/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import './AdminApps.scss'
import { Stack, TextField } from '@mui/material'
import useIsMounted from 'src/hooks/useIsMounted'
import { useAdminApp } from 'src/hooks/IAM/AdminApp/useAdminApp'
import TableAdminApps from './Components/TableAdminApps'
import FormAdminApp from './Components/FormAdminApp'
/**
 * AdminApps component for managing and displaying applications.
 *
 * @remarks
 * The `AdminApps` component serves as a container for the admin application management interface.
 * It includes a form for adding new applications, a search field for filtering applications,
 * and a table that lists all existing applications. The component fetches the list of applications
 * when it is mounted.
 *
 * ### Key Changes:
 * - **Usage Examples**:
 *   - The first example demonstrates how to use the `AdminApps` component in a parent component.
 *   - The second example illustrates how props might be added in the future, though currently, `AdminApps` does not accept any props.
 *
 * ### Additional Notes:
 * - **Flexibility for Future Props**: This documentation anticipates future developments in the component by indicating how props can be added.
 *
 * @component
 * @example
 * ```tsx
 * import AdminApps from 'path/to/AdminApps';
 *
 * // Example of using AdminApps in a parent component
 * const AppManagementPage = () => {
 *   return <AdminApps />;
 * };
 * ```
 *
 * @example
 * // Example with different props (though AdminApps currently does not accept any props)
 * ```tsx
 * // Props can be added in the future
 * const propsExample = {
 *   // future prop examples
 * };
 *
 * <AdminApps {...propsExample} />
 * ```
 *
 * @returns {JSX.Element} The rendered `AdminApps` component.
 */
const AdminApps = (): JSX.Element => {
  const { loadApps } = useAdminApp()
  const isMounted = useIsMounted()

  // GET all apps
  useEffect(() => {
    if (!isMounted) return
    loadApps({})
  }, [isMounted])

  return (
    <div className="container-admin-apps">
      <FormAdminApp />
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        sx={{ marginBlock: '20px' }}
      >
        <h2>All Applications</h2>
        <TextField
          id="search-app"
          label="Search Application"
          variant="outlined"
          size="small"
          multiline
          required
        />
      </Stack>
      <TableAdminApps />
    </div>
  )
}

export default AdminApps
