// src/features/user/appSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { IAdminApp } from 'src/services/IAM/AdminApps/AdminApp.interface'
import {
  getApps,
  deleteApp,
  updateApp,
} from 'src/services/IAM/AdminApps/AdminApp.service'
import { IAdminAppState } from './adminApps.interface'

const initialState: IAdminAppState = {
  app: {
    apiGatewayId: '',
    name: '',
    type: '',
    description: '',
  },
  appUpdated: {
    apiGatewayId: '',
    name: '',
    type: '',
    description: '',
  },
  apps: [],
  loading: false,
  error: null,
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setApp: (state, action: PayloadAction<IAdminApp>) => {
      state.app = action.payload
    },
    setAppUpdated: (state, action: PayloadAction<IAdminApp>) => {
      state.appUpdated = action.payload
    },
    resetApp: () => initialState,
    resetApps: (state) => {
      state.apps = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getApps.fulfilled,
        (state, action: PayloadAction<IAdminApp[]>) => {
          state.loading = false
          state.apps = action.payload
        },
      )
      .addCase(getApps.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(deleteApp.pending, (state, action: PayloadAction<any>) => {
        state.loading = false
      })
      .addCase(deleteApp.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false
      })
      .addCase(deleteApp.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(updateApp.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false
      })
      .addCase(updateApp.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload
      })
  },
})

export const { resetApp, setApp, resetApps, setAppUpdated } = appSlice.actions
export default appSlice.reducer
