/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { Button, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import useIsMounted from 'src/hooks/useIsMounted'
import InfiniteAutocomplete from 'src/components/IAM/InfiniteAutocomplete'
import { useAdminModule } from 'src/hooks/IAM/AdminModule/useAdminModule'
import TableModules from './Components/TableModules'
import { usePagination } from 'src/hooks/IAM/Components/Pagination/usePagination'
import BoxWithShadow from 'src/components/BoxWithShadow'

const AdminModule = () => {
  const {
    modules,
    selectedModules,
    loadModules,
    loadModulesWithoutFormat,
    createModuleAdmin,
  } = useAdminModule()
  const { currentPage, rowsPerPage } = usePagination()
  const isMounted = useIsMounted()
  const [nameModule, setNameModule] = useState('')
  useEffect(() => {
    if (!isMounted) return
    loadModules({
      currentPage,
      pageSize: rowsPerPage,
    })
    loadModulesWithoutFormat({
      currentPage,
      pageSize: rowsPerPage,
    })
  }, [isMounted])

  // TABLE DATA

  return (
    <BoxWithShadow sx={{
      padding: '20px',
    }}>
      {/* Dialog confirmar la eliminacion */}
      <Typography variant="h4" gutterBottom>
        Crear modulo
      </Typography>

      <form
        onSubmit={(e) => {
          e.preventDefault()
          createModuleAdmin({
            path: selectedModules[selectedModules.length - 1]?.path ?? '',
            keyName: nameModule,
          })
        }}
      >
        <Stack direction="row" gap={2} mb={2}>
          <InfiniteAutocomplete
            data={{
              keyName: 'Carpeta raiz',
              moduleId: 0,
              path: '',
              subModules: modules,
            }}
          />
          <TextField
            id="outlined-basic"
            label="Nombre del modulo"
            variant="outlined"
            value={nameModule}
            required
            onChange={(e) => setNameModule(e.target.value)}
            sx={{
              ml: -2,
            }}
          />
        </Stack>

        <Button variant="contained" color="primary" type="submit">
          Crear
        </Button>
      </form>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        sx={{ marginBlock: '20px' }}
      >
        <h2>Todos los modulos</h2>
        <TextField
          id="outlined-basic"
          label="Buscar modulo"
          variant="outlined"
        />
      </Stack>
      <TableModules />
    </BoxWithShadow>
  )
}

export default AdminModule
