import { useDispatch, useSelector } from 'react-redux'
import { useAlert } from 'src/contexts/AlertContext'
import useLoading from 'src/hooks/useLoading'
import { AppDispatch, RootState } from 'src/redux'
import {
  getCreditLimitUser,
  getModelsUser,
  getUserkp,
  getDeviceData,
  getURLField,
  getPressignedUrl,
  getCreditLimitNewUser,
} from 'src/services/Onboarding/Users/user.service'
import {
  IGetUserParams,
  IPostModelNewUser,
} from 'src/services/Onboarding/Users/user.service.interface'

const useUserkeypago = () => {
  const { kpUser, responseCreditHistory, responseModelsUser, deviceInfo } =
    useSelector((state: RootState) => state.kpUser)
  const dispatch = useDispatch<AppDispatch>()
  const { startLoading, stopLoading } = useLoading()
  const { showAlert } = useAlert()
  const getUserInfo = (payload: IGetUserParams) => {
    try {
      startLoading()
      dispatch(getUserkp(payload))
        .then((result: any) => {
          if (getUserkp.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) ||
                'Error al obtener la información del usuario',
            )
          } else if (getUserkp.fulfilled.match(result)) {
            showAlert(
              'success',
              'Información del usuario obtenida correctamente',
            )
          }
        })
        .finally(() => {
          stopLoading()
        })
    } catch (error) {
      showAlert('error', 'Error al obtener la información del usuario')
    }
  }
  const getAllModelsUsers = (payload: IGetUserParams) => {
    try {
      startLoading()
      dispatch(getModelsUser(payload))
        .then((result: any) => {
          if (getModelsUser.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) ||
                'Error al obtener la información del usuario',
            )
          } else if (getModelsUser.fulfilled.match(result)) {
            showAlert(
              'success',
              'Información del usuario obtenida correctamente',
            )
          }
        })
        .finally(() => {
          stopLoading()
        })
    } catch (error) {
      showAlert('error', 'Error al obtener la información del usuario')
    }
  }
  const getCreditLimit = (payload: IGetUserParams) => {
    try {
      startLoading()
      dispatch(getCreditLimitUser(payload))
        .then((result: any) => {
          if (getCreditLimitUser.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) ||
                'Error al obtener el historial de crédito del usuario',
            )
          } else if (getCreditLimitUser.fulfilled.match(result)) {
            showAlert(
              'success',
              'Historial de crédito del usuario obtenido correctamente',
            )
          }
        })
        .finally(() => {
          stopLoading()
        })
    } catch (error) {
      showAlert('error', 'Error al obtener el historial de crédito del usuario')
    }
  }
  const getCreditLimitNew = (payload: IPostModelNewUser) => {
    try {
      startLoading()
      dispatch(getCreditLimitNewUser(payload))
        .then((result: any) => {
          if (getCreditLimitNewUser.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) ||
                'Error al obtener el historial de crédito del usuario',
            )
          } else if (getCreditLimitNewUser.fulfilled.match(result)) {
            showAlert(
              'success',
              'Historial de crédito del usuario obtenido correctamente',
            )
          }
        })
        .finally(() => {
          stopLoading()
        })
    } catch (error) {
      showAlert('error', 'Error al obtener el historial de crédito del usuario')
    }
  }
  const getDeviceInfo = (payload: IGetUserParams) => {
    try {
      startLoading()
      dispatch(getDeviceData(payload))
        .then((result: any) => {
          if (getDeviceData.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) ||
                'Error al obtener el historial de crédito del usuario',
            )
          } else if (getDeviceData.fulfilled.match(result)) {
            showAlert(
              'success',
              'Historial de crédito del usuario obtenido correctamente',
            )
          }
        })
        .finally(() => {
          stopLoading()
        })
    } catch (error) {
      showAlert('error', 'Error al obtener el historial de crédito del usuario')
    }
  }

  const downloadDocument = async (fileId: string) => {
    try {
      startLoading()
      const response = await getURLField(fileId)
      window.open(response.data.url)
      showAlert('success', 'Documento obtenido correctamente')
    } catch (error) {
      showAlert('error', 'Error al obtener el documento')
    } finally {
      stopLoading()
    }
  }
  return {
    kpUser,
    responseCreditHistory,
    responseModelsUser,
    deviceInfo,
    getUserInfo,
    getCreditLimit,
    getAllModelsUsers,
    getDeviceInfo,
    downloadDocument,
    getCreditLimitNew,
  }
}

export default useUserkeypago
