import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import BoxWithShadow from 'src/components/BoxWithShadow'
import { useIndicators } from 'src/hooks/Dashboard/Indicators/useIndicators'
import useIsMounted from 'src/hooks/useIsMounted'
import { IResponseGetIndicator } from 'src/services/dashboard/indicator/indicator.interface'
import TableQuestionsIndicator from './Components/TableQuestionsIndicator'
import AlertDialog from 'src/components/Dialog'
import { useKPIForm } from 'src/hooks/Dashboard/KPIForm/useKPIForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DialogAsociate from './Components/DialogAsociate'

const AsociateQuestions = () => {
  // Variables de estado
  const isMounted = useIsMounted()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const {
    responseLoadIndicator,
    filter,
    loadIndicator,
    copyQuestionsIndicator,
    setfilterIndicators,
  } = useIndicators()
  const [questionsSelected, setQuestionsSelected] = useState<
    IResponseGetIndicator['rows']
  >([])
  const { idIndicator } = useParams()
  const [showModalToCopy, setShowModalToCopy] = useState(false)
  const { filters, loadFilters } = useKPIForm()
  const [showModal, setShowModal] = useState(false)
  const [rows] = useState<
    | {
      id: number
    }[]
    | null
  >([])

  // Funciones de paginación
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  //Efects
  useEffect(() => {
    if (!isMounted) return
    loadFilters()
    loadIndicator({
      indicatorId: String(idIndicator),
      currentPage: 1,
      pageSize: 25,
    })
  }, [isMounted])
  return (
    <BoxWithShadow sx={{ padding: 4 }}>
      <Typography variant="h6" id="tableTitle">
        {responseLoadIndicator?.infoIndicator?.indicatorName}
      </Typography>
      {/* Space designed for filters */}
      <Stack direction="row" spacing={2} justifyContent={'flex-end'} p={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setShowModalToCopy(true)
            setfilterIndicators({
              ...filter,
              areaName: responseLoadIndicator?.infoIndicator?.areaName ?? '',
            })
          }}
        >
          Copiar preguntas
        </Button>
        <Button
          onClick={() => {
            setShowModal(true)
            setfilterIndicators({
              ...filter,
              areaName: responseLoadIndicator?.infoIndicator?.areaName ?? '',
            })
          }}
          variant="contained"
          color="secondary"
          sx={{ ml: 2 }}
        >
          Asociar preguntas
        </Button>
        <DialogAsociate
          showModal={showModal}
          setShowModal={setShowModal}
          responseLoadIndicator={responseLoadIndicator}
        />
        <Dialog
          open={showModalToCopy}
          onClose={() => setShowModalToCopy(false)}
        >
          <DialogContent>
            {/* List of questions */}
            <Typography variant="h6" id="tableTitle">
              Preguntas seleccionadas
            </Typography>
            <List dense>
              {questionsSelected.length
                ? questionsSelected.map((question) => (
                  <ListItem key={question.questionId}>
                    - <ListItemText primary={question.questionName} />
                  </ListItem>
                ))
                : 'No hay preguntas seleccionadas'}
            </List>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                copyQuestionsIndicator({
                  copyFrom: {
                    indicatorId: Number(idIndicator),
                    questions: questionsSelected.map((question) => {
                      return {
                        answerDefault: question.answerDefault,
                        parameterByStrategyId: question.parameterByStrategyId,
                        parameterId: question.parameterId,
                        questionId: question.questionId,
                      }
                    }),
                  },
                  copyTo: {
                    indicatorId: Number(idIndicator),
                    periodId: Number(filter.periodId),
                    quarterId: Number(filter.quarterId),
                  },
                })
              }}
            >
              <Stack direction={'column'} marginBlock={4} gap={2}>
                {filters?.find((f) => f.areaName === filter.areaName)?.channels
                  ?.length ? (
                  <>
                    {filters?.find((f) => f?.areaName === filter?.areaName)
                      ?.channels?.[0].channelName === 'General' || {} ? null : (
                      <Autocomplete
                        sx={{
                          width: '400px',
                        }}
                        clearIcon={null}
                        value={
                          filters
                            ?.find((f) => f?.areaName === filter?.areaName)
                            ?.channels?.find(
                              (channel) =>
                                channel.channelId === filter.channelId,
                            ) ?? null
                        }
                        options={
                          filters?.find((f) => f.areaName === filter.areaName)
                            ?.channels ?? []
                        }
                        onChange={(event, newValue) => {
                          setfilterIndicators({
                            ...filter,
                            channelId: newValue?.channelId ?? 0,
                          })
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.channelName === value.channelName
                        }
                        getOptionLabel={(option) => option.channelName}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Canal"
                            placeholder="Selecciona un canal"
                          />
                        )}
                      />
                    )}
                  </>
                ) : null}
                {/* periods */}
                <Autocomplete
                  sx={{
                    width: '400px',
                  }}
                  clearIcon={null}
                  value={
                    filters
                      ?.find((f) => f?.areaName === filter?.areaName)
                      ?.periods?.find(
                        (channel) => channel.periodId === filter.periodId,
                      ) ?? null
                  }
                  options={
                    filters?.find((f) => f.areaName === filter.areaName)
                      ?.periods ?? []
                  }
                  onChange={(event, newValue) => {
                    setfilterIndicators({
                      ...filter,
                      periodId: newValue?.periodId ?? 0,
                    })
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.periodName === value.periodName
                  }
                  getOptionLabel={(option) => option.periodName}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Periodo"
                      placeholder="Selecciona un periodo"
                    />
                  )}
                />
                {/* quarters */}
                <Autocomplete
                  sx={{
                    width: '400px',
                  }}
                  clearIcon={null}
                  value={
                    filters
                      ?.find((f) => f?.areaName === filter?.areaName)
                      ?.periods?.find(
                        (channel) => channel.periodId === filter.periodId,
                      )
                      ?.quarters?.find(
                        (q) => q.quarterId === filter.quarterId,
                      ) ?? null
                  }
                  options={
                    filters
                      ?.find((f) => f.areaName === filter.areaName)
                      ?.periods?.find((p) => p.periodId === filter.periodId)
                      ?.quarters ?? []
                  }
                  onChange={(_, newValue) => {
                    setfilterIndicators({
                      ...filter,
                      quarterId: newValue?.quarterId ?? 0,
                    })
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.quarterName === value.quarterName
                  }
                  getOptionLabel={(option) => option.quarterName}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Trimestre"
                      placeholder="Selecciona un trimestre"
                    />
                  )}
                />

                <Button variant="contained" color="primary" type="submit">
                  Copiar ({questionsSelected.length})
                </Button>
              </Stack>
            </form>
          </DialogContent>
        </Dialog>
      </Stack>

      <TableQuestionsIndicator
        questionsSelected={questionsSelected}
        setQuestionsSelected={setQuestionsSelected}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        selectedQuestions={questionsSelected}
        responseLoadIndicator={responseLoadIndicator}
      />
    </BoxWithShadow>
  )
}

export default AsociateQuestions
