import React from 'react'
import Paper from '@mui/material/Paper'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TablePagination from '@mui/material/TablePagination'
import { IRowsStores } from '../SearchFilesCollections.interface'
import { formatToCOP } from 'src/utils/utils'

interface Header {
  label: string
  columns: number
}

interface TablePSEProps {
  headerStores: Header[]
  rows: IRowsStores[] | null
  rowsPerPage: number
  page: number
  totalRows: number
  totalItems: number
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const TableStores: React.FC<TablePSEProps> = ({
  headerStores,
  rows,
  rowsPerPage,
  page,
  totalRows,
  totalItems,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  return (
    <div id="table-stores">
      {/* TABLE START */}
      <Paper sx={{ width: '100%' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow style={{ zIndex: 0, position: 'relative' }}>
                {headerStores.map((item, index) => (
                  <TableCell align="center" key={index} colSpan={item.columns}>
                    {item.label}
                  </TableCell>
                ))}
              </TableRow>
              {/* HEADERS PSE */}
              <TableRow style={{ zIndex: 0, position: 'relative' }}>
                <TableCell align={'center'} style={{ top: 57, minWidth: 170 }}>
                  Fecha
                </TableCell>
                {[0, 1, 2, 3, 4].map((item) => (
                  <React.Fragment key={item}>
                    <TableCell
                      align={'center'}
                      style={{ top: 57, minWidth: 170 }}
                    >
                      {item === 3 ? 'Mismo periodo' : ''}
                      {item === 4 ? 'Periodos anteriores' : ''}
                      <div>Número</div>
                    </TableCell>
                    <TableCell
                      align={'center'}
                      style={{ top: 57, minWidth: 170 }}
                    >
                      {item === 3 ? 'Mismo Periodo' : ''}
                      {item === 4 ? 'Periodos anteriores' : ''}
                      <div>Monto</div>
                    </TableCell>
                  </React.Fragment>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows && rows.length ? (
                rows.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell align="center">
                        <strong>{row.date}</strong>
                      </TableCell>
                      <TableCell align="center">{row.suiteCount}</TableCell>
                      <TableCell align="center">
                        {formatToCOP(Number(row.suiteAmount))}
                      </TableCell>
                      <TableCell align="center">{row.coreCount}</TableCell>
                      <TableCell align="center">
                        {formatToCOP(Number(row.coreAmount))}
                      </TableCell>
                      <TableCell align="center">
                        {row.differenceCount}
                      </TableCell>
                      <TableCell align="center">
                        {formatToCOP(Number(row.differenceAmount))}
                      </TableCell>
                      <TableCell align="center">
                        {row.samePeriodCount}
                      </TableCell>
                      <TableCell align="center">
                        {formatToCOP(Number(row.samePeriodAmount))}
                      </TableCell>
                      <TableCell align="center">
                        {row.previousPeriodCount}
                      </TableCell>
                      <TableCell align="center">
                        {formatToCOP(Number(row.previousPeriodAmount))}
                      </TableCell>
                    </TableRow>
                  )
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={11} align="center">
                    No hay registros
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalItems || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* TABLE END */}
    </div>
  )
}

export default TableStores
