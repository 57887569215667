import { useDispatch, useSelector } from 'react-redux'
import { useAlert } from 'src/contexts/AlertContext'
import useLoading from 'src/hooks/useLoading'
import { AppDispatch, RootState } from 'src/redux'
import {
  getConfigModelCreditLimit,
  getFiltersSettings,
  getInitialSettiongByVersion,
  testGetModelsCreditLimit,
} from 'src/services/Onboarding/TestModels/testModels.service'

const useTestModels = () => {
  const {
    responseGetModelsCreditLimit,
    responseGetInitialSettiongByVersion,
    rsponseGetConfigModelCreditLimit,
    responseGetFilterSettings,
  } = useSelector((state: RootState) => state.testModel)
  const dispatch = useDispatch<AppDispatch>()
  const { startLoading, stopLoading } = useLoading()
  const { showAlert } = useAlert()

  const testModelCreditLimit = () => {
    try {
      startLoading()
      dispatch(testGetModelsCreditLimit())
        .then((result: any) => {
          if (testGetModelsCreditLimit.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) ||
                'Error al obtener la información del usuario',
            )
          } else if (testGetModelsCreditLimit.fulfilled.match(result)) {
            showAlert(
              'success',
              'Información del usuario obtenida correctamente',
            )
          }
        })
        .finally(() => {
          stopLoading()
        })
    } catch (error) {
      showAlert('error', 'Error al obtener la información del usuario')
    }
  }

  const getFiltersModels = () => {
    try {
      startLoading()
      dispatch(getFiltersSettings())
        .then((result: any) => {
          if (getFiltersSettings.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) ||
                'Error al obtener los filtros de los modelos',
            )
          } else if (getFiltersSettings.fulfilled.match(result)) {
            showAlert(
              'success',
              'Filtros de los modelos obtenidos correctamente',
            )
          }
        })
        .finally(() => {
          stopLoading()
        })
    } catch (error) {
      showAlert('error', 'Error al obtener los filtros de los modelos')
    }
  }

  const testConfigModelCreditLimit = (payload: { modelVersionId: number }) => {
    try {
      startLoading()
      dispatch(getConfigModelCreditLimit(payload))
        .then((result: any) => {
          if (getConfigModelCreditLimit.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) ||
                'Error al obtener la información del usuario',
            )
          } else if (getConfigModelCreditLimit.fulfilled.match(result)) {
            showAlert(
              'success',
              'Información del usuario obtenida correctamente',
            )
          }
        })
        .finally(() => {
          stopLoading()
        })
    } catch (error) {
      showAlert('error', 'Error al obtener la información del usuario')
    }
  }

  const testInitialSettiongByVersion = (payload: {
    settingVersionId: number
  }) => {
    try {
      startLoading()
      dispatch(getInitialSettiongByVersion(payload))
        .then((result: any) => {
          if (getInitialSettiongByVersion.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) ||
                'Error al obtener la información del usuario',
            )
          } else if (getInitialSettiongByVersion.fulfilled.match(result)) {
            showAlert(
              'success',
              'Información del usuario obtenida correctamente',
            )
          }
        })
        .finally(() => {
          stopLoading()
        })
    } catch (error) {
      showAlert('error', 'Error al obtener la información del usuario')
    }
  }

  return {
    responseGetModelsCreditLimit,
    responseGetInitialSettiongByVersion,
    rsponseGetConfigModelCreditLimit,
    responseGetFilterSettings,
    getFiltersModels,
    testModelCreditLimit,
    testConfigModelCreditLimit,
    testInitialSettiongByVersion,
  }
}

export default useTestModels
