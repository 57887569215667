import { fetchWithToken, getHeaders, getParamsToURL } from 'src/utils/utils'
import { IModule } from 'src/components/Sidebar/Sidebar.Interface'
import { createAsyncThunk } from '@reduxjs/toolkit'

const BASE_URL = process.env.REACT_APP_API || 'NO_URL'

export const createModule = createAsyncThunk(
  'permission/createModule',
  async (payload: IModule, { rejectWithValue }) => {
    try {
      const response = await fetchWithToken(`${BASE_URL}/iam/module/create`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
      })
      const data = await response.json()
      if (!response.ok && response) {
        throw new Error(
          data.error.message || '-- A ocurrido un error inesperado --',
        )
      }
      return data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)
export const getModules = createAsyncThunk(
  'permission/getModules',
  async (
    pagination: {
      currentPage?: number
      pageSize?: number
    } = {},
    { rejectWithValue },
  ) => {
    try {
      const paramsString = getParamsToURL(pagination)

      const response = await fetchWithToken(
        `${BASE_URL}/iam/module/get-modules?${paramsString}&type=frontend`,
        {
          method: 'GET',
          headers: getHeaders(),
        },
      )
      const data = await response.json()
      if (!response.ok && response) {
        throw new Error(
          data.error.message || '-- A ocurrido un error inesperado --',
        )
      }

      return {
        data: data.data,
        details: data.detail.totalItems,
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)
export const getModulesWithoutFormat = createAsyncThunk(
  'permission/getModulesWithoutFormat',
  async (
    pagination: {
      currentPage?: number
      pageSize?: number
    } = {},
    { rejectWithValue },
  ) => {
    try {
      const paramsString = getParamsToURL(pagination)

      const response = await fetchWithToken(
        `${BASE_URL}/iam/module/get-modules?${paramsString}`,
        {
          method: 'GET',
          headers: getHeaders(),
        },
      )
      const data = await response.json()
      if (!response.ok && response) {
        throw new Error(
          data.error.message || '-- A ocurrido un error inesperado --',
        )
      }

      return {
        data: data.data,
        details: data.detail.totalItems,
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)

export const deleteModule = createAsyncThunk(
  'permission/deleteModule',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await fetchWithToken(
        `${BASE_URL}/iam/module/delete/${id}`,
        {
          method: 'DELETE',
          headers: getHeaders(),
        },
      )

      const data = await response.json()
      if (!response.ok) {
        throw new Error(
          data.error.message || '-- A ocurrido un error inesperado --',
        )
      }

      return data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)

export const updateModule = createAsyncThunk(
  'permission/updateModule',
  async ({ moduleId, parentPath, keyName }: IModule, { rejectWithValue }) => {
    try {
      const response = await fetchWithToken(
        `${BASE_URL}/iam/module/update/${moduleId}`,
        {
          method: 'PUT',
          headers: getHeaders(),
          body: JSON.stringify({
            path: parentPath,
            keyName,
          }),
        },
      )
      const data = await response.json()
      if (!response.ok) {
        throw new Error(
          data.error.message || '-- A ocurrido un error inesperado --',
        )
      }

      return data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)
