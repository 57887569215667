// src/hooks/useAdminModule.ts
import { useDispatch, useSelector } from 'react-redux'
import { RootState, AppDispatch } from 'src/redux/index'
import {
  setNewModule,
  resetModule,
  setUpdateModule,
  setModulesWithoutFormat,
  addSelectedModule,
  removeSelectedModule,
} from 'src/redux/IAM/AdminModules/adminModulesSlice'
import {
  getModules,
  createModule,
  deleteModule,
  updateModule,
  getModulesWithoutFormat,
} from 'src/services/IAM/AdminModule/AdminModule.service'
import { useAlert } from 'src/contexts/AlertContext'
import { usePagination } from '../Components/Pagination/usePagination'
import { IModule } from 'src/components/Sidebar/Sidebar.Interface'
import useLoading from 'src/hooks/useLoading'

export const useAdminModule = () => {
  const { showAlert } = useAlert()
  const { startLoading, stopLoading } = useLoading()
  const dispatch = useDispatch<AppDispatch>()
  const { handleTotalItems, currentPage, rowsPerPage } = usePagination()
  const {
    modules,
    selectedModules,
    newModule,
    updatedModule,
    modulesWithoutFormat,
    module,
    loading,
    error,
  } = useSelector((state: RootState) => state.modules)

  const loadModules = ({
    currentPage = 1,
    pageSize = 25,
  }: {
    currentPage?: number
    pageSize?: number
  } = {}) => {
    try {
      startLoading()
      dispatch(
        getModules({
          currentPage,
          pageSize,
        }),
      )
        .then((result: any) => {
          stopLoading()
          if (getModules.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) || 'Error al crear la app',
            )
          } else if (getModules.fulfilled.match(result)) {
            showAlert('success', 'Módulos obtenidos correctamente')
            if (
              {
                currentPage,
                pageSize,
              }
            ) {
              handleTotalItems(result.payload.details)
            }
          }
        })
        .finally(() => {
          stopLoading()
        })
    } catch (error) {
      showAlert('error', 'Error al obtener los permisos')
    }
  }
  const updateModuleAdmin = (module: IModule) => {
    dispatch(updateModule(module))
      .then((result: any) => {
        stopLoading()
        if (updateModule.rejected.match(result)) {
          showAlert('error', String(result.payload) || 'Error al crear la app')
        } else if (updateModule.fulfilled.match(result)) {
          loadModules()
          loadModulesWithoutFormat({
            currentPage,
            pageSize: rowsPerPage,
          })
          showAlert('success', 'Módulos obtenidos correctamente')
        }
      })
      .finally(() => {
        stopLoading()
      })
  }

  const removeModule = (id: number) => {
    try {
      startLoading()
      dispatch(deleteModule(id))
        .then((result: any) => {
          stopLoading()
          if (deleteModule.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) || 'Error al eliminar el permiso',
            )
          } else if (deleteModule.fulfilled.match(result)) {
            showAlert('success', 'Permiso eliminado correctamente')
            loadModules()
            loadModulesWithoutFormat({
              currentPage,
              pageSize: rowsPerPage,
            })
          }
        })
        .finally(() => {
          stopLoading()
        })
    } catch (error) {
      showAlert('error', 'Error al eliminar el permiso')
    }
  }
  const loadModulesWithoutFormat = (pagination: {
    currentPage?: number
    pageSize?: number
  }) => {
    try {
      startLoading()
      dispatch(getModulesWithoutFormat(pagination))
        .then((result: any) => {
          stopLoading()
          if (getModulesWithoutFormat.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) || 'Error al crear la app',
            )
          } else if (getModulesWithoutFormat.fulfilled.match(result)) {
            showAlert('success', 'Módulos obtenidos correctamente')
            if (pagination) {
              handleTotalItems(result.payload.details)
            }
          }
        })
        .finally(() => {
          stopLoading()
        })
    } catch (error) {
      showAlert('error', 'Error al obtener los permisos')
    }
  }
  const createModuleAdmin = (module: IModule) => {
    try {
      startLoading()
      dispatch(createModule(module))
        .then((result: any) => {
          stopLoading()
          if (createModule.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) || 'Error al crear la app',
            )
          } else if (createModule.fulfilled.match(result)) {
            showAlert('success', 'App creada correctamente')
            loadModules()
            loadModulesWithoutFormat({
              currentPage,
              pageSize: rowsPerPage,
            })
          }
        })
        .finally(() => {
          stopLoading()
        })
    } catch (error) {
      showAlert('error', 'Error al crear el permiso')
    }
  }

  const setNewModuleAdmin = (module: IModule) => {
    dispatch(setNewModule(module))
  }
  const addModuleSelect = (module: IModule) => {
    dispatch(addSelectedModule(module))
  }

  const removeModuleSelect = (module: IModule) => {
    dispatch(removeSelectedModule(module))
  }

  const setModuleWithoutFormatAdmin = (module: IModule[]) => {
    dispatch(setModulesWithoutFormat(module))
  }
  const setUpdateModuleAdmin = (module: IModule) => {
    dispatch(setUpdateModule(module))
  }

  const clearModules = () => {
    dispatch(resetModule())
  }

  return {
    modules,
    module,
    newModule,
    loading,
    error,
    updatedModule,
    modulesWithoutFormat,
    selectedModules,
    addModuleSelect,
    removeModuleSelect,
    loadModulesWithoutFormat,
    setModuleWithoutFormatAdmin,
    createModuleAdmin,
    setNewModuleAdmin,
    loadModules,
    setUpdateModuleAdmin,
    updateModuleAdmin,
    removeModule,
    clearModules,
  }
}
