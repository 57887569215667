// src/features/user/KPIFormSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IFilter,
  IFiltersForm,
  IKPIFormField,
  IKPIFormState,
} from './KPIForm.interface'
import { getFilters } from 'src/services/KPI/KPIForm/KPIForm.service'
import { getQuestiosForm } from 'src/services/KPI/Questions/Questions.service'

const initialState: IKPIFormState = {
  filter: {
    areaName: null,
    monthNumber: null,
    periodId: null,
    quarterId: null,
    channelId: null,
  },
  filters: [],
  kpiForm: {
    indicatorId: 0,
    indicatorDescription: '',
    indicatorName: '',
    labelOne: '',
    labelTwo: '',
    indicatorQuestions: [],
  },
  kpiFormList: [],
  error: null,
  loading: false,
}

const KPIFormSlice = createSlice({
  name: 'KPIForm',
  initialState,
  reducers: {
    setField: (state, action: PayloadAction<IKPIFormField>) => {
      state.kpiForm = { ...state.kpiForm, ...action.payload }
    },

    setFilter: (state, action: PayloadAction<IFilter>) => {
      state.filter = { ...state.filter, ...action.payload }
    },
    setKPIForm: (state, action: PayloadAction<IKPIFormField>) => {
      state.kpiForm = action.payload
    },
    resetFilters: (state) => {
      state.filter = initialState.filter
    },
    resetKPIFormFields: (state) => {
      state.kpiFormList = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getQuestiosForm.fulfilled,
        (state, action: PayloadAction<IKPIFormField[]>) => {
          state.loading = false
          state.kpiFormList = action.payload
        },
      )
      .addCase(
        getQuestiosForm.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false
          state.error = action.payload
        },
      )
      .addCase(
        getFilters.fulfilled,
        (state, action: PayloadAction<IFiltersForm[]>) => {
          state.loading = false
          state.filters = action.payload
        },
      )
      .addCase(getFilters.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload
      })
  },
})

export const { setKPIForm, setField, setFilter, resetFilters, resetKPIFormFields } = KPIFormSlice.actions
export default KPIFormSlice.reducer
