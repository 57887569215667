// src/features/user/userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUser, UserState } from './User.interface'

const initialState: UserState = {
  name: '',
  email: '',
  isLoggedIn: false,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login(state: UserState, action: PayloadAction<IUser>) {
      const { name, email } = action.payload
      state.name = name
      state.email = email
      state.isLoggedIn = true
    },
    logout(state: UserState) {
      state.name = ''
      state.email = ''
      state.isLoggedIn = false
      localStorage.removeItem('accessToken')
    },
  },
})

export const { login, logout } = userSlice.actions
export default userSlice.reducer
