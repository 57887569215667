// src/features/user/KPIFormSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IQuestionState } from './Questions.interface'
import {
  getQuestiosForm,
  associateQuestionsToIndicator,
} from 'src/services/KPI/Questions/Questions.service'
import { IKPIFormField } from '../KPIForm/KPIForm.interface'

const initialState: IQuestionState = {
  questions: [],
  associatedQuestion: {
    indicatorId: null,
    periodId: null,
    quarterId: null,
    parameterId: null,
    question: null,
  },
  error: null,
  loading: false,
}

const KPIFormSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    setAssociatedQuestion: (
      state,
      action: PayloadAction<IQuestionState['associatedQuestion']>,
    ) => {
      state.associatedQuestion = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getQuestiosForm.fulfilled,
        (state, action: PayloadAction<IKPIFormField[]>) => {
          state.loading = false
          state.questions = action.payload
        },
      )
      .addCase(
        associateQuestionsToIndicator.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false
        },
      )
  },
})

export const { setAssociatedQuestion } = KPIFormSlice.actions
export default KPIFormSlice.reducer
