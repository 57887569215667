import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchWithToken, getHeaders } from 'src/utils/utils'
import { IParamsUpdateAnswers } from './KPIForm.interface'

const BASE_URL = process.env.REACT_APP_API || 'NO_URL'

export const updateAnswers = createAsyncThunk(
  'KPIForm/updateAnswers',
  async (payload: IParamsUpdateAnswers[], { rejectWithValue }) => {
    try {
      const response = await fetchWithToken(`${BASE_URL}/kpis/answer/update`, {
        method: 'PUT',
        headers: getHeaders(),
        body: JSON.stringify({
          answers: payload,
        }),
      })
      const data = await response.json()
      if (!response.ok) {
        throw new Error(
          data.error.message || '-- A ocurrido un error inesperado --',
        )
      }
      return data.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)

export const getFilters = createAsyncThunk(
  'KPIForm/getFilters',
  async (
    { includeGeneral = false }: { includeGeneral?: boolean } = {},
    { rejectWithValue },
  ) => {
    try {
      const response = await fetchWithToken(
        `${BASE_URL}/kpis/get-filters?includeGeneral=${includeGeneral}`,
        {
          method: 'GET',
          headers: getHeaders(),
        },
      )
      const data = await response.json()
      if (!response.ok) {
        throw new Error(
          data.error.message || '-- A ocurrido un error inesperado --',
        )
      }
      return data.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)
