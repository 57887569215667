/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import BoxWithShadow from 'src/components/BoxWithShadow'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import JsonView from '@uiw/react-json-view'
import useIsMounted from 'src/hooks/useIsMounted'
import { Link } from 'react-router-dom'
import {
  Button,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import useTestModels from 'src/hooks/Onboarding/testModels/useTestModels'
import {
  IFilterSettings,
  ResponseGetModelsCreditLimit,
} from 'src/redux/Onboarding/testModels/testModelsSlice.interface'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const ConfigOnboarding = () => {
  const [externalTabValue, setExternalTabValue] = useState(0)
  const isMounted = useIsMounted()
  const {
    responseGetInitialSettiongByVersion,
    getFiltersModels,
    responseGetFilterSettings,
    testInitialSettiongByVersion,
  } = useTestModels()
  const [filtersSelected, setFiltersSelected] = useState({
    settingId: '',
    settingVersionId: '',
  })
  const handleExternalTabChange = (
    event: React.SyntheticEvent,
    newValue: number,
  ) => {
    setExternalTabValue(newValue)
  }

  useEffect(() => {
    if (!isMounted) return
    getFiltersModels()
  }, [isMounted])

  return (
    <Container>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          testInitialSettiongByVersion({
            settingVersionId: Number(filtersSelected.settingVersionId),
          })
        }}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          my={2}
        >
          {/* {JSON.stringify(formValues, null)} */}
          <Link to="/keypago/users" style={{ textDecoration: 'none' }}>
            <IconButton aria-label="arrow back" color="primary">
              <FontAwesomeIcon icon={faArrowLeft} size="1x" />
            </IconButton>
          </Link>
          <Stack
            direction={'row'}
            justifyContent={'flex-end'}
            alignItems={'center'}
            gap={2}
            flexWrap={'wrap'}
          >
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="demo-simple-select-label">Modelos</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Modelos"
                value={filtersSelected.settingId}
                sx={{ backgroundColor: 'white' }}
                onChange={(e) => {
                  setFiltersSelected({
                    ...filtersSelected,
                    settingId: e.target.value,
                  })
                }}
              >
                {responseGetFilterSettings?.map(
                  (item: IFilterSettings, index: number) => (
                    <MenuItem value={item.settingId} key={index}>
                      {item.settingName}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="demo-simple-select-label">Versiones</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Versiones"
                value={filtersSelected.settingVersionId}
                sx={{ backgroundColor: 'white' }}
                onChange={(e) => {
                  setFiltersSelected({
                    ...filtersSelected,
                    settingVersionId: e.target.value,
                  })
                }}
              >
                {responseGetFilterSettings
                  ?.find((item: IFilterSettings) => {
                    console.log('item', item)
                    console.log('filtersSelected', filtersSelected)
                    return (
                      Number(item.settingId) ===
                      Number(filtersSelected.settingId)
                    )
                  })
                  ?.versions.map((item, index: number) => (
                    <MenuItem value={item.settingVersionId} key={index}>
                      {item.settingVersion}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
            >
              Buscar
            </Button>
          </Stack>
        </Stack>
      </form>
      <BoxWithShadow>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={externalTabValue}
              onChange={handleExternalTabChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Configuración de Onboarding"
                {...a11yProps(0)}
                sx={{
                  alignItems: 'flex-end',
                }}
              />
            </Tabs>
          </Box>
          {/* Panels */}
          <CustomTabPanel value={externalTabValue} index={0}>
            <JsonView value={responseGetInitialSettiongByVersion ?? {}} />
          </CustomTabPanel>
        </Box>
      </BoxWithShadow>
    </Container>
  )
}

export default ConfigOnboarding
