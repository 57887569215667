import React, { useState } from 'react'
import { useAdminApp } from 'src/hooks/IAM/AdminApp/useAdminApp'
import { useAdminEndpoint } from 'src/hooks/IAM/AdminEndpoint/useAdminEndpoint'
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import {
  IBodyEndpoint,
  IEndpoint,
} from 'src/services/IAM/AdminEndpoints/AdminEndpoints.interface'

/**
 * FiltersEndpoints component for creating new API endpoints.
 *
 * @remarks
 * The `FiltersEndpoints` component provides a form for users to create new API endpoints.
 * It allows the user to select an application, define the endpoint path, specify its public
 * access status, and choose the HTTP methods (GET, POST, PUT, DELETE) associated with the endpoint.
 *
 * ### Key Changes:
 * - **Usage Examples**:
 *   - The first example shows how to use the `FiltersEndpoints` component in a parent component.
 *   - The second example highlights that it can accept props for further customization in the future.
 *
 * ### Additional Notes:
 * - **Dynamic Fields**: Users can dynamically add or remove endpoint definitions in the form.
 * - **Public Checkbox**: A checkbox to specify if the endpoint is public.
 * - **Method Selection**: Allows for multiple HTTP methods to be selected for each endpoint.
 *
 * @component
 * @example
 * ```tsx
 * import FiltersEndpoints from 'path/to/FiltersEndpoints';
 *
 * // Example of using FiltersEndpoints in a parent component
 * const ApiCreationPage = () => {
 *   return <FiltersEndpoints />;
 * };
 * ```
 *
 * @example
 * // Example with different props (though FiltersEndpoints currently does not accept any props)
 * ```tsx
 * // Props can be added in the future
 * const propsExample = {
 *   // future prop examples
 * };
 *
 * <FiltersEndpoints {...propsExample} />
 * ```
 *
 * @returns {JSX.Element} The rendered `FiltersEndpoints` component.
 */
const FiltersEndpoints = (): JSX.Element => {
  const { createEndpointAdmin } = useAdminEndpoint()
  const { apps } = useAdminApp()
  const [endpointsLocal, setEndpointsLocal] = useState<IEndpoint>({
    appId: apps ? apps[0]?.appId ?? 0 : 0,
    endpoints: [
      {
        methods: [],
        path: '',
        public: false,
      },
    ],
  })

  const handlePlusItem = () => {
    setEndpointsLocal({
      appId: endpointsLocal?.appId,
      endpoints: [
        ...endpointsLocal.endpoints,
        {
          methods: [],
          path: '',
          public: false,
        },
      ],
    })
  }

  const handleMinusItem = (item: IBodyEndpoint) => {
    const updatedEndpoints = endpointsLocal.endpoints?.filter(
      (endpoint) => endpoint !== item,
    )
    setEndpointsLocal({
      ...endpointsLocal,
      endpoints: updatedEndpoints,
    })
  }

  const handleChangeMethodsLocal = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedEndpoints = endpointsLocal.endpoints?.map((endpoint) => {
      if (
        endpoint ===
        endpointsLocal.endpoints[endpointsLocal.endpoints.length - 1]
      ) {
        const methodExists = endpoint?.methods?.some(
          (methodObj) => methodObj.method === e.target.value,
        )

        // If the method already exists, remove it
        if (methodExists) {
          return {
            ...endpoint,
            methods: endpoint?.methods?.filter(
              (methodObj) => methodObj.method !== e.target.value,
            ),
          }
        } else {
          // If the method doesn't exist, add it
          return {
            ...endpoint,
            methods: [
              ...(endpoint.methods || []),
              {
                method: e.target.value,
              },
            ],
          }
        }
      }
      return endpoint
    })

    setEndpointsLocal({
      ...endpointsLocal,
      endpoints: updatedEndpoints,
    })
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        createEndpointAdmin(endpointsLocal)
      }}
    >
      <Typography variant="h4" className="form-title" mb={4}>
        New Endpoint
      </Typography>
      <Stack spacing={2} direction={'row'}>
        <Autocomplete
          id="combo-box-demo"
          options={apps || []}
          value={apps?.find((app) => app.appId === endpointsLocal.appId)}
          getOptionLabel={(option) => option.name}
          sx={{ width: 200 }}
          onChange={(e, value) => {
            setEndpointsLocal({
              ...endpointsLocal,
              appId: value?.appId ?? 0,
            })
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Application"
              variant="outlined"
              required
            />
          )}
        />
        {/* DESCRIPTION */}
        <Stack spacing={2} direction={'column'}>
          {endpointsLocal.endpoints?.map((item, index) => (
            <Stack
              direction={'row'}
              spacing={8}
              mt={2}
              mb={2}
              key={index}
              alignItems={'center'}
            >
              <TextField
                id="outlined-basic"
                label="Path"
                variant="outlined"
                sx={{ width: 200 }}
                defaultValue={item.path}
                onChange={(e) => {
                  const updatedEndpoints = endpointsLocal.endpoints?.map(
                    (endpoint) => {
                      if (endpoint === item) {
                        return { ...endpoint, path: e.target.value }
                      }
                      return endpoint
                    },
                  )
                  setEndpointsLocal({
                    ...endpointsLocal,
                    endpoints: updatedEndpoints,
                  })
                }}
                required
              />
              <FormGroup>
                <Stack direction={'row'} spacing={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          const updatedEndpoints =
                            endpointsLocal.endpoints?.map((endpoint) => {
                              if (endpoint === item) {
                                return {
                                  ...endpoint,
                                  public: e.target.checked,
                                }
                              }
                              return endpoint
                            })
                          setEndpointsLocal({
                            ...endpointsLocal,
                            endpoints: updatedEndpoints,
                          })
                        }}
                      />
                    }
                    label="Public"
                  />
                </Stack>
              </FormGroup>
              <FormGroup>
                <Stack direction={'row'} spacing={2}>
                  {/* METHODS */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          handleChangeMethodsLocal(e)
                        }}
                        value="get"
                      />
                    }
                    label="GET"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          handleChangeMethodsLocal(e)
                        }}
                        value="post"
                      />
                    }
                    label="POST"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          handleChangeMethodsLocal(e)
                        }}
                        value="put"
                      />
                    }
                    label="PUT"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          handleChangeMethodsLocal(e)
                        }}
                        value="delete"
                      />
                    }
                    label="DELETE"
                  />
                </Stack>
              </FormGroup>
              {/* ADD ITEM */}
              {index === endpointsLocal.endpoints.length - 1 && (
                <Button
                  variant="contained"
                  sx={{
                    height: '40px',
                  }}
                  color="primary"
                  onClick={handlePlusItem}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              )}
              {/* REMOVE ITEM */}
              {index !== 0 && (
                <Button
                  variant="contained"
                  sx={{
                    height: '40px',
                  }}
                  color="error"
                  onClick={(e) => handleMinusItem(item)}
                >
                  <FontAwesomeIcon icon={faMinus} />
                </Button>
              )}
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Button
        variant="contained"
        type="submit"
        color="primary"
        sx={{
          width: '200px',
          mt: 2,
        }}
      >
        Create
      </Button>
    </form>
  )
}

export default FiltersEndpoints
