import React, { useEffect, useState } from 'react'
import BoxWithShadow from 'src/components/BoxWithShadow'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import JsonView from '@uiw/react-json-view'
import useUserkeypago from 'src/hooks/Onboarding/user/useUserkeypago'
import useIsMounted from 'src/hooks/useIsMounted'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
  Button,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { DatePicker } from '@mui/x-date-pickers'
import { format } from 'date-fns'
import useTestModels from 'src/hooks/Onboarding/testModels/useTestModels'
import { ResponseGetModelsCreditLimit } from 'src/redux/Onboarding/testModels/testModelsSlice.interface'
import FieldsToShow from './Components/FieldsToShow'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const CreditInfoView = () => {
  const [externalTabValue, setExternalTabValue] = useState(0)
  const [creditHistoryTabValue, setCreditHistoryTabValue] = useState(0)
  const [modelsTabValue, setModelsTabValue] = useState(0)
  const [userType, setUserType] = useState('new')
  const [formValues, setFormValues] = useState<{ [key: string]: any }>({})

  const handleFieldChange = (key: string, value: any) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }))
  }
  const {
    responseCreditHistory,
    deviceInfo,
    responseModelsUser,
    getAllModelsUsers,
    getDeviceInfo,
    getCreditLimit,
    getCreditLimitNew,
  } = useUserkeypago()
  const isMounted = useIsMounted()
  const location = useLocation()
  const { responseGetModelsCreditLimit, testModelCreditLimit } = useTestModels()
  const [filtersSelected, setFiltersSelected] = useState({
    modelId: '',
    modelVersionId: '',
  })

  const [filters, setFilters] = useState({
    userDocId: '',
    country: 'CO',
    userDocType: 'CC',
  })
  const [filtersNewUser, setFiltersNewUser] = useState({
    ...filters,
    firstName: '',
    lastName: '',
    issueDate: '',
  })
  const handleExternalTabChange = (
    event: React.SyntheticEvent,
    newValue: number,
  ) => {
    setExternalTabValue(newValue)
  }

  const handleCreditHistoryTabChange = (
    event: React.SyntheticEvent,
    newValue: number,
  ) => {
    setCreditHistoryTabValue(newValue)
  }

  const handleModelsTabChange = (
    event: React.SyntheticEvent,
    newValue: number,
  ) => {
    setModelsTabValue(newValue)
  }

  const removeObjKeys = (obj: any, keys: string[]) => {
    const newObj = { ...obj }
    keys.forEach((key) => {
      delete newObj[key]
    })
    return newObj
  }

  useEffect(() => {
    if (!isMounted) return
    const filters = {
      country: location.state?.country,
      userDocId: location.state?.userDocId,
      userDocType: location.state?.userDocType?.toUpperCase(),
    }
    if (location.state?.userType) {
      setUserType(location.state?.userType)
    }
    setFilters(filters)
    testModelCreditLimit()
    getCreditLimit(filters)
    getAllModelsUsers(filters)
    getDeviceInfo(filters)
  }, [isMounted])

  //FUNCTIONS
  const handleSearch = () => {
    if (userType === 'new') {
      getCreditLimitNew({
        modelVersionId: Number(filtersSelected.modelVersionId),
        metadataVersion: formValues,
      })
    } else {
      getCreditLimit(filters)
      getAllModelsUsers(filters)
      getDeviceInfo(filters)
    }
  }
  return (
    <Container>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleSearch()
        }}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          my={2}
        >
          {/* {JSON.stringify(formValues, null)} */}
          <Link to="/keypago/users" style={{ textDecoration: 'none' }}>
            <IconButton aria-label="arrow back" color="primary">
              <FontAwesomeIcon icon={faArrowLeft} size="1x" />
            </IconButton>
          </Link>
          <Stack
            direction={'row'}
            justifyContent={'flex-end'}
            alignItems={'center'}
            gap={2}
            flexWrap={'wrap'}
          >
            {userType === 'new' && (
              <>
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel id="demo-simple-select-label">Modelos</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Modelos"
                    value={filtersSelected.modelId}
                    sx={{ backgroundColor: 'white' }}
                    onChange={(e) => {
                      setFiltersSelected({
                        ...filtersSelected,
                        modelId: e.target.value,
                      })
                    }}
                  >
                    {responseGetModelsCreditLimit?.map(
                      (item: ResponseGetModelsCreditLimit, index: number) => (
                        <MenuItem value={item.modelId} key={index}>
                          {item.modelName}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel id="demo-simple-select-label">
                    Versiones
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Versiones"
                    value={filtersSelected.modelVersionId}
                    sx={{ backgroundColor: 'white' }}
                    onChange={(e) => {
                      setFormValues({})
                      setFiltersSelected({
                        ...filtersSelected,
                        modelVersionId: e.target.value,
                      })
                    }}
                  >
                    {responseGetModelsCreditLimit
                      ?.find(
                        (item) =>
                          item.modelId === Number(filtersSelected.modelId),
                      )
                      ?.versions.map((item, index) => (
                        <MenuItem value={item.modelVersionId} key={index}>
                          {item.modelVersion}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {responseGetModelsCreditLimit &&
                  responseGetModelsCreditLimit
                    .find(
                      (item) =>
                        item.modelId === Number(filtersSelected.modelId),
                    )
                    ?.versions.find(
                      (item) =>
                        item.modelVersionId ===
                        Number(filtersSelected.modelVersionId),
                    )
                    ?.modelVersionFields.map((item, index) => {
                      return (
                        <FieldsToShow
                          onChange={handleFieldChange}
                          value={formValues[item.key]}
                          key={index}
                          payload={item}
                        />
                      )
                    })}
              </>
            )}
            {userType === 'old' && (
              <>
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel id="demo-simple-select-label">
                    Tipo de documento
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Tipo de documento"
                    value={filters.userDocType}
                    sx={{
                      backgroundColor: 'white',
                    }}
                    onChange={(e) => {
                      setFilters({
                        ...filters,
                        userDocType: e.target.value as string,
                      })
                    }}
                  >
                    <MenuItem value={'CC'}>Cédula de ciudadania</MenuItem>
                    <MenuItem value={'CE'}>Cédula de extranjeria</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  label="Número de documento"
                  variant="outlined"
                  type="number"
                  sx={{ backgroundColor: 'white', maxWidth: 200 }}
                  value={filters.userDocId}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      userDocId: e.target.value,
                    })
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleSearch()
                    }
                  }}
                />
              </>
            )}
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
            >
              Buscar
            </Button>
          </Stack>
        </Stack>
      </form>
      <BoxWithShadow>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={externalTabValue}
              onChange={handleExternalTabChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Historial crediticio"
                {...a11yProps(0)}
                sx={{
                  alignItems: 'flex-end',
                }}
              />
              {userType === 'old' && (
                <Tab
                  label="Modelos"
                  {...a11yProps(1)}
                  sx={{
                    alignItems: 'flex-end',
                  }}
                />
              )}
              {userType === 'old' && (
                <Tab
                  label="Dispositivo"
                  {...a11yProps(2)}
                  sx={{
                    alignItems: 'flex-end',
                  }}
                />
              )}
            </Tabs>
          </Box>
          {/* Panels */}
          <CustomTabPanel value={externalTabValue} index={0}>
            <Box sx={{ display: 'flex', width: '100%' }}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={creditHistoryTabValue}
                onChange={handleCreditHistoryTabChange}
                aria-label="Historial crediticio"
                sx={{ borderRight: 1, borderColor: 'divider', minWidth: 130 }}
              >
                <Tab
                  label="Resultado"
                  {...a11yProps(0)}
                  sx={{
                    alignItems: 'flex-end',
                  }}
                />
                <Tab
                  label="Historial"
                  {...a11yProps(1)}
                  sx={{
                    alignItems: 'flex-end',
                  }}
                />
                <Tab
                  label="Políticas"
                  {...a11yProps(2)}
                  sx={{
                    alignItems: 'flex-end',
                  }}
                />
              </Tabs>
              <CustomTabPanel value={creditHistoryTabValue} index={0}>
                {responseCreditHistory ? (
                  <JsonView
                    value={removeObjKeys(responseCreditHistory, [
                      'logsPolicies',
                      'flow',
                      'historyCredit',
                    ])}
                    displayDataTypes={false}
                    displayObjectSize={false}
                    shortenTextAfterLength={150}
                  />
                ) : (
                  <Typography>No hay información disponible</Typography>
                )}
              </CustomTabPanel>
              <CustomTabPanel value={creditHistoryTabValue} index={1}>
                {responseCreditHistory ? (
                  <JsonView
                    value={responseCreditHistory.historyCredit ?? {}}
                    displayDataTypes={false}
                    displayObjectSize={false}
                    shortenTextAfterLength={150}
                  />
                ) : (
                  <Typography>No hay información disponible</Typography>
                )}
              </CustomTabPanel>

              <CustomTabPanel value={creditHistoryTabValue} index={2}>
                {responseCreditHistory?.logsPolicies ? (
                  <JsonView
                    value={responseCreditHistory.logsPolicies}
                    displayDataTypes={false}
                    displayObjectSize={false}
                    shortenTextAfterLength={150}
                  />
                ) : (
                  <Typography>No hay información disponible</Typography>
                )}
              </CustomTabPanel>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={externalTabValue} index={1}>
            <Box sx={{ display: 'flex' }}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={modelsTabValue}
                onChange={handleModelsTabChange}
                aria-label="Modelos"
                sx={{ borderRight: 1, borderColor: 'divider' }}
              >
                <Tab
                  label="Es humano"
                  {...a11yProps(0)}
                  sx={{
                    alignItems: 'flex-end',
                  }}
                />
                <Tab
                  label="Comparación facial"
                  {...a11yProps(1)}
                  sx={{
                    alignItems: 'flex-end',
                  }}
                />
                <Tab
                  label="Extracción cédula frontal"
                  {...a11yProps(2)}
                  sx={{
                    alignItems: 'flex-end',
                  }}
                />
                <Tab
                  label="Extracción cédula trasera"
                  {...a11yProps(3)}
                  sx={{
                    alignItems: 'flex-end',
                  }}
                />
              </Tabs>
              <CustomTabPanel value={modelsTabValue} index={0}>
                <JsonView
                  value={responseModelsUser?.isPerson ?? {}}
                  displayDataTypes={false}
                  displayObjectSize={false}
                  shortenTextAfterLength={150}
                />
              </CustomTabPanel>
              <CustomTabPanel value={modelsTabValue} index={1}>
                <JsonView
                  value={responseModelsUser?.faceCompare ?? {}}
                  displayDataTypes={false}
                  displayObjectSize={false}
                  shortenTextAfterLength={150}
                />
              </CustomTabPanel>
              <CustomTabPanel value={modelsTabValue} index={2}>
                <JsonView
                  value={{
                    ...{
                      frontDocExtracted: responseModelsUser?.frontDocExtracted,
                    },
                    ...{
                      frontDocModel: responseModelsUser?.frontDocModel,
                    },
                  }}
                  displayDataTypes={false}
                  displayObjectSize={false}
                  shortenTextAfterLength={150}
                />
              </CustomTabPanel>
              <CustomTabPanel value={modelsTabValue} index={3}>
                <JsonView
                  value={{
                    ...{
                      backDocExtracted: responseModelsUser?.backDocExtracted,
                    },
                    ...{
                      backDocModel: responseModelsUser?.backDocModel,
                    },
                  }}
                  displayDataTypes={false}
                  displayObjectSize={false}
                  shortenTextAfterLength={150}
                />
              </CustomTabPanel>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={externalTabValue} index={2}>
            {deviceInfo ? (
              <JsonView
                value={deviceInfo}
                displayDataTypes={false}
                displayObjectSize={false}
                shortenTextAfterLength={150}
              />
            ) : (
              <Typography>No hay información disponible</Typography>
            )}
          </CustomTabPanel>
        </Box>
      </BoxWithShadow>
    </Container>
  )
}

export default CreditInfoView
