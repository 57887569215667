import { Backdrop, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux';

/**
 * @module LoadingModule
 * 
 * This module provides the `GlobalLoading` component to display a global loading indicator.
 * 
 * @category User Interface Components
 * 
 * @since 1.0.0
 */

/**
 * GlobalLoading component props.
 * 
 * The component does not require any external props since it utilizes the Redux store.
 * 
 * @typedef {Object} GlobalLoadingProps
 * @since 1.0.0
 */

/**
 * A global loading indicator that appears when an asynchronous action is in progress.
 * 
 * It renders a full-screen `Backdrop` with a `CircularProgress` spinner and a loading message.
 * 
 * The `open` property of the `Backdrop` is controlled by the `isLoading` state from the Redux store.
 * 
 * @component
 * @category User Interface Components
 * @since 1.0.0
 * 
 * @returns {JSX.Element} The loading overlay component.
 * 
 * @example
 * ```tsx
 * // Usage within a Redux-based app
 * <GlobalLoading />
 * ```
 * 
 * @see https://mui.com/material-ui/react-backdrop/ for more information on the MUI Backdrop component.
 * @see https://mui.com/material-ui/react-circular-progress/ for more on CircularProgress.
 */
const GlobalLoading: React.FC = () => {
  // Selects the loading state from the Redux store
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);

  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 999,
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
      <Typography variant="h6" color="inherit">
        Cargando...
      </Typography>
    </Backdrop>
  );
};

export default GlobalLoading;
