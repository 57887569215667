/**
 * @module services/access.ts
 * @categoryDescription Este archivo define la función `getSessionToken` que realiza una solicitud a la API para obtener un token de sesión.
 */

import { fetchWithToken, getHeaders, getParamsToURL } from 'src/utils/utils'
import { IGetUserParams, IPostModelNewUser } from './user.service.interface'
import { createAsyncThunk } from '@reduxjs/toolkit'

/**
 * Realiza una solicitud a la API para obtener un token de sesión.
 *
 * @param {Info} infoDevice - La información del dispositivo que se enviará en la solicitud.
 * @returns {Promise<any>} Una promesa que se resuelve con la respuesta de la API.
 * @throws {Error} Lanza un error si la respuesta de la API no es exitosa.
 */
export const getUserkp = createAsyncThunk(
  'keypago/getUserkp',
  async (payload: IGetUserParams, { rejectWithValue }) => {
    try {
      const paramsString = getParamsToURL(payload)
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API}/onboarding-data/user?${paramsString}`,
        {
          method: 'GET',
          headers: getHeaders(),
        },
      )
      const data = await response.json()


      localStorage.setItem('sessionToken', data.session_token)
      if (!response.ok && response) {
        throw new Error(
          data?.error?.message || '-- A ocurrido un error inesperado --',
        )
      }
      return data.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)

export const getCreditLimitUser = createAsyncThunk(
  'keypago/getCreditLimitUser',
  async (payload: IGetUserParams, { rejectWithValue }) => {
    try {
      const paramsString = getParamsToURL(payload)
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API}/onboarding-data/model?${paramsString}`,
        {
          method: 'GET',
          headers: getHeaders(),
        },
      )
      const data = await response.json()


      localStorage.setItem('sessionToken', data.session_token)
      if (!response.ok && response) {
        throw new Error(
          data?.error?.message || '-- A ocurrido un error inesperado --',
        )
      }
      return data.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)
export const getCreditLimitNewUser = createAsyncThunk(
  'keypago/getCreditLimitNewUser',
  async (payload: IPostModelNewUser, { rejectWithValue }) => {
    try {
      const paramsString = getParamsToURL(payload)
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API}/onboarding-testing/model-credit-limit`,
        {
          method: 'POST',
          body: JSON.stringify(payload),
          headers: getHeaders(),
        },
      )
      const data = await response.json()


      localStorage.setItem('sessionToken', data.session_token)
      if (!response.ok && response) {
        throw new Error(
          data?.error?.message || '-- A ocurrido un error inesperado --',
        )
      }
      console.log(data.data, 'data.data')

      return data.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)

export const getModelsUser = createAsyncThunk(
  'keypago/getModelUser',
  async (payload: IGetUserParams, { rejectWithValue }) => {
    try {
      const paramsString = getParamsToURL(payload)
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API}/onboarding-data/services?${paramsString}`,
        {
          method: 'GET',
          headers: getHeaders(),
        },
      )
      const data = await response.json()


      localStorage.setItem('sessionToken', data.session_token)
      if (!response.ok && response) {
        throw new Error(
          data?.error?.message || '-- A ocurrido un error inesperado --',
        )
      }
      return data.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)

export const getDeviceData = createAsyncThunk(
  'keypago/getDeviceData',
  async (payload: IGetUserParams, { rejectWithValue }) => {
    try {
      const paramsString = getParamsToURL(payload)
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API}/onboarding-data/device?${paramsString}`,
        {
          method: 'GET',
          headers: getHeaders(),
        },
      )
      const data = await response.json()


      localStorage.setItem('sessionToken', data.session_token)
      if (!response.ok && response) {
        throw new Error(
          data?.error?.message || '-- A ocurrido un error inesperado --',
        )
      }
      return data.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)

export const getURLField = async (
  fieldId: string,
): Promise<{
  success: boolean
  data: {
    url: string
  }
}> => {
  try {
    const response = await fetchWithToken(
      `${process.env.REACT_APP_API}/onboarding-data/documents?fileId=${fieldId}`,
      {
        method: 'GET',
        headers: getHeaders(),
      },
    )
    const data = await response.json()


    localStorage.setItem('sessionToken', data.session_token)
    if (!response.ok && response) {
      throw new Error(
        data?.error?.message || '-- A ocurrido un error inesperado --',
      )
    }
    return data
  } catch (error: any) {
    return error.response?.data || error.message
  }
}

export const getPressignedUrl = async (url: string) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
    })
    const data = await response.json()
    if (!response.ok && response) {
      throw new Error(
        data?.error?.message || '-- A ocurrido un error inesperado --',
      )
    }
    return data.data
  } catch (error: any) {
    throw new Error(error.response?.data || error.message)
  }
}
