// src/features/user/userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUserkp, kpUserState } from './kpUser.interface'
import {
  getCreditLimitNewUser,
  getCreditLimitUser,
  getDeviceData,
  getModelsUser,
  getUserkp,
} from 'src/services/Onboarding/Users/user.service'

const initialState: kpUserState = {
  kpUser: null,
  responseCreditHistory: null,
  responseModelsUser: null,
  deviceInfo: null,
}

const kpUserSlice = createSlice({
  name: 'userkp',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserkp.pending, (state) => {
        state.kpUser = null
      })
      .addCase(getUserkp.fulfilled, (state, action: PayloadAction<IUserkp>) => {
        state.kpUser = action.payload
      })
      .addCase(getCreditLimitUser.pending, (state) => {
        state.responseCreditHistory = null
      })
      .addCase(
        getCreditLimitUser.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.responseCreditHistory = action.payload
        },
      )
      .addCase(getModelsUser.pending, (state) => {
        state.responseModelsUser = null
      })
      .addCase(getModelsUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.responseModelsUser = action.payload
      })
      .addCase(getCreditLimitNewUser.pending, (state) => {
        state.responseCreditHistory = null
      })
      .addCase(
        getCreditLimitNewUser.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.responseCreditHistory = action.payload
        },
      )
      .addCase(getDeviceData.pending, (state) => {
        state.deviceInfo = null
      })
      .addCase(getDeviceData.fulfilled, (state, action: PayloadAction<any>) => {
        state.deviceInfo = action.payload
      })
  },
})

export const {} = kpUserSlice.actions
export default kpUserSlice.reducer
