import React, { useState } from 'react'
import MuiPhoneNumber from 'mui-phone-number'
import { Button, Container, Stack, TextField, Typography } from '@mui/material'
import { AuthContext } from 'src/contexts/AuthContext'
import { useContext } from 'react'
import { changePassword } from 'src/services/auth/auth.service'
import useLoading from 'src/hooks/useLoading'
import GlobalLoading from 'src/components/Loadings/GlobalLoading'
import { useAlert } from 'src/contexts/AlertContext'

const Profile = () => {
  const { user } = useContext(AuthContext)
  const { showAlert } = useAlert()

  const { isLoading, startLoading, stopLoading } = useLoading()
  const [userInfo, setUserInfo] = useState<{
    lastName: string
    firstName: string
    email: string
    phone: string
    userDocId: string
    userDocType: string
    country: string
    state: string
  }>({
    phone: user?.phone || '',
    email: user?.email || '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    userDocId: user?.userDocId || '',
    userDocType: user?.userDocType || '',
    country: user?.country || '',
    state: user?.state || '',
  })

  const [objPassword, setObjPassword] = useState<{
    oldPassword: string
    newPassword: string
    confirmPassword: string
  }>({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  })

  const handleChangePassword = async () => {
    try {
      startLoading()
      await changePassword(
        {
          password: objPassword.newPassword,
          oldPassword: objPassword.oldPassword,
          confirmPassword: objPassword.confirmPassword,
        },
        true,
      )
      showAlert('success', 'Sesión cerrada correctamente')
    } catch (error) {
      console.error('Error en la autenticación', error)
      showAlert('error', 'Error al cambiar la contraseña')
    } finally {
      stopLoading()
    }
  }
  return (
    <Container>
      {isLoading && <GlobalLoading />}
      <Typography variant="h4" mb={2}>
        TU PERFIL
      </Typography>
      <Stack direction={'row'} gap={2}>
        {/* FIRSTNAME */}
        <TextField
          label="Nombre"
          variant="outlined"
          fullWidth
          value={user?.firstName}
          disabled
        />
        {/* LASTNAME */}
        <TextField
          label="Apellido"
          variant="outlined"
          fullWidth
          value={user?.lastName}
          disabled
        />

        <MuiPhoneNumber
          label="Phone Number"
          variant="outlined"
          fullWidth
          defaultCountry="co"
          value={userInfo.phone}
          onChange={(value) =>
            setUserInfo({ ...userInfo, phone: String(value) })
          }
        />
        {/* EMAIL */}
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          value={userInfo.email}
          onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })}
        />
      </Stack>
      <Button
        variant="contained"
        color="primary"
        sx={{
          mt: 2,
        }}
      >
        Actualiza datos
      </Button>
      <Typography variant="h4" my={4}>
        Cambiar contraseña
      </Typography>
      <Stack direction={'row'} gap={2}>
        {/* OLD PASSWORD */}
        <TextField
          label="Contraseña actual"
          variant="outlined"
          fullWidth
          value={objPassword.oldPassword}
          onChange={(e) =>
            setObjPassword({ ...objPassword, oldPassword: e.target.value })
          }
        />
        {/* NEW PASSWORD */}
        <TextField
          label="Nueva contraseña"
          variant="outlined"
          fullWidth
          type='password'
          value={objPassword.newPassword}
          onChange={(e) =>
            setObjPassword({ ...objPassword, newPassword: e.target.value })
          }
        />
        {/* CONFIRM PASSWORD */}
        <TextField
          label="Confirmar contraseña"
          variant="outlined"
          fullWidth
          type='password'
          value={objPassword.confirmPassword}
          onChange={(e) =>
            setObjPassword({ ...objPassword, confirmPassword: e.target.value })
          }
        />
      </Stack>
      <Button
        variant="contained"
        color="primary"
        sx={{
          mt: 2,
        }}
        onClick={() => handleChangePassword()}
      >
        Cambiar contraseña
      </Button>
    </Container>
  )
}

export default Profile
