import {
  Autocomplete,
  Button,
  FormControl,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { format } from 'date-fns'
import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import AlertDialog from 'src/components/Dialog'
import useLoading from 'src/hooks/useLoading'
import GlobalLoading from 'src/components/Loadings/GlobalLoading'
import { formatToCOP } from 'src/utils/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
const Budgets = () => {
  const { isLoading } = useLoading()
  const [filters, setFilters] = useState({
    startDate: format(new Date(), 'yyyy/MM/dd'),
    endDate: format(new Date(), 'yyyy/MM/dd'),
    budget: 0,
  })
  const handleBudget = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = Number(e.target.value)
    if (inputValue < 0) {
      return
    }
    setFilters((prevFilters) => ({
      ...prevFilters,
      budget: inputValue,
    }))
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    console.log('filters', filters)
  }
  const users = [
    {
      userDocId: '123456',
      firstName: 'Juan',
      lastName: 'Perez',
      email: '',
      active: true,
      phone: '123456789',
      type: 'admin',
    },
    {
      userDocId: '123456',
      firstName: 'Juan',
      lastName: 'Perez',
      email: '',
      active: true,
      phone: '123456789',
      type: 'admin',
    },
    {
      userDocId: '123456',
      firstName: 'Juan',
      lastName: 'Perez',
      email: '',
      active: true,
      phone: '123456789',
      type: 'admin',
    },
    {
      userDocId: '123456',
      firstName: 'Juan',
      lastName: 'Perez',
      email: '',
      active: true,
      phone: '123456789',
      type: 'admin',
    },
    {
      userDocId: '123456',
      firstName: 'Juan',
      lastName: 'Perez',
      email: '',
      active: true,
      phone: '123456789',
      type: 'admin',
    },
  ]
  return (
    <div>
      <Typography mb={4} variant="h4">
        Presupuestos
      </Typography>
      <Stack direction={'row-reverse'} gap={2} mb={2} flexWrap={'wrap'}>
        <AlertDialog
          title="Crear presupuesto"
          buttonLabel="Crear presupuesto"
          children={
            <Stack gap={2} pt={1}>
              <TextField
                label="$ Presupuesto"
                onChange={handleBudget}
                type="number"
                value={filters.budget}
                fullWidth
              />
              <DatePicker
                selected={
                  filters.startDate ? new Date(filters.startDate) : null
                }
                onChange={(date): void => {
                  setFilters(
                    (prevFilters) =>
                      ({
                        ...prevFilters,
                        startDate: format(new Date(date ?? ''), 'yyyy/MM/dd'),
                      }) as any,
                  )
                }}
                dateFormat="dd/MM/yyyy"
                customInput={
                  <FormControl fullWidth>
                    <TextField
                      label="Fecha de inicio"
                      placeholder="Fecha de inicio"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={filters.startDate ? filters.startDate : ''}
                    />
                  </FormControl>
                }
              />
            </Stack>
          }
        />
        {/*Descargar*/}
        <Button
          variant="contained"
          color="primary"
          onClick={() => console.log('Descargar')}
        >
          <FontAwesomeIcon icon={faFileExcel} />
        </Button>
      </Stack>
      {isLoading && <GlobalLoading />}
      <Paper>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Presupuesto</TableCell>
                <TableCell>Fecha</TableCell>
                <TableCell align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users && users.length ? (
                users.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell>{formatToCOP(2000000)}</TableCell>
                    <TableCell>{new Date().toLocaleDateString()}</TableCell>
                    <TableCell align="right">
                      {user.active ? (
                        <Stack
                          gap={2}
                          direction="row"
                          justifyContent={'flex-end'}
                        >
                          <AlertDialog
                            title={`Editar user ${user.firstName} ${user.lastName}`}
                            buttonLabel={'Editar'}
                            disagreeLabel="Cancelar"
                            agreeLabel="Editar"
                            onDisagree={() => console.log('user no editado')}
                            children={<></>}
                          />
                          <AlertDialog
                            buttonLabel={'desactivar'}
                            title={`¿Estás seguro de activar el usuario "${user.firstName} ${user.lastName}"?`}
                            disagreeLabel="Cancelar"
                            agreeLabel="desactivar"
                            color="error"
                            nameToDelete={user.userDocId}
                            withConfirmation
                            onDisagree={() => console.log('user no eliminado')}
                            children={
                              <div>
                                <p>
                                  Al activar el usuario
                                  <strong> {user.userDocId}</strong> podrá
                                  acceder de nuevo al sistema
                                </p>
                                <p>
                                  Escriba <strong>{user.userDocId}</strong> para
                                  confirmar
                                </p>
                              </div>
                            }
                          />
                        </Stack>
                      ) : (
                        <AlertDialog
                          buttonLabel={'activar'}
                          title={`¿Estás seguro de activar el user "${user.firstName} ${user.lastName}"?`}
                          disagreeLabel="Cancelar"
                          agreeLabel="activar"
                          color="success"
                          nameToDelete={user.userDocId}
                          withConfirmation
                          onDisagree={() => console.log('user no eliminado')}
                          children={
                            <div>
                              <p>
                                Al activar el user{' '}
                                <strong>{user.userDocId}</strong> no podrá
                                acceder al sistema
                              </p>
                              <p>
                                Escriba <strong>{user.userDocId}</strong> para
                                confirmar
                              </p>
                            </div>
                          }
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No hay usuarios
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}

export default Budgets
