/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import CreateUser from './components/CreateUser'
import TableUsers from './components/TableUsers'
import { Stack } from '@mui/material'
import useIsMounted from 'src/hooks/useIsMounted'
import { useAdminPermission } from 'src/hooks/IAM/AdminPermission/useAdminPermission'
import { useUserIAM } from 'src/hooks/IAM/AdminUser/useUserIAM'
import { useAdminGroup } from 'src/hooks/IAM/AdminGroups/useAdminGroups'
import { usePagination } from 'src/hooks/IAM/Components/Pagination/usePagination'
const AdminUser = () => {
  const { loadUsers } = useUserIAM()
  const isMounted = useIsMounted()
  const { loadPermissions } = useAdminPermission()
  const { loadGroups } = useAdminGroup()
  const { currentPage, rowsPerPage } = usePagination()
  useEffect(() => {
    if (!isMounted) return
    loadGroups({})
    loadPermissions({})
    loadUsers({
      currentPage,
      pageSize: rowsPerPage,
    })
  }, [isMounted])
  return (
    <Stack gap={4}>
      <CreateUser />
      <TableUsers />
    </Stack>
  )
}
export default AdminUser
