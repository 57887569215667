/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import BackDocument from 'src/assets/images/keypago/BackDocument.svg'
import Selfie from 'src/assets/images/keypago/Selfie.svg'
import FrontDocument from 'src/assets/images/keypago/FrontDocument.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCamera,
  faImages,
  faTrash,
  faEye,
} from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import Webcam from 'react-webcam'
import { getSessionToken } from 'src/services/Onboarding/CrossCore/access.service'
import useIsMounted from 'src/hooks/useIsMounted'
import { useAlert } from 'src/contexts/AlertContext'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const ImageContainer = styled('div')({
  position: 'relative',
  width: '100%',
  height: '100%',
  transition: 'all 0.3s',
  filter: 'brightness(1)',
  maxWidth: '250px',
  '& img': {
    transition: 'all 0.3s',
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    '& img': {
      filter: 'brightness(0.5)',
    },
  },
  '&:hover .hover-buttons': {
    opacity: 1,
  },
})

const HoverButtons = styled('div')({
  position: 'absolute',
  top: '50%',
  right: '50%',
  transform: 'translate(50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  opacity: 0,
  transition: 'opacity 0.3s',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
})

const UploadPhotoKeypago = () => {
  const webcamRef = useRef<Webcam>(null)
  const isMounted = useIsMounted()
  const { showAlert } = useAlert()
  const [facingMode, setFacingMode] = useState<'user' | 'environment'>('user')
  const [isMirrored, setIsMirrored] = useState(true)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [photoType, setPhotoType] = useState<
    'selfie' | 'backDocument' | 'frontDocument'
  >('selfie')

  const [photos, setPhotos] = useState<{
    selfie: string
    backDocument: string
    frontDocument: string
  }>({
    selfie: '',
    backDocument: '',
    frontDocument: '',
  })

  const [previewDialogOpen, setPreviewDialogOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')

  const videoConstraints = {
    width: 720,
    height: 1280,
    facingMode: 'user',
  }

  const handleCapture = () => {
    const imageSrc = webcamRef.current?.getScreenshot()
    if (imageSrc) {
      setPhotos((prevPhotos) => ({
        ...prevPhotos,
        [photoType]: imageSrc,
      }))
      setDialogOpen(false)
    }
  }

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: 'selfie' | 'backDocument' | 'frontDocument',
  ) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader()
      reader.onload = (e) => {
        setPhotos((prevPhotos) => ({
          ...prevPhotos,
          [type]: e.target?.result as string,
        }))
      }
      reader.readAsDataURL(event.target.files[0])
    }
  }

  const handleDelete = (type: 'selfie' | 'backDocument' | 'frontDocument') => {
    setPhotos((prevPhotos) => ({
      ...prevPhotos,
      [type]: '',
    }))
  }

  const handlePreview = (image: string) => {
    setPreviewImage(image)
    setPreviewDialogOpen(true)
  }

  const documents: {
    label: string
    type: 'selfie' | 'backDocument' | 'frontDocument'
    image: string
    defaultImage: string
  }[] = [
    {
      label: 'Selfie',
      type: 'selfie',
      image: photos.selfie,
      defaultImage: Selfie,
    },
    {
      label: 'Documento Anverso',
      type: 'frontDocument',
      image: photos.frontDocument,
      defaultImage: FrontDocument,
    },
    {
      label: 'Documento Reverso',
      type: 'backDocument',
      image: photos.backDocument,
      defaultImage: BackDocument,
    },
  ]

  useEffect(() => {
    if (!isMounted) return
    const fetchData = async () => {
      try {
        await getSessionToken({
          generalInfo: {},
          termsAndConditions: [],
        })
      } catch (error) {
        showAlert('error', 'Error al obtener token de sesión')
      }
    }
    fetchData()
  }, [isMounted])

  return (
    <Container>
      <Dialog open={dialogOpen}>
        <DialogContent>
          <Webcam
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            audio={false}
            ref={webcamRef}
            forceScreenshotSourceSize
            screenshotFormat="image/png"
            videoConstraints={videoConstraints}
            height="1280"
            width="720"
            onUserMedia={() => {}}
            onUserMediaError={() => {}}
            mirrored={facingMode === 'user' ? isMirrored : false}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" fullWidth onClick={handleCapture}>
            Capturar
          </Button>
          <Button
            variant="contained"
            fullWidth
            onClick={() => setDialogOpen(false)}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Typography
        variant="h5"
        sx={{ textAlign: 'center', marginBottom: 2 }}
        gutterBottom
      >
        Sube tus fotos
      </Typography>
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        justifyContent={'space-between'}
        spacing={8}
      >
        {documents.map(({ label, type, image, defaultImage }) => (
          <Stack key={type} spacing={2} alignItems={'center'} flex={1}>
            <ImageContainer>
              {image}
              <img
                width={'100%'}
                height={'100%'}
                src={image || defaultImage}
                alt={label}
                style={{
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                  position: 'relative',
                }}
              />
              {image && (
                <HoverButtons className="hover-buttons">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handlePreview(image || defaultImage)}
                    startIcon={<FontAwesomeIcon icon={faEye} />}
                  >
                    Ver
                  </Button>

                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      handleDelete(
                        type as 'selfie' | 'backDocument' | 'frontDocument',
                      )
                    }
                    startIcon={<FontAwesomeIcon icon={faTrash} />}
                  >
                    Eliminar
                  </Button>
                </HoverButtons>
              )}
            </ImageContainer>
            <Stack spacing={2} direction="row">
              {!image ? (
                <>
                  <Button
                    fullWidth
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<FontAwesomeIcon icon={faCamera} />}
                    onClick={() => {
                      setPhotoType(
                        type as 'selfie' | 'backDocument' | 'frontDocument',
                      )
                      setDialogOpen(true)
                    }}
                  >
                    Tomar
                  </Button>
                  <Button
                    fullWidth
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<FontAwesomeIcon icon={faImages} />}
                  >
                    Cargar
                    <VisuallyHiddenInput
                      type="file"
                      accept="image/*"
                      onChange={(e: any) =>
                        handleFileChange(
                          e,
                          type as 'selfie' | 'backDocument' | 'frontDocument',
                        )
                      }
                    />
                  </Button>
                </>
              ) : (
                <Button
                  fullWidth
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                >
                  Testear
                </Button>
              )}
            </Stack>
          </Stack>
        ))}
      </Stack>

      <Dialog
        open={previewDialogOpen}
        onClose={() => setPreviewDialogOpen(false)}
        aria-labelledby="preview-dialog-title"
      >
        <DialogContent>
          <img
            width={'100%'}
            height={'100%'}
            src={previewImage}
            alt="Preview"
            style={{
              objectFit: 'cover',
              width: '100%',
              height: '100%',
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            fullWidth
            onClick={() => setPreviewDialogOpen(false)}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default UploadPhotoKeypago
