// src/features/user/moduleSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IModuleState } from './adminModules.interface'
import {
  createModule,
  getModules,
  deleteModule,
  updateModule,
  getModulesWithoutFormat,
} from 'src/services/IAM/AdminModule/AdminModule.service'
import { IModule } from 'src/components/Sidebar/Sidebar.Interface'

const initialState: IModuleState = {
  module: {
    moduleId: 0,
    keyName: '',
    path: '',
  },
  modulesWithoutFormat: [],
  selectedModules: [],
  modules: [],
  newModule: {
    moduleId: 0,
    keyName: '',
    path: '',
  },
  updatedModule: {
    moduleId: 0,
    keyName: '',
    path: '',
  },
  loading: false,
  error: null,
}

const moduleSlice = createSlice({
  name: 'module',
  initialState,
  reducers: {
    setModule: (state, action: PayloadAction<IModule>) => {
      state.module = action.payload
    },
    setNewModule: (state, action: PayloadAction<IModule>) => {
      state.newModule = action.payload
    },
    resetModule: () => initialState,
    setUpdateModule: (state, action: PayloadAction<IModule>) => {
      state.updatedModule = action.payload
    },
    setModulesWithoutFormat: (state, action: PayloadAction<IModule[]>) => {
      state.modulesWithoutFormat = action.payload
    },
    resetModules: (state) => {
      state.modules = []
    },
    addSelectedModule: (state, action: PayloadAction<IModule>) => {
      state.selectedModules.push(action.payload)
    },
    removeSelectedModule: (state, action: PayloadAction<IModule>) => {
      state.selectedModules = state.selectedModules.filter(
        (module) => module !== action.payload,
      )
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getModules.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: IModule[]
            details: number
          }>,
        ) => {
          state.loading = false
          state.modules = action.payload.data
        },
      )
      .addCase(getModules.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(
        getModulesWithoutFormat.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: IModule[]
            details: number
          }>,
        ) => {
          state.loading = false
          state.modulesWithoutFormat = action.payload.data
        },
      )
      .addCase(
        getModulesWithoutFormat.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false
          state.error = action.payload
        },
      )
      .addCase(
        createModule.fulfilled,
        (state, action: PayloadAction<IModule>) => {
          state.loading = false
          state.modules.push(action.payload)
        },
      )
      .addCase(createModule.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(
        updateModule.fulfilled,
        (state, action: PayloadAction<IModule>) => {
          state.loading = false
          state.modules = state.modules.map((p) =>
            p.moduleId === action.payload.moduleId ? action.payload : p,
          )
        },
      )
      .addCase(updateModule.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(
        deleteModule.fulfilled,
        (state, action: PayloadAction<number>) => {
          state.loading = false
          state.modules = state.modules.filter(
            (p) => p.moduleId !== action.payload,
          )
        },
      )
      .addCase(deleteModule.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload
      })
  },
})

export const {
  setNewModule,
  resetModule,
  setModule,
  setUpdateModule,
  setModulesWithoutFormat,
  addSelectedModule,
  removeSelectedModule,
  resetModules,
} = moduleSlice.actions
export default moduleSlice.reducer
