// src/features/user/userIAMSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUserIAMState } from './UserIAM.interface'
import { IUser } from 'src/services/IAM/AdminUser/AdminUser.interface'
import { getUsers } from 'src/services/IAM/AdminUser/AdminUser.service'
const initialState: IUserIAMState = {
  userIAM: {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    type: '',
    userDocId: '',
    active: false,
    country: 'CO',
    groups: null,
    permissions: null,
    userDocType: '',
    userId: undefined,
  },
  updatedUserIAM: {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    type: '',
    userDocId: '',
    active: false,
    country: '',
    groups: null,
    permissions: null,
    userDocType: '',
    userId: undefined,
  },
  usersIAM: [],
}

const userIAMSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserIAM: (state, action: PayloadAction<IUser>) => {
      state.userIAM = action.payload
    },
    setUpdatedUserIAM: (state, action: PayloadAction<IUser>) => {
      state.userIAM = action.payload
    },
    resetUserIAM: () => {
      return { ...initialState }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getUsers.fulfilled,
      (
        state,
        action: PayloadAction<{
          data: IUser[]
          details: number
        }>,
      ) => {
        state.usersIAM = action.payload.data
      },
    )
    builder.addCase(getUsers.rejected, (state, action) => {
      console.log(action.payload)
    })
  },
})

export const { resetUserIAM, setUserIAM, setUpdatedUserIAM } =
  userIAMSlice.actions
export default userIAMSlice.reducer
