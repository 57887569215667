/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Stack } from '@mui/material'
import useIsMounted from 'src/hooks/useIsMounted'
import { useAdminPermission } from 'src/hooks/IAM/AdminPermission/useAdminPermission'
import CreatePermissions from './components/CreatePermissions'
import TablePermissions from './components/TablePermissions'
import { usePagination } from 'src/hooks/IAM/Components/Pagination/usePagination'
import { useAdminModule } from 'src/hooks/IAM/AdminModule/useAdminModule'
import { useAdminGroup } from 'src/hooks/IAM/AdminGroups/useAdminGroups'
const AdminPermissions = () => {
  const isMounted = useIsMounted()
  const { loadPermissions, permissions, setSelectPermission } =
    useAdminPermission()
  const { currentPage, rowsPerPage } = usePagination()
  const { loadGroups } = useAdminGroup()
  const { loadModules, loadModulesWithoutFormat } = useAdminModule()
  useEffect(() => {
    if (!isMounted) return
    loadGroups({})
    loadModules({})
    loadModulesWithoutFormat({})
    loadPermissions({
      currentPage,
      pageSize: rowsPerPage,
    })
  }, [isMounted])

  return (
    <Stack gap={4}>
      <CreatePermissions />
      <TablePermissions
        loadPermissions={loadPermissions}
        permissions={permissions}
        handlerSelectedPermissions={setSelectPermission}
      />
    </Stack>
  )
}
export default AdminPermissions
