import { Autocomplete, Chip, Stack, TextField } from '@mui/material'
import React, { useState } from 'react'
import AlertDialog from 'src/components/Dialog'
import { useAdminPermission } from 'src/hooks/IAM/AdminPermission/useAdminPermission'
import { useUserIAM } from 'src/hooks/IAM/AdminUser/useUserIAM'
import { IUser } from 'src/services/IAM/AdminUser/AdminUser.interface'
import StepperModal from 'src/views/AdminUser/components/StepperModal'
import { useAdminGroup } from 'src/hooks/IAM/AdminGroups/useAdminGroups'

const CreateGroup = () => {
  const { group, setGroupAdmin } = useAdminGroup()
  const { usersIAM } = useUserIAM()
  const {
    selectedPermissions,
    permissions,
    loadPermissions,
    setSelectPermission,
  } = useAdminPermission()
  return (
    <Stack direction={'row'} gap={2} mb={2} justifyContent={'flex-start'}>
      <TextField
        sx={{
          flex: 1,
        }}
        id="outlined-basic"
        label="Nombre nuevo grupo"
        variant="outlined"
        onChange={(e) => setGroupAdmin({ ...group, groupName: e.target.value })}
        required
      />
      <TextField
        sx={{
          flex: 1,
        }}
        id="outlined-basic"
        label="Descripción"
        variant="outlined"
        onChange={(e) =>
          setGroupAdmin({ ...group, description: e.target.value })
        }
        required
      />

      <Autocomplete
        sx={{ flex: 1 }}
        disablePortal
        id="combo-box-demo"
        options={usersIAM}
        multiple
        value={group.users}
        onChange={(event, newValue: IUser[]) => {
          console.log('newValue', newValue)

          setGroupAdmin({
            ...group,
            users: newValue,
          })
        }}
        getOptionLabel={(option) => `${option.userDocType} - ${option.userDocId}`}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option: IUser, index) => (
            <Chip
              label={`${option.userDocType} - ${option.userDocId}`}
              {...getTagProps({ index })}
              key={index}
            />
          ))
        }
        renderInput={(params) => <TextField {...params} label="Usuarios" />}
      />
      <AlertDialog
        title="Permisos"
        buttonLabel={`Agregar permisos: ${selectedPermissions.length}`}
        maxWidth="lg"
        children={
          <Stack direction={'row'}>
            <StepperModal
              handlerSelectedPermissions={setSelectPermission}
              selectedPermissions={selectedPermissions}
              loadPermissions={loadPermissions}
              permissionsProp={permissions}
            />
          </Stack>
        }
      />
    </Stack>
  )
}

export default CreateGroup
