// src/hooks/useAdminGroup.ts
import { useDispatch, useSelector } from 'react-redux'
import { RootState, AppDispatch } from 'src/redux/index'
import { IGroup } from 'src/services/IAM/AdminGroup/AdminGroup.interface'
import {
  resetGroup,
  updateGroup,
  setGroup,
} from 'src/redux/IAM/AdminGroups/adminGroupsSlice'
import {
  creategroup,
  deleteGroup,
  getGroups,
  updateGroup as updateGroupService,
} from 'src/services/IAM/AdminGroup/AdminGroup.service'
import { useAlert } from 'src/contexts/AlertContext'
import useLoading from 'src/hooks/useLoading'

export const useAdminGroup = () => {
  const { showAlert } = useAlert()
  const { startLoading, stopLoading } = useLoading()
  const dispatch = useDispatch<AppDispatch>()
  const { group, groups, updatedGroup } = useSelector(
    (state: RootState) => state.groups,
  )

  const loadGroups = ({
    currentPage = 1,
    pageSize = 25,
  }: {
    currentPage?: number
    pageSize?: number
  } = {}) => {
    try {
      startLoading()
      dispatch(getGroups({ currentPage, pageSize }))
        .then((result: any) => {
          stopLoading()
          if (getGroups.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) || 'Error al cargar los grupos',
            )
          } else if (getGroups.fulfilled.match(result)) {
            showAlert('success', 'Grupos cargados correctamente')
          }
        })
        .finally(() => {
          stopLoading()
        })
      showAlert('success', 'Grupos cargados correctamente')
    } catch (error) {
      showAlert('error', 'Error al cargar los permisos')
    }
  }
  const createGroupAdmin = (group: IGroup) => {
    dispatch(creategroup(group))
      .then((result: any) => {
        stopLoading()
        if (creategroup.rejected.match(result)) {
          showAlert(
            'error',
            String(result.payload) || 'Error al crear el grupo',
          )
        } else if (creategroup.fulfilled.match(result)) {
          loadGroups({ currentPage: 1, pageSize: 25 })
          showAlert('success', 'Grupos creados correctamente')
        }
      })
      .finally(() => {
        stopLoading()
      })
  }

  const updatedGroupAdmin = (group: IGroup) => {
    dispatch(updateGroupService(group))
      .then((result: any) => {
        stopLoading()
        if (updateGroupService.rejected.match(result)) {
          showAlert(
            'error',
            String(result.payload) || 'Error al actualizar el grupo',
          )
        } else if (updateGroupService.fulfilled.match(result)) {
          showAlert('success', 'Grupo actualizado correctamente')
          loadGroups()
          clearGroups()
        }
      })
      .finally(() => {
        stopLoading()
      })
  }
  const removeGroup = (groupId: number) => {
    dispatch(deleteGroup(groupId))
      .then((result: any) => {
        stopLoading()
        if (deleteGroup.rejected.match(result)) {
          showAlert(
            'error',
            String(result.payload) || 'Error al eliminar el grupo',
          )
        } else if (deleteGroup.fulfilled.match(result)) {
          showAlert('success', 'Grupo eliminado correctamente')
          loadGroups()
        }
      })
      .finally(() => {
        stopLoading()
      })
  }
  const setUpdateGroup = (group: IGroup) => {
    dispatch(updateGroup(group))
  }
  const setGroupAdmin = (group: IGroup) => {
    dispatch(setGroup(group))
  }

  const clearGroups = () => {
    dispatch(resetGroup())
  }

  return {
    groups,
    group,
    updatedGroup,
    createGroupAdmin,
    setGroupAdmin,
    loadGroups,
    setUpdateGroup,
    removeGroup,
    clearGroups,
    updatedGroupAdmin,
  }
}
