export interface IDataCards {
  netWorthAmount: string
  netWorthCount: string
  sumAchAmount?: string
  sumAchCount?: string
  sumCoreAmount: string
  sumCoreCount: string
  sumReverseSamePeriodAmount: string
  sumReverseSamePeriodCount: string
  sumSuiteAmount: string
  sumSuiteCount: string
}

export interface ICardType {
  titleValue: string
  value: number | null
  titleAmount: string
  amount: number | null
  color: string
}

export interface IFilters {
  startDate: string
  endDate: string
  kindOfStore: MagicKindOfStore
  businessName: string
  store: string
}
export interface IRowsPSE {
  date: string
  achCount: number
  achAmount: number
  suiteAmount: number
  suiteCount: number
  coreAmount: number
  coreCount: number
  samePeriodAmount: number
  samePeriodCount: number
  previousPeriodAmount: number
  previousPeriodCount: number
}
export interface IRowsStores {
  date: string
  suiteAmount: number
  suiteCount: number
  coreAmount: number
  coreCount: number
  differenceAmount: number
  differenceCount: number
  samePeriodAmount: number
  samePeriodCount: number
  previousPeriodAmount: number
  previousPeriodCount: number
}

export interface IData {
  name: string
  code: string
  population: number
  size: number
  density: number
}
export enum EKingOfCollection {
  PSE = 'Recaudos PSE',
  TIENDAS = 'Tiendas',
}

export enum MagicKindOfStore {
  Todas = 'Todas',
  Franquicias = 'Franquicias',
  Nalsani = 'Nalsani',
  Distribuidores = 'Distribuidores',
}
export interface IDataFilter {
  id: string
  label: string
  disabled?: boolean
  franchises?: IDataFilter[]
  stores?: IDataFilter[]
}
