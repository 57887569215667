/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Stack, TextField } from '@mui/material'
import useIsMounted from 'src/hooks/useIsMounted'
import { useAdminEndpoint } from 'src/hooks/IAM/AdminEndpoint/useAdminEndpoint'
import { useAdminApp } from 'src/hooks/IAM/AdminApp/useAdminApp'
import TableEndpoints from './Components/TableEndpoints'
import FiltersEndpoints from './Components/FiltersEndpoints'
/**
 * AdminEndpoints component for managing and displaying API endpoints.
 *
 * @remarks
 * The `AdminEndpoints` component provides a user interface for managing API endpoints
 * within the application. It includes a filter component for searching endpoints and
 * a table for displaying all available endpoints. The component fetches the list of endpoints
 * and applications when it is mounted.
 *
 * ### Key Changes:
 * - **Usage Examples**:
 *   - The first example shows how to use the `AdminEndpoints` component in a parent component.
 *   - The second example highlights the possibility of future props, though currently, `AdminEndpoints` does not accept any props.
 *
 * ### Additional Notes:
 * - **Search Functionality**: The search functionality allows users to filter endpoints efficiently.
 * - **Flexibility for Future Props**: Documentation anticipates future developments where props can be added.
 *
 * @component
 * @example
 * ```tsx
 * import AdminEndpoints from 'path/to/AdminEndpoints';
 *
 * // Example of using AdminEndpoints in a parent component
 * const ApiManagementPage = () => {
 *   return <AdminEndpoints />;
 * };
 * ```
 *
 * @example
 * // Example with different props (though AdminEndpoints currently does not accept any props)
 * ```tsx
 * // Props can be added in the future
 * const propsExample = {
 *   // future prop examples
 * };
 *
 * <AdminEndpoints {...propsExample} />
 * ```
 *
 * @returns {JSX.Element} The rendered `AdminEndpoints` component.
 */
const AdminEndpoints = (): JSX.Element => {
  const isMounted = useIsMounted()
  const { loadEndpoints } = useAdminEndpoint()
  const { loadApps } = useAdminApp()

  // Get all endpoints
  useEffect(() => {
    if (!isMounted) return
    loadEndpoints()
    loadApps({})
  }, [isMounted])

  return (
    <div>
      <FiltersEndpoints />
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        sx={{ marginBlock: '20px' }}
      >
        <h2>All Endpoints</h2>
        <TextField
          id="search-endpoint"
          label="Search Endpoint"
          variant="outlined"
          size="small"
          multiline
          required
        />
      </Stack>
      <TableEndpoints />
    </div>
  )
}

export default AdminEndpoints
