import { fetchWithToken, getHeaders, getParamsToURL } from 'src/utils/utils'
import { INPermission, IPermissionCreate } from './AdminPermissions.interface'
import { createAsyncThunk } from '@reduxjs/toolkit'

const BASE_URL = process.env.REACT_APP_API || 'NO_URL'

export const createPermission = createAsyncThunk(
  'permission/createPermission',
  async (payload: IPermissionCreate, { rejectWithValue }) => {
    const endpoint = `${BASE_URL}/iam/permission/create`
    console.log('Request Details:', payload)
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
      })
      const data = await response.json()
      console.log('Response:', data)
      if (!response.ok) {
        throw new Error(
          data.error?.message || '-- A ocurrido un error inesperado --',
        )
      }
      console.groupEnd()
      return data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)

export const getPermissions = createAsyncThunk(
  'permission/getPermissions',
  async (
    pagination: {
      currentPage?: number
      pageSize?: number
      type?: string
    } = {},
    { rejectWithValue },
  ) => {
    try {
      const paramsString = getParamsToURL(pagination)
      const response = await fetchWithToken(
        `${BASE_URL}/iam/permission/get-permissions?${paramsString}`,
        {
          method: 'GET',
          headers: getHeaders(),
        },
      )
      const data = await response.json()
      if (!response.ok && response) {
        throw new Error(
          data.error.message || '-- A ocurrido un error inesperado --',
        )
      }
      //ADD ACTIVE PROPERTY
      return {
        data: (data.data = data.data.map((p: INPermission) => ({
          ...p,
          active: false,
        }))),
        details: data.detail.totalItems,
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)

export const getPermission = async (payload: INPermission) => {
  try {
    const response = await fetch(`${BASE_URL}/iam/permission/get-permission`, {
      method: 'GET',
      headers: getHeaders(),
    })
    const data = await response.json()
    if (!response.ok && response) {
      throw new Error(
        data.error.message || '-- A ocurrido un error inesperado --',
      )
    }
    return {
      data: data.data,
      detail: data.detail.totalPages,
    }
  } catch (error) {
    console.error('Error en la creación de módulo', error)
    throw error
  }
}

export const deletePermission = createAsyncThunk(
  'permission/deletePermission',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await fetch(`${BASE_URL}/iam/permission/delete/${id}`, {
        method: 'DELETE',
        headers: getHeaders(),
      })
      const data = await response.json()
      if (!response.ok && response) {
        throw new Error(
          data.error.message || '-- A ocurrido un error inesperado --',
        )
      }

      return data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)

/**
 * Updates the permissions for a given user.
 * @param data - The permission data to be updated.
 * @returns A Promise that resolves to the updated permission data.
 * @throws If the network response is not ok or an error occurs during the update.
 */
export const updatePermissions = createAsyncThunk(
  'permission/updatePermissions',
  async (payload: IPermissionCreate, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${BASE_URL}/iam/permission/update/${payload.permissionId}`,
        {
          method: 'PUT',
          headers: getHeaders(),
          body: JSON.stringify(payload),
        },
      )
      const data = await response.json()

      if (!response.ok && response) {
        throw new Error(
          data.error.message || '-- A ocurrido un error inesperado --',
        )
      }

      return data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)

export const getUserPermissions = async () => {
  try {
    const reponse = await fetchWithToken(`${BASE_URL}/me/access-routes`, {
      method: 'GET',
      headers: getHeaders(),
    })
    const data = await reponse.json()
    return data.data
  } catch (error) {
    console.log('Error en la obtención de permisos', error)
    return { error: String(error) }
  }
}
