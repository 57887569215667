/* eslint-disable react-hooks/exhaustive-deps */

import { Fragment, useContext, useEffect, useState } from 'react'
import {
  EKingOfCollection,
  IFilters,
  MagicKindOfStore,
  IDataFilter,
  IRowsPSE,
  IRowsStores,
} from 'src/views/SearchFilesCollections/SearchFilesCollections.interface'
import {
  Autocomplete,
  Button,
  CircularProgress,
  Stack,
  TextField,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileExcel,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons'
import { format } from 'date-fns'
import DateRangePicker from 'src/components/DatepickerComponent/DatepickerComponent'
import useLoading from 'src/hooks/useLoading'
import {
  getAllStores,
} from 'src/services/accounting/accountingService'
import useIsMounted from 'src/hooks/useIsMounted'
import TableMui from 'src/components/TableMUI/TableMUI'
import { WebSocketContext } from 'src/contexts/WebSocketContext'
import { useNavigate } from 'react-router-dom'
import React from 'react'
import { DummyData } from 'src/components/TableMUI/TableMui.interface'
import { fetchToSocket } from 'src/services/websocket.service'
import { TableInfo } from './Docs.interface'
import useWebsocket from 'src/hooks/useWebSocket'
import TableInfoSales from './Componentes/TableInfoSales'
import { IWSPathsHttpAccounting, IWsState } from 'src/redux/Websocket/webSocket.interface'
import { useAlert } from 'src/contexts/AlertContext'

const SearchFilesCollectionsView = () => {
  //HOOKS
  const { messages, resetMessagesWS, waitForConnection } = useWebsocket();
  const { setMessage } = useContext(WebSocketContext)
  const navigate = useNavigate()
  const isMounted = useIsMounted()
  const [loadingSelect, setLoadingSelect] = useState<boolean>(false)
  const { startLoading, stopLoading } = useLoading()
  const { showAlert } = useAlert()
  const [rowsPerPage] = useState(100)
  const [rowsStores, setRowsStores] = useState<IRowsStores[] | null>(null)
  const [rowsPSE, setRowsPSE] = useState<IRowsPSE[] | null>(null)
  const [dataModule, setDataModule] = useState<DummyData | null>(null)
  const [tableInfo, setTableInfo] = useState<TableInfo | null>(null)
  const [storeDistributors, setStoreDistributors] = useState<
    IDataFilter[] | null
  >(null)

  const [storeFranchise, setStoreFranchise] = useState<IDataFilter[] | null>(
    null,
  )
  const [stores] = useState<string[]>(['Ventas', 'Recaudos'])

  const [kindOfCollection, setKindOfCollection] = useState<string>(
    EKingOfCollection.TIENDAS,
  )
  const [storesDistributors, setStoresDistributors] = useState<
    IDataFilter[] | null
  >(null)
  const getData = async ({ page, pageSize }: { page: number, pageSize: number }) => {
    console.log({ page, pageSize }, 'page');

    try {
      startLoading()
      setDataModule(null)
      setTableInfo(null)
      await waitForConnection()
      if (
        filters.kindOfStore === MagicKindOfStore.Distribuidores ||
        filters.kindOfStore === MagicKindOfStore.Franquicias
      ) {
        await fetchToSocket({
          options: {
            path: IWSPathsHttpAccounting.GET_SALES,
            method: 'POST',
            body: {
              dateGte: format(new Date(filters.startDate), 'yyyy/MM/dd'),
              dateLte: format(new Date(filters.endDate), 'yyyy/MM/dd'),
              kindOfStore: filters.kindOfStore,
              setMessage,
              stores: filters.store,
              businessName: filters.businessName,
              currentPage: page + 1,
              pageSize,
            }
          }
        })
      }
      if (
        filters.kindOfStore === MagicKindOfStore.Nalsani ||
        filters.kindOfStore === MagicKindOfStore.Todas
      ) {
        await fetchToSocket({
          options: {
            path: IWSPathsHttpAccounting.GET_SALES,
            method: 'POST',
            body: {
              dateGte: format(new Date(filters.startDate), 'yyyy/MM/dd'),
              dateLte: format(new Date(filters.endDate), 'yyyy/MM/dd'),
              kindOfStore: filters.kindOfStore,
              setMessage,
              stores: filters.businessName,
              currentPage: page + 1,
              pageSize: rowsPerPage,
            }
          }
        })
      }
    } catch (error: any) {
      showAlert('error', String(error?.message) || 'Error al obtener los datos')
    } finally {
      stopLoading()
    }
  }


  const [businessName, setBusinessName] = useState<IDataFilter[] | null>(null)
  const [storesNalsani, setStoresNalsani] = useState<IDataFilter[] | null>(null)
  //handler unico para todos los filtros
  const handleFilterChange = (
    value: {
      id: number
      label: string
      disabled: boolean
    },
    filter: string,
  ) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filter]: value?.label ?? '',
    }))
  }

  const handleDownloadExcel = async () => {
    try {
      setMessage('Descargando archivo...')
      startLoading()
      await waitForConnection()
      if (filters.kindOfStore === MagicKindOfStore.Nalsani) {
        await fetchToSocket({
          options: {
            path: IWSPathsHttpAccounting.DOWNLOAD_REPORT_SALES,
            method: 'POST',
            body: {
              stores: filters.businessName,
              dateGte: format(new Date(filters.startDate), 'yyyy/MM/dd'),
              dateLte: format(new Date(filters.endDate), 'yyyy/MM/dd'),
              kindOfStore: filters.kindOfStore,
            }
            ,
          }
        })
      }
      if (
        filters.kindOfStore === MagicKindOfStore.Franquicias ||
        filters.kindOfStore === MagicKindOfStore.Distribuidores
      ) {
        await fetchToSocket({
          options: {
            body: {
              path: IWSPathsHttpAccounting.DOWNLOAD_REPORT_SALES,
              dateGte: format(new Date(filters.startDate), 'yyyy/MM/dd'),
              dateLte: format(new Date(filters.endDate), 'yyyy/MM/dd'),
              kindOfStore: filters.kindOfStore,
              stores: filters.store,
              businessName: filters.businessName,
            },
            method: 'POST',
          }
        }
        )
      }
    } catch (error: any) {
      console.log(typeof error, 'error222');

      showAlert('error', String(error?.message) || 'Error al descargar el archivo')
    } finally {
      stopLoading()
    }
  }

  // GET STORES


  // useEffect(() => {
  //   if (!isMounted) return
  //   const fetchData = async () => {
  //     getData()
  //   }
  //   fetchData()
  // }, [rowsPerPage, isMounted])


  useEffect(() => {
    setTableInfo(null)
  }, [dataModule])


  useEffect(() => {
    setRowsPSE(null)
    setRowsStores(null)
    setDataModule(null)
  }, [kindOfCollection])

  const [filters, setFilters] = useState<IFilters>({
    startDate: String(new Date()),
    endDate: String(new Date()),
    kindOfStore: MagicKindOfStore.Nalsani,
    businessName: '',
    store: '',
  })

  // Efects to filters
  useEffect(() => {
    //LIMPIAR FILTROS
    //AGREGAR TIENDAS DE FRANQUICIA
    setFilters((prevFilters) => ({
      ...prevFilters,
      businessName: '',
      store: '',
    }))
  }, [filters.kindOfStore])


  useEffect(() => {
    //SETEAR TIENDAS DE LA FRANQUICIA
    setStoreFranchise(null)
    setStoreDistributors(null)
    if (filters.kindOfStore === MagicKindOfStore.Franquicias) {
      const foundItem = businessName?.find(
        (item) => item.label === filters.businessName,
      )
      if (foundItem) {
        setStoreFranchise(foundItem.franchises || null)
      } else {
        setStoreFranchise(null)
      }
    }

    if (filters.kindOfStore === MagicKindOfStore.Nalsani) {
      setStoreFranchise(null)
    }

    if (filters.kindOfStore === MagicKindOfStore.Distribuidores) {
      const foundItem = storesDistributors?.find(
        (item) => item.label === filters.businessName,
      )
      console.log(foundItem, 'foundItem')

      if (foundItem) {
        setStoreDistributors(foundItem.stores || null)
      } else {
        setStoreDistributors(null)
      }
    }
  }, [filters.businessName])
  const handleDateChange = (startDate: Date | null, endDate: Date | null) => {
    const formattedStartDate = startDate
      ? startDate.toISOString().split('T')[0].replaceAll('-', '/')
      : ''
    const formattedEndDate = endDate
      ? endDate.toISOString().split('T')[0].replaceAll('-', '/')
      : ''
    setFilters((prevFilters) => ({
      ...prevFilters,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    }))
  }
  useEffect(() => {
    if (!isMounted) return
    const fetchData = async () => {
      try {
        setLoadingSelect(true)
        const stores = await getAllStores()
        setBusinessName(stores.data.businessName)
        setStoresNalsani(stores.data.storesNalsani)
        setStoresDistributors(stores.data.distributors)
      } catch (error) {
        showAlert('error', 'Error al obtener las tiendas')
      } finally {
        setLoadingSelect(false)
      }
    }
    fetchData()
  }, [isMounted])
  useEffect(() => {
    if (!isMounted) return
    const lastMessage = messages[messages.length - 1] || {}
    const { advertisingMessage, message, state, body } = lastMessage
    if (lastMessage?.path === IWSPathsHttpAccounting.DOWNLOAD_REPORT_SALES) {
      startLoading()
      if (state === IWsState.ERROR) {
        showAlert('error', message)
        stopLoading()
      }
      if (state === IWsState.FINISHED) {
        showAlert('success', message)
        window.location.href = body?.data?.url
        stopLoading()
        resetMessagesWS();
      }
    }
    if (lastMessage?.path === IWSPathsHttpAccounting.GET_SALES) {
      startLoading()
      if (state === IWsState.ERROR) {
        showAlert('error', message)
        stopLoading()
      }
      if (state === IWsState.FINISHED) {
        showAlert('success', message)
        setDataModule(body?.data)
        stopLoading()
        resetMessagesWS();
      }
    }
  }, [messages, isMounted])
  return (
    <div>
      <Stack gap={4}>
        <TableInfoSales dataModule={dataModule} />
        {/* FILTERS START */}
        <Stack
          direction={{ sm: 'row' }}
          justifyContent={'space-between'}
          gap={2}
        >
          <Autocomplete
            disablePortal
            id="autocomplete-route"
            options={stores}
            sx={{ width: 200, textTransform: 'capitalize' }}

            size="small"
            value={'Ventas'}
            onChange={(event, newValue) =>
              setKindOfCollection(String(newValue || ''))
            }
            inputValue={'Ventas'}
            onInputChange={(event, newInputValue) =>
              navigate(
                `/contabilidad/buscar-archivos-${newInputValue?.toLowerCase()}`,
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ textTransform: 'capitalize' }}
                label="Archivos"
              />
            )}
          />
          <Stack direction={{ sm: 'row' }} gap={2}>
            {kindOfCollection === EKingOfCollection.TIENDAS ? (
              <Fragment>
                <Autocomplete
                  disablePortal
                  id="autocomplete-store"
                  options={[
                    MagicKindOfStore.Todas,
                    MagicKindOfStore.Nalsani,
                    MagicKindOfStore.Franquicias,
                    MagicKindOfStore.Distribuidores,
                  ]}
                  sx={{ width: 200, textTransform: 'capitalize' }}

                  size="small"
                  value={filters.kindOfStore}
                  onChange={(event, value) =>
                    handleFilterChange(
                      { disabled: false, id: 0, label: value || '' },
                      'kindOfStore',
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tipo de tienda"
                      value={filters.kindOfStore}
                    />
                  )}
                />
                {filters.kindOfStore === MagicKindOfStore.Nalsani && (
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={storesNalsani || []}
                    sx={{ width: 200, textTransform: 'capitalize' }}

                    size="small"
                    onChange={(event, value) =>
                      handleFilterChange(value as any, 'businessName')
                    }
                    loading={loadingSelect}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ textTransform: 'capitalize' }}
                        label="Tienda"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Fragment>
                              {loadingSelect ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
                {filters.kindOfStore === MagicKindOfStore.Franquicias && (
                  <Fragment>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={businessName || []}
                      sx={{ width: 200, textTransform: 'capitalize' }}

                      size="small"
                      onChange={(event, value) => {
                        console.log(value, 'value')
                        handleFilterChange(value as any, 'businessName')
                      }}
                      loading={loadingSelect}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ textTransform: 'capitalize' }}
                          label="Franquicia"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <Fragment>
                                {loadingSelect ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </Fragment>
                            ),
                          }}
                        />
                      )}
                    />

                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={storeFranchise || []}
                      sx={{ width: 200, textTransform: 'capitalize' }}

                      size="small"
                      onChange={(event, value) =>
                        handleFilterChange(value as any, 'store')
                      }
                      loading={loadingSelect}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ textTransform: 'capitalize' }}
                          label="Tiendas"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <Fragment>
                                {loadingSelect ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Fragment>
                )}
                {filters.kindOfStore === MagicKindOfStore.Distribuidores && (
                  <Fragment>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={storesDistributors || []}
                      sx={{ width: 200, textTransform: 'capitalize' }}

                      size="small"
                      onChange={(event, value) => {
                        handleFilterChange(value as any, 'businessName')
                      }}
                      loading={loadingSelect}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ textTransform: 'capitalize' }}
                          label="Distribuidor"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <Fragment>
                                {loadingSelect ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={storeDistributors || []}
                      sx={{ width: 200, textTransform: 'capitalize' }}

                      size="small"
                      onChange={(event, value) =>
                        handleFilterChange(value as any, 'store')
                      }
                      loading={loadingSelect}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ textTransform: 'capitalize' }}
                          label="Tiendas"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <Fragment>
                                {loadingSelect ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Fragment>
                )}
              </Fragment>
            ) : null}
            <DateRangePicker
              startDate={filters.startDate ? new Date(filters.startDate) : null}
              endDate={filters.endDate ? new Date(filters.endDate) : null}
              onDateChange={(startDate: Date | null, endDate: Date | null) =>
                handleDateChange(startDate as Date, endDate as Date)
              }
            />
            <Button
              onClick={() => handleDownloadExcel()}
              sx={{
                borderRadius: 1,
                backgroundColor: '#fff',
                color: '#201646',
                border: '1px solid #201646',
                textAlign: 'center',
                'svg': {
                  transition: 'all 0.3s',
                },
                ':hover': {
                  backgroundColor: '#201646',
                  color: '#fff',
                  '& svg': {
                    color: '#fff',
                  },
                },
              }}
              id="download-button"
            >
              <FontAwesomeIcon
                size='xl'
                color='#201646'
                icon={faFileExcel}
              />
            </Button>
            <Button
              onClick={() => getData({
                page: 0,
                pageSize: rowsPerPage
              })}
              id="search-button"
              sx={{
                borderRadius: 1,
                backgroundColor: '#201646',
                color: '#fff',
                border: '1px solid #201646',
                textAlign: 'center',
                transition: 'all 0.3s',
                ':hover': {
                  opacity: 0.8,
                  backgroundColor: '#201646',
                  'svg': {
                    color: '#fff',
                  },
                },
              }}
            >
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
              />
            </Button>
          </Stack>
        </Stack>
        {/* FILTERS END */}
        <TableMui dataTable={dataModule} onGetData={getData} page={(Number((dataModule?.currentPage ? dataModule.currentPage - 1 : 0) ?? 0))}
          rowsPerPage={rowsPerPage} totalItems={dataModule?.totalItems ?? 0}
        />
      </Stack>
    </div>
  )
}

export default SearchFilesCollectionsView
