/**
 * @module services/access.ts
 * @categoryDescription Este archivo define la función `getSessionToken` que realiza una solicitud a la API para obtener un token de sesión.
 */

/**
 * Realiza una solicitud a la API para obtener un token de sesión.
 *
 * @param {Info} infoDevice - La información del dispositivo que se enviará en la solicitud.
 * @returns {Promise<any>} Una promesa que se resuelve con la respuesta de la API.
 * @throws {Error} Lanza un error si la respuesta de la API no es exitosa.
 */
export const getSessionToken = async (payload: {
  generalInfo: {}
  termsAndConditions: { termAndConditionVersionId: number }[]
}): Promise<any> => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API + '/onboarding/access',
      {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `none`,
        },
      },
    )
    const data = await response.json()


    localStorage.setItem('sessionToken', data.session_token)
    if (!response.ok && response) {
      throw new Error(
        data?.error?.message || '-- A ocurrido un error inesperado --',
      )
    }
    return data
  } catch (error) {
    throw error
  }
}

export const getTermsAndConditions = async (): Promise<any> => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API + '/onboarding/terms-and-conditions',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
        },
      },
    )
    const data = await response.json()
    if (!response.ok && response) {
      throw new Error(
        data.error.message || '-- A ocurrido un error inesperado --',
      )
    }
    return data.data
  } catch (error) {
    throw error
  }
}
