// src/features/user/userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IInitialState,
  ResponseGetModelsCreditLimit,
} from './testModelsSlice.interface'
import {
  testGetModelsCreditLimit,
  getConfigModelCreditLimit,
  getInitialSettiongByVersion,
  getFiltersSettings,
} from 'src/services/Onboarding/TestModels/testModels.service'

const initialState: IInitialState = {
  responseGetModelsCreditLimit: null,
  responseGetInitialSettiongByVersion: null,
  rsponseGetConfigModelCreditLimit: null,
  responseGetFilterSettings: [],
}

const testModelsSlice = createSlice({
  name: 'testModels',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(testGetModelsCreditLimit.pending, (state) => {
        state.responseGetModelsCreditLimit = null
      })
      .addCase(
        testGetModelsCreditLimit.fulfilled,
        (state, action: PayloadAction<ResponseGetModelsCreditLimit[]>) => {
          state.responseGetModelsCreditLimit = action.payload
        },
      )
      .addCase(testGetModelsCreditLimit.rejected, (state) => {
        state.responseGetModelsCreditLimit = null
      })
      .addCase(getConfigModelCreditLimit.pending, (state) => {
        state.rsponseGetConfigModelCreditLimit = null
      })
      .addCase(
        getConfigModelCreditLimit.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.rsponseGetConfigModelCreditLimit = action.payload
        },
      )
      .addCase(getConfigModelCreditLimit.rejected, (state) => {
        state.rsponseGetConfigModelCreditLimit = null
      })
      .addCase(getInitialSettiongByVersion.pending, (state) => {
        state.responseGetInitialSettiongByVersion = null
      })
      .addCase(
        getInitialSettiongByVersion.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.responseGetInitialSettiongByVersion = action.payload
        },
      )
      .addCase(getInitialSettiongByVersion.rejected, (state) => {
        state.responseGetInitialSettiongByVersion = null
      })
      .addCase(getFiltersSettings.pending, (state) => {
        state.responseGetFilterSettings = []
      })
      .addCase(
        getFiltersSettings.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.responseGetFilterSettings = action.payload
        },
      )
      .addCase(getFiltersSettings.rejected, (state) => {
        state.responseGetFilterSettings = []
      })
  },
})

export const {} = testModelsSlice.actions
export default testModelsSlice.reducer
