import { useState } from 'react'
/**
 * Tipos de valores para el formulario de inicio de sesión.
 */
interface FormValues {
  cedula: string
  password: string
  userDocType: string
  country: string
}

/**
 * Custom hook for managing login form state.
 *
 * @returns An object containing the form data, a function to handle form input changes, and a function to set the form data.
 */
/**
 * Custom hook for managing login form state.
 *
 * @returns An object containing the form data, a function to handle form input changes, and a function to set the form data.
 */
/**
 * Custom hook for managing the login form state.
 *
 * @returns An object containing the form data, a function to handle form input changes, and a function to set the form data.
 */
const useLoginForm = () => {
  const [formData, setFormData] = useState<FormValues>({
    userDocType: 'CC',
    cedula: '',
    country: 'CO',
    password: '',
  })

  /**
   * Handles changes in the form input fields.
   *
   * @param e - The event object representing the input change event.
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  return {
    formData,
    handleChange,
    setFormData, // Adds this line
  }
}

export default useLoginForm
