import { fetchWithToken, getHeaders } from 'src/utils/utils'

/**
 * Propiedades para el servicio de WebSocket.
 * @typedef {Object} WebSocketServiceProps
 * @property {(message: string) => void} setMessage - Función para establecer el mensaje.
 * @property {(message: number) => void} setPercentage - Función para establecer el porcentaje.
 * @property {(close: boolean) => void} setClose - Función para establecer el estado de cierre.
 * @property {(status: 'error' | 'info' | 'success' | 'warning') => void} setStatus - Función para establecer el estado.
 * @property {string} dateGte - Fecha de inicio.
 * @property {string} dateLte - Fecha de fin.
 * @property {string} [table] - Nombre de la tabla.
 * @property {string} [kindOfStore] - Tipo de tienda.
 * @property {string} [stores] - Tiendas.
 * @property {string} [businessName] - Nombre del negocio.
 */

const BASE_URL = process.env.REACT_APP_API
const BASE_URL_WEBSOCKET = process.env.REACT_APP_WEBSOCKET
interface WebSocketServiceProps {
  setMessage: (message: string) => void
  setPercentage: (message: number) => void
  setClose: (close: boolean) => void
  setStatus: (status: 'error' | 'info' | 'success' | 'warning') => void
  dateGte: string
  dateLte: string
  table?: string
  businessName?: string
  kindOfStore?: string
  stores?: string
}
/**
 * Inicia la conexión con el WebSocket y maneja los mensajes entrantes.
 * @param {WebSocketServiceProps} props Propiedades necesarias para iniciar el WebSocket.
 */
export const startWebSocket = async ({
  setMessage,
  setPercentage,
  setClose,
  setStatus,
  dateGte,
  dateLte,
}: WebSocketServiceProps) => {
  const ws = new WebSocket(String(BASE_URL_WEBSOCKET))
  ws.onopen = (e) => {
    ws.send(JSON.stringify({ action: 'getConnectionId' }))
    setStatus('info')
    setMessage('Analizando archivos...')
    setClose(true)
  }
  ws.onmessage = async (e) => {
    try {
      const jsonResponse = JSON.parse(e.data)
      if (jsonResponse.data.connectionId) {
        handleWebSocketMessage({
          connectionID: jsonResponse.data.connectionId,
          dateGte,
          dateLte,
        })
      }
      console.log('antes del error')
      if (jsonResponse.state === 'Error') {
        setMessage('Error al procesar el mensaje del WebSocket')
      }
      if (jsonResponse.state === 'Finished') {
        setMessage('Proceso finalizado')
        ws.close()
      }
      if (jsonResponse.state === 'Processing') {
        setMessage(jsonResponse.data.detail)
      }
    } catch (error) {
      setMessage('Error al procesar el mensaje del WebSocket')
    }
  }

  ws.onclose = () => {
    console.log('Conexión cerrada')
  }
}

/**
 * Maneja el mensaje recibido del WebSocket.
 * @param {Object} params Parámetros necesarios para manejar el mensaje.
 * @param {string} params.connectionID ID de la conexión.
 * @param {string} params.dateGte Fecha de inicio.
 * @param {string} params.dateLte Fecha de fin.
 */
export const handleWebSocketMessage = async ({
  connectionID,
  dateGte,
  dateLte,
}: {
  connectionID: string
  dateGte: string
  dateLte: string
}) => {
  try {
    await fetch(`${BASE_URL}/async/accounting/report-credit-cancellation`, {
      method: 'POST',
      body: JSON.stringify({
        dateGte,
        dateLte,
        ext: 'xlsx',
        connectionId: connectionID,
      }),
      headers: getHeaders(),
    })
  } catch (error) {
    console.error('Error al procesar el mensaje del WebSocket:', error)
  }
}

/**
 * Inicia la descarga de datos utilizando WebSocket.
 * @param {WebSocketServiceProps} props Propiedades necesarias para iniciar la descarga.
 */
export const startWebSocketDownload = async ({
  setMessage,
  dateGte,
  dateLte,
  businessName,
  kindOfStore,
  stores,
}: {
  setMessage: (message: string) => void
  dateGte: string
  dateLte: string
  businessName?: string
  kindOfStore: string
  stores: string
}): Promise<any> => {
  if (stores === 'empty' || stores === 'Todas') stores = ''
  const ws = new WebSocket(String(BASE_URL_WEBSOCKET))
  let data = {}
  let jsonResponse = {} as any

  await new Promise((resolve, reject) => {
    ws.onopen = (e) => {
      ws.send(JSON.stringify({ action: 'getConnectionId' }))
      console.log('Conectado')
    }

    ws.onmessage = async (e) => {
      try {
        jsonResponse = JSON.parse(e.data)
        setMessage(
          jsonResponse.data.detail || 'Obteniendo datos de la tienda...',
        )
        if (jsonResponse.data.connectionId) {
          //POST PARA ACTIVAR EL WEBSOCKET
          await fetch(
            BASE_URL + '/async/accounting/report-credit-cancellation/download',
            {
              method: 'POST',
              body: JSON.stringify({
                dateGte: dateGte.replaceAll('-', '/'),
                dateLte: dateLte.replaceAll('-', '/'),
                connectionId: jsonResponse.data.connectionId,
                kindOfStore,
                store: stores,
                businessName,
              }),
              headers: getHeaders(),
            },
          )
        }
        if (jsonResponse.state === 'Finished') {
          data = jsonResponse.data
          //Redirect to jsonResponse.data.url
          window.location.href = jsonResponse.data.url
          ws.close()
          resolve(data)
        }
      } catch (error) {
        setMessage('Error al obtener los datos de la tienda')
        reject(error)
      }
    }
  })
}

/**
 * Inicia la descarga de información de tesorería.
 * @param {WebSocketServiceProps} props Propiedades para la descarga de información de tesorería.
 */
export const startDownloadTreasury = async ({
  setMessage,
  setPercentage,
  setStatus,
  setClose,
  dateGte,
  dateLte,
  table,
}: WebSocketServiceProps) => {
  table = table?.toLocaleLowerCase()
  const ws = new WebSocket(String(BASE_URL_WEBSOCKET))
  ws.onopen = (e) => {
    ws.send(JSON.stringify({ action: 'getConnectionId' }))
    setClose(true)
    setStatus('info')
    setMessage('Analizando archivos...')
  }
  ws.onmessage = async (e) => {
    try {
      const jsonResponse = JSON.parse(e.data)
      if (jsonResponse.data.connectionId) {
        handleDownloadTreasury({
          connectionId: jsonResponse.data.connectionId,
          dateGte,
          dateLte,
          table: table || 'ventas',
        })
      }
      console.log(jsonResponse.state, 'jsonResponse.state')
      if (jsonResponse.state === 'Finished') {
        await downloadFile({ url: jsonResponse.data.url, setMessage })
        setStatus('success')
        setMessage('Proceso finalizado')
        setPercentage(100)
        setTimeout(() => {
          setClose(false)
        }, 5000)
        ws.close()
      } else {
        setStatus('info')
        setMessage(jsonResponse.data.detail)
        setPercentage(jsonResponse.percentage)
        setClose(true)
      }
    } catch (error) {
      setStatus('error')
      setMessage('Error al procesar el mensaje del WebSocket')
    }
  }
}

/**
 * Maneja la descarga de información de tesorería a través del WebSocket.
 * @param {Object} params Parámetros para la descarga de información.
 * @param {string} params.connectionId ID de la conexión.
 * @param {string} params.dateGte Fecha de inicio.
 * @param {string} params.dateLte Fecha de fin.
 * @param {string} params.table Nombre de la tabla.
 */
export const handleDownloadTreasury = async ({
  connectionId,
  dateGte,
  dateLte,
  table,
}: {
  connectionId: string
  dateGte: string
  dateLte: string
  table: string
}) => {
  try {
    await fetch(`${BASE_URL}/async/treasury/information/download`, {
      method: 'POST',
      body: JSON.stringify({
        dateGte,
        dateLte,
        ext: 'xlsx',
        connectionId: connectionId,
        table,
      }),
      headers: getHeaders(),
    })
  } catch (error) {
    console.error('Error al procesar el mensaje del WebSocket:', error)
  }
}

/**
 * Descarga un archivo desde una URL.
 * @param {Object} params Parámetros para la descarga del archivo.
 * @param {(message: string) => void} params.setMessage Función para establecer el mensaje.
 * @param {string} params.url URL del archivo a descargar.
 */
export const downloadFile = async ({
  setMessage,
  url,
}: {
  setMessage: (message: string) => void
  url: string
}) => {
  try {
    const fetchTest = await fetch(url)
    const blob = await fetchTest.blob()
    const newUrl = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = newUrl
    a.download = 'report.xlsx'
    a.click()
  } catch (error) {
    setMessage('No se pudo descargar el archivo')
  }
}

/**
 * Realiza una solicitud a través de un proxy de WebSocket.
 *
 * @param {Object} params - Los parámetros de la solicitud.
 * @param {string} [params.url] - La URL a la que se realizará la solicitud, concatenada a la URL base del proxy.
 * @param {IFetchOptions} params.options - Opciones adicionales para la solicitud, como el método HTTP, cabeceras y cuerpo de la solicitud.
 * @returns {Promise<any>} Una promesa que se resuelve con la respuesta en formato JSON de la solicitud.
 * @throws {Error} Lanza un error si la respuesta no es exitosa o si ocurre un problema durante la solicitud.
 *
 * @example
 * fetchToSocket({
 *   url: '/endpoint',
 *   options: {
 *     method: 'POST',
 *     body: { key: 'value' },
 *     headers: { 'Authorization': 'Bearer token' },
 *   },
 * })
 * .then(data => console.log(data))
 * .catch(error => console.error('Error:', error));
 */
export interface IFetchOptions {
  method?: string
  headers?: Record<string, string>
  body?: any
  [key: string]: any // Permite cualquier otra opción adicional
}

export const fetchToSocket = async ({
  url,
  options,
}: {
  url?: string
  options: IFetchOptions
}): Promise<any> => {
  try {
    const response = await fetchWithToken(
      `${process.env.REACT_APP_API}/socket/execute-proxy?method=${options.method}&path=${options.path}`,
      {
        method: options?.method || 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}` || '',
          ...options?.headers,
        },
        body: options?.body
          ? JSON.stringify({
              connectionId: localStorage.getItem('connectionId'),
              apiGatewayId: process.env.REACT_APP_APIGATEWAY,
              payload: { ...options.body },
            })
          : null,
      },
    )
    const data = await response.json()
    if (!response.ok && response) {
      throw new Error(
        data.error.message || '-- A ocurrido un error inesperado --',
      )
    }

    return data
  } catch (error) {
    throw error
  }
}
