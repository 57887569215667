import { fetchWithToken, getHeaders, getParamsToURL } from 'src/utils/utils'
import { createAsyncThunk } from '@reduxjs/toolkit'

const API_URL = process.env.REACT_APP_API
export const testGetModelsCreditLimit = createAsyncThunk(
  'keypago/testGetModelsCreditLimit',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchWithToken(
        `${API_URL}/onboarding-testing/get-models-credit-limit`,
        {
          method: 'GET',
          headers: getHeaders(),
        },
      )
      const data = await response.json()


      localStorage.setItem('sessionToken', data.session_token)
      if (!response.ok && response) {
        throw new Error(
          data?.error?.message || '-- A ocurrido un error inesperado --',
        )
      }
      return data.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)
export const getFiltersSettings = createAsyncThunk(
  'keypago/getFiltersSettings',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchWithToken(
        `${API_URL}/onboarding-testing/get-initial-settings`,
        {
          method: 'GET',
          headers: getHeaders(),
        },
      )
      const data = await response.json()


      localStorage.setItem('sessionToken', data.session_token)
      if (!response.ok && response) {
        throw new Error(
          data?.error?.message || '-- A ocurrido un error inesperado --',
        )
      }
      return data.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)

export const getConfigModelCreditLimit = createAsyncThunk(
  'keypago/getConfigModelCreditLimit',
  async (
    payload: {
      modelVersionId: number
    },
    { rejectWithValue },
  ) => {
    try {
      const paramsString = getParamsToURL(payload)
      const response = await fetchWithToken(
        `${API_URL}/onboarding-testing/get-config-model-credit-limit?${paramsString}`,
        {
          method: 'GET',
          headers: getHeaders(),
        },
      )
      const data = await response.json()


      localStorage.setItem('sessionToken', data.session_token)
      if (!response.ok && response) {
        throw new Error(
          data?.error?.message || '-- A ocurrido un error inesperado --',
        )
      }
      return data.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)

export const getInitialSettiongByVersion = createAsyncThunk(
  'keypago/getInitialSettiongByVersion',
  async (
    payload: {
      settingVersionId: number
    },
    { rejectWithValue },
  ) => {
    try {
      const paramsString = getParamsToURL(payload)

      const response = await fetchWithToken(
        `${API_URL}/onboarding-testing/get-initial-settings-by-version?${paramsString}`,
        {
          method: 'GET',
          headers: getHeaders(),
        },
      )
      const data = await response.json()


      localStorage.setItem('sessionToken', data.session_token)
      if (!response.ok && response) {
        throw new Error(
          data?.error?.message || '-- A ocurrido un error inesperado --',
        )
      }
      return data.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)
