import { fetchWithToken, getHeaders, getParamsToURL } from 'src/utils/utils'
import { IBodyEndpoint, IEndpoint } from './AdminEndpoints.interface'
import { createAsyncThunk } from '@reduxjs/toolkit'

const BASE_URL = process.env.REACT_APP_API || 'NO_URL'

export const createEndpoint = createAsyncThunk(
  'permission/createEndpoint',
  async (payload: IEndpoint, { rejectWithValue }) => {
    try {
      const response = await fetchWithToken(`${BASE_URL}/iam/endpoint/create`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
      })
      const data = await response.json()
      if (!response.ok && response) {
        throw new Error(
          data.error.message || '-- A ocurrido un error inesperado --',
        )
      }
      return {
        data: data.data,
        detail: data.detail.totalPages,
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)

export const getEndpoints = createAsyncThunk(
  'permission/getEndpoints',
  async (
    pagination: {
      currentPage?: number
      pageSize?: number
      type?: string
    } = {},
    { rejectWithValue },
  ) => {
    try {
      const paramsString = getParamsToURL(pagination)
      const response = await fetchWithToken(
        `${BASE_URL}/iam/endpoint/get-endpoints?${paramsString}`,
        {
          method: 'GET',
          headers: getHeaders(),
        },
      )
      const data = await response.json()
      if (!response.ok && response) {
        throw new Error(
          data.error.message || '-- A ocurrido un error inesperado --',
        )
      }
      return data.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)

export const deleteEndpoint = createAsyncThunk(
  'permission/deleteEndpoint',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await fetchWithToken(
        `${BASE_URL}/iam/endpoint/delete/${id}`,
        {
          method: 'DELETE',
          headers: getHeaders(),
        },
      )
      const data = await response.json()
      if (!response.ok && response) {
        throw new Error(
          data.error.message || '-- A ocurrido un error inesperado --',
        )
      }

      return data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)

export const updateEndpoint = createAsyncThunk(
  'permission/updateEndpoint',
  async (payload: IBodyEndpoint, { rejectWithValue }) => {
    try {
      const response = await fetchWithToken(
        `${BASE_URL}/iam/endpoint/update/${payload.endpointId}`,
        {
          method: 'PUT',
          headers: getHeaders(),
          body: JSON.stringify(payload),
        },
      )

      if (!response.ok && response) {
        throw new Error('Network response was not ok')
      }

      return await response.json()
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)
