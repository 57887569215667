// src/features/user/endpointSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IEndpoint,
  IEndpointGet,
} from 'src/services/IAM/AdminEndpoints/AdminEndpoints.interface'
import { IEndpointState } from './adminEndpoints.interface'
import {
  getEndpoints,
  createEndpoint,
  updateEndpoint,
  deleteEndpoint,
} from 'src/services/IAM/AdminEndpoints/AdminEndpoints.service'

const initialState: IEndpointState = {
  endpoint: {
    appId: 0,
    endpoints: [],
  },
  endpoints: [],
  loading: false,
  error: null,
}

const endpointSlice = createSlice({
  name: 'endpoint',
  initialState,
  reducers: {
    setEndpoint: (state, action: PayloadAction<IEndpoint>) => {
      state.endpoint = action.payload
    },
    resetEndpoint: () => initialState,
    resetEndpoints: (state) => {
      state.endpoints = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getEndpoints.fulfilled,
        (state, action: PayloadAction<IEndpointGet[]>) => {
          state.loading = false
          state.endpoints = action.payload
        },
      )
      .addCase(getEndpoints.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(
        deleteEndpoint.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false
        },
      )
      .addCase(deleteEndpoint.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(
        createEndpoint.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false
        },
      )
      .addCase(createEndpoint.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(
        updateEndpoint.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false
        },
      )
      .addCase(updateEndpoint.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload
      })
  },
})

export const { resetEndpoint, setEndpoint } = endpointSlice.actions
export default endpointSlice.reducer
