// src/features/user/KPIFormSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IGetStrategyResponse,
  IStrategy,
} from 'src/services/dashboard/strategies/strategies.interface'
import {
  createStrategy,
  getStrategies,
} from 'src/services/dashboard/strategies/strategies.service'
import { IStrategyState } from './Strategies.interface'

const initialState: IStrategyState = {
  loading: false,
  filter: {
    areaName: null,
    channelId: null,
    indicatorName: null,
    periodId: null,
    quarterId: null,
  },
  strategy: {
    strategyName: '',
    description: '',
    detailLogical: '',
    parameters: [],
    type: 'goal',
  },
  strategies: [],
}

const StrategySlice = createSlice({
  name: 'KPIForm',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<IStrategyState['filter']>) => {
      state.filter = action.payload
    },
    setStrategy: (state, action: PayloadAction<IStrategyState['strategy']>) => {
      state.strategy = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        createStrategy.fulfilled,
        (state, action: PayloadAction<IStrategy[]>) => {
          state.loading = false
          state.strategy = initialState.strategy
        },
      )
      .addCase(
        getStrategies.fulfilled,
        (state, action: PayloadAction<IGetStrategyResponse[]>) => {
          state.loading = false
          state.strategies = action.payload
        },
      )
  },
})

export const { setFilter, setStrategy } = StrategySlice.actions
export default StrategySlice.reducer
