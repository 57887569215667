import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material'
import React, { useState } from 'react'

import AlertDialog from 'src/components/Dialog'
import { useAdminApp } from 'src/hooks/IAM/AdminApp/useAdminApp'
import { useAdminEndpoint } from 'src/hooks/IAM/AdminEndpoint/useAdminEndpoint'
import { usePagination } from 'src/hooks/IAM/Components/Pagination/usePagination'
import { IBodyEndpoint } from 'src/services/IAM/AdminEndpoints/AdminEndpoints.interface'

const TableEndpoints = () => {
  const [endpointUpdated, setEndpointUpdated] = useState<IBodyEndpoint>()
  const { endpoints, loadEndpoints, removeEndpoint, updateEndpointAdmin } =
    useAdminEndpoint()
  const { apps } = useAdminApp()
  const {
    currentPage,
    handlePageChange,
    handleRowsPerPageChange,
    rowsPerPage,
    rowsPerPageOptions,
  } = usePagination()
  const handleChangeMethodsUpdated = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const methodExists = endpointUpdated?.methods?.some(
      (methodObj) => methodObj.method === e.target.value,
    )

    // If the method already exists, remove it
    if (methodExists) {
      setEndpointUpdated({
        ...endpointUpdated,
        methods: endpointUpdated?.methods?.filter(
          (methodObj) => methodObj.method !== e.target.value,
        ),
      })
    } else {
      // If the method doesn't exist, add it
      setEndpointUpdated({
        ...endpointUpdated,
        methods: [
          ...(endpointUpdated?.methods || []),
          {
            method: e.target.value,
          },
        ],
      })
    }
  }
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nombre App</TableCell>
              <TableCell align="left">Path</TableCell>
              <TableCell align="left">Público</TableCell>
              <TableCell align="left">Métodos</TableCell>
              <TableCell align="right">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {endpoints ? (
              endpoints?.map((endpoint, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {endpoint.appName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {endpoint.path}
                    </TableCell>
                    <TableCell align="left">
                      {endpoint.public ? 'Sí' : 'No'}
                    </TableCell>
                    <TableCell align="left">
                      {endpoint?.methods?.map((method, index) => {
                        return <span key={index}>{method.method} </span>
                      })}
                    </TableCell>
                    <TableCell align="right">
                      <Stack
                        justifyContent={'flex-end'}
                        direction={'row'}
                        spacing={2}
                      >
                        <AlertDialog
                          title={`Editar endpoint ${endpoint.appName}`}
                          buttonLabel={'Editar'}
                          disagreeLabel="Cancelar"
                          agreeLabel="Editar"
                          onOpen={() =>
                            setEndpointUpdated({
                              appId: endpoint?.appId ?? 0,
                              methods: endpoint.methods,
                              path: endpoint.path,
                              public: endpoint.public,
                              appName: endpoint.appName ?? '',
                            })
                          }
                          onAgree={async () => {
                            await updateEndpointAdmin(endpointUpdated ?? {})
                          }}
                          onDisagree={() => console.log('endpoint no editado')}
                          children={
                            <form
                              onSubmit={() => {
                                updateEndpointAdmin(endpointUpdated ?? {})
                              }}
                            >
                              <Autocomplete
                                id="combo-box-demo"
                                options={apps || []}
                                value={apps?.find(
                                  (app) => app?.appId === endpoint?.appId,
                                )}
                                fullWidth
                                getOptionLabel={(option) => option.name}
                                sx={{
                                  my: 2,
                                }}
                                onChange={(e, value) => {
                                  setEndpointUpdated({
                                    ...endpointUpdated,
                                    appId: value?.appId ?? 0,
                                  })
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Aplicación"
                                    variant="outlined"
                                    required
                                  />
                                )}
                              />
                              {/* DESCRIPTION */}
                              <TextField
                                id="outlined-basic"
                                label="Descripción"
                                variant="outlined"
                                sx={{
                                  mb: 2,
                                }}
                                fullWidth
                                value={endpointUpdated?.description ?? ''}
                                onChange={(e) =>
                                  setEndpointUpdated({
                                    ...endpointUpdated,
                                    description: e.target.value,
                                  })
                                }
                              />

                              <Stack spacing={2} direction={'column'}>
                                {/* PATH */}
                                <TextField
                                  id="outlined-basic"
                                  label="path"
                                  variant="outlined"
                                  sx={{
                                    mb: 2,
                                  }}
                                  fullWidth
                                  value={endpointUpdated?.path}
                                  onChange={(e) =>
                                    setEndpointUpdated({
                                      ...endpointUpdated,
                                    })
                                  }
                                  required
                                />
                                {/* METHODS */}
                                <FormGroup>
                                  <Stack direction={'row'} spacing={2}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={endpointUpdated?.methods?.some(
                                            (methodObj) =>
                                              methodObj.method === 'get',
                                          )}
                                          onChange={(e) => {
                                            handleChangeMethodsUpdated(e)
                                          }}
                                          value="get"
                                        />
                                      }
                                      label="get"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={endpointUpdated?.methods?.some(
                                            (methodObj) =>
                                              methodObj.method === 'post',
                                          )}
                                          onChange={(e) => {
                                            handleChangeMethodsUpdated(e)
                                          }}
                                          value="post"
                                        />
                                      }
                                      label="post"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={endpointUpdated?.methods?.some(
                                            (methodObj) =>
                                              methodObj.method === 'put',
                                          )}
                                          onChange={(e) => {
                                            handleChangeMethodsUpdated(e)
                                          }}
                                          value="put"
                                        />
                                      }
                                      label="put"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={endpointUpdated?.methods?.some(
                                            (methodObj) =>
                                              methodObj.method === 'delete',
                                          )}
                                          onChange={(e) => {
                                            handleChangeMethodsUpdated(e)
                                          }}
                                          value="delete"
                                        />
                                      }
                                      label="delete"
                                    />
                                  </Stack>
                                </FormGroup>
                              </Stack>
                            </form>
                          }
                        />
                        <AlertDialog
                          buttonLabel={'Eliminar'}
                          title={`¿Estás seguro de eliminar el endpoint "${endpoint.path}"?`}
                          disagreeLabel="Cancelar"
                          agreeLabel="Eliminar"
                          color="error"
                          onAgree={() => {
                            removeEndpoint(endpoint.endpointId ?? 0)
                          }}
                          nameToDelete={endpoint.path}
                          withConfirmation
                          onDisagree={() =>
                            console.log('endpoint no eliminado')
                          }
                          children={
                            <div>
                              <p>
                                Al eliminar este endpoint, se eliminaran todos
                                los permisos asociados
                              </p>
                              <p>
                                Escriba <strong>{endpoint.path}</strong> para
                                confirmar
                              </p>
                            </div>
                          }
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No hay endpoints
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={endpoints.length + 1}
        rowsPerPage={rowsPerPage}
        page={currentPage - 1}
        onPageChange={(event, newPage) => {
          handlePageChange(event, newPage + 1)
          loadEndpoints({
            currentPage: newPage + 1,
            pageSize: rowsPerPage,
          })
        }}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Paper>
  )
}

export default TableEndpoints
