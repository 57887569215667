// src/hooks/useStrategies.ts
import { useDispatch, useSelector } from 'react-redux'
import { RootState, AppDispatch } from 'src/redux/index'
import { useAlert } from 'src/contexts/AlertContext'
import useLoading from 'src/hooks/useLoading'
import {
  IFilterIndicators,
  IIndicator,
  IIndicatorCreate,
  IPayloadCopyQuestion,
  IPayloadRemoveQuestion,
  IPayloadUpdateQuestion,
  IResponseGetIndicator,
} from 'src/services/dashboard/indicator/indicator.interface'
import {
  copyQuestions,
  createIndicator,
  getIndicator,
  getIndicators,
  removeQuestion,
  updateQuestion,
} from 'src/services/dashboard/indicator/indicator.service'
import {
  setIndicatorCreate,
  setIndicator,
  setFilter,
  resetFilters,
} from 'src/redux/Dashboard/Indicators/IndicatorsSlice'
export const useIndicators = () => {
  const { showAlert } = useAlert()
  const { startLoading, stopLoading } = useLoading()
  const dispatch = useDispatch<AppDispatch>()
  const {
    indicatorCreate,
    indicator,
    filter,
    indicators,
    responseLoadIndicator,
  } = useSelector((state: RootState) => state.indicators)
  // ENDPOINTS TO INDICATORS
  const createNewIndicator = (indicator: IIndicatorCreate) => {
    try {
      startLoading()
      dispatch(createIndicator(indicator))
        .then((result: any) => {
          if (createIndicator.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) || 'Error al cargar las preguntas',
            )
          } else if (createIndicator.fulfilled.match(result)) {
            loadIndicators({
              currentPage: 1,
              pageSize: 25,
            })
            showAlert('success', 'Indicador creado correctamente')
          }
        })
        .finally(() => {
          stopLoading()
        })
    } catch (error: any) {
      showAlert('error', error.message || 'Ocurrió un error inesperado')
    }
  }

  const loadIndicator = async ({
    indicatorId,
    currentPage,
    pageSize,
  }: {
    indicatorId: string
    currentPage?: number | null
    pageSize?: number | null
  }) => {
    try {
      startLoading()
      dispatch(getIndicator({ indicatorId, currentPage, pageSize })).then(
        (result: any) => {
          if (getIndicator.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) || 'Error al cargar las preguntas',
            )
          } else if (getIndicator.fulfilled.match(result)) {
            showAlert('success', 'Indicador cargado correctamente')
          }
        },
      )
    } catch (error: any) {
      showAlert('error', error.message || 'Ocurrió un error inesperado')
      return null
    } finally {
      stopLoading()
    }
  }

  const updateIndicator = (indicator: IIndicator) => {
    dispatch(setIndicator(indicator))
  }
  const updateIndicatorCreate = (indicator: IIndicatorCreate) => {
    dispatch(setIndicatorCreate(indicator))
  }
  const setfilterIndicators = (filter: IFilterIndicators) => {
    dispatch(setFilter(filter))
  }

  const resetFiltersIndicators = () => {
    dispatch(resetFilters())
  }
  // ENDPOINTS TO QUESTIONS
  const loadIndicators = (pagination: {
    currentPage?: number | null
    pageSize?: number | null
    areaName?: string | null
    indicatorId?: number | null
    periodId?: number | null
    quarterId?: number | null
    channelId?: number | null
  }) => {
    try {
      startLoading()
      dispatch(getIndicators(pagination))
        .then((result: any) => {
          if (getIndicators.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) || 'Error al cargar las estrategias',
            )
          } else if (getIndicators.fulfilled.match(result)) {
            showAlert('success', 'Estrategias cargadas correctamente')
          }
        })
        .finally(() => {
          stopLoading()
        })
    } catch (error: any) {
      showAlert('error', error.message || 'Ocurrió un error inesperado')
    }
  }

  const updateQuestionIndicator = (question: IPayloadUpdateQuestion) => {
    try {
      startLoading()
      dispatch(updateQuestion(question))
        .then((result: any) => {
          if (updateQuestion.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) || 'Error al cargar las preguntas',
            )
          } else if (updateQuestion.fulfilled.match(result)) {
            loadIndicator({
              indicatorId: String(question.indicatorId),
              currentPage: 1,
              pageSize: 25,
            })
            showAlert('success', 'Indicador creado correctamente')
          }
        })
        .finally(() => {
          stopLoading()
        })
    } catch (error: any) {
      showAlert('error', error.message || 'Ocurrió un error inesperado')
    }
  }

  const copyQuestionsIndicator = (questions: IPayloadCopyQuestion) => {
    try {
      startLoading()
      dispatch(copyQuestions(questions))
        .then((result: any) => {
          if (copyQuestions.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) || 'Error al copiar las preguntas',
            )
          } else if (copyQuestions.fulfilled.match(result)) {
            loadIndicator({
              indicatorId: String(questions.copyFrom.indicatorId),
              currentPage: 1,
              pageSize: 25,
            })
            showAlert('success', 'Preguntas copiadas correctamente')
          }
        })
        .finally(() => {
          stopLoading()
        })
    } catch (error: any) {
      showAlert('error', error.message || 'Ocurrió un error inesperado')
    }
  }
  const removeQuestionsIndicator = (question: IPayloadRemoveQuestion[]) => {
    try {
      startLoading()
      dispatch(removeQuestion(question))
        .then((result: any) => {
          if (removeQuestion.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) || 'Error al cargar las preguntas',
            )
          } else if (removeQuestion.fulfilled.match(result)) {
            loadIndicator({
              indicatorId: String(question[0].indicatorId),
              currentPage: 1,
              pageSize: 25,
            })
            showAlert('success', 'Indicador creado correctamente')
          }
        })
        .finally(() => {
          stopLoading()
        })
    } catch (error: any) {
      showAlert('error', error.message || 'Ocurrió un error inesperado')
    }
  }

  return {
    filter,
    indicatorCreate,
    responseLoadIndicator,
    indicators,
    indicator,
    copyQuestionsIndicator,
    updateIndicator,
    loadIndicator,
    setfilterIndicators,
    loadIndicators,
    createNewIndicator,
    updateIndicatorCreate,
    resetFiltersIndicators,
    updateQuestionIndicator,
    removeQuestionsIndicator,
  }
}
