// src/hooks/useUserIAM.ts
import { useDispatch, useSelector } from 'react-redux'
import { RootState, AppDispatch } from 'src/redux/index'
import {
  setUserIAM,
  resetUserIAM,
  setUpdatedUserIAM,
} from 'src/redux/IAM/AdminUser/UserIAMSlice'
import { getUsers } from 'src/services/IAM/AdminUser/AdminUser.service'
import { useAlert } from 'src/contexts/AlertContext'
import { IUser } from 'src/services/IAM/AdminUser/AdminUser.interface'
import { usePagination } from '../Components/Pagination/usePagination'
import useLoading from 'src/hooks/useLoading'

export const useUserIAM = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { userIAM, usersIAM, updatedUserIAM } = useSelector(
    (state: RootState) => state.userIAM,
  )
  const { handleTotalItems } = usePagination()
  const { showAlert } = useAlert()
  const { startLoading, stopLoading } = useLoading()
  const loadUsers = (pagination: {
    currentPage?: number
    pageSize?: number
    type?: 'all'
  }) => {
    startLoading()
    dispatch(getUsers())
      .then((result) => {
        stopLoading()
        if (getUsers.rejected.match(result)) {
          showAlert(
            'error',
            String(result.payload) || 'Error al cargar los usuarios',
          )
        } else if (getUsers.fulfilled.match(result)) {
          showAlert('success', 'Usuarios cargados correctamente')
          if (pagination) {
            console.log(result.payload.details, 'result.payload.detailsss')
            console.log('usuarios cargados correctamente')

            handleTotalItems(result.payload.details)
          }
        }
      })
      .finally(() => {
        stopLoading()
      })
  }
  const setUserIAMAdmin = (user: IUser) => {
    dispatch(setUserIAM(user))
  }
  const setUserUpdatedIAMAdmin = (user: IUser) => {
    dispatch(setUpdatedUserIAM(user))
  }

  const clearUserIAM = () => {
    dispatch(resetUserIAM())
  }

  return {
    userIAM,
    usersIAM,
    updatedUserIAM,
    setUserUpdatedIAMAdmin,
    setUserIAMAdmin,
    clearUserIAM,
    loadUsers,
  }
}
