/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Box,
  Chip,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import AlertDialog from 'src/components/Dialog'
import GlobalLoading from 'src/components/Loadings/GlobalLoading'
import { useAlert } from 'src/contexts/AlertContext'
import { useAdminGroup } from 'src/hooks/IAM/AdminGroups/useAdminGroups'
import { useAdminPermission } from 'src/hooks/IAM/AdminPermission/useAdminPermission'
import { useUserIAM } from 'src/hooks/IAM/AdminUser/useUserIAM'
import useLoading from 'src/hooks/useLoading'
import { IGroup } from 'src/services/IAM/AdminGroup/AdminGroup.interface'
import { IUser } from 'src/services/IAM/AdminUser/AdminUser.interface'
import {
  disableUserOrEnable,
  updateUser,
} from 'src/services/IAM/AdminUser/AdminUser.service'
import StepperModal from './StepperModal'
import { usePagination } from 'src/hooks/IAM/Components/Pagination/usePagination'
import useIsMounted from 'src/hooks/useIsMounted'

const TableUsers = () => {
  const { groups } = useAdminGroup()
  const { showAlert } = useAlert()
  const { loadUsers, usersIAM } = useUserIAM()
  const { isLoading, startLoading, stopLoading } = useLoading()
  const {
    selectedPermissions,
    permissions,
    loadPermissions,
    setSelectPermission,
  } = useAdminPermission()
  const isMounted = useIsMounted()

  const [userUpdated, setUserUpdated] = useState<IUser>({
    userId: 0,
    userDocId: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    type: '',
    active: true,
  })
  const {
    currentPage,
    rowsPerPage,
    rowsPerPageOptions,
    totalItems,
    handlePageChange,
    handleRowsPerPageChange,
  } = usePagination()
  useEffect(() => {
    if (!isMounted) return
    handlePageChange(null, 1)
  }, [isMounted])
  const handleDisableOrEnable = async ({
    user,
    active,
  }: {
    user: IUser
    active: boolean
  }) => {
    try {
      startLoading()
      await disableUserOrEnable({
        id: user.userId ?? 0,
        active,
      })
      loadUsers({
        currentPage,
        pageSize: rowsPerPage,
      })
      showAlert('success', 'Usuario eliminado correctamente')
    } catch (error) {
      showAlert('error', String(error))
    } finally {
      stopLoading()
    }
  }

  const handleUpdateUser = async () => {
    try {
      startLoading()
      console.log('userUpdated', userUpdated)
      await updateUser(userUpdated)
      loadUsers({
        currentPage,
        pageSize: rowsPerPage,
      })
      showAlert('success', 'Usuario actualizado correctamente')
    } catch (error) {
      showAlert('error', String(error))
    } finally {
      stopLoading()
    }
  }

  const handleChangeInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    property: string,
  ) => {
    setUserUpdated({ ...userUpdated, [property]: e.target.value } as IUser)
  }

  return (
    <Fragment>
      {isLoading && <GlobalLoading />}
      <Paper>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>ID usuario</TableCell>
                <TableCell>Documento ID</TableCell>
                <TableCell>Nombres</TableCell>
                <TableCell>Apellidos</TableCell>
                <TableCell>Correo</TableCell>
                <TableCell>Celular</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersIAM && usersIAM.length ? (
                usersIAM.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell>{user.userId}</TableCell>
                    <TableCell>{user.userDocId}</TableCell>
                    <TableCell>{user.firstName}</TableCell>
                    <TableCell>{user.lastName}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.phone}</TableCell>
                    <TableCell>{user.type}</TableCell>
                    <TableCell
                      sx={{
                        color: user.active ? 'green' : 'red',
                      }}
                    >
                      {user.active ? 'Activo' : 'Inactivo'}
                    </TableCell>
                    <TableCell align="right">
                      {user.active ? (
                        <Stack
                          gap={2}
                          direction="row"
                          justifyContent={'flex-end'}
                        >
                          <AlertDialog
                            title={`Editar usuario ${user.firstName} ${user.lastName}`}
                            buttonLabel={'Editar'}
                            disagreeLabel="Cancelar"
                            agreeLabel="Editar"
                            onOpen={() => setUserUpdated(user)}
                            onAgree={async () => {
                              handleUpdateUser()
                            }}
                            children={
                              <form>
                                <Box>
                                  <TextField
                                    sx={{ mt: 2 }}
                                    fullWidth
                                    id="outlined-basic"
                                    label="Nombre"
                                    variant="outlined"
                                    value={userUpdated?.firstName || ''}
                                    onChange={(e) =>
                                      handleChangeInput(e, 'firstName')
                                    }
                                    required
                                  />
                                  <TextField
                                    sx={{ mt: 2 }}
                                    fullWidth
                                    id="outlined-basic"
                                    label="Apellido"
                                    variant="outlined"
                                    value={userUpdated?.lastName || ''}
                                    onChange={(e) =>
                                      handleChangeInput(e, 'lastName')
                                    }
                                    required
                                  />
                                  <TextField
                                    sx={{ mt: 2 }}
                                    fullWidth
                                    id="outlined-basic"
                                    label="Correo"
                                    variant="outlined"
                                    value={userUpdated?.email || ''}
                                    onChange={(e) =>
                                      handleChangeInput(e, 'email')
                                    }
                                    required
                                  />
                                  <TextField
                                    sx={{ mt: 2 }}
                                    fullWidth
                                    id="outlined-basic"
                                    label="Celular"
                                    variant="outlined"
                                    value={userUpdated?.phone || ''}
                                    onChange={(e) =>
                                      handleChangeInput(e, 'phone')
                                    }
                                    required
                                  />
                                  <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={['backoffice', 'client']}
                                    sx={{ my: 2 }}
                                    className="form-group-input"
                                    value={userUpdated?.type}
                                    fullWidth
                                    onChange={(event, newValue) => {
                                      setUserUpdated({
                                        ...(userUpdated as IUser),
                                        type: newValue as string,
                                      })
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Tipo de usuario"
                                      />
                                    )}
                                  />
                                  <Autocomplete
                                    sx={{
                                      flex: 1,
                                    }}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={groups ?? []}
                                    multiple
                                    value={userUpdated.groups ?? []}
                                    onChange={(event, newValue: IGroup[]) =>
                                      setUserUpdated({
                                        ...userUpdated,
                                        groups: newValue,
                                      })
                                    }
                                    getOptionLabel={(option) =>
                                      `${option.groupName}`
                                    }
                                    renderTags={(tagValue, getTagProps) =>
                                      tagValue.map((option: IGroup, index) => (
                                        <Chip
                                          label={`${option.groupName}`}
                                          {...getTagProps({ index })}
                                          key={index}
                                        />
                                      ))
                                    }
                                    isOptionEqualToValue={(option, value) => {
                                      return (
                                        option.groupName === value.groupName
                                      )
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params} label="Grupos" />
                                    )}
                                  />
                                  <AlertDialog
                                    title="Permisos"
                                    buttonLabel={`Agregar permisos: ${selectedPermissions.length}`}
                                    maxWidth="lg"
                                    children={
                                      <Stack direction={'row'}>
                                        <StepperModal
                                          handlerSelectedPermissions={
                                            setSelectPermission
                                          }
                                          selectedPermissions={
                                            selectedPermissions
                                          }
                                          permissionsProp={permissions}
                                          loadPermissions={loadPermissions}
                                        />
                                      </Stack>
                                    }
                                  />
                                </Box>
                              </form>
                            }
                          />
                          <AlertDialog
                            buttonLabel={'desactivar'}
                            title={`¿Estás seguro de activar el usuario "${user.firstName} ${user.lastName}"?`}
                            disagreeLabel="Cancelar"
                            agreeLabel="desactivar"
                            color="error"
                            onAgree={() => {
                              handleDisableOrEnable({
                                user,
                                active: false,
                              })
                            }}
                            nameToDelete={user.userDocId}
                            withConfirmation
                            onDisagree={() => console.log('user no eliminado')}
                            children={
                              <div>
                                <p>
                                  Al activar el usuario
                                  <strong> {user.userDocId}</strong> podrá
                                  acceder de nuevo al sistema
                                </p>
                                <p>
                                  Escriba <strong>{user.userDocId}</strong> para
                                  confirmar
                                </p>
                              </div>
                            }
                          />
                        </Stack>
                      ) : (
                        <AlertDialog
                          buttonLabel={'activar'}
                          title={`¿Estás seguro de activar el user "${user.firstName} ${user.lastName}"?`}
                          disagreeLabel="Cancelar"
                          agreeLabel="activar"
                          color="success"
                          onAgree={() => {
                            handleDisableOrEnable({
                              user,
                              active: true,
                            })
                          }}
                          nameToDelete={user.userDocId}
                          withConfirmation
                          onDisagree={() => console.log('user no eliminado')}
                          children={
                            <div>
                              <p>
                                Al activar el user{' '}
                                <strong>{user.userDocId}</strong> no podrá
                                acceder al sistema
                              </p>
                              <p>
                                Escriba <strong>{user.userDocId}</strong> para
                                confirmar
                              </p>
                            </div>
                          }
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No hay usuarios
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={usersIAM.length + 1}
          rowsPerPage={rowsPerPage}
          page={currentPage - 1}
          onPageChange={(event, newPage) => {
            handlePageChange(event, newPage + 1)

            loadUsers({
              currentPage: newPage + 1,
              pageSize: rowsPerPage,
            })
          }}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>
    </Fragment>
  )
}
export default TableUsers
