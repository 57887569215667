import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
} from '@mui/material'
import React, { Fragment } from 'react'
import { useStrategies } from 'src/hooks/Dashboard/Strategies/useStrategies'
import {
  IGetStrategyResponse,
  IStrategy,
} from 'src/services/dashboard/strategies/strategies.interface'

const TableIndicator = () => {
  const { strategies } = useStrategies()
  const extractStrategies = (data: IGetStrategyResponse[]): IStrategy[] => {
    let result: IStrategy[] = []

    data.forEach((group) => {
      const strategyType = group.strategyType
      group.strategies.forEach((strategy) => {
        result.push({
          ...strategy,
          strategyType,
        })
      })
    })

    return result
  }
  return (
    <div>
      <Fragment>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Nombres</TableCell>
                  <TableCell>Ecuación</TableCell>
                  <TableCell>Descripción</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell align="right">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {extractStrategies(strategies).length ? (
                  extractStrategies(strategies)?.map((strategy, index) => (
                    <TableRow key={index}>
                      <TableCell>{strategy.strategyName}</TableCell>
                      <TableCell>{strategy.detailLogical}</TableCell>
                      <TableCell>{strategy.description}</TableCell>
                      <TableCell>{strategy.strategyType}</TableCell>
                      <TableCell align="right" width={'200px'}>
                        <Stack
                          direction={'row'}
                          gap={1}
                          justifyContent={'flex-end'}
                        >
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      No hay usuarios
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Fragment>
    </div>
  )
}

export default TableIndicator
