import { refreshToken } from 'src/services/auth/auth.service'
let isRefreshingToken = false;
let refreshTokenPromise: Promise<boolean> | null = null;

export const formatToCOP = (amount: number): string => {
  // Separador de miles en el formato colombiano sin decimales
  try {
    // Convertir el número a una cadena con formato colombiano sin decimales
    const formattedNumber: string = amount.toLocaleString('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    return formattedNumber
  } catch (error) {
    return '-'
  }
}

//METODO STANDART PARA HACER PETICIONES FETCH
export async function fetchWithToken(
  url: string,
  options: RequestInit = {},
): Promise<Response> {
  try {
    const response = await fetch(url, {
      ...options,
      headers: getHeaders(), // Incluye los headers necesarios con el token
    });

    // Verificar si el token ha expirado
    if (response.status === 403) {
      // Maneja el token expirado
      return handleTokenExpiration(url, options);
    }

    return response;
  } catch (error) {
    throw new Error('Error al realizar la solicitud');
  }
}

async function handleTokenExpiration(
  url: string,
  options: RequestInit,
): Promise<Response> {
  if (!isRefreshingToken) {
    isRefreshingToken = true;
    refreshTokenPromise = refreshToken(); // Inicia el refresco del token
  }

  try {
    // Espera a que se refresque el token
    const success = await refreshTokenPromise;

    if (!success) {
      // Si no se pudo refrescar, limpiar sesión
      localStorage.clear();
      throw new Error('No se pudo refrescar el token');
    }

    // Reintentar la solicitud original con el nuevo token
    return fetchWithToken(url, options);
  } catch (error) {
    // Manejo adicional en caso de fallo en el refresco
    window.location.href = '/login';
    throw error;
  } finally {
    // Resetear el estado para permitir futuros refrescos
    isRefreshingToken = false;
    refreshTokenPromise = null;
  }
}

//FUNCION PARA RESFRESCAR EL TOKEN

//Headers API
export function getHeaders(): Record<string, string> {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }

  const accessToken = localStorage.getItem('accessToken')
  // const refreshToken = localStorage.getItem('refreshToken')
  // const session = localStorage.getItem('session')
  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`
  }
  // if (refreshToken) {
  //   headers['Refresh-Token'] = `Bearer ${refreshToken}`
  // }
  // if (session) {
  //   headers['Session'] = `Bearer ${session}`
  // }

  return headers
}

export const getParamsToURL = (params: any): string => {
  try {
    if (params === null) {
      return ''
    }

    const cleanParams: any = {}

    Object.keys(params).forEach((key) => {
      const value = (params as any)[key]
      if (value !== null && value !== '') {
        cleanParams[key] = value
      }
    })

    return new URLSearchParams(cleanParams).toString()
  } catch (error) {
    return ''
  }
}

export const cleanRequestBody = (body: any): any => {
  try {
    if (body === null) {
      return null
    }

    Object.keys(body).forEach((key) => {
      if (body[key] === null) {
        delete body[key]
      }
    })

    Object.keys(body).forEach((key) => body[key] === '' && delete body[key])

    return body
  } catch (error) {
    return null
  }
}
