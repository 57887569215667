import { fetchWithToken, getHeaders, getParamsToURL } from 'src/utils/utils'
import { IGroup } from './AdminGroup.interface'
import { createAsyncThunk } from '@reduxjs/toolkit'

const BASE_URL = process.env.REACT_APP_API || 'NO_URL'

export const creategroup = createAsyncThunk(
  'permission/creategroup',
  async (payload: IGroup, { rejectWithValue }) => {
    try {
      const response = await fetchWithToken(`${BASE_URL}/iam/group/create`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
      })
      const data = await response.json()
      if (!response.ok && response) {
        throw new Error(
          data.error.message || '-- A ocurrido un error inesperado --',
        )
      }
      return data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)

/**
 * Retrieves the groups from the server.
 * @param options - Optional parameters for the request.
 * @param options.type - Specifies the type of groups to retrieve. Defaults to true.
 * @returns A Promise that resolves to the array of groups.
 * @throws If an error occurs during the request or if the response is not successful.
 */
export const getGroups = createAsyncThunk(
  'permission/getGroups',
  async (
    {
      type,
      currentPage,
      pageSize,
    }: { type?: boolean; currentPage?: number; pageSize?: number },
    { rejectWithValue },
  ) => {
    try {
      const paramsString = getParamsToURL({ currentPage, pageSize })
      console.log(paramsString, 'paramsString')

      const response = await fetchWithToken(
        `${BASE_URL}/iam/group/get-groups?${paramsString}`,
        {
          method: 'GET',
          headers: getHeaders(),
        },
      )
      const data = await response.json()
      if (!response.ok) {
        throw new Error(data.error.message)
      }

      return data.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)

/**
 * Deletes a group by its ID.
 * @param id - The ID of the group to delete.
 * @returns A Promise that resolves to the deleted group data.
 * @throws If an error occurs during the deletion process.
 */
export const deleteGroup = createAsyncThunk(
  'permission/deleteGroup',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await fetchWithToken(
        `${BASE_URL}/iam/group/delete/${id}`,
        {
          method: 'DELETE',
          headers: getHeaders(),
        },
      )

      const data = await response.json()
      if (!response.ok) {
        throw new Error(
          data.error.message || '-- A ocurrido un error inesperado --',
        )
      }

      return data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)

/**
 * Updates a group with the specified name and permissions.
 * @param {IGroup} group - The group object containing the name and permissions to update.
 * @returns {Promise<any>} - A promise that resolves to the updated group data.
 * @throws {Error} - If an error occurs during the update process.
 */
export const updateGroup = createAsyncThunk(
  'permission/updateGroup',
  async (payload: IGroup, { rejectWithValue }) => {
    try {
      const response = await fetchWithToken(
        `${BASE_URL}/iam/group/update/${payload.groupId}`,
        {
          method: 'PUT',
          headers: getHeaders(),
          body: JSON.stringify(payload),
        },
      )
      const data = await response.json()
      if (!response.ok) {
        throw new Error(
          data.error.message || '-- A ocurrido un error inesperado --',
        )
      }

      return data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)
