// src/features/user/permissionSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  INPermission,
  IPermissionCreate,
} from 'src/services/IAM/AdminPermissions/AdminPermissions.interface'
import { IPermissionState } from './permission.interface'
import {
  createPermission,
  getPermissions,
  deletePermission,
} from 'src/services/IAM/AdminPermissions/AdminPermissions.service'

const initialState: IPermissionState = {
  newPermission: {
    condition: '{"allow": "*"}',
    description: '',
    endpointId: 0,
    modules: [],
  },
  permission: {
    appName: '',
    completeEndpoint: '',
    condition: '',
    methods: [],
    modules: [],
    permissionId: 0,
    public: false,
  },
  updatedPermission: {
    condition: '',
    description: '',
    endpointId: 0,
    modules: [],
  },
  permissions: [],
  selectedPermissions: [],
  loading: false,
  error: null,
}

const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    setPermission: (state, action: PayloadAction<INPermission>) => {
      state.permission = action.payload
    },
    setNewPermission: (state, action: PayloadAction<IPermissionCreate>) => {
      state.newPermission = action.payload
    },
    resetPermission: () => initialState,
    selectPermission: (state, action: PayloadAction<INPermission>) => {
      //CHANGE ACTIVE STATUS and ADD TO SELECTED PERMISSIONS
      const index = state.permissions.findIndex(
        (p) => p.permissionId === action.payload.permissionId,
      )
      state.permissions[index].active = !state.permissions[index].active
      state.selectedPermissions = state.permissions.filter((p) => p.active)
    },
    updatePermission: (state, action: PayloadAction<IPermissionCreate>) => {
      state.updatedPermission = action.payload
    },

    resetPermissions: (state) => {
      state.permissions = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getPermissions.fulfilled,
        (state, action: PayloadAction<{ data: INPermission[] }>) => {
          state.loading = false
          state.permissions = action.payload.data
        },
      )
      .addCase(getPermissions.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(
        createPermission.fulfilled,
        (state, action: PayloadAction<INPermission>) => {
          state.loading = false
          state.permissions.push(action.payload)
        },
      )
      .addCase(
        createPermission.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false
          state.error = action.payload
        },
      )
      .addCase(
        deletePermission.fulfilled,
        (state, action: PayloadAction<number>) => {
          state.loading = false
          state.permissions = state.permissions.filter(
            (p) => p.permissionId !== action.payload,
          )
        },
      )
      .addCase(
        deletePermission.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false
          state.error = action.payload
        },
      )
  },
})

export const {
  setNewPermission,
  resetPermission,
  setPermission,
  selectPermission,
  updatePermission,
} = permissionSlice.actions
export default permissionSlice.reducer
