import { Middleware } from '@reduxjs/toolkit';
import { messageReceived, webSocketConnected, webSocketDisconnected } from './webScoketSlice';

const webSocketMiddleware: Middleware = (store) => {
    let socket: WebSocket | null = null;

    return (next) => (action: any) => {
        switch (action.type) {
            case 'webSocket/connect':
                if (socket) {
                    socket.close();
                }

                // Crear la conexión WebSocket
                socket = new WebSocket(`${process.env.REACT_APP_WEBSOCKET
                    }/socket.io?token=${localStorage.getItem('accessToken')}`);

                // Manejar la conexión abierta
                socket.onopen = () => {
                    socket?.send(JSON.stringify({ action: 'getConnectionId' }))

                };

                // Manejar los mensajes recibidos
                socket.onmessage = (event) => {
                    const data = JSON.parse(event.data);
                    if (data?.data?.connectionId) {
                        localStorage.setItem(
                            'connectionId',
                            data?.data?.connectionId ?? '',
                        )
                        console.log('ConnectionId:', data?.data?.connectionId);

                        store.dispatch(webSocketConnected());
                    } else {
                        try {
                            store.dispatch(messageReceived(data))
                        } catch (error) {
                            store.dispatch(webSocketDisconnected());
                            console.error('Error al recibir mensaje', error)
                        }
                    }
                };

                // Manejar cierre de la conexión
                socket.onclose = () => {
                    store.dispatch(webSocketDisconnected());
                };
                break;

            case 'webSocket/disconnect':
                if (socket) {
                    socket.close();
                }
                break;

            case 'webSocket/sendMessage':
                if (socket) {
                    socket.send(JSON.stringify(action.payload));
                }
                break;

            default:
                break;
        }

        return next(action);
    };
};

export default webSocketMiddleware;
