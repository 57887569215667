import React, { createContext, useContext, useState } from 'react'
import { IModule } from 'src/components/Sidebar/Sidebar.Interface'

interface SelectedModulesContextType {
  selectedModules: IModule[]
  addSelectedModule: (module: IModule) => void
  removeSelectedModule: (module: IModule) => void
}

const SelectedModulesContext = createContext<SelectedModulesContextType>({
  selectedModules: [],
  addSelectedModule: () => {},
  removeSelectedModule: () => {},
})

export const SelectedModulesProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [selectedModules, setSelectedModules] = useState<IModule[]>([])

  const addSelectedModule = (module: IModule) => {
    console.log([...selectedModules, module], '[...selectedModules, module]')

    setSelectedModules([...selectedModules, module])
  }

  const removeSelectedModule = (moduleToRemove: IModule) => {
    setSelectedModules(
      selectedModules.filter((module) => module !== moduleToRemove),
    )
  }

  return (
    <SelectedModulesContext.Provider
      value={{ selectedModules, addSelectedModule, removeSelectedModule }}
    >
      {children}
    </SelectedModulesContext.Provider>
  )
}

export const useSelectedModules = () => {
  return useContext(SelectedModulesContext)
}
