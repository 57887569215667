// src/hooks/useStrategies.ts
import { useDispatch, useSelector } from 'react-redux'
import { RootState, AppDispatch } from 'src/redux/index'
import { useAlert } from 'src/contexts/AlertContext'
import useLoading from 'src/hooks/useLoading'
import {
  setFilter,
  setStrategy,
} from 'src/redux/Dashboard/Strategies/StrategiesSlice'
import {
  IStrategy,
  IFilterStrategies,
} from 'src/services/dashboard/strategies/strategies.interface'
import {
  createStrategy,
  getStrategies,
} from 'src/services/dashboard/strategies/strategies.service'

export const useStrategies = () => {
  const { showAlert } = useAlert()
  const { startLoading, stopLoading } = useLoading()
  const dispatch = useDispatch<AppDispatch>()
  const { strategy, strategies, filter } = useSelector(
    (state: RootState) => state.strategy,
  )
  const loadStrategies = (pagination: {
    currentPage?: number
    pageSize?: number
    indicatorId?: number
    channelId?: number
  }) => {
    try {
      startLoading()
      dispatch(getStrategies(pagination))
        .then((result: any) => {
          if (getStrategies.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) || 'Error al cargar las estrategias',
            )
          } else if (getStrategies.fulfilled.match(result)) {
            showAlert('success', 'Estrategias cargadas correctamente')
          }
        })
        .finally(() => {
          stopLoading()
        })
      showAlert('success', 'Estrategias cargadas correctamente')
    } catch (error: any) {
      showAlert('error', error.message || 'Ocurrió un error inesperado')
    }
  }

  const createNewStrategy = (strategy: IStrategy) => {
    try {
      dispatch(createStrategy(strategy))
        .then((result: any) => {
          stopLoading()
          if (createStrategy.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) || 'Error al cargar las preguntas',
            )
          } else if (createStrategy.fulfilled.match(result)) {
            showAlert('success', 'Preguntas cargadas correctamente')
          }
        })
        .finally(() => {
          stopLoading()
        })
      showAlert('success', 'Estrategia creada correctamente')
    } catch (error: any) {
      showAlert('error', error.message || 'Ocurrió un error inesperado')
    }
  }

  const setStrategiesFilter = (filter: IFilterStrategies) => {
    dispatch(setFilter(filter))
  }
  const updateStrategy = (strategy: IStrategy) => {
    dispatch(setStrategy(strategy))
  }

  return {
    strategy,
    strategies,
    filter,
    createNewStrategy,
    loadStrategies,
    setStrategiesFilter,
    updateStrategy,
  }
}
