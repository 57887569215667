import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchWithToken, getHeaders, getParamsToURL } from 'src/utils/utils'
import { IFilter } from 'src/redux/Dashboard/KPIForm/KPIForm.interface'
import {
  IFilterIndicators,
  IPayloadAsociateQuestions,
} from 'src/services/dashboard/indicator/indicator.interface'

const BASE_URL = process.env.REACT_APP_API || 'NO_URL'

//ENDPONINT SHOULD BE PLURAL
export const getQuestiosForm = createAsyncThunk(
  'questions/getQuestiosForm',
  async (params: IFilter, { rejectWithValue }) => {
    try {
      const paramsString = getParamsToURL(params)
      const response = await fetchWithToken(
        `${BASE_URL}/kpis/question/get-questions?${paramsString}`,
        {
          method: 'GET',
          headers: getHeaders(),
        },
      )
      const data = await response.json()
      if (!response.ok && response) {
        throw new Error(
          data.error.message || '-- A ocurrido un error inesperado --',
        )
      }
      console.log(data.data, ' data.data')

      return data.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)
export const associateQuestionsToIndicator = createAsyncThunk(
  'questions/associateQuestionsToIndicator',
  async (params: IPayloadAsociateQuestions, { rejectWithValue }) => {
    try {
      const response = await fetchWithToken(
        `${BASE_URL}/kpis/question/associate-questions-to-indicator`,
        {
          method: 'POST',
          headers: getHeaders(),
          body: JSON.stringify(params),
        },
      )
      const data = await response.json()
      if (!response.ok && response) {
        throw new Error(
          data.error.message || '-- A ocurrido un error inesperado --',
        )
      }
      console.log(data.data, ' data.data')

      return data.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message)
    }
  },
)
