// src/features/user/KPIFormSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IFilter, IFiltersForm, IKPIChartState } from './Charts.interface'
import { getFilters } from 'src/services/KPI/KPIForm/KPIForm.service'
import { getChartKPI } from 'src/services/KPI/Charts/Charts.service'
import { IChartKPI } from 'src/services/KPI/Charts/Charts.interface'

const initialState: IKPIChartState = {
  charts: [],
  filter: {
    areaName: '',
    monthNumber: 0,
    periodId: 0,
    quarterId: 0,
    channelId: 0,
  },
  filters: [],
  error: null,
  loading: false,
}

const KPIFormSlice = createSlice({
  name: 'KPIForm',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<IFilter>) => {
      state.filter = { ...state.filter, ...action.payload }
    },
    resetFilters: (state) => {
      state.filter = initialState.filter
    },
    resetCharts: (state) => {
      state.charts = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getFilters.fulfilled,
        (state, action: PayloadAction<IFiltersForm[]>) => {
          state.loading = false
          state.filters = action.payload
        },
      )
      .addCase(getFilters.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(
        getChartKPI.fulfilled,
        (state, action: PayloadAction<IChartKPI[]>) => {
          state.loading = false
          state.charts = action.payload
        },
      )
      .addCase(getChartKPI.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false
        state.error = action.payload
      })
  },
})

export const { setFilter, resetFilters, resetCharts } = KPIFormSlice.actions
export default KPIFormSlice.reducer
