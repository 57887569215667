/* eslint-disable react-hooks/exhaustive-deps */
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Autocomplete, Button, Stack, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useIndicators } from 'src/hooks/Dashboard/Indicators/useIndicators'
import { useKPIForm } from 'src/hooks/Dashboard/KPIForm/useKPIForm'
import { usePagination } from 'src/hooks/IAM/Components/Pagination/usePagination'
import useIsMounted from 'src/hooks/useIsMounted'
import CreateIndicator from './CreateInditcator'

const FiltersIndicator = () => {
  const [open, setOpen] = useState(false)
  const isMounted = useIsMounted()
  const { filters, loadFilters } = useKPIForm()
  const { currentPage, rowsPerPage } = usePagination()
  const { loadIndicators, filter, setfilterIndicators, resetFiltersIndicators } = useIndicators()
  useEffect(() => {
    if (!isMounted) return
    loadFilters({
      includeGeneral: true,
    })
    loadIndicators({
      currentPage,
      pageSize: rowsPerPage,
    })
    resetFiltersIndicators()
  }, [isMounted])
  return (
    <div>
      <Stack direction={'row'} justifyContent={'flex-end'} gap={2} my={2}>
        <Button
          onClick={() => setOpen(true)}
          variant="contained"
          color="primary"
        >
          Crear Indicador
        </Button>
      </Stack>
      <Stack direction={'row'} justifyContent={'flex-end'} gap={2} my={2}>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            loadIndicators({
              ...filter,
              currentPage,
              pageSize: rowsPerPage ?? 25,
            })
          }}
        >
          <Stack direction={'row'} marginBlock={4} gap={2}>
            <Autocomplete
              sx={{
                width: '200px',
              }}
              inputValue={filter.areaName ?? ''}
              clearIcon={null}
              options={filters ?? []}
              onChange={(event, newValue) => {
                setfilterIndicators({
                  ...filter,
                  areaName: newValue?.areaName ?? '',
                })
              }}
              getOptionLabel={(option) => option.areaName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Area"
                  placeholder="Selecciona un area"
                />
              )}
            />

            {filters?.find((f) => f.areaName === filter.areaName)?.channels
              ?.length ? (
              <>
                {filters?.find((f) => f?.areaName === filter?.areaName)
                  ?.channels?.[0].channelName === 'General' ?? {} ? null : (
                  <Autocomplete
                    sx={{
                      width: '200px',
                    }}
                    clearIcon={null}
                    value={
                      filters
                        ?.find((f) => f?.areaName === filter?.areaName)
                        ?.channels?.find(
                          (channel) => channel.channelId === filter.channelId,
                        ) ?? null
                    }
                    options={
                      filters?.find((f) => f.areaName === filter.areaName)
                        ?.channels ?? []
                    }
                    onChange={(event, newValue) => {
                      setfilterIndicators({
                        ...filter,
                        channelId: newValue?.channelId ?? 0,
                      })
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.channelName === value.channelName
                    }
                    getOptionLabel={(option) => option.channelName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Canal"
                        placeholder="Selecciona un canal"
                      />
                    )}
                  />
                )}
              </>
            ) : null}
            {/* indicators */}
            <TextField
              sx={{
                width: '200px',
              }}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Buscar indicador"
              label="Indicador"
              value={filter.indicatorName ?? ''}
              onChange={(event) => {
                setfilterIndicators({
                  ...filter,
                  indicatorName: event.target.value,
                })
              }}
            />

            <Button variant="contained" color="primary" type="submit">
              <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" />
            </Button>
          </Stack>
        </form>
      </Stack>
      <CreateIndicator open={open} setOpen={setOpen} />
    </div>
  )
}

export default FiltersIndicator
