// src/hooks/useAdminPermission.ts
import { useDispatch, useSelector } from 'react-redux'
import { RootState, AppDispatch } from 'src/redux/index'
import {
  INPermission,
  IPermissionCreate,
} from 'src/services/IAM/AdminPermissions/AdminPermissions.interface'
import {
  setNewPermission,
  resetPermission,
  selectPermission,
  updatePermission,
} from 'src/redux/IAM/AdminPermission/permissionSlice'
import {
  getPermissions,
  createPermission,
  deletePermission,
  updatePermissions,
} from 'src/services/IAM/AdminPermissions/AdminPermissions.service'
import { useAlert } from 'src/contexts/AlertContext'
import { usePagination } from '../Components/Pagination/usePagination'
import useLoading from 'src/hooks/useLoading'

export const useAdminPermission = () => {
  const { showAlert } = useAlert()
  const { startLoading, stopLoading } = useLoading()
  const dispatch = useDispatch<AppDispatch>()
  const { handleTotalItems, currentPage, rowsPerPage } = usePagination()
  const {
    permissions,
    newPermission,
    selectedPermissions,
    updatedPermission,
    permission,
    loading,
    error,
  } = useSelector((state: RootState) => state.permissions)

  const loadPermissions = (pagination: {
    currentPage?: number
    pageSize?: number
    type?: 'all'
  }) => {
    try {
      startLoading()
      dispatch(getPermissions(pagination))
        .then((result: any) => {
          stopLoading()
          if (getPermissions.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) || 'Error al crear la app',
            )
          } else if (getPermissions.fulfilled.match(result)) {
            showAlert('success', 'Permisos obtenidos correctamente')

            if (pagination) {
              console.log('Permisos obtenidos correctamente')
              handleTotalItems(result.payload.details)
            }
          }
        })
        .finally(() => {
          stopLoading()
        })
    } catch (error) {
      showAlert('error', 'Error al obtener los permisos')
    }
  }
  const createPermissionAdmin = (permission: IPermissionCreate) => {
    try {
      startLoading()
      dispatch(createPermission(permission))
        .then((result: any) => {
          stopLoading()
          if (createPermission.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) || 'Error al crear la app',
            )
          } else if (createPermission.fulfilled.match(result)) {
            showAlert('success', 'App creada correctamente')
            loadPermissions({})
          }
        })
        .finally(() => {
          stopLoading()
        })
    } catch (error) {
      showAlert('error', 'Error al crear el permiso')
    }
  }

  const setNewPermissionAdmin = (permission: IPermissionCreate) => {
    dispatch(setNewPermission(permission))
  }

  const setSelectPermission = (permission: INPermission) => {
    dispatch(selectPermission(permission))
  }

  const setUpdatePermissionAdmin = (permission: IPermissionCreate) => {
    dispatch(updatePermission(permission))
  }

  const updatePermissionAdmin = (permission: IPermissionCreate) => {
    try {
      startLoading()
      dispatch(updatePermissions(permission))
        .then((result: any) => {
          stopLoading()
          if (updatePermissions.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) || 'Error al crear la app',
            )
          } else if (updatePermissions.fulfilled.match(result)) {
            showAlert('success', 'Permiso actualizado correctamente')
          }
        })
        .finally(() => {
          stopLoading()
        })
    } catch (error) {
      showAlert('error', 'Error al actualizar el permiso')
    }
  }

  const removePermission = (id: number) => {
    try {
      startLoading()
      dispatch(deletePermission(id))
        .then((result: any) => {
          stopLoading()
          if (deletePermission.rejected.match(result)) {
            showAlert(
              'error',
              String(result.payload) || 'Error al eliminar el permiso',
            )
          } else if (deletePermission.fulfilled.match(result)) {
            showAlert('success', 'Permiso eliminado correctamente')
            loadPermissions({
              currentPage: currentPage,
              pageSize: rowsPerPage,
            })
          }
        })
        .finally(() => {
          stopLoading()
        })
    } catch (error) {
      showAlert('error', 'Error al eliminar el permiso')
    }
  }

  const clearPermissions = () => {
    dispatch(resetPermission())
  }

  return {
    selectedPermissions,
    permissions,
    permission,
    newPermission,
    loading,
    error,
    updatedPermission,
    createPermissionAdmin,
    setNewPermissionAdmin,
    loadPermissions,
    setSelectPermission,
    setUpdatePermissionAdmin,
    updatePermissionAdmin,
    removePermission,
    clearPermissions,
  }
}
