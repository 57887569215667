import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import React from "react"
import { DummyData } from "src/components/TableMUI/TableMui.interface"
import { formatToCOP } from "src/utils/utils"

const TableInfoSales = ({ dataModule }: { dataModule: DummyData | null }) => {
    return (<div>
        {/* TABLE INFO */}
        <Stack direction={{ sm: 'row' }} gap={2}>
            <TableContainer component={Paper}>
                <Table
                    sx={{ '& thead th:nth-of-type(1)': { width: '40%' } }}
                    size={'small'}
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell component={'th'} scope="row">
                                <strong>Ventas</strong>
                            </TableCell>
                            <TableCell>
                                <strong>Total</strong>
                            </TableCell>
                            <TableCell>
                                <strong>Monto</strong>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>keypago</TableCell>
                            <TableCell>{dataModule?.totalSales ?? '-'}</TableCell>
                            <TableCell>
                                {formatToCOP(dataModule?.totalSalesValue || 0) ?? '-'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Totto</TableCell>
                            <TableCell>{dataModule?.totalSalesTotto ?? '-'}</TableCell>
                            <TableCell>
                                {formatToCOP(dataModule?.totalSalesValueTotto || 0) ?? '-'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Anulaciones mismo periodo</TableCell>
                            <TableCell>{dataModule?.totalCancellations ?? '-'}</TableCell>
                            <TableCell>
                                {formatToCOP(dataModule?.totalCancellationsValue || 0) ??
                                    '-'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Anulaciones periodos anteriores</TableCell>
                            <TableCell>
                                {dataModule?.totalPastCancellations ?? '-'}
                            </TableCell>
                            <TableCell>
                                {formatToCOP(
                                    dataModule?.totalPastCancellationsValue || 0,
                                ) ?? '-'}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer component={Paper}>
                <Table
                    sx={{ '& thead th:nth-of-type(1)': { width: '40%' } }}
                    size={'small'}
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell component={'th'} scope="row">
                                <strong>Diferencias</strong>
                            </TableCell>
                            <TableCell>
                                <strong>Total</strong>
                            </TableCell>
                            <TableCell>
                                <strong>Monto</strong>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Está en keypago y no en Totto</TableCell>
                            <TableCell>
                                {dataModule?.totalIsKpButIsNotTotto ?? '-'}
                            </TableCell>
                            <TableCell>
                                {formatToCOP(dataModule?.amountIsKpButIsNotTotto || 0) ??
                                    '-'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Está en Totto y no en keypago </TableCell>
                            <TableCell>
                                {dataModule?.totalIsNotKpButIsTotto ?? '-'}
                            </TableCell>
                            <TableCell>
                                {formatToCOP(dataModule?.amountIsNotKpButIsTotto || 0) ??
                                    '-'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Está en keypago y está en Totto pero con otro medio de pago
                            </TableCell>
                            <TableCell>
                                {dataModule?.totalIskpIsTottoWithOtherPaymentMethod ?? '-'}
                            </TableCell>
                            <TableCell>
                                {formatToCOP(
                                    dataModule?.amountIsKpIsTottoWithOtherPaymentMethod || 0,
                                ) ?? '-'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Está en keypago y está en Totto con medio de pago keypago
                                pero por otro monto
                            </TableCell>
                            <TableCell>
                                {dataModule?.totalIsKpTottoWithAmountDifferent ?? '-'}
                            </TableCell>
                            <TableCell>
                                {formatToCOP(
                                    dataModule?.amountIsKpTottoWithAmountDifferent || 0,
                                ) ?? '-'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Facturas de keypago que no se pudieron imputar
                            </TableCell>
                            <TableCell>
                                {dataModule?.totalIsKpInvoiceNumberTottoNan ?? '-'}
                            </TableCell>
                            <TableCell>
                                {formatToCOP(
                                    dataModule?.amountIsKpInvoiceNumberTottoNan || 0,
                                ) ?? '-'}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
        {/* TABLE INFO END */}
    </div>
    )

}
export default TableInfoSales